import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class ExternalRedirectGuard implements CanActivate {
    constructor() { }

    public canActivate(route: ActivatedRouteSnapshot): boolean {
        window.location.href = route.paramMap.get('externalUrl');

        return true;
    }
}
