import { createAction } from '@ngrx/store';

import { LanguageModel } from './state';


export const SetTranslationAction = createAction(
    '[Translation] Translate Request',
    (languageConfig: LanguageModel) => ({
        languageConfig,
    }),
);

export const SetTranslationLanguageAction = createAction(
    '[Translation] Set Language', (language: string) => ({
        language,
    }));

export const HyrdateTranslationsAction = createAction(
    '[Translation] Hydrate translations from state'
);
