import { Action, ActionReducer } from '@ngrx/store';

import * as HydrationActions from './actions';

function isHydrateSuccess(action: Action): action is ReturnType<typeof HydrationActions.HydrateSuccess> {
    return action.type === HydrationActions.HydrateSuccess.type;
}

function isHydrateRemove(action: Action): action is ReturnType<typeof HydrationActions.ClearState> {
    return action.type === HydrationActions.ClearState.type;
}

export const hydrationMetaReducer = (reducer: ActionReducer<unknown>): ActionReducer<unknown> => {
    return (state, action) => {
        if (isHydrateRemove(action)) {
            return reducer(undefined, action);
        }

        if (isHydrateSuccess(action)) {
            return action.state;
        } else {
            return reducer(state, action);
        }
    };
};
