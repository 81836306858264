import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, map } from 'rxjs';

import { logInfo } from '../../../utils/log';
import { HeaderState } from '../header.state';

@Injectable({
    providedIn: 'root',
})
export class HeaderService {
    private readonly headerStateSource = new BehaviorSubject<HeaderState>({
        state: {
            isVisible: true,
            contentIsVisible: true,
            faceSheetVisible: false,
            toolbarVisible: false
        },
    });
    public readonly headerState$ = this.headerStateSource.asObservable();

    constructor(private readonly router: Router) {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.router.routerState.root),
                map((route) => {
                    let mergedState: HeaderState = { state: {}, components: [] };
                    while (route.firstChild) {
                        route = route.firstChild;
                        const newState = route.snapshot.data.header;

                        mergedState = {
                            ...mergedState,
                            ...newState,
                        };
                    }

                    const currentState = route.snapshot.data.header;

                    const states = { mergedState, currentState };
                    logInfo('Header Service', `traversing state tree ->`, states);

                    return states;
                }),
            )
            .subscribe(({ mergedState, currentState }) => {
                if (currentState?.state?.stopPropagation) {
                    this.updateState({
                        ...currentState,
                        state: {
                            ...currentState.state,
                            faceSheetVisible: false,
                        },
                    });
                } else {
                    this.updateState({
                        ...mergedState,
                        state: {
                            ...mergedState.state,
                            faceSheetVisible: false,
                        },
                    });
                }
            });
    }

    public get faceSheetVisible(): boolean | undefined {
        const headerState = this.headerStateSource.getValue();

        return headerState ? headerState.state.faceSheetVisible : undefined;
    }

    public updateState(newState: HeaderState): void {
        const header = this.getHeaderState();

        newState = {
            ...header,
            ...newState,
            state: { ...header.state, ...newState.state },
        };

        this.headerStateSource.next(newState);
    }

    public getHeaderState() {
        return this.headerStateSource.getValue();
    }
}
