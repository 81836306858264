import { Component, Input } from '@angular/core';
import { EstimateGroupModel } from '../../models/estimateGroupModel';

@Component({
    selector: 'provider-group-total',
    templateUrl: './provider-group-total.component.html',
    styleUrls: ['../../scss/styles.scss']
})
export class ProviderGroupTotalComponent {
    @Input() providerGroup: EstimateGroupModel | undefined;

    public get show() {
        if (!this.providerGroup) {
            return false;
        }

        if (this.providerGroup.totalVisible) {
            return true;
        }

        return false;
    }
}
