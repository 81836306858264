<rs-modal #modal (show)="show($event)" [config]="modalConfig">
  <div class="pb-4">
    <div class="bg-white pb-4 text-center px-4">

    </div>
    <div class="px-4">
      <p class="py-3 text-center mb-0">All locations</p>
      <button *ngFor="let location of activeLocations"
        (click)="options.locationId === location.id ? selectActiveLocation(null): selectActiveLocation(location)"
        class="rs-btn-link p-4 bg-white w-100 mb-3 text-left d-flex"
        [class]="{'btn-location-selectable':  options.locationId !== location.id, 'btn-location-selected': options.locationId === location.id} ">
        <div>
          <div class="fw-700 color-secondary fs-18 line-height-1">{{ location.name }}</div>
          <div *ngIf="options.locationId !== location.id; else elseBlock;">Select this location</div>
          <ng-template #elseBlock>
            <div>
              Remove Selection
            </div>
          </ng-template>
        </div>
      </button>
      <div *ngIf="inactiveLocations.length">
        <hr>
        <button *ngFor="let location of inactiveLocations" (click)="selectInactiveLocation(location)"
          class="rs-btn-link btn-location-selectable p-4 bg-white w-100 mb-3 text-left d-flex inactive-location">
          <div>
            <div class="fw-700 color-secondary fs-18 line-height-1">{{ location.name }}</div>
            <div>Select this location</div>
          </div>
        </button>
      </div>
    </div>
  </div>
</rs-modal>
