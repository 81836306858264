import { UntypedFormBuilder, ValidationErrors, UntypedFormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { mapValues } from 'lodash-es';

import { ValidationRegistryService } from '../validation-registry/validation-registry.service';
import { RsFormModel, RsFormGroup, RsConsumerGroup } from '../schema/rs-form.schema';
import { RsFormControl } from '../schema/rs-form-control.schema';
import { RsGridConfig } from '../schema/rs-grid.schema';

@Injectable()
export class RsFormFactory {
  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly validationRegistry: ValidationRegistryService
  ) {
  }

  public create(model: RsFormModel | RsGridConfig) {
    const form = this.createForm(model);

    return this.fb.group(form);
  }

  public createForm(model: RsFormModel | RsGridConfig) {

    return mapValues(model, (field: any, fieldName) => {
      if (!['form-group', 'group'].includes(field.type)) {
        return this.createControl(field);
      }
      const settings = field.type === 'group' ? {
        validator: (group: UntypedFormGroup): ValidationErrors => {
          const controls = Object.keys(group.controls);
          const oneValid = controls.find(control =>
            group.controls[control].valid && group.controls[control].value);
          if (oneValid) {
            controls.forEach((control: string) => {
              group.controls[control].setErrors(null);
            });

            return null;
          }

          return { oneValid: true };
        },
      } : undefined;

      return this.fb.group(this.createGroup(field), settings);
    });
  }

  public createGroup(group: RsFormGroup | RsConsumerGroup) {
    return mapValues(group.controls, (field: any, fieldName) => {
      if (!['form-group', 'group'].includes(field.type)) {
        return this.createControl(field as RsFormControl);
      }

      return this.fb.group(this.createGroup(field));
    });
  }

  public createControl(input: RsFormControl) {
    const validations = this.validationRegistry.getControlValidators(
      input.validations
    );

    // TODO ? Type this? It's the form control config.
    // tslint:disable-next-line:no-any
    const config: any = {};

    if (input.elementOptions) {
      config.value = input.elementOptions.value;
      config.disabled = !!input.elementOptions.disabled;
    }

    return this.fb.control(config, validations);
  }
}
