import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import * as Sentry from '@sentry/angular';
import { NgxGaugeModule } from 'ngx-gauge';

import { RsComponentModule, RsComponent } from '@revspringinc/rs-component';
import { RsSharedModule, UnsupportedBrowserService } from '@revspringinc/shared';
import { RsCoreModule, ComponentRegistryService, ValidationRegistryService } from '@revspringinc/rs-core';
import { ModalsModule } from '@revspringinc/rs-shared';
import { ModalRegistryService } from '@revspringinc/rs-shared';
import { RsFormsModule } from '@revspringinc/rs-shared';
import { PaUiModule } from '@pa-ui';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LandingComponent } from './landing/landing.component';
import { ModalComponents } from './modal.components';
import { EstimateServiceComponent } from './estimate-service/estimate-service.component';
import { AddInsurancePlanModal } from './modals/add-insurance-plan/add-insurance-plan.component';
import { SelectServicesModal } from './modals/select-services/select-services.component';
import { SelectLocationModal } from './modals/select-location/select-location.component';
import { SelectProceduresModal } from './modals/select-procedures/select-procedures.component';
import { CompareCostsModal } from './modals/compare-costs/compare-costs.component';
import { SearchProceduresComponent } from './modals/search-procedures/search-procedures.component';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { RSTranslateLoader } from './translate.loader';

import { BASE_PATH, ApiModule, Configuration, SessionService, ProcedureService } from '@shopping';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MainComponent } from './main/main.component';
import { OrganizationService } from './main/organization.service';
import { ShoppingSessionService } from './main/session.service';
import { SessionResolverService } from './main/session-resolver.service';
import { SiteConfigService } from '@shopping';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SessionToolbarComponent } from './session-toolbar/session-toolbar.component';
import { ContactMeModal } from './modals/contact-me/contact-me.component';
import { TextEstimateSummaryModal } from './modals/text-estimate-summary/text-estimate-summary.component';
import { LocalStorageService } from './services/local-storage.service';
import { FaqComponent } from './faq/faq.component';
import { LocationDeterminationService } from './services/location-determination.service';
import { NoticeMessageModal } from './modals/notice-message/notice-message.component';
import { ServerSentEventsService } from './services/sse.service';
import { CookieDisclaimerService } from './services/cookie-disclaimer.service';
import { CookieDisclaimerModal } from './modals/cookie-disclaimer/cookie-disclaimer.component';
import { AmaNoticeModal } from './modals/ama-notice/ama-notice.component';
import { ApiService } from './services/api.service';
import { EstimateModule } from '@estimate';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { EstimateServicePlaceHolderComponent } from './estimate-service-placeholder/estimate-service-placeholder.component';
import { UnsupportedBrowserGuard } from './guards/unsupported-browser.guard';
import { NgxMaskModule } from 'ngx-mask';
import { RootStoreModule } from './+store/root.store.module';
import { EesApiOptions, EncounterGraphQLModule } from '@encounter';

const modalRegistry = ModalRegistryService.configure((registry) => {
    return registry.load(ModalComponents);
});

const componentRegistry = ComponentRegistryService.configure((registry) => {
    registry = registry.load(RsComponent);

    return registry;
});

const validationRegistry = ValidationRegistryService.configure((registry) => {
    return registry;
});

const browserSupportInitializerFn = (unsupportedBrowserService: UnsupportedBrowserService) => {
    return () => {
        return unsupportedBrowserService.storeBrowserSupport();
    };
};

const UICore = {
    ngModule: RsCoreModule,
    providers: [
        {
            provide: 'ComponentRegistryService',
            useValue: componentRegistry,
        },
        {
            provide: ValidationRegistryService,
            useValue: validationRegistry,
        },
    ],
};
@NgModule({
    declarations: [
        PageNotFoundComponent,
        AppComponent,
        MainComponent,
        HeaderComponent,
        FooterComponent,
        LandingComponent,
        EstimateServiceComponent,
        AddInsurancePlanModal,
        SelectServicesModal,
        SelectLocationModal,
        SelectProceduresModal,
        CompareCostsModal,
        ContactMeModal,
        TextEstimateSummaryModal,
        SearchProceduresComponent,
        SessionToolbarComponent,
        FaqComponent,
        NoticeMessageModal,
        CookieDisclaimerModal,
        AmaNoticeModal,
        EstimateServicePlaceHolderComponent,
    ],
    imports: [
        ApiModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        RsSharedModule,
        RsCoreModule,
        FormsModule,
        ReactiveFormsModule,
        RootStoreModule,
        EncounterGraphQLModule,
        NgxMaskModule.forRoot(),
        TranslateModule.forRoot({
            loader: { provide: TranslateLoader, useClass: RSTranslateLoader },
        }),
        {
            ngModule: ModalsModule,
            providers: [
                {
                    provide: ModalRegistryService,
                    useValue: modalRegistry,
                },
            ],
        },
        ToastrModule.forRoot(),

        ContentLoaderModule,
        RsFormsModule,
        RsComponentModule,
        UICore,
        ClickOutsideModule,
        ScrollingModule,
        NgxGaugeModule,
        EstimateModule,
        PaUiModule,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: true,
            }),
        },
        {
            provide: BASE_PATH,
            useValue: environment.shoppingApi,
        },
        {
            provide: SessionService,
            deps: [HttpClient],
            useFactory: (httpClient: HttpClient) => {
                return new SessionService(httpClient, environment.shoppingApi, new Configuration());
            },
        },
        {
            provide: ProcedureService,
            deps: [HttpClient],
            useFactory: (httpClient: HttpClient) => {
                return new ProcedureService(httpClient, environment.shoppingApi, new Configuration());
            },
        },
        {
            provide: SiteConfigService,
            deps: [HttpClient],
            useFactory: (httpClient: HttpClient) => {
                return new SiteConfigService(httpClient, environment.shoppingApi, new Configuration());
            },
        },
        {
            provide: ApiService,
            deps: [HttpClient],
            useFactory: (httpClient: HttpClient) => {
                return new ApiService(httpClient, new Configuration(), environment.shoppingApi);
            },
        },
        OrganizationService,
        ShoppingSessionService,
        SessionResolverService,
        LocalStorageService,
        LocationDeterminationService,
        ServerSentEventsService,
        CookieDisclaimerService,
        UnsupportedBrowserGuard,
        {
            provide: APP_INITIALIZER,
            useFactory: browserSupportInitializerFn,
            multi: true,
            deps: [UnsupportedBrowserService],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
