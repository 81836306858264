import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { logInfo } from '../../../utils/log';
import { SidenavContentComponent } from './sidenav-content.component';
import { SidenavComponent } from './sidenav.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule
    ],
    declarations: [
        SidenavComponent,
        SidenavContentComponent,
    ],
    exports: [
        SidenavComponent,
        SidenavContentComponent
    ]
})
export class SidenavModule {
    constructor() {
        logInfo('[PA-UI] SidenavModule', 'Initialized');
    }
}
