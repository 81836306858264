<span *ngIf="item?.text && !item?.text?.editable"
      [ngClass]="item?.text?.class"
      class="w-full">{{item.text.content | translate}}</span>

<div *ngIf="item?.text && item?.text?.editable"
     class="flex w-full rounded-sm group hover:shadow-lg outline-2 outline-offset-8 outline-transparent focus-within:outline-blue-600"
     (click)="!editing && edit()">

      <input #input
             [ngClass]="item?.text?.class"
             [ngModel]="item?.text?.content | translate"
             [required]="item?.text?.required"
             (blur)="onEdit($event)"
             class="w-full p-0 m-0 overflow-visible bg-transparent border-none "
             type="text" />
</div>
