import { Component, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { createPopper } from '@popperjs/core';

import { HeaderDropdownOption } from '@revspringinc/shared';

import { BaseComponent } from '../../../base-component';
import { SidenavService } from '../../sidenav/sidenav.service';
import { HeaderService } from '../header.service';

@Component({
    selector: 'pa-header-dropdown',
    templateUrl: './header-dropdown.component.html',
    styleUrls: ['../../../../../../scss/styles.scss'],
})
export class HeaderDropdownComponent extends BaseComponent implements AfterViewInit {
    public dropdownPopoverShow = false;
    @Input() public dropdownOptions: HeaderDropdownOption[] | undefined;
    @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef | undefined;
    @ViewChild("popoverDropdownRef", { static: false }) popoverDropdownRef: ElementRef | undefined;

    constructor(
        public readonly headerService: HeaderService,
        public readonly sideNavService: SidenavService,
    ) {
        super();
    }

    public ngAfterViewInit() {
        if(this.btnDropdownRef && this.popoverDropdownRef) {
            createPopper(
                this.btnDropdownRef.nativeElement,
                this.popoverDropdownRef.nativeElement,
                {
                    placement: 'bottom-end',
                }
            );
        }
    }

    public toggleDropdown = () => {
        if (window.innerWidth < 1024) {
            this.sideNavService.toggle();
        }
        this.dropdownPopoverShow = !this.dropdownPopoverShow;
    }
}
