import { uniqueId } from 'lodash-es';
import { Injectable } from "@angular/core";

import { RsForm } from '../models/form.model';

export interface FormConfig {
    name: string;
    method: string;
    theme?: any;
    submitButtonVisible?: boolean;
    buttonText?: string;
    existing?: boolean;
    isGroup?: boolean;
}

@Injectable()
export class FormsService {
    public create(elements, config: FormConfig) {
        const form: RsForm = {
            type: 'form',
            action: config.method,
            name: config.name,
            submitButtonVisible: typeof config.submitButtonVisible === 'undefined' ?
                true : config.submitButtonVisible,
            model: {},
            theme: config.theme,
            buttonText: config.buttonText,
            existing: !!config.existing,
        };
        elements.forEach(current => {
            const control = { ...current, DataKey: current.DataKey || uniqueId('el-') };
            return form.model[control.DataKey] = config.isGroup ?
                this.createGroups(control) : this.createInput(control);
        });
        return form;
    }

    public fixFormat(obj, fullLower = false) {
        if (Array.isArray(obj)) return obj;
        const formatted = {};
        Object.keys(obj).forEach((key) => {
            let correctKey = fullLower ? key.toLowerCase() : key[0].toLowerCase() + key.slice(1);
            if (correctKey === 'regex') {
                correctKey = 'pattern';
            }
            if (typeof obj[key] === 'object' && (obj !== null)) {
                formatted[correctKey] = this.fixFormat(obj[key]);
            } else {
                formatted[correctKey] = obj[key];
            }
        });
        return formatted;
    }

    private createGroups(control) {
        if (control.Type !== 'group') return this.createInput(control);
        const id = control.DataKey;
        const group = {
            name: id,
            id,
            type: 'group',
            controls: {},
        };
        control.Elements.forEach((el) => {
            group.controls[el.DataKey] = this.createInput(el);
        });
        return group;
    }

    public createInput(control) {
        let validations;
        if (control.Validation) {
            validations = this.fixFormat(control.Validation, true);
        }

        return {
            type: control.Type.toLowerCase(),
            name: control.DataKey,
            id: control.DataKey,
            elementOptions: {
                label: control.Label,
                value: control.value || '',
                type: control.InputType,
                placeholder: control.PlaceHolder,
                autocomplete: '',
                required: control.required,
                options: control.Type === 'Select' ? control.Options.map(element => element.Value) : null
            },
            mask: (control?.Regex.Mask ?? control?.regex?.mask),
            prefix: control.Regex && control.Type === 'Input' ? control.Regex.Prefix : undefined,
            dropSpecialCharacters: control.Regex && control.Type === 'Input' ? control.Regex.DropSpecialCharacters : undefined,
            maxLength: control.Type === 'Input' ? control.MaxLength : null,
            minLength: control.Type === 'Input' ? control.MinLength : null,
            validations,
            theme: {
                outerWrap: control.Theme && control.Theme.Class ? ` ${control.Theme.Class}` : '',
                control: 'form-control form-control-lg rounded-0',
                labelClass: ''
            }
        };
    }

    // public validateAllFormFields(formGroup: FormGroup) {
    //   formGroup.markAsTouched({ onlySelf: true });
    //   Object.keys(formGroup.controls).forEach(field => {
    //     const control = formGroup.get(field);
    //     if (control instanceof FormControl) {
    //       control.markAsTouched({ onlySelf: true });
    //     } else if (control instanceof FormGroup) {
    //       this.validateAllFormFields(control);
    //     }
    //   });
    // }
}
