<ng-container *ngIf="discounts?.length">
  <div style="border-top: dotted 2px" class="mt-2 mb-1"></div>

  <ng-container *ngFor="let discount of discounts">
    <div
      class="flex flex-col items-center justify-between print:service-group-total bg-th-accent md:flex-row fs-20 fw-700 md:px-6 px-5 py-2 ">
      <div class="text-sm md:text-lg">
        {{ discount.accountSelfpay.display }}
      </div>
      <div class="">-{{ discount.discountApplied | currency }}</div>
    </div>
  </ng-container>
</ng-container>
