import { Injectable } from '@angular/core';

@Injectable()
export class UnsupportedBrowserService {
    private readonly localStorageKey = 'usingSupportedBrowser';

    constructor() { this.storeBrowserSupport(); }

    public storeBrowserSupport() {
        let usingSupportedBrowser = true;

        if (navigator.userAgent.indexOf('MSIE') !== -1
            || navigator.appVersion.indexOf('Trident/') > -1) {
            usingSupportedBrowser = false;
        }

        localStorage.setItem(this.localStorageKey, JSON.stringify(usingSupportedBrowser));
    }

    public browserIsSupported(): boolean {
        if (!localStorage.getItem(this.localStorageKey)) {
            this.storeBrowserSupport();
        }

        return JSON.parse(localStorage.getItem(this.localStorageKey));
    }
}
