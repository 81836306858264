import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { EstimateRenderData } from '@encounter';
import { PDFDocumentProxy, PdfViewerComponent } from 'ng2-pdf-viewer';
import { EstimateSection } from '../../../models/estimate-section';

@Component({
    selector: 'estimate-gfe-renderer',
    templateUrl: './good-faith-estimate-renderer.component.html',
    styleUrls: ['../../../scss/styles.scss'],
    styles: [
        `
            :host {
                display: contents;
            }
            pdf-viewer {
                height: 100vh;
                position: relative;
            }
            .ng2-pdf-viewer-container {
                overflow: hidden;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        `,
    ],
})
export class GoodFaithEstimateRendererComponent implements OnInit {
    @Input() public headerSections: EstimateSection[] | undefined;
    @Input() public additionalSections: EstimateSection[] | undefined;
    @Input() public disclaimerSections: EstimateSection[] | undefined;
    @Input() public estimate: EstimateRenderData | undefined;
    @Input() public estimateChange: ((estimate: EstimateRenderData) => Promise<boolean>) | undefined;
    @Input() public appName!: 'patientPortal' | 'pricing' | 'registrar';

    @ViewChild('viewer', { read: ElementRef, static: false })
    private pdfComponent: ElementRef | undefined = undefined;
    private pdfDocumentProxy: PDFDocumentProxy | undefined = undefined;

    private pageIdsRendered: number[] = [];

    public showDownloadToolbar: boolean = false;
    public src: ArrayBufferLike | undefined = undefined;

    public startsWith(value: string, key: string): boolean {
        return value ? value.startsWith(key) : false;
    }

    public toText(value: any) {
        return value.toString().replace(/\$/g, '');
    }

    public totalPages: number = 0;
    public height: string = '';

    public ngOnInit(): void {
        this.showDownloadToolbar = ['patientPortal', 'pricing'].includes(this.appName);
        this.updateSource();
    }

    public onLoaded(pdf: PDFDocumentProxy) {
        this.pdfDocumentProxy = pdf;
    }
    public onPagesInitialized(event: any) {}

    public pageRendered(type: string, event: any): void {
        if (type === 'page-rendered') {
            const pageId = event.source.id;

            if (this.pageIdsRendered.includes(pageId)) {
                return;
            }

            this.pageIdsRendered.push(pageId);

            this.totalPages += 1;
            this.height = `${event.source.height * this.totalPages + 32}px`;

            if (this.pdfComponent) {
                this.pdfComponent.nativeElement.style.height = this.height;
                this.pdfComponent.nativeElement.getElementsByClassName('ng2-pdf-viewer-container')[0].style.height =
                    this.height;
            }
        }
    }

    public async download() {
        const createSaveLink = (function () {
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');

            return function (data: Blob, fileName: string) {
                const blob = new Blob([data], { type: 'octet/stream' });
                const url = window.URL.createObjectURL(blob);

                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
            };
        })();

        const pdfData = await this.pdfDocumentProxy?.getData();

        if (pdfData) {
            let blob = new Blob([pdfData.buffer], {
                type: 'application/pdf',
            });

            createSaveLink(blob, 'estimate' + this.estimate?.estimateNumber + '.pdf');
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.estimate?.currentValue) {
            this.updateSource();
        }
    }

    updateSource() {
        if (this.estimate?.pdfData) {
            this.src = this._base64ToArrayBuffer(this.estimate.pdfData);
        }
    }

    _base64ToArrayBuffer(base64: string) {
        var binary_string = base64.replace(/\\n/g, '');
        binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }
}
