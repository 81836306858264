<div id="toast"
     [@animateToast]=currentState
     aria-live="polite"
     aria-atomic="true"
     class="toast-wrapper">
    <div class="text-right close-wrapper">
      <span (click)="closeToast()"
            (keydown.enter)="closeToast()"
            [tabindex]="tabindex"
            aria-label="Close"
            role="button"
            class="close-toast">
      </span>
    </div>
    <div class="toast show">
        <div class="toast-body toast-alt fw-400 p-0"
             [ngStyle]="{
                'background-image': data?.backgroundImage ? 'url(' + data.backgroundImage + ')' : ''
             }">
            <div class="inner-body d-flex flex-column flex-md-row align-items-start text-center text-md-left">
                <h4 *ngIf="data?.title"
                    class="title fw-700 pb-2">{{ data.title | translate }}</h4>
                <div>
                    {{ data?.message | translate }}
                </div>
            </div>
        </div>
    </div>
</div>