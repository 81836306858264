import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { BYPASS_ERROR } from './bypass-error.decorator';

/**
 * Configuration for the performed HTTP requests
 */
@Injectable({ providedIn: 'root' })
export class ApiRequestConfiguration {
    private nextIgnoreErrorInterceptor = false;


    ignoreErrorInterceptor(ignore: boolean = true): void {
        this.nextIgnoreErrorInterceptor = ignore;
    }

    clear(): void {
        this.nextIgnoreErrorInterceptor = false;
    }

    apply(req: HttpRequest<any>): HttpRequest<any> {

        req.context.set(BYPASS_ERROR, this.nextIgnoreErrorInterceptor);

        return req;
    }
}
