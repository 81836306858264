import { APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloLink, InMemoryCache, split } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';

import { logEvent } from '@pa-ui';
import { authLink, buildHeader } from './auth-link';

export const createLinks = (
    accessTokenStorageKey: string,
    eesApiPath: string,
    eesApiWs: string,
    httpLink: HttpLink,
) => {
    const auth = authLink(accessTokenStorageKey);
    const apiPath = eesApiPath;
    const httpUri = `${apiPath}/graphql`;
    const wsUri = `${eesApiWs}/graphql`;

    const eesApiHttpLink = ApolloLink.from([auth, httpLink.create({ uri: httpUri })]);
    const connectionParams = buildHeader(accessTokenStorageKey);

    if ('headers' in connectionParams) {
        logEvent('[EES-API] Subscription', 'connectionParams set to =>', connectionParams);

        const eesApiWebsocketLink = new WebSocketLink({
            uri: wsUri,
            options: {
                reconnect: true,
                lazy: true,
                connectionParams,
            },
        });

        // If we have an auth header, we can enable subscriptions
        const link = split(
            // split based on operation type
            ({ query }) => {
                const definitionNode = getMainDefinition(query);
                return definitionNode.kind === 'OperationDefinition' && definitionNode.operation === 'subscription';
            },
            eesApiWebsocketLink,
            eesApiHttpLink,
        );

        return link;
    }

    // If auth header is not present, return the HTTP link only.
    return eesApiHttpLink;
};

export const EesApiOptions = (eesApiPath: string, eesApiWs: string, accessTokenStorageKey: string) => {
    return {
        provide: APOLLO_NAMED_OPTIONS,
        useFactory: (httpLink: HttpLink): NamedOptions => {
            const link = createLinks(accessTokenStorageKey, eesApiPath, eesApiWs, httpLink);

            return {
                'ees-api': {
                    cache: new InMemoryCache(),
                    link,
                },
            };
        },
        deps: [HttpLink],
    };
};
