import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rs-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor() {

  }

  public async ngOnInit(): Promise<void> {

  }
}
