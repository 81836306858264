import { Injectable } from '@angular/core';
import { OrganizationLocation, ProcedureGroupModel } from '@shopping';
import * as _ from 'lodash-es';

@Injectable()
export class LocationDeterminationService {
    private selectedProcedures;
    private locations;

    public determineLocationLimitations(options: { selectedProcedures: ProcedureGroupModel[], locations: OrganizationLocation[] })
        : { activeList: OrganizationLocation[], inactiveList: OrganizationLocation[] } {

        this.selectedProcedures = options.selectedProcedures;
        this.locations = options.locations;

        if (!this.selectedProcedures.length || this.selectedProcedures.every(x => !(x as any).locations.length) ) return { activeList: this.locations, inactiveList: [] };

        const listOfLocationLimitations = [];

        this.selectedProcedures.forEach(selectedProc => {
            const locations = (selectedProc as any).locations;
            if (!locations.length) return;

            locations.forEach(l => {
                if (!listOfLocationLimitations.find(x => x.pgId === selectedProc.id && x.locationId === l.id)) {
                    listOfLocationLimitations.push({ pgId: selectedProc.id, locationId: l.id });
                }
            });
        });

        const groupedLimitations = _.groupBy(listOfLocationLimitations, 'pgId');

        return this.determineGroupedLimitations(groupedLimitations);
    }

    private determineGroupedLimitations(groupedLimitations): { activeList: OrganizationLocation[], inactiveList: OrganizationLocation[] } {
        const conflictedPgs = [];
        let activeLocations = [];
        let inactiveLocations = [];

        for (const [key, value] of Object.entries(groupedLimitations)) {
            const pgArray = (value as any[]);
            const pgId = (key as string);
            const pgLocationLimits = [];

            pgArray.forEach(x => {
                pgLocationLimits.push(x.locationId);
            });

            const conflictedItem = {};
            conflictedItem[`${pgId}`] = pgLocationLimits;

            conflictedPgs.push(conflictedItem);
        }

        if (!conflictedPgs.length) {
            activeLocations = this.locations;
        } else {
            const limitedLocations = [];
            const sharedLocationLimitations = [];
            conflictedPgs.forEach(conflictedPg => {
                const locationConflicts = conflictedPg[Object.keys(conflictedPg)[0]];

                if (locationConflicts.length === 1) {
                    const singleLocation = (locationConflicts[0]);
                    if (!limitedLocations.includes(singleLocation)) limitedLocations.push(singleLocation);
                } else {
                    locationConflicts.forEach(locationId => {
                        const otherItems = conflictedPgs.filter(y => Object.keys(y)[0] !== Object.keys(conflictedPg)[0]);

                        if (!otherItems.every(y => y[Object.keys(y)[0]].some(z => z === locationId))) {
                            if (!limitedLocations.includes(locationId)) limitedLocations.push(location);
                        } else {
                            if (!sharedLocationLimitations.includes(locationId)) sharedLocationLimitations.push(locationId);
                        }
                    });
                }
            });

            if (limitedLocations.length > 1 && !sharedLocationLimitations.length) {
                inactiveLocations = this.locations;
            } else if (limitedLocations.length === 1 && !sharedLocationLimitations.length) {
                activeLocations = [this.locations.find(x => x.id === limitedLocations[0])];
                inactiveLocations = this.locations.filter(x => x.id !== limitedLocations[0]);
            } else {
                activeLocations = this.locations.filter(x => sharedLocationLimitations.includes(x.id));
                inactiveLocations = this.locations.filter(x => !sharedLocationLimitations.includes(x.id));
            }
        }

        return { activeList: activeLocations, inactiveList: inactiveLocations };
    }
}
