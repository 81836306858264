import { Injectable } from '@angular/core';
import moment from 'moment';

interface TransactionStyle {
    value?: string | undefined;
    label?: string | undefined;
    [key: string]: string | undefined;
}

type TransactionLabel = string | undefined;
type TransactionValue = number;

type LineItem = {
    label: TransactionLabel;
    value: TransactionValue;
    class?: TransactionStyle;
};

type TotalLineItem = {
    type: 'total';
} & LineItem;

type PaymentLineItem = {
    type: 'payment';
    pending: boolean;
} & LineItem;

type Transaction = {
    label: TransactionLabel;
    value: TransactionValue;
    class?: TransactionStyle;
    totals: (PaymentLineItem | TotalLineItem)[];
};

type LineItemGroup = {
    lineItems: Transaction[];
    groupTotal?: LineItem;
    total?: number;
};

export interface TransactionSection {
    total: LineItem;
    lineItemGroups: LineItemGroup[];
}

export type Payment = {
    date: string;
    amount: number;
    pending: boolean;
};

type Balance = number | undefined;

export type EncounterPaymentInfo = {
    estimateTotal: number;
    paymentPlanBalance?: Balance;
    accountBalance?: Balance;
    payments: Payment[];
};

@Injectable()
export class PrepaymentService {
    public buildPrepayment(paymentInfo: EncounterPaymentInfo): TransactionSection {
        const balanceGroup = this.balanceGroup(paymentInfo);
        const estimateGroup = this.estimateGroup(paymentInfo);

        const total = Math.max(0, (balanceGroup?.total || 0) + (estimateGroup?.total || 0));

        let totalLine;
        if (!paymentInfo.paymentPlanBalance && !paymentInfo.accountBalance) {
            totalLine = this.payThisNowLine(total);
        } else if (paymentInfo.paymentPlanBalance) {
            totalLine = this.newPaymentPlanBalanceLine(total);
        } else if (paymentInfo.accountBalance) {
            totalLine = this.payThisNowLine(total);
        }

        return {
            total: totalLine,
            lineItemGroups: [estimateGroup, balanceGroup],
        };
    }

    public getPriorPayments(paymentTransaction: TransactionSection) {
        return (paymentTransaction?.lineItemGroups || []).filter((tg) =>
            tg.lineItems.some((tx) =>
                tx.totals.some((t) => {
                    return t.type === 'payment' && t.pending;
                }),
            ),
        ).length;
    }

    private payThisNowLine(total: number): LineItem {
        let label = 'Pay this now for your upcoming visit';
        if (!total) {
            label = 'No payment necessary';
        }

        return {
            label,
            value: total,
            class: { label: 'font-bold text-th-secondary' },
        };
    }

    private newPaymentPlanBalanceLine(total: number): LineItem {
        return {
            label: 'New payment plan balance',
            value: total,
            class: { label: 'font-bold text-th-secondary' },
        };
    }

    private paymentLine(payment: Payment): PaymentLineItem {
        return {
            pending: payment.pending,
            type: 'payment',
            label: `Prior payment (${payment.date})`,
            value: payment.amount,
            class: {
                value: '',
                label: '',
                pending: 'text-gray-700 p-1 py-0.5',
            },
        };
    }

    private estimateGroup(paymentInfo: EncounterPaymentInfo): LineItemGroup {
        const total = paymentInfo.payments.reduce((previous, current) => {
            return previous - current.amount;
        }, paymentInfo.estimateTotal);

        const lineItems = [
            {
                label: 'Estimate of what you will owe for your upcoming visit',
                value: paymentInfo.estimateTotal,
                class: {
                    value: 'font-bold mr-4',
                    label: 'font-bold',
                },
                totals: [
                    ...paymentInfo.payments.map((payment) => this.paymentLine(payment)),
                    this.totalLineItem(total),
                ],
            },
        ].filter((x) => !!x);

        return {
            groupTotal: this.totalLineItem(lineItems.reduce((previous, current) => previous - current.value, 0)),
            lineItems,
            total,
        };
    }

    private balanceGroup(paymentInfo: any): LineItemGroup {
        const lineItems = [this.paymentPlanBalanceLine(paymentInfo), this.accountBalanceLine(paymentInfo)].filter(
            (x) => !!x,
        );

        const total = lineItems.reduce((previous: number, current: Transaction) => {
            return previous + current.value;
        }, 0);

        return {
            lineItems,
            total,
        };
    }

    private paymentPlanBalanceLine(paymentInfo: EncounterPaymentInfo): Transaction {
        if (!paymentInfo.paymentPlanBalance) {
            return null;
        }

        const lineItems = [this.totalLineItem(paymentInfo.paymentPlanBalance)];

        return {
            label: 'Balance on your current payment plan',
            value: paymentInfo.paymentPlanBalance,
            class: {
                value: 'font-bold mr-4',
                label: 'font-bold',
                icon: 'money',
            },
            totals: lineItems,
        };
    }

    private accountBalanceLine(paymentInfo: EncounterPaymentInfo): Transaction {
        if (!paymentInfo.accountBalance) {
            return null;
        }
        const totals = [this.totalLineItem(paymentInfo.accountBalance)];

        return {
            label: 'Account balance as of ' + moment().format('MM/DD/yy'),
            value: paymentInfo.accountBalance,
            class: {
                value: 'font-bold mr-4',
                label: 'font-bold',
            },
            totals,
        };
    }

    private totalLineItem(value: number): TotalLineItem {
        return {
            type: 'total',
            label: 'Total',
            value,
            class: {
                value: 'text-th-primary ',
                label: 'text-th-primary ',
            },
        };
    }
}
