import { createAction, props } from '@ngrx/store';

export enum RouterActionTypes {
    NavigateUrl = '[Router] Navigate to Url',
    NavigateRoute = '[Router] Navigate to Route',
    NavigateToPreviousPage = '[Router] Navigate to Previous Page',
    NavigateExternal = '[Router] Navigate to External URL',
}

export const NavigateUrlAction = createAction(
    RouterActionTypes.NavigateUrl,
    props<{ payload: string; }>()
);

export const NavigateRouteAction = createAction(
    RouterActionTypes.NavigateRoute,
    props<{ commands: any[], extras?: any; }>()
);

export const NavigateToPreviousPage = createAction(
    RouterActionTypes.NavigateToPreviousPage
);

export const NavigateExternalAction = createAction(
    RouterActionTypes.NavigateExternal,
    props<{ url: string; }>()
);
