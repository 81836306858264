<rs-modal #modal
          (show)="show($event)"
          [config]="modalConfig"
          (submit)="send()">

  <div class="container p-3">
    <div *ngIf="!config && !mainForm"
         class="w-100 position-relative">
      <rs-spinner [loading]="true"
                  wrapClass="spinner-border-lg spinner-center">
      </rs-spinner>
    </div>
    <div *ngIf="config && mainForm">
      <form *ngIf="!readyForPreview; else reviewBlock"
            [formGroup]="form"
            (ngSubmit)="send()">
        <div class="flex-grow-1">
          <div class="container">
            <div *ngFor="let group of config.form.cards[0].groups; let i = index; let lastGroup = last;">
              <h3 *ngIf="group.title"
                  class="fs-20 pb-2 fw-700">{{ group.title | translate }}</h3>

              <rs-ui [config]="mainForm[0]"
                     #resolver></rs-ui>

              <hr *ngIf="!lastGroup" />
            </div>

          </div>
        </div>
      </form>

      <ng-template #reviewBlock>
        <div *ngIf="!readyForClosingMessage; else closingMessageBlock;">
          <ng-container *ngIf="!submissionInProgress; else submissionInProgressBlock">
            <dl>
              <dt>{{ config.form.cards[1].groups[0].title | translate }}</dt>
              <dd>{{orgSubmissionPayload?.formFields?.healthInsurance || 'N/A' }}</dd>


              <dt>{{ config.form.cards[1].groups[1].title | translate }}</dt>
              <dd>{{orgSubmissionPayload?.formFields?.memberLastName }}</dd>

              <dt>{{ config.form.cards[1].groups[2].title | translate }}</dt>
              <dd>{{orgSubmissionPayload?.formFields?.memberFirstName }}</dd>

              <dt>{{ config.form.cards[1].groups[3].title | translate }}</dt>
              <dd>{{orgSubmissionPayload?.formFields?.preferredContactMethod }}</dd>

              <dt>{{ config.form.cards[1].groups[4].title | translate }}</dt>
              <dd>{{orgSubmissionPayload?.formFields?.emailAddress || 'N/A' }}</dd>

              <dt>{{ config.form.cards[1].groups[5].title | translate }}</dt>
              <dd>{{orgSubmissionPayload?.formFields?.phoneNumber || 'N/A' }}</dd>

              <div *ngIf="orgSubmissionPayload?.formFields?.currentProcedures?.length">
                <dt>{{ config.form.cards[1].groups[6].title | translate }}</dt>
                <dd *ngFor="let procedure of orgSubmissionPayload?.formFields?.currentProcedures">{{procedure}}</dd>
              </div>

              <dt>{{ config.form.cards[1].groups[7].title | translate }}</dt>
              <dd>{{orgSubmissionPayload?.formFields?.locationName }}</dd>

              <div *ngIf="orgSubmissionPayload?.formFields?.additionalComments">
                <dt>{{ config.form.cards[1].groups[8].title | translate }}</dt>
                <dd>{{orgSubmissionPayload?.formFields?.additionalComments}}</dd>
              </div>

            </dl>
          </ng-container>
          <ng-template #submissionInProgressBlock>
            <div class="container overflow-hidden p-2 mb-2">
              <rs-spinner [loading]="true"
                          wrapClass="spinner-border-lg spinner-center">
              </rs-spinner>
            </div>
          </ng-template>
        </div>

        <ng-template #closingMessageBlock>
          <div>
            {{ config.form.cards[2].groups[0].title | translate}}
          </div>
        </ng-template>

      </ng-template>
    </div>
  </div>
</rs-modal>