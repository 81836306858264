import { Component, Input } from '@angular/core';
import { EstimateServiceGroupModel } from '../../models/estimateServiceGroupModel';

@Component({
    selector: 'service-group-header',
    templateUrl: './service-group-header.component.html',
    styleUrls: [
        '../../scss/styles.scss'
    ]
})
export class ServiceGroupHeaderComponent {
    @Input() public serviceGroup: EstimateServiceGroupModel | undefined;

    public get show() {

        if (!this.serviceGroup) {
            return false;
        }

        if (this.serviceGroup.headerVisible) {
            return true;
        }

        return false;
    }
}
