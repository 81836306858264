import { Component, Output, EventEmitter, Input, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { InputFieldComponent } from '@revspringinc/rs-shared';

@Component({
    selector: 'rs-search-field',
    templateUrl: './rs-search.component.html',
    styleUrls: ['./rs-search.component.scss'],
})
export class RsSearchComponent implements AfterViewInit {
    @Input() public label = '';
    @Input() public id = '';
    @Input() public name: string;
    @Input() public formControl = new UntypedFormControl();
    @Input() public autofocus = false;
    @Input() public minChars = 0;
    @Output() public search = new EventEmitter<any>();
    @Output() public clear = new EventEmitter();

    @ViewChild('inputRef', { static: false }) public inputRef: InputFieldComponent;
    @Input() public value = '';
    public hasSearched = false;
    public isSearchEnabled = this.meetsMinRequirement();

    ngAfterViewInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        if (this.value) {
            setTimeout(() => {
                this.inputRef?.writeValue(this.value);
                this.hasSearched = true;
            });
        }
    }

    public toggleSearch(val: boolean) {
        this.isSearchEnabled = val && this.meetsMinRequirement();
    }

    public onClick(forceSearch = false) {
        if (this.hasSearched && !forceSearch) {
            this.resetValue();
            this.search.emit({ name: this.name, filter: { [this.name]: this.value } });
        } else {
            this.hasSearched = true;

            if (this.meetsMinRequirement()) {
                this.search.emit({ name: this.name, filter: { [this.name]: this.value } });
            }
        }
    }

    public valueChanged(value) {
        if (typeof value !== 'string') {
            return;
        }
        this.value = value;
        this.toggleSearch(true);
    }

    public resetValue() {
        this.inputRef.writeValue('');
        this.value = '';
        this.hasSearched = false;

        this.clear.emit();
    }

    public meetsMinRequirement() {
        return this.value?.length >= this.minChars;
    }
}
