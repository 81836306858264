import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'join' })
export class JoinPipe implements PipeTransform {
    public transform(value: any, key?: string): any {
        if (!value) return '';

        if (key) {
            value = value.map((v) => v[key]);
        }

        return value.join(', ');
    }
}
