<div class="h-100 pt-sm-6 position-relative">
  <div *ngIf="landingConfig" class="container">
    <div class="card card-shadow p-5 col-md-8 col-lg-5 h-auto">
      <ng-container *ngIf="browserIsSupported; else browserNotSupportedBlock;">
        <h1 id="leadTitle"
            class="card-title pb-2">
            {{ landingConfig.landing.title | translate}}
        </h1>
        <p id="leadText"
           class="fs-18 pb-5">
           {{ landingConfig.landing.lead_text | translate}}
        </p>
        <div>
          <button id="toEstimate"
                  (click)="toEstimate()"
                  class="btn btn-primary fw-700 py-3 px-5">
                  {{ landingConfig.landing.action_text | translate}}
          </button>
        </div>
      </ng-container>
      <ng-template #browserNotSupportedBlock>
        <h1 id="leadTitle"
            class="card-title pb-2">
            {{ landingConfig.unsupportedBrowserLanding.title | translate}}
        </h1>
        <p id="leadText"
           class="fs-18 pb-5">
          {{ landingConfig.unsupportedBrowserLanding.lead_text | translate}}
        </p>
      </ng-template>
    </div>
  </div>
  <div class="lead-image-container">
    <img class="lead-image img-fluid"
         width="1223px"
         height="596px"
         [src]="leadImage"
         alt="">
  </div>
</div>
