<div [formGroup]="element.group" [ngClass]="element?.theme?.outerWrap" class="form-group">
  <label [attr.for]="element.elementOptions.id" [ngClass]="element?.theme?.labelClass">
    {{ element.elementOptions.label | translate }}
    <b *ngIf="element.validations?.required" style="color:red">*</b>
    <span *ngIf="element?.tooltip?.display">
      <i class="fa fa-question-circle" aria-hidden="true" placement="right" ngbTooltip="{{element.tooltip.text || 'right'}}"></i>
    </span> :
  </label>
  <textarea [attr.id]="element.id" class="form-control" [formControlName]="element.name" [ngClass]="element?.theme?.control"
    [attr.rows]="element.elementOptions.rows" [attr.cols]="element.elementOptions.cols" [attr.placeholder]="element.elementOptions.placeholder"
    [value]="element.elementOptions.value"></textarea>

  <div class="alert alert-danger" role="alert" *ngIf="element.group.controls[this.element['name']].dirty && !element.group.controls[this.element['name']].pristine && (element.group.controls[this.element['name']].status === 'INVALID')">
    <rs-error-message [fieldForm]="element.group.controls[this.element['name']]" [field]="element"></rs-error-message>
  </div>
</div>
