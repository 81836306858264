import { Injectable, InjectionToken, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Configuration as PreserviceConfiguration } from '@preservice';

export const API_BASE_URL = new InjectionToken<Storage>('ApiBaseUrl');

@Injectable({ providedIn: 'root' })
export class ApiService {
    private readonly basePath: string;
    private readonly defaultHeaders: HttpHeaders = new HttpHeaders();

    constructor(
        private readonly http: HttpClient,
        private readonly configuration: PreserviceConfiguration,
        @Inject(API_BASE_URL) basePath: string,
    ) {
        this.basePath = basePath;
    }

    public getFeatures(xSessionId: string) {
        const sessionHeader = this.defaultHeaders.set('x-sessionid', xSessionId);

        return this.http.get(`${this.basePath}/feature/`, { headers: sessionHeader });
    }
}
