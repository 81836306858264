import { Injectable } from '@angular/core'
import { RsFormComponent } from '../rs-form/rs-form.component'
import { RsFormGroupComponent } from '../rs-form-group/rs-form-group.component'
import { RsConsumerGroupComponent } from '../rs-consumer-group/rs-consumer-group.component'
import { Registry } from '../registry/registry.class'

import { ComponentRegistryCallback } from './component-registry-callback';


/**
 * We use the ComponentRegistry to configure which components are rendered. It accepts a dictionary of component label pairs
 * in the constructor or can be built without an argument to receive a blank registry.
 *
 * @export
 * @class ComponentRegistry
 */
@Injectable()
export class ComponentRegistryService extends Registry<any> {
  static configure(cb: ComponentRegistryCallback) {
    // TODO: Do we want to just pass the component registry class instead of building it?
    const registry = new ComponentRegistryService()
      .add('form', RsFormComponent)
      .add('form-group', RsFormGroupComponent)
      .add('group', RsConsumerGroupComponent)

    return cb(registry)
  }

  constructor() {
    super('Component')
  }
}
