import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ModalsModule } from '@revspringinc/rs-shared';

import { ToastComponent } from './toast.component';

@NgModule({
    imports: [CommonModule, ModalsModule, TranslateModule.forChild()],
    declarations: [ToastComponent],
    exports: [ToastComponent],
})
export class ToastModule {}
