import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'PaCurrency' })
export class PaCurrencyPipe implements PipeTransform {
    public transform(value: number): string {
        if (value < 0) {
            return `( ${value.toFixed(2)} )`;
        }

        return value.toFixed(2);
    }
}
