import { RsGridConfig } from '../schema/rs-grid.schema'

export interface RsGridState<T = any> {
  config: RsGridConfig
  collection: T[]
  expandedIndex: number
  filter: {
    global: string
    columns: {
      [columnName: string]: string
    }
  }

}

export const RsGridInitialState: RsGridState = {
  config: {} as RsGridConfig,
  collection: [],
  expandedIndex: -1,
  filter: {
    global: '',
    columns: {}
  }
}

