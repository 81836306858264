import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../../environments/environment';
import { metaReducers } from './entity-metadata';

// Stores

// Effects
import { HydrationEffects } from '@pa-store';
import { EstimateStoreModule } from '@estimate';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forRoot(
            {},
            {
                metaReducers,
                runtimeChecks: {
                    strictActionImmutability: true,
                    strictStateImmutability: true,
                    strictActionSerializability: true,
                    strictActionTypeUniqueness: true,
                    strictActionWithinNgZone: true,
                    strictStateSerializability: true,
                },
            },
        ),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            autoPause: true,
        }),
        EffectsModule.forRoot([HydrationEffects]),

        EstimateStoreModule,
    ],
})
export class RootStoreModule {}
