import { Injectable } from '@angular/core';

import { Registry } from '../../registry/registry';
import { InputFieldComponent } from '../components/input-field/input-field.component';


@Injectable()
export class ComponentRegistryService extends Registry<any> {
    static configure(cb: any) {
        const registry = new ComponentRegistryService()
            .add('input', InputFieldComponent);

        return cb(registry);
    }

    constructor() {
        super('Component');
    }

    configure(el: any, formControl, config: any) {
        switch (el.type) {
            case 'input': {
                this.configureInput(el, formControl, config);
                break;
            }
            default: throw new Error();
        }
    }

    private configureInput(el, formControl, config) {
        el.id = config.id;
        el.name = config.name;
        el.label = config.elementOptions.label;
        el.wrapClass = config.elementOptions.outerWrap;
        el.type = config.elementOptions.type;
        el.formControl = formControl;
        el.validation = config.validations;
    }
}
