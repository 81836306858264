<div class="has-float-label {{ wrapClass }}">
  <input #input
         [id]="id"
         [name]="name"
         [type]="type"
         (focus)="onFocus($event);$event.target.select()"
         (blur)="onBlur($event)"
         (input)="onChange($event)"
         (cut)="onCut($event)"
         (keydown)="onKeydown($event)"
         (keypress)="onKeypress($event)"
         (paste)="onPaste($event)"
         rsAutofocus
         placeholder=" "
         [autofocus]="autofocus" />
  <label (click)="setInputFocus()"
         [for]="id">
    {{ label | translate }}
  </label>
  <span *ngIf="error"
        class="input-err text-danger"
        attr.data-test="{{element?.name}}-error">
    {{ error }}
  </span>
</div>
