import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RsModalConfig } from '@revspringinc/rs-shared';
import { ModalWrapComponent } from '@revspringinc/rs-shared';
import { ModalService } from '@revspringinc/rs-shared';
import { OrganizationLocation, ProcedureGroupModel } from '@shopping';
import { lastValueFrom } from 'rxjs';
import { ShoppingActivityType } from '../../main/shopping-activity-type';
import { LocationDeterminationService } from '../../services/location-determination.service';

@Component({
    selector: 'rs-select-location',
    templateUrl: './select-location.component.html',
    styleUrls: ['./select-location.component.scss'],
})
export class SelectLocationModal implements ModalWrapComponent, OnInit {
    public static modalName = 'SelectLocationModal';
    @Input() public selectedLocation: OrganizationLocation;
    @Output() public selectedLocationChange: EventEmitter<OrganizationLocation> =
        new EventEmitter<OrganizationLocation>();

    @ViewChild('modal', { static: true }) public modal;
    public modalConfig = {
        activityType: ShoppingActivityType.SelectLocation,
        name: 'SelectLocationModal',
        title: 'Select Location',
        maxWidth: '650px',
        bodyClass: 'gray-bck px-0',
        blockBackdropScrolling: true,
        footer: {
            hidden: true,
        },
    } as RsModalConfig;

    public activeLocations = [];
    public inactiveLocations = [];
    public options: any;

    constructor(
        private readonly modalService: ModalService,
        private readonly locationDeterminationService: LocationDeterminationService,
    ) {}

    public cancel(target?: any) {
        this.hide();
    }

    public async ngOnInit(): Promise<void> {}

    public show(options: {
        locations: OrganizationLocation[];
        selectedProcedures: ProcedureGroupModel[];
        locationId: number;
    }): void {
        this.options = options;
        const { activeList, inactiveList } = this.locationDeterminationService.determineLocationLimitations(options);
        this.activeLocations = activeList;
        this.inactiveLocations = inactiveList;
    }

    public hide(inState?: boolean): void {
        this.modal.hide();
    }

    public selectActiveLocation(location: OrganizationLocation) {
        this.selectedLocationChange.emit(location);
        this.modalService.hide({ location, procedures: this.options.selectedProcedures });
    }

    public async selectInactiveLocation(location: OrganizationLocation): Promise<void> {
        const modalData = await lastValueFrom(
            this.modalService.show('NoticeMessageModal', 'InactiveLocationSelectedNoticeMessage'),
        );

        if (modalData.accepted) {
            this.options.selectedProcedures = this.options.selectedProcedures.filter((proc) => {
                const locations = (proc as any).locations;
                const validLocations = locations.filter((x) => x.id === location.id);

                if (!locations.length || validLocations.length) return proc;
            });

            this.selectedLocationChange.emit(location);
            this.modalService.hide({ location, procedures: this.options.selectedProcedures });
        }
    }
}
