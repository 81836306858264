import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'rs-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent implements ControlValueAccessor, OnInit {
  @Input() label: string;
  @Input() items: any;
  @Input() dropdownSelect: boolean;
  @Input() wrapClass: string;
  @Input() formControl: UntypedFormControl;
  @Output() select = new EventEmitter<string>();
  isToggled = false;
  isDisabled = false;
  value: any;
  private cb: (_: any) => any;
  private cbBlur: () => any;
  private subscription: Subscription;

  constructor() { }

  ngOnInit() {
    if (this.formControl) {
      this.value = this.formControl.value;
      this.subscription = this.formControl.valueChanges
        .pipe(filter((val) => val !== this.value))
        .subscribe((val) => this.value = val);
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.cb = fn;
  }

  registerOnTouched(fn: any): void {
    this.cbBlur = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onSelect(value: string) {
    if (this.isDisabled) return;
    this.value = value;
    if (this.cb) this.cb(value);
    if (this.formControl) this.formControl.setValue(value);
    this.select.emit(value);
  }

  onClick() {
    if (this.isDisabled && !this.isToggled) return;
    this.isToggled = !this.isToggled;
  }

  onClickOutside() {
    if (this.cbBlur) this.cbBlur();
    this.isToggled = false;
  }
}
