import { RsInputComponent } from './rs-input/rs-input.component';
import { RsTextAreaComponent } from './rs-text-area/rs-text-area.component';
import { RsSelectComponent } from './rs-select/rs-select.component';
import { RsMultiSelectComponent } from './rs-multi-select/rs-multi-select.component';
import { RsButtonComponent } from './rs-button/rs-button.component';
import { RsRadioComponent } from './rs-radio/rs-radio.component';
import { RsCheckBoxComponent } from './rs-checkbox/rs-checkbox.component';
import { RsDateComponent } from './rs-date/rs-date.component';

export const RsComponent = {
  input: RsInputComponent,
  textarea: RsTextAreaComponent,
  select: RsSelectComponent,
  multiselect: RsMultiSelectComponent,
  button: RsButtonComponent,
  radio: RsRadioComponent,
  checkbox: RsCheckBoxComponent,
  date: RsDateComponent
};
