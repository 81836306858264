import { createAction, props, State } from '@ngrx/store';

export enum ActionTypes {
    HYDRATE_REQUEST = '[Hydration] Hydration Request',
    HYDRATE_SUCCESS = '[Hydration] Hydration Success',
    HYDRATE_FAILURE = '[Hydration] Hydration Failure',
    HYDRATE_CLEAR_STATE = '[Hydration] Hydration Clear State',
}

export type HydrationStatus = {
    failure?: boolean;
};

export const HydrateRequest = createAction(ActionTypes.HYDRATE_REQUEST);
export const HydrateFailure = createAction(ActionTypes.HYDRATE_FAILURE);
export const ClearState = createAction(ActionTypes.HYDRATE_CLEAR_STATE, (payload: HydrationStatus = {}) => payload);

export const HydrateSuccess = createAction(ActionTypes.HYDRATE_SUCCESS, props<{ state: State<unknown> }>());
