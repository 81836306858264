import { Component, OnInit, ViewChild, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { ModalService } from '@revspringinc/rs-shared';
import { TranslateService } from '@ngx-translate/core';
import { EstimateComponent, EstimateConfigModel } from '@estimate';
import { Alert } from '@revspringinc/shared';
import { ShoppingSessionService } from '../main/session.service';
import { OrganizationService } from '../main/organization.service';

import * as Sentry from '@sentry/angular';
import { LocalStorageService } from '../services/local-storage.service';
import {
    EstimateService,
    LocationService,
    OrganizationLocation,
    ProcedureGroupModel,
    ProcedureService,
    SessionService,
    ShoppingSessionResponseModel,
    SiteConfigService,
} from '@shopping';
import { AmaNoticeService } from '@revspringinc/shared';
import _ from 'lodash-es';
import { filter } from 'rxjs/operators';
import { ServerSentEventsService } from '../services/sse.service';
import { ApiService } from '../services/api.service';
import moment from 'moment';
import { BenefitStatus } from '@encounter';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'rs-estimate-service',
    templateUrl: './estimate-service.component.html',
    styleUrls: ['./estimate-service.component.scss'],
})
export class EstimateServiceComponent implements OnInit {
    @ViewChild(EstimateComponent, { read: ElementRef }) public estimateView: ElementRef;

    public locations: OrganizationLocation[];
    public features: any;
    public loading = false;
    public estimate: EstimateConfigModel;
    public alert: Alert = null;
    public errorConfig: any;
    public estimateDisclaimerAcknowledged: Date;
    public estimateDisclaimerAcknowledgedMessage: string;
    public estimateDisclaimerConfig: any;
    public printConfig = {
        organizationName: '',
        locationName: '',
        dateTimeStamp: '',
        insurance: '',
    };

    public estimateItems = [
        {
            id: 0,
            label: 'Services',
            placeholder: 'Select Services',
            value: null,
            selectedCount: 0,
        },
        {
            id: 2,
            label: 'Insurance',
            placeholder: 'Select Insurance',
            value: null,
        },
    ];

    private selectedProcedures: ProcedureGroupModel[] = [];
    private sessionId: string;
    private organizationName: string;
    private planName: string;
    private payerName: string;
    private benefitStatus: string;
    private locationId: number;
    private procedureGroupsTranslation;

    constructor(
        private readonly modalService: ModalService,
        private readonly amaNoticeService: AmaNoticeService,
        private readonly translate: TranslateService,
        private readonly sessionService: SessionService,
        private readonly shoppingSessionService: ShoppingSessionService,
        private readonly organizationService: OrganizationService,
        private readonly estimateService: EstimateService,
        private readonly localStorageService: LocalStorageService,
        private readonly locationService: LocationService,
        private readonly sseService: ServerSentEventsService,
        private readonly procedureService: ProcedureService,
        private readonly api: ApiService,
        private readonly siteConfig: SiteConfigService,
        private readonly router: Router,
    ) {}

    public async ngOnInit(): Promise<void> {
        // subscribe to any session changes
        this.shoppingSessionService.session.subscribe(async (session) => {
            this.sessionId = session.sessionId;

            this.estimateDisclaimerAcknowledged = session.estimateDisclaimerAcknowledged;
            this.features = await lastValueFrom(this.api.getFeatures(this.sessionId));

            if (this.features.truePriceRequireEstimateDisclaimer) {
                this.estimateDisclaimerConfig = await lastValueFrom(
                    this.siteConfig.siteConfigApiControllerGetSiteConfig(this.sessionId, 'estimate-disclaimer'),
                );

                Object.keys(this.estimateDisclaimerConfig.language).forEach((lang) =>
                    this.translate.setTranslation(lang, this.estimateDisclaimerConfig.language[lang], true),
                );
            }

            this.organizationService.organization.subscribe(async (organization) => {
                this.organizationName = organization;
            });

            // load the estimate when the session updates
            this.procedureGroupsTranslation = await lastValueFrom(
                this.procedureService.procedureApiControllerGetAll(this.sessionId),
            );

            await this.pingForEstimate(false);

            // set the insurance name
            if (session.insurance) {
                this.setInsuranceDisplayName(session);
            }

            if (this.estimateDisclaimerAcknowledged) {
                const { estimateMessage, dateFormat } = this.estimateDisclaimerConfig.config;
                const acknowledgeDate = moment(this.estimateDisclaimerAcknowledged).local().format(dateFormat);

                this.estimateDisclaimerAcknowledgedMessage = this.translate
                    .instant(estimateMessage)
                    .replace(':acknowledgeDate', acknowledgeDate);
            }

            // set the estimate diplay items
            await this.setSessionSelectedProcedures(session);

            // location feature is enabled ? set the location display name : hide location box
            await this.setLocation(session);

            await this.setPrintHeader(session);
        });

        // subscribe to backend estimate updates sent via SSE
        this.shoppingSessionService.estimateUpdated.pipe(filter((x) => !!x)).subscribe((eventData) => {
            // get the updated estimate
            if (eventData) {
                this.pingForEstimate(false);
            }
        });
    }

    public async estimateInfo(id: number) {
        switch (id) {
            case 0: {
                const lastSavedSelectedProcedures = this.selectedProcedures;

                if (!this.amaNoticeService.isViewed()) {
                    await lastValueFrom(this.modalService.show('AmaNoticeModal'));
                }

                const modalData = (await lastValueFrom(
                    this.modalService.show('SelectServicesModal', {
                        selectedProcedures: lastSavedSelectedProcedures,
                        locationId: this.locationId,
                        locations: this.locations,
                        hasLocationEnabled: this.features?.truePriceShowLocations,
                    }),
                )) as { procedures: ProcedureGroupModel[]; location?: OrganizationLocation };

                if (modalData) {
                    await this.setSelectedProcedures(modalData.procedures);
                    if (modalData.location) {
                        await this.setSelectedLocation(modalData.location);
                    }
                    await this.syncLatestSessionUpdates();
                    this.loading = true;
                } else {
                    this.selectedProcedures = lastSavedSelectedProcedures;
                }

                break;
            }
            case 1: {
                const modalData = (await lastValueFrom(
                    this.modalService.show('SelectLocationModal', {
                        locations: this.locations,
                        selectedProcedures: this.selectedProcedures,
                        locationId: this.locationId,
                    }),
                )) as { location: OrganizationLocation; procedures: ProcedureGroupModel[] };

                if (modalData) {
                    await this.setSelectedLocation(modalData.location);
                    await this.syncLatestSessionUpdates();
                }

                this.printConfig.locationName = modalData?.location?.name;

                break;
            }
            case 2: {
                const insuranceEstimateItem = this.estimateItems.find((x) => x.id === 2);

                const modalData = await lastValueFrom(
                    this.modalService.show('AddInsurancePlanModal', insuranceEstimateItem.value),
                );
                const cancelled = modalData.cancel ?? false;

                if (cancelled) {
                    break;
                }

                await this.syncLatestSessionUpdates();

                this.printConfig.insurance = this.payerName + '-' + this.planName;

                break;
            }
        }
    }

    public async toEstimateActions(action: any) {
        switch (action.id) {
            case 1: {
                const currentProcedures = this.selectedProcedures?.map((x) => this.translate.instant(x.name)) ?? [];
                this.modalService.show('ContactMeModal', { currentProcedures });
                break;
            }
            case 4: {
                this.downloadPdf();
                break;
            }
            case 3: {
                this.modalService.show('CompareCostsModal');
                break;
            }
            case 5: {
                this.modalService.show('TextEstimateSummaryModal', this.generateTextSummary());
            }
            case 6: {
                this.router.navigate(['external-redirect', { externalUrl: action.link }], { skipLocationChange: true });
            }
        }
    }

    public inlineCss(sourceElement, targetElement) {
        Array.from(targetElement.children).forEach((child, index) => {
            this.inlineCss(sourceElement.children[index], child);
        });

        const computedStyle = getComputedStyle(sourceElement);

        Array.from(computedStyle).forEach((property) => {
            targetElement.style[property] = computedStyle.getPropertyValue(property);
        });
    }

    public toActionIcon(iconName: string) {
        return `../assets/img/actions/${iconName}.svg`;
    }

    public async pingForEstimate(waitForResponse: boolean, maxAttempts?: number, delay?: number) {
        this.loading = true;

        setTimeout(async () => {
            this.errorConfig = (this.estimate as any)?.error || {
                maxAttempts: 10,
                delay: 1,
                message: 'An unknown error occurred.  Please try again.',
                priority: 'danger',
                icon: 'icon-alert',
            };

            if (!maxAttempts) {
                maxAttempts = this.errorConfig.maxAttempts;
            }

            if (!delay) {
                delay = this.errorConfig.delay;
            }

            if (maxAttempts <= 1) {
                this.alert = this.errorConfig;

                console.error('Maximum retries attemped.');
                this.loading = false;

                return;
            }

            // reset the alert
            this.alert = null;

            await lastValueFrom(this.estimateService.estimationApiControllerEstimateLatest(this.sessionId))
                .then(async (estimateData) => {
                    Object.keys(estimateData.language).forEach((lang) =>
                        this.translate.setTranslation(lang, estimateData.language[lang], true),
                    );

                    if (!waitForResponse) {
                        this.loading = false;
                        this.estimate = estimateData.estimate;

                        if (estimateData.status === 'unable to estimate') {
                            this.alert = {
                                icon: 'icon-alert',
                                priority: 'danger',
                                message: `${estimateData.message.join(' ')}`,
                            };
                        }

                        return;
                    }
                })
                .catch(async (response) => {
                    Sentry.captureException(response);

                    this.alert = {
                        icon: 'icon-alert',
                        priority: 'danger',
                        message: `${response.statusText} - Retrying`,
                    };

                    await this.pingForEstimate(false, --maxAttempts, delay);

                    return;
                });
        }, delay);
    }

    public async restartSession(): Promise<void> {
        this.organizationService.organization.subscribe(async (organization) => {
            this.locationId = null;
            this.sseService.closeServerSentEvent(this.sessionId);
            await this.shoppingSessionService.createSession(organization, true);
            await this.localStorageService.setSessionId(this.shoppingSessionService.sessionId);
            await this.setLocationDisplayName(null);
            await this.setSessionSelectedProcedures(null);
            this.setInsuranceDisplayName(null);
            await this.setPrintHeader(null);
        });
    }

    public locationsReady() {
        return (
            (this.features?.truePriceShowLocations && this.locations) ||
            (this.features && !this.features?.truePriceShowLocations)
        );
    }

    public async setEstimateDisclaimerAcknowledged() {
        await this.shoppingSessionService.estimateDisclaimerAcknowledged();
        await this.syncLatestSessionUpdates();
    }

    public blockedByDisclaimer() {
        return (
            this.features && this.features.truePriceRequireEstimateDisclaimer && !this.estimateDisclaimerAcknowledged
        );
    }

    private async setPrintHeader(session: ShoppingSessionResponseModel) {
        const gmtDate = new Date();
        const localDate = gmtDate.toLocaleString('en', {
            timeZoneName: 'short',
            hour: 'numeric',
            second: 'numeric',
            minute: 'numeric',
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            weekday: 'short',
        });

        this.printConfig.organizationName = session?.orgDisplayName;
        this.printConfig.locationName = session?.locationName;

        this.printConfig.dateTimeStamp = localDate;
        this.printConfig.insurance = session?.insurance
            ? session?.insurance?.payerName + ' - ' + session?.insurance?.planName
            : null;
    }

    private setLocationDisplayName(locationName: string) {
        const locationEstimateItem = this.estimateItems.find((x) => x.id === 1);

        locationEstimateItem.value = locationName ? [locationName] : null;
        locationEstimateItem.selectedCount = 0;
    }

    private setInsuranceDisplayName(session: ShoppingSessionResponseModel) {
        const insurance = session?.insurance;
        const insuranceEstimateItem = this.estimateItems.find((x) => x.id === 2);

        this.payerName = insurance ? insurance?.payerName : null;
        this.planName = insurance ? insurance?.planName : null;
        this.benefitStatus = session?.benefitStatus;

        if (this.payerName !== 'SELFPAY') {
            if (this.benefitStatus === BenefitStatus.Active) {
                insuranceEstimateItem.value = insurance
                    ? [insurance?.payerName + ' - Your insurance was verified.']
                    : [];
            } else if (this.benefitStatus === BenefitStatus.NotActive) {
                insuranceEstimateItem.value = insurance
                    ? [insurance?.payerName + ' - Your insurance was verified, but your coverage is not active.']
                    : [];
            } else if (this.benefitStatus === BenefitStatus.MemberNotFound) {
                insuranceEstimateItem.value = insurance
                    ? [insurance?.payerName + ' - Your insurance was verified, but the provided member was not found.']
                    : [];
            } else if (this.benefitStatus === BenefitStatus.Error) {
                insuranceEstimateItem.value = insurance
                    ? [insurance?.payerName + ' - There was a problem verifying your insurance.']
                    : [];
            } else {
                insuranceEstimateItem.value = insurance?.payerName ? [insurance?.payerName] : [];
            }
        } else {
            insuranceEstimateItem.value = insurance ? ['Select Insurance'] : [];
        }
    }

    private downloadPdf() {
        window.print();

        /*
                const pdfData = await lastValueFrom(this.estimateService.estimationApiControllerGeneratePdf(this.sessionId, { template: htmlToPrint }));

                const b64toBlob = (base64, type = 'application/octet-stream') => fetch(`data:${type};base64,${base64}`).then(res => res.blob());

                // Convert the base64 PDF into a blob so that we can launch the browser to download the PDF.
                const pdfBlob = new Blob([await b64toBlob(pdfData)], { type: 'application/pdf' });

                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(pdfBlob);

                    return;
                }

                // For other browsers:
                // Create a link pointing to the ObjectURL containing the blob.
                const data = window.URL.createObjectURL(pdfBlob);

                const link = document.createElement('a');
                link.href = data;
                link.download = 'estimate.pdf';

                // this is necessary as link.click() does not work on the latest firefox
                link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                    link.remove();
                }, 100);*/
    }

    private async setSelectedProcedures(procedures: ProcedureGroupModel[]) {
        if (procedures) {
            this.selectedProcedures = procedures;
            const sevicesEstimateItem = this.estimateItems.find((x) => x.id === 0);

            sevicesEstimateItem.value = this.selectedProcedures.map((x) => {
                const procedure = x as any;
                const procedureName = procedure.name ?? procedure.procedureDisplayName;

                return this.translate.instant(procedureName);
            });

            sevicesEstimateItem.selectedCount = this.selectedProcedures.length;

            await lastValueFrom(
                this.sessionService.shoppingSessionControllerUpdateProcedures(this.sessionId, {
                    procedures: this.selectedProcedures.map((x) => x.id.toString()),
                }),
            );
        }
    }

    private async setSessionSelectedProcedures(session: ShoppingSessionResponseModel) {
        if (session?.procedures?.length) {
            const selectedProcedureGroups = _.uniqBy(
                session.procedures.map((x) => x.procedureGroup as any),
                'id',
            );

            const mappedSelectedProcedureGroups = selectedProcedureGroups.map((x) =>
                this.procedureGroupsTranslation.config.find((translatedPg) => translatedPg.id === x.id),
            );
            Object.keys(this.procedureGroupsTranslation.language).forEach((lang) =>
                this.translate.setTranslation(lang, this.procedureGroupsTranslation.language[lang], true),
            );

            this.selectedProcedures = mappedSelectedProcedureGroups;
        } else {
            this.selectedProcedures = [];
        }

        const sevicesEstimateItem = this.estimateItems.find((x) => x.id === 0);

        sevicesEstimateItem.selectedCount = this.selectedProcedures.length;
        sevicesEstimateItem.value = this.selectedProcedures.map((x) => {
            const procedure = x as any;
            const procedureName = procedure.name ?? procedure.procedureDisplayName;
            const translatedName = this.translate.instant(procedureName);

            return translatedName;
        });
    }

    private async setSelectedLocation(location: OrganizationLocation) {
        const locationItem = this.estimateItems.find((x) => x.id === 1);
        if (location) {
            locationItem.value = [location.name];
            locationItem.selectedCount = 1;
            this.locationId = location.id;
        } else {
            locationItem.value = null;
            locationItem.selectedCount = 0;
            this.locationId = null;
        }

        await lastValueFrom(
            this.sessionService.shoppingSessionControllerUpdateLocation(this.sessionId, {
                locationId: this.locationId,
            }),
        );
    }

    private generateTextSummary(): string {
        let estimateSummary;

        if (this.estimate?.createdAt) {
            estimateSummary = `*_Estimate: ${this.translate.instant(this.estimate.estimateNumber)}\n`;
            this.estimate.serviceGroups.forEach(
                (group) => (estimateSummary += `Procedure: ${this.translate.instant(group.title?.toString())}\n`),
            );
            estimateSummary += `Cost: $${(this.estimate.summary as any).amount.toLocaleString()}\n`;
            estimateSummary += `Insurance: ${this.estimateItems.find((x) => x.id === 2).value ?? 'Not Selected'}\n`;
            estimateSummary += `Generated On: ${this.estimate.createdAt}`;
        } else {
            estimateSummary = 'No estimate generated. Please select services and try again.';
        }

        return estimateSummary;
    }

    private async setLocation(session: ShoppingSessionResponseModel): Promise<void> {
        if (!this.features?.truePriceShowLocations) {
            return;
        }

        this.locations = (
            await lastValueFrom(this.locationService.locationControllerGetSession(session.sessionId))
        ).locations;

        if (this.estimateItems[0].label !== 'Location') {
            this.estimateItems.unshift({
                id: 1,
                label: 'Location',
                placeholder: 'Select Location',
                value: null,
            });
        }

        if (session.locationName) {
            this.locationId = this.locations.find((x) => x.name === session.locationName)?.id;
            this.setLocationDisplayName(session.locationName);
        } else {
            const defaultLocation = this.locations.find((x) => x.defaultLocation);

            if (!session.procedures?.length && defaultLocation) {
                this.setSelectedLocation(defaultLocation);
                await lastValueFrom(
                    this.sessionService.shoppingSessionControllerUpdateLocation(this.sessionId, {
                        locationId: defaultLocation.id,
                    }),
                );
            }
        }
    }

    private async syncLatestSessionUpdates() {
        // Called when the session is updated in the back-end from a UI action
        // ensures the subscribed UI session always contains the latest session changes
        await this.shoppingSessionService.getExistingSession(this.sessionId);
    }
}
