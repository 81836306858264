const HttpMethods = [
    { label: 'DELETE', value: 'DELETE' },
    { label: 'GET', value: 'GET' },
    { label: 'PATCH', value: 'PATCH' },
    { label: 'POST', value: 'POST' },
    { label: 'PUT', value: 'PUT' },
]

export const DataSources = {
    HttpMethods,
}