<div [formGroup]="element.group"
     class="has-float-label"
     [ngClass]="element?.theme?.outerWrap"
     *ngIf="visible">
    <select [id]="id"
            class="form-control"
            [ngClass]="element?.theme?.control"
            [formControlName]="element.name">

        <option *ngFor="let option of values"
                [ngValue]="option.value"
                [selected]="isSelected(option)"
                [disabled]="option.disabled"
                [hidden]="option.hidden">
            {{ option.label | translate }}
        </option>


    </select>

    <label *ngIf="(element.elementOptions.label | translate)"
           [ngClass]="element?.theme?.labelClass"
           [for]="id">
        {{ element.elementOptions.label | translate }}
        <span *ngIf="element?.tooltip?.display">
            <i class="fa fa-question-circle"
               aria-hidden="true"
               placement="{{element.tooltip.placement || 'right'}}"
               ngbTooltip="{{element.tooltip.text}}"></i>
        </span>
    </label>
    <div class="input-err text-danger">
        <span *ngIf="element?.group?.controls[element.name]?.touched && error"
              attr.data-test="{{element?.name}}-error">{{ error | translate }}</span>
    </div>
</div>
