import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgxGaugeModule } from 'ngx-gauge';

import { FormsModule } from '@angular/forms';

import {
    HeroIconsModule,
    PaUiModule,
    cakeSolid,
    calendarSolid,
    identificationSolid,
    locationMarkerSolid,
    officeBuildingSolid,
    userSolid,
    healingSolid,
    localHospitalSolid,
    clipboardListSolid,
    exclamationSolid,
    arrowNarrowRightSolid,
} from '@pa-ui';
import { RsFormsModule } from '@revspringinc/rs-shared';

import { EstimateScoreComponent } from './estimate-score/estimate-score.component';
import { EstimateComponent } from './estimate/estimate.component';
import { EstimateActionsComponent } from './estimate-actions/estimate-actions.component';
import { EstimateDisclaimerComponent } from './estimate-disclaimer/estimate-disclaimer.component';
import { EstimateSectionComponent } from './estimate-section/estimate-section.component';

import { ServiceGroupTotalComponent } from './service-group-total/service-group-total.component';
import { ServiceGroupHeaderComponent } from './service-group-header/service-group-header.component';

import { ProviderGroupServiceComponent } from './provider-group-service/provider-group-service.component';
import { ProviderGroupServicesComponent } from './provider-group-services/provider-group-services.component';
import { ProvideGroupServiceHeaderComponent } from './provider-group-service-header/provider-group-service-header.component';
import { ProviderGroupHeaderComponent } from './provider-group-header/provider-group-header.component';
import { ProviderGroupTotalComponent } from './provider-group-total/provider-group-total.component';

import { DefaultEstimateRendererComponent } from './renders/default-estimate-renderer/default-estimate-renderer.component';
import { AttachmentEstimateRendererComponent } from './renders/attachment-estimate-renderer/attachment-estimate-renderer.component';
import { GoodFaithEstimateRendererComponent } from './renders/good-faith-estimate-renderer/good-faith-estimate-renderer.component';
import { EditableItemComponent } from './editable-item/editable-item.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { EstimateStoreModule } from '../+store/estimate-store.module';
import { SelfpayDiscountTotalComponent } from './selfpay-discount-total/selfpay-discount-total.component';
@NgModule({
    declarations: [
        EstimateScoreComponent,
        EstimateActionsComponent,
        EstimateComponent,
        EstimateDisclaimerComponent,
        ProviderGroupTotalComponent,
        ServiceGroupTotalComponent,
        ProviderGroupHeaderComponent,
        EstimateSectionComponent,

        ProviderGroupServiceComponent,
        ProviderGroupServicesComponent,
        ServiceGroupHeaderComponent,
        ProvideGroupServiceHeaderComponent,

        DefaultEstimateRendererComponent,
        AttachmentEstimateRendererComponent,
        GoodFaithEstimateRendererComponent,
        EditableItemComponent,

        SelfpayDiscountTotalComponent,
    ],
    exports: [
        EstimateScoreComponent,
        EstimateActionsComponent,
        EstimateComponent,
        EstimateDisclaimerComponent,
        DefaultEstimateRendererComponent,
        AttachmentEstimateRendererComponent,
        GoodFaithEstimateRendererComponent,
    ],
    imports: [
        CommonModule,
        EstimateStoreModule,
        FormsModule,
        TranslateModule.forChild(),
        NgxGaugeModule,
        RsFormsModule,
        HeroIconsModule.withIcons({
            identificationSolid,
            calendarSolid,
            locationMarkerSolid,
            cakeSolid,
            officeBuildingSolid,
            userSolid,
            healingSolid,
            localHospitalSolid,
            clipboardListSolid,
            exclamationSolid,
            arrowNarrowRightSolid,
        }),
        PdfViewerModule,
        PaUiModule,
    ],
})
export class EstimateModule {}
