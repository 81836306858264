//third-party imports
import { Component, Input } from '@angular/core';
/**
 *
 *
 * @export
 * @class BsTextAreaComponent
 */
@Component({
    selector: 'rs-text-area',
    templateUrl: './rs-text-area.component.html',
    styleUrls: ['../../../scss/styles.scss']
})
export class RsTextAreaComponent {
    @Input() element;
}
