import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ModalWrapComponent } from '@revspringinc/rs-shared';
import { ModalService } from '@revspringinc/rs-shared';
import { ProcedureService, ProcedureCategoryModel, ProcedureGroupModel, OrganizationLocation } from '@shopping';
import { lastValueFrom, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { RsModalConfig } from '@revspringinc/rs-shared';
import { ShoppingActivityType } from '../../main/shopping-activity-type';
import { LocationDeterminationService } from '../../services/location-determination.service';
import { ShoppingSessionService } from '../../main/session.service';

@Component({
    selector: 'rs-select-services',
    templateUrl: './select-services.component.html',
    styleUrls: ['./select-services.component.scss'],
})
export class SelectServicesModal implements ModalWrapComponent, OnInit, OnDestroy {
    public static modalName = 'SelectServicesModal';
    @ViewChild('modal', { static: true }) public modal;

    public modalConfig = {
        activityType: ShoppingActivityType.SelectServices,
        name: 'SelectServicesModal',
        title: 'Select Services',
        maxWidth: '650px',
        bodyClass: 'gray-bck px-0',
        blockBackdropScrolling: true,
        footer: {
            hidden: false,
            save: 'Save',
            saveEnabled: false,
        },
    } as RsModalConfig;

    public modalName?: string;
    public visible?: boolean;
    public searchMode: boolean = false;
    public payloadSub?: any;
    public proceduresList: ProcedureCategoryModel[] = [];

    public options: any;
    private proceduresList$: Subscription;

    constructor(
        private readonly modalService: ModalService,
        private readonly procedureService: ProcedureService,
        private readonly sessionService: ShoppingSessionService,
        private readonly translate: TranslateService,
        private readonly locationDeterminationService: LocationDeterminationService,
    ) {}

    public async ngOnInit(): Promise<void> {}

    public ngOnDestroy(): void {
        this.proceduresList$.unsubscribe();
    }

    public show(options: {
        selectedProcedures: ProcedureGroupModel[];
        locationId: number;
        locations: OrganizationLocation[];
        hasLocationEnabled: boolean;
    }): void {
        this.options = options;

        this.proceduresList$ = this.procedureService
            .procedureApiControllerGetProcedureCategories(this.sessionService.sessionId, this.options.locationId)
            .subscribe((procedureList) => {
                this.proceduresList = procedureList.config;
                this.setLanguage(procedureList.language);

                this.selectedProceduresChanged(this.options.selectedProcedures);
            });
    }

    public cancel(target?: any) {
        this.hide();
    }

    public hide(inState?: boolean): void {
        this.modal.hide();
    }

    public async submit() {
        if (!this.options.selectedProcedures || this.options.selectedProcedures.length === 0) {
            this.modalService.hide();
        } else if (this.options.hasLocationEnabled) {
            const { activeList, inactiveList } = this.locationDeterminationService.determineLocationLimitations(
                this.options,
            );

            if (inactiveList.length === this.options.locations.length || activeList.length === 0) {
                const modalData = await lastValueFrom(
                    this.modalService.show('NoticeMessageModal', 'ProcedureLocationConflictNoticeMessage'),
                );
            } else if (activeList.length === 1) {
                const location = this.options.locations.find((x) => x.id === activeList[0].id);
                this.modalService.hide({ procedures: this.options.selectedProcedures, location });
            } else {
                this.modalService.hide({ procedures: this.options.selectedProcedures });
            }
        } else {
            this.modalService.hide({ procedures: this.options.selectedProcedures });
        }
    }

    public selectedProceduresChanged(selectedProcedureGroups: ProcedureGroupModel[]) {
        this.options.selectedProcedures = [];
        this.setSelectedProcedureGroups(selectedProcedureGroups);
    }

    public async selectProcedures(procedureCategory) {
        const lastSelectedProcedureGroups = this.options.selectedProcedures;
        const selectedProcedureGroups = await lastValueFrom(
            this.modalService.show('SelectProceduresModal', {
                procedureCategory,
                selectedProcedures: this.options.selectedProcedures,
                locationId: this.options.locationId,
                locations: this.options.locations,
                hasLocationEnabled: this.options.hasLocationEnabled,
            }),
        );

        if (selectedProcedureGroups) {
            this.deselectProceduresForCategory(procedureCategory);
            this.setSelectedProcedureGroups(selectedProcedureGroups);
        } else {
            this.setSelectedProcedureGroups(lastSelectedProcedureGroups, false);
        }
    }

    public showContactMeModal(searchTerm?: string) {
        const currentProcedures = this.options.selectedProcedures?.map((x) => this.translate.instant(x.name)) ?? [];
        this.modalService.show('ContactMeModal', { currentProcedures, searchTerm });
    }

    private deselectProceduresForCategory(procedureCategory: ProcedureCategoryModel) {
        this.options.selectedProcedures = this.options.selectedProcedures.filter(
            (item) => item.categoryId !== procedureCategory.id,
        );
    }

    private setSelectedProcedureGroups(selectedProcedureGroups: ProcedureGroupModel[], shouldAdd: boolean = true) {
        this.modalConfig.footer.saveEnabled = selectedProcedureGroups.length > 0;

        if (shouldAdd && selectedProcedureGroups && selectedProcedureGroups.length > 0) {
            this.options.selectedProcedures.push(...selectedProcedureGroups);
        }

        this.reevaluateSelectedProcedures();
    }

    private reevaluateSelectedProcedures() {
        this.proceduresList?.forEach((procedureGroup) => {
            (procedureGroup as any).selected = this.options.selectedProcedures?.filter(
                (spg) => spg.categoryId === procedureGroup.id,
            );
        });
    }

    private setLanguage(language: any) {
        Object.keys(language).forEach((lang) => this.translate.setTranslation(lang, language[lang], true));
    }
}
