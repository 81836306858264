import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router } from '@angular/router';
import { UnsupportedBrowserService } from '@revspringinc/shared';

@Injectable()
export class UnsupportedBrowserGuard implements CanActivateChild {
    constructor(private readonly unsupportedBrowserservice: UnsupportedBrowserService, private readonly router: Router) { }

    public canActivateChild(route: ActivatedRouteSnapshot): boolean {
        const path = route.url[0].path;
        const orgName = route.params.orgName;

        if (!this.unsupportedBrowserservice.browserIsSupported() && path !== 'landing') {
            this.router.navigate([`${orgName}/landing`]);
        }

        return true;
    }
}
