<div *ngIf="headerService.headerState$ | async as headerState">
  <nav class="z-10 flex flex-col w-full pt-4 transition-all bg-th-navbar-background print:hidden"
       [ngClass]="{'sticky top-0': !renderingForms}"
       *ngIf="headerState.state.isVisible">

    <div class="flex justify-between w-full">
      <div class="w-full transition-all"
           [ngClass]="{
           'shadow-md pt-4': scrolled,
           'shadow-none': !scrolled
       }">
        <div class="relative flex items-center justify-between pb-2 mx-0 md:mx-4"
             [ngClass]="{
            'border-none': scrolled,
            'border-b border-th-line': !scrolled
         }">

          <ng-container *ngIf="headerState.state.contentIsVisible">
            <pa-header-content *ngFor="let component of headerState.components"
                               [component]="component">
            </pa-header-content>
          </ng-container>

          <pa-header-dropdown class="ml-auto md:hidden" [dropdownOptions]="dropdownOptions"></pa-header-dropdown>
        </div>

        <!-- loading indicator -->
        <div class="flex w-full h-15">
          <div class="flex w-full overflow-hidden"
               [ngClass]="{'animate-running bg-th-primary': loading, 'bg-white': !loading}">
            <div class="h-1 bg-white bg-th-primary ">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="relative border-b-4 border-red-400"
         *ngIf="error">
      <div class="flex items-center justify-between px-3 py-3">
        <p class="flex items-center space-x-4 font-medium text-red-400">
          <svg xmlns="http://www.w3.org/2000/svg"
               class="w-5 h-5 rounded-full shadow-md ring-2 ring-white"
               viewBox="0 0 20 20"
               fill="currentColor">
            <path fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clip-rule="evenodd" />
          </svg>


          <span class="inline">
            {{ error.message }}
          </span>
        </p>

        <span class="flex p-1 mr-8 text-xs text-white bg-red-400 rounded-lg text-monospace ">
          {{ error.remaining }}
        </span>

        <div class="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:pt-1 sm:pr-2 sm:items-start">
          <button type="button"
                  (click)="closeError()"
                  class="flex p-2 text-red-400 rounded-md hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-white hover:text-white">
            <span class="sr-only">Dismiss</span>
            <!-- Heroicon name: outline/x -->
            <svg class="w-6 h-6 "
                 xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
                 aria-hidden="true">
              <path stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </nav>
</div>
