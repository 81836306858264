import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RsBaseComponent } from '../rs-base.component';

@Component({
    selector: 'rs-select',
    templateUrl: './rs-select.component.html',
    styleUrls: ['./rs-select.component.scss', '../../../scss/styles.scss']
})
export class RsSelectComponent extends RsBaseComponent implements OnInit {
    @Input() public values;
    public formControl;

    constructor(private readonly translate: TranslateService) {
        super();
    }

    public ngOnInit() {
        super.ngOnInit();

        this.formControl = this.element.group.get(this.element.name);

        const placeHolderOption = {
            value: '',
            label: `Select ${this.translate.instant(this.element.elementOptions.label)}`,
            disabled: true,
            hidden: true,
            selected: true
        };

        this.values = [
            placeHolderOption,
            ...this.element.elementOptions.options.map(item => ({
                value: item.Value || item.value,
                label: item.Label || item.label || ''
            }))
        ];
    }

    public isSelected(option: { value: any, label: string, disabled: boolean, hidden: boolean, selected: boolean; }): boolean {
        const curValue = this.formControl.value;

        if (option.selected) {
            return true;
        }

        if (!curValue) {
            return;
        }

        if (curValue.id) {
            return curValue?.id === option.value?.id;
        }

        return curValue === option.value;
    }
}
