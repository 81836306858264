/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, filter, finalize } from 'rxjs/operators';

import { LoadingService } from './loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    constructor(private loadingService: LoadingService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loadingService.setLoading(true, request.url);

        return next
            .handle(request)
            .pipe(
                catchError((err) => {
                    this.loadingService.setLoading(false, request.url);
                    return throwError(() => err);
                }),
            )

            .pipe(
                filter((evt: any) => evt instanceof HttpResponse),
                map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
                    this.loadingService.setLoading(false, request.url);
                    return evt;
                }),
                finalize(() => this.loadingService.setLoading(false, request.url)),
            );
    }
}
