import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

    private readonly sessionIdKey = 'sessionId';

    public getSessionId(): any {
        try {
            const sessionItem = JSON.parse(localStorage.getItem(this.sessionIdKey));

            if (Date.now() > sessionItem?.expiry) {
                localStorage.removeItem(this.sessionIdKey);

                return null;
            }

            return sessionItem?.value;
        }
        catch (e) {
            console.log('invalid session', e);
            localStorage.removeItem(this.sessionIdKey);

            return null;
        }
    }

    public setSessionId(sessionId: any): void {
        const sessionItem = {
            value: sessionId,
            expiry: Date.now() + (5 * 60 * 1000)
        };

        localStorage.setItem(this.sessionIdKey, JSON.stringify(sessionItem));
    }
}
