import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VERSION } from '../../environments/version';
@Component({
  selector: 'rs-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() public footerData: any;

  public version: string = `rev ${VERSION.split('@').pop()}`;

  constructor(private readonly router: Router) { }

  public ngOnInit(): void { }

  public redirect(link: string, typeOfLink: string): void {
    if (link.startsWith('tel:')) {
      window.location.href = link;
    } else if (typeOfLink === 'internal') {
      const orgName = this.router.url.split('/').filter(segment => segment)[0];
      this.router.navigate([`${orgName}/${link}`]);
    } else {
      this.router.navigate(['external-redirect', { externalUrl: link }], { skipLocationChange: true });
    }
  }

}
