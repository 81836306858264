import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { DateInput, TextInput } from '@revspringinc/rs-core';
import { RsBaseComponent } from '../rs-base.component';

@Component({
    selector: 'rs-input',
    templateUrl: './rs-input.component.html',
    styleUrls: ['./rs-input.component.scss', '../../../scss/styles.scss'],
})
export class RsInputComponent extends RsBaseComponent implements AfterViewInit{
    public maxDateCurrentDate = new Date().toISOString().split('T')[0];

    public ngAfterViewInit(): void {
        const textInput = <HTMLInputElement>document.querySelector('.input-datepicker');
        const dateInput = <HTMLInputElement>document.querySelector('.datepicker-input')
        const formControl =  this.element.group.controls[this.element.name];
        const onlySelf = Object.keys(this.element.group.controls).length > 1; 

        dateInput?.addEventListener('change', event => {
            const target = event.target as HTMLTextAreaElement
            const modifiedDate = target.value.replace(/\-/g, '/');
            textInput.value = this.formatDate(modifiedDate);

            formControl.errors = null;
            formControl.status = 'VALID';
            formControl.value = textInput.value;
            formControl.updateValueAndValidity({onlySelf});
        })

        textInput?.addEventListener('change', event => {
            const target = event.target as HTMLTextAreaElement;
            const modifiedDate = target.value.replace(/\//g, '-');
            dateInput.value = this.formatDate(modifiedDate);
        })
    }

    private formatDate(date: string): string {
        if (date.includes('/')) {
            const year = date.slice(0, 4);
            const monthDay = date.slice(-5) + '/';
            return monthDay + year;
        } else {
            const monthDay = date.slice(0, 5);
            const year = date.slice(-4) + '-';
            return year + monthDay;
        }
    }
}
