<div
  class="p-1 border border-gray-300 rounded-md shadow-sm hover:bg-yellow-50 md:p-4">
  <div class="flex items-center">
    <div class="content-center flex-shrink-0">
      <svg
        class="w-8 h-8"
        [ngClass]="{
          'text-th-primary': alert.priority === 'warning',
          'text-red-700': alert.priority === 'error',
          'text-blue-700': alert.priority === 'info'
        }"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true">
        <path
          fill-rule="evenodd"
          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
          clip-rule="evenodd" />
      </svg>
    </div>
    <div class="ml-3">
      <h3
        class="mb-2 font-medium text-th-secondary"
        *ngIf="alert.title">
        {{ alert.title | translate }}
      </h3>
      <div class="text-th-secondary" *ngIf="alert.message">
        <p>
          {{ alert.message | translate }}
        </p>
      </div>

      <ng-container *ngIf="alertAcknowledged">
        <div class="flex mt-2 font-bold">
          <!-- Heroicon: badge-check solid -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5 text-green-600"
            viewBox="0 0 20 20"
            fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd" />
          </svg>
          <div class="mt-3 ml-3 md:mt-0">
            <p class="text-sm text-green-600">{{ alertAcknowledged }}</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
