<div  [ngClass]="element?.theme?.outerWrap">
    <input [id]="element.id" [type]="element.elementOptions.type"
        [disabled]="element.elementOptions.disabled"
        class="form-control"
        [ngClass]="element?.theme?.control"
        [title]="element?.elementOptions?.title | translate"
        [tabindex]="element?.elementOptions?.tabindex"
        (click)="defaultEvent()" 
        [value]="value | translate"> 
    </div>

