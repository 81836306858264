import { Component, OnInit, Input } from '@angular/core';
import { RsCard } from '../schemas/rs-card.schema';

@Component({
    selector: 'rs-card',
    templateUrl: './rs-card.component.html',
    styleUrls: ['./rs-card.component.scss', '../../../scss/styles.scss']
})
export class RsCardComponent {

    @Input() cardDetails: RsCard;

}
