<ng-container>
  <div *ngIf="loading"
       class="flex w-full">
    <div class="h-full cp-estimate w-100"></div>
  </div>

  <ng-container *ngIf="estimate?.type == 'EstimateRenderConfig'">
    <estimate-default-renderer [loading]="loading"
                               [estimateDisclaimerAcknowledged]="estimateDisclaimerAcknowledged"
                               [estimate]="estimate"></estimate-default-renderer>
  </ng-container>

  <ng-container *ngIf="estimate?.type == 'GoodFaithEstimateRenderConfig'">
    <estimate-gfe-renderer [headerSections]="headerSections"
                           [disclaimerSections]="disclaimerSections"
                           [additionalSections]="additionalSections"
                           [estimateChange]="estimateChange"
                           [estimate]="estimate"
                           [appName]="appName"></estimate-gfe-renderer>

  </ng-container>

  <ng-container *ngIf="estimate?.type == 'EstimateAttachmentRenderConfig'">
    <estimate-attachment-renderer [loading]="loading"
                                  [estimateDisclaimerAcknowledged]="estimateDisclaimerAcknowledged"
                                  [estimate]="estimate"></estimate-attachment-renderer>
  </ng-container>

</ng-container>
