/**
 * Registrar Api
 * The registrar api
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent, HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { EncounterModel, WorkQueuesModel } from '../model/models';
import { GetWorkQueueItemsQuery } from '../model/models';
import { UpdateWorkQueueItemCommand } from '../model/models';
import { WorkQueueCriteriaModel } from '../model/models';
import { WorkQueueFilterResponseModel } from '../model/models';
import { WorkQueueItemModel } from '../model/models';
import { WorkQueueItemStatusModel } from '../model/models';
import { WorkQueueModel } from '../model/models';
import { WorkQueueRolesModel } from '../model/models';
import { WorkQueueSortByResponseModel } from '../model/models';
import { WorkQueueUserRolesModel } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';



@Injectable({
    providedIn: 'root'
})
export class WorkQueueService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                    throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param workQueueCriteriaModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerCreateCriteria(workQueueCriteriaModel: WorkQueueCriteriaModel, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<WorkQueueCriteriaModel>;
    public workQueueApiControllerCreateCriteria(workQueueCriteriaModel: WorkQueueCriteriaModel, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<WorkQueueCriteriaModel>>;
    public workQueueApiControllerCreateCriteria(workQueueCriteriaModel: WorkQueueCriteriaModel, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<WorkQueueCriteriaModel>>;
    public workQueueApiControllerCreateCriteria(workQueueCriteriaModel: WorkQueueCriteriaModel, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (workQueueCriteriaModel === null || workQueueCriteriaModel === undefined) {
            throw new Error('Required parameter workQueueCriteriaModel was null or undefined when calling workQueueApiControllerCreateCriteria.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<WorkQueueCriteriaModel>(`${this.configuration.basePath}/workQueue/criteria`,
            workQueueCriteriaModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param workQueueItemStatusModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerCreateStatus(workQueueItemStatusModel: WorkQueueItemStatusModel, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<WorkQueueItemStatusModel>;
    public workQueueApiControllerCreateStatus(workQueueItemStatusModel: WorkQueueItemStatusModel, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<WorkQueueItemStatusModel>>;
    public workQueueApiControllerCreateStatus(workQueueItemStatusModel: WorkQueueItemStatusModel, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<WorkQueueItemStatusModel>>;
    public workQueueApiControllerCreateStatus(workQueueItemStatusModel: WorkQueueItemStatusModel, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (workQueueItemStatusModel === null || workQueueItemStatusModel === undefined) {
            throw new Error('Required parameter workQueueItemStatusModel was null or undefined when calling workQueueApiControllerCreateStatus.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<WorkQueueItemStatusModel>(`${this.configuration.basePath}/workQueue/status`,
            workQueueItemStatusModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param workQueueUserRolesModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerCreateUserRole(workQueueUserRolesModel: WorkQueueUserRolesModel, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<WorkQueueUserRolesModel>;
    public workQueueApiControllerCreateUserRole(workQueueUserRolesModel: WorkQueueUserRolesModel, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<WorkQueueUserRolesModel>>;
    public workQueueApiControllerCreateUserRole(workQueueUserRolesModel: WorkQueueUserRolesModel, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<WorkQueueUserRolesModel>>;
    public workQueueApiControllerCreateUserRole(workQueueUserRolesModel: WorkQueueUserRolesModel, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (workQueueUserRolesModel === null || workQueueUserRolesModel === undefined) {
            throw new Error('Required parameter workQueueUserRolesModel was null or undefined when calling workQueueApiControllerCreateUserRole.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<WorkQueueUserRolesModel>(`${this.configuration.basePath}/workQueue/userRoles`,
            workQueueUserRolesModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param workQueueModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerCreateWorkQueue(workQueueModel: WorkQueueModel, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<WorkQueueModel>;
    public workQueueApiControllerCreateWorkQueue(workQueueModel: WorkQueueModel, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<WorkQueueModel>>;
    public workQueueApiControllerCreateWorkQueue(workQueueModel: WorkQueueModel, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<WorkQueueModel>>;
    public workQueueApiControllerCreateWorkQueue(workQueueModel: WorkQueueModel, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (workQueueModel === null || workQueueModel === undefined) {
            throw new Error('Required parameter workQueueModel was null or undefined when calling workQueueApiControllerCreateWorkQueue.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<WorkQueueModel>(`${this.configuration.basePath}/workQueue/`,
            workQueueModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param workQueueItemModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerCreateWorkQueueItemAssignment(workQueueItemModel: WorkQueueItemModel, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<WorkQueueItemModel>;
    public workQueueApiControllerCreateWorkQueueItemAssignment(workQueueItemModel: WorkQueueItemModel, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<WorkQueueItemModel>>;
    public workQueueApiControllerCreateWorkQueueItemAssignment(workQueueItemModel: WorkQueueItemModel, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<WorkQueueItemModel>>;
    public workQueueApiControllerCreateWorkQueueItemAssignment(workQueueItemModel: WorkQueueItemModel, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (workQueueItemModel === null || workQueueItemModel === undefined) {
            throw new Error('Required parameter workQueueItemModel was null or undefined when calling workQueueApiControllerCreateWorkQueueItemAssignment.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<WorkQueueItemModel>(`${this.configuration.basePath}/workQueue/item/assign`,
            workQueueItemModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param criteriaId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerDeleteCriteria(criteriaId: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: undefined; }): Observable<any>;
    public workQueueApiControllerDeleteCriteria(criteriaId: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: undefined; }): Observable<HttpResponse<any>>;
    public workQueueApiControllerDeleteCriteria(criteriaId: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: undefined; }): Observable<HttpEvent<any>>;
    public workQueueApiControllerDeleteCriteria(criteriaId: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: undefined; }): Observable<any> {
        if (criteriaId === null || criteriaId === undefined) {
            throw new Error('Required parameter criteriaId was null or undefined when calling workQueueApiControllerDeleteCriteria.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/workQueue/criteria/${encodeURIComponent(String(criteriaId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param itemStatusId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerDeleteStatus(itemStatusId: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: undefined; }): Observable<any>;
    public workQueueApiControllerDeleteStatus(itemStatusId: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: undefined; }): Observable<HttpResponse<any>>;
    public workQueueApiControllerDeleteStatus(itemStatusId: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: undefined; }): Observable<HttpEvent<any>>;
    public workQueueApiControllerDeleteStatus(itemStatusId: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: undefined; }): Observable<any> {
        if (itemStatusId === null || itemStatusId === undefined) {
            throw new Error('Required parameter itemStatusId was null or undefined when calling workQueueApiControllerDeleteStatus.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/workQueue/status/${encodeURIComponent(String(itemStatusId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param userRoleId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerDeleteUserRole(userRoleId: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: undefined; }): Observable<any>;
    public workQueueApiControllerDeleteUserRole(userRoleId: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: undefined; }): Observable<HttpResponse<any>>;
    public workQueueApiControllerDeleteUserRole(userRoleId: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: undefined; }): Observable<HttpEvent<any>>;
    public workQueueApiControllerDeleteUserRole(userRoleId: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: undefined; }): Observable<any> {
        if (userRoleId === null || userRoleId === undefined) {
            throw new Error('Required parameter userRoleId was null or undefined when calling workQueueApiControllerDeleteUserRole.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/workQueue/userRoles/${encodeURIComponent(String(userRoleId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param workQueueId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerDeleteWorkQueue(workQueueId: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: undefined; }): Observable<any>;
    public workQueueApiControllerDeleteWorkQueue(workQueueId: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: undefined; }): Observable<HttpResponse<any>>;
    public workQueueApiControllerDeleteWorkQueue(workQueueId: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: undefined; }): Observable<HttpEvent<any>>;
    public workQueueApiControllerDeleteWorkQueue(workQueueId: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: undefined; }): Observable<any> {
        if (workQueueId === null || workQueueId === undefined) {
            throw new Error('Required parameter workQueueId was null or undefined when calling workQueueApiControllerDeleteWorkQueue.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/workQueue/${encodeURIComponent(String(workQueueId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param assignmentId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerDeleteWorkQueueItemAssignment(assignmentId: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: undefined; }): Observable<any>;
    public workQueueApiControllerDeleteWorkQueueItemAssignment(assignmentId: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: undefined; }): Observable<HttpResponse<any>>;
    public workQueueApiControllerDeleteWorkQueueItemAssignment(assignmentId: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: undefined; }): Observable<HttpEvent<any>>;
    public workQueueApiControllerDeleteWorkQueueItemAssignment(assignmentId: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: undefined; }): Observable<any> {
        if (assignmentId === null || assignmentId === undefined) {
            throw new Error('Required parameter assignmentId was null or undefined when calling workQueueApiControllerDeleteWorkQueueItemAssignment.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/workQueue/item/assign/${encodeURIComponent(String(assignmentId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param itemId
     * @param limit
     * @param index
     * @param limit2
     * @param index2
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerFindWorkQueueItemAssignment(itemId: number, limit?: number, index?: number, limit2?: number, index2?: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<WorkQueueCriteriaModel>;
    public workQueueApiControllerFindWorkQueueItemAssignment(itemId: number, limit?: number, index?: number, limit2?: number, index2?: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<WorkQueueCriteriaModel>>;
    public workQueueApiControllerFindWorkQueueItemAssignment(itemId: number, limit?: number, index?: number, limit2?: number, index2?: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<WorkQueueCriteriaModel>>;
    public workQueueApiControllerFindWorkQueueItemAssignment(itemId: number, limit?: number, index?: number, limit2?: number, index2?: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (itemId === null || itemId === undefined) {
            throw new Error('Required parameter itemId was null or undefined when calling workQueueApiControllerFindWorkQueueItemAssignment.');
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (limit !== undefined && limit !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>limit, 'limit');
        }
        if (index !== undefined && index !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>index, 'index');
        }
        if (limit2 !== undefined && limit2 !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>limit2, 'limit');
        }
        if (index2 !== undefined && index2 !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>index2, 'index');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<WorkQueueCriteriaModel>(`${this.configuration.basePath}/workQueue/item/assign/${encodeURIComponent(String(itemId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param limit
     * @param index
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerGetCriteria(limit?: number, index?: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<Array<WorkQueueCriteriaModel>>;
    public workQueueApiControllerGetCriteria(limit?: number, index?: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<Array<WorkQueueCriteriaModel>>>;
    public workQueueApiControllerGetCriteria(limit?: number, index?: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<Array<WorkQueueCriteriaModel>>>;
    public workQueueApiControllerGetCriteria(limit?: number, index?: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (limit !== undefined && limit !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>limit, 'limit');
        }
        if (index !== undefined && index !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>index, 'index');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<WorkQueueCriteriaModel>>(`${this.configuration.basePath}/workQueue/criteria`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerGetFilterFields(observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<Array<WorkQueueFilterResponseModel>>;
    public workQueueApiControllerGetFilterFields(observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<Array<WorkQueueFilterResponseModel>>>;
    public workQueueApiControllerGetFilterFields(observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<Array<WorkQueueFilterResponseModel>>>;
    public workQueueApiControllerGetFilterFields(observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<WorkQueueFilterResponseModel>>(`${this.configuration.basePath}/workQueue/filterFields`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param limit
     * @param index
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerGetRoles(limit?: number, index?: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<Array<WorkQueueRolesModel>>;
    public workQueueApiControllerGetRoles(limit?: number, index?: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<Array<WorkQueueRolesModel>>>;
    public workQueueApiControllerGetRoles(limit?: number, index?: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<Array<WorkQueueRolesModel>>>;
    public workQueueApiControllerGetRoles(limit?: number, index?: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (limit !== undefined && limit !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>limit, 'limit');
        }
        if (index !== undefined && index !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>index, 'index');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<WorkQueueRolesModel>>(`${this.configuration.basePath}/workQueue/roles`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param workQueueType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerGetSortFields(workQueueType: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<Array<WorkQueueSortByResponseModel>>;
    public workQueueApiControllerGetSortFields(workQueueType: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<Array<WorkQueueSortByResponseModel>>>;
    public workQueueApiControllerGetSortFields(workQueueType: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<Array<WorkQueueSortByResponseModel>>>;
    public workQueueApiControllerGetSortFields(workQueueType: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (workQueueType === null || workQueueType === undefined) {
            throw new Error('Required parameter workQueueType was null or undefined when calling workQueueApiControllerGetSortFields.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<WorkQueueSortByResponseModel>>(`${this.configuration.basePath}/workQueue/sortFields/${encodeURIComponent(String(workQueueType))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param workQueueType
     * @param limit
     * @param index
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerGetStatuses(workQueueType: number, limit?: number, index?: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<Array<WorkQueueItemStatusModel>>;
    public workQueueApiControllerGetStatuses(workQueueType: number, limit?: number, index?: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<Array<WorkQueueItemStatusModel>>>;
    public workQueueApiControllerGetStatuses(workQueueType: number, limit?: number, index?: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<Array<WorkQueueItemStatusModel>>>;
    public workQueueApiControllerGetStatuses(workQueueType: number, limit?: number, index?: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (workQueueType === null || workQueueType === undefined) {
            throw new Error('Required parameter workQueueType was null or undefined when calling workQueueApiControllerGetStatuses.');
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (limit !== undefined && limit !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>limit, 'limit');
        }
        if (index !== undefined && index !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>index, 'index');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<WorkQueueItemStatusModel>>(`${this.configuration.basePath}/workQueue/status/${encodeURIComponent(String(workQueueType))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param limit
     * @param index
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerGetUserRoles(limit?: number, index?: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<Array<WorkQueueUserRolesModel>>;
    public workQueueApiControllerGetUserRoles(limit?: number, index?: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<Array<WorkQueueUserRolesModel>>>;
    public workQueueApiControllerGetUserRoles(limit?: number, index?: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<Array<WorkQueueUserRolesModel>>>;
    public workQueueApiControllerGetUserRoles(limit?: number, index?: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (limit !== undefined && limit !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>limit, 'limit');
        }
        if (index !== undefined && index !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>index, 'index');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<WorkQueueUserRolesModel>>(`${this.configuration.basePath}/workQueue/userRoles`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param workQueueId
     * @param limit
     * @param index
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerGetWorkQueue(workQueueId: number, limit?: number, index?: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<WorkQueueModel>;
    public workQueueApiControllerGetWorkQueue(workQueueId: number, limit?: number, index?: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<WorkQueueModel>>;
    public workQueueApiControllerGetWorkQueue(workQueueId: number, limit?: number, index?: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<WorkQueueModel>>;
    public workQueueApiControllerGetWorkQueue(workQueueId: number, limit?: number, index?: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (workQueueId === null || workQueueId === undefined) {
            throw new Error('Required parameter workQueueId was null or undefined when calling workQueueApiControllerGetWorkQueue.');
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (limit !== undefined && limit !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>limit, 'limit');
        }
        if (index !== undefined && index !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>index, 'index');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<WorkQueueModel>(`${this.configuration.basePath}/workQueue/${encodeURIComponent(String(workQueueId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param workQueueId
     * @param limit
     * @param index
     * @param currentWorkQueueItemId
     * @param searchTerms
     * @param sortBy
     * @param sortDirection
     * @param filterFields
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerGetWorkQueueEncounterByWorkQueueIdAndPriority(workQueueId: number, limit?: number, index?: number, currentWorkQueueItemId?: number, searchTerms?: string, sortBy?: string, sortDirection?: string, filterFields?: object, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<EncounterModel>;
    public workQueueApiControllerGetWorkQueueEncounterByWorkQueueIdAndPriority(workQueueId: number, limit?: number, index?: number, currentWorkQueueItemId?: number, searchTerms?: string, sortBy?: string, sortDirection?: string, filterFields?: object, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<EncounterModel>>;
    public workQueueApiControllerGetWorkQueueEncounterByWorkQueueIdAndPriority(workQueueId: number, limit?: number, index?: number, currentWorkQueueItemId?: number, searchTerms?: string, sortBy?: string, sortDirection?: string, filterFields?: object, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<EncounterModel>>;
    public workQueueApiControllerGetWorkQueueEncounterByWorkQueueIdAndPriority(workQueueId: number, limit?: number, index?: number, currentWorkQueueItemId?: number, searchTerms?: string, sortBy?: string, sortDirection?: string, filterFields?: object, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (workQueueId === null || workQueueId === undefined) {
            throw new Error('Required parameter workQueueId was null or undefined when calling workQueueApiControllerGetWorkQueueEncounterByWorkQueueIdAndPriority.');
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (limit !== undefined && limit !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>limit, 'limit');
        }
        if (index !== undefined && index !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>index, 'index');
        }
        if (currentWorkQueueItemId !== undefined && currentWorkQueueItemId !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>currentWorkQueueItemId, 'currentWorkQueueItemId');
        }
        if (searchTerms !== undefined && searchTerms !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>searchTerms, 'searchTerms');
        }
        if (sortBy !== undefined && sortBy !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>sortBy, 'sortBy');
        }
        if (sortDirection !== undefined && sortDirection !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>sortDirection, 'sortDirection');
        }
        if (filterFields !== undefined && filterFields !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>filterFields, 'filterFields');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EncounterModel>(`${this.configuration.basePath}/workQueue/${encodeURIComponent(String(workQueueId))}/encounters/next`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param workQueueId
     * @param getWorkQueueItemsQuery
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerGetWorkQueueEncounterItemsByWorkQueueId(workQueueId: number, getWorkQueueItemsQuery: GetWorkQueueItemsQuery, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<Array<EncounterModel>>;
    public workQueueApiControllerGetWorkQueueEncounterItemsByWorkQueueId(workQueueId: number, getWorkQueueItemsQuery: GetWorkQueueItemsQuery, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<Array<EncounterModel>>>;
    public workQueueApiControllerGetWorkQueueEncounterItemsByWorkQueueId(workQueueId: number, getWorkQueueItemsQuery: GetWorkQueueItemsQuery, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<Array<EncounterModel>>>;
    public workQueueApiControllerGetWorkQueueEncounterItemsByWorkQueueId(workQueueId: number, getWorkQueueItemsQuery: GetWorkQueueItemsQuery, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (workQueueId === null || workQueueId === undefined) {
            throw new Error('Required parameter workQueueId was null or undefined when calling workQueueApiControllerGetWorkQueueEncounterItemsByWorkQueueId.');
        }
        if (getWorkQueueItemsQuery === null || getWorkQueueItemsQuery === undefined) {
            throw new Error('Required parameter getWorkQueueItemsQuery was null or undefined when calling workQueueApiControllerGetWorkQueueEncounterItemsByWorkQueueId.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<EncounterModel>>(`${this.configuration.basePath}/workQueue/${encodeURIComponent(String(workQueueId))}/encounters`,
            getWorkQueueItemsQuery,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param workQueueId
     * @param workQueueItemId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerGetWorkQueueItemById(workQueueId: number, workQueueItemId: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<WorkQueueItemModel>;
    public workQueueApiControllerGetWorkQueueItemById(workQueueId: number, workQueueItemId: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<WorkQueueItemModel>>;
    public workQueueApiControllerGetWorkQueueItemById(workQueueId: number, workQueueItemId: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<WorkQueueItemModel>>;
    public workQueueApiControllerGetWorkQueueItemById(workQueueId: number, workQueueItemId: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (workQueueId === null || workQueueId === undefined) {
            throw new Error('Required parameter workQueueId was null or undefined when calling workQueueApiControllerGetWorkQueueItemById.');
        }
        if (workQueueItemId === null || workQueueItemId === undefined) {
            throw new Error('Required parameter workQueueItemId was null or undefined when calling workQueueApiControllerGetWorkQueueItemById.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<WorkQueueItemModel>(`${this.configuration.basePath}/workQueue/${encodeURIComponent(String(workQueueId))}/item/${encodeURIComponent(String(workQueueItemId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param workQueueId
     * @param getWorkQueueItemsQuery
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerGetWorkQueueSessionItemsByWorkQueueId(workQueueId: number, getWorkQueueItemsQuery: GetWorkQueueItemsQuery, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<Array<WorkQueueItemModel>>;
    public workQueueApiControllerGetWorkQueueSessionItemsByWorkQueueId(workQueueId: number, getWorkQueueItemsQuery: GetWorkQueueItemsQuery, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<Array<WorkQueueItemModel>>>;
    public workQueueApiControllerGetWorkQueueSessionItemsByWorkQueueId(workQueueId: number, getWorkQueueItemsQuery: GetWorkQueueItemsQuery, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<Array<WorkQueueItemModel>>>;
    public workQueueApiControllerGetWorkQueueSessionItemsByWorkQueueId(workQueueId: number, getWorkQueueItemsQuery: GetWorkQueueItemsQuery, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (workQueueId === null || workQueueId === undefined) {
            throw new Error('Required parameter workQueueId was null or undefined when calling workQueueApiControllerGetWorkQueueSessionItemsByWorkQueueId.');
        }
        if (getWorkQueueItemsQuery === null || getWorkQueueItemsQuery === undefined) {
            throw new Error('Required parameter getWorkQueueItemsQuery was null or undefined when calling workQueueApiControllerGetWorkQueueSessionItemsByWorkQueueId.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<WorkQueueItemModel>>(`${this.configuration.basePath}/workQueue/${encodeURIComponent(String(workQueueId))}/sessions`,
            getWorkQueueItemsQuery,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param limit
     * @param index
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerGetWorkQueues(limit?: number, index?: number, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<WorkQueuesModel>;
    public workQueueApiControllerGetWorkQueues(limit?: number, index?: number, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<WorkQueuesModel>>;
    public workQueueApiControllerGetWorkQueues(limit?: number, index?: number, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<WorkQueuesModel>>;
    public workQueueApiControllerGetWorkQueues(limit?: number, index?: number, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (limit !== undefined && limit !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>limit, 'limit');
        }
        if (index !== undefined && index !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                <any>index, 'index');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<WorkQueuesModel>(`${this.configuration.basePath}/workQueue/`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param workQueueCriteriaModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerUpdateCriteria(workQueueCriteriaModel: WorkQueueCriteriaModel, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<WorkQueueCriteriaModel>;
    public workQueueApiControllerUpdateCriteria(workQueueCriteriaModel: WorkQueueCriteriaModel, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<WorkQueueCriteriaModel>>;
    public workQueueApiControllerUpdateCriteria(workQueueCriteriaModel: WorkQueueCriteriaModel, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<WorkQueueCriteriaModel>>;
    public workQueueApiControllerUpdateCriteria(workQueueCriteriaModel: WorkQueueCriteriaModel, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (workQueueCriteriaModel === null || workQueueCriteriaModel === undefined) {
            throw new Error('Required parameter workQueueCriteriaModel was null or undefined when calling workQueueApiControllerUpdateCriteria.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<WorkQueueCriteriaModel>(`${this.configuration.basePath}/workQueue/criteria`,
            workQueueCriteriaModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param workQueueItemStatusModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerUpdateStatus(workQueueItemStatusModel: WorkQueueItemStatusModel, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<WorkQueueItemStatusModel>;
    public workQueueApiControllerUpdateStatus(workQueueItemStatusModel: WorkQueueItemStatusModel, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<WorkQueueItemStatusModel>>;
    public workQueueApiControllerUpdateStatus(workQueueItemStatusModel: WorkQueueItemStatusModel, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<WorkQueueItemStatusModel>>;
    public workQueueApiControllerUpdateStatus(workQueueItemStatusModel: WorkQueueItemStatusModel, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (workQueueItemStatusModel === null || workQueueItemStatusModel === undefined) {
            throw new Error('Required parameter workQueueItemStatusModel was null or undefined when calling workQueueApiControllerUpdateStatus.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<WorkQueueItemStatusModel>(`${this.configuration.basePath}/workQueue/status`,
            workQueueItemStatusModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param workQueueUserRolesModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerUpdateUserRole(workQueueUserRolesModel: WorkQueueUserRolesModel, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<WorkQueueUserRolesModel>;
    public workQueueApiControllerUpdateUserRole(workQueueUserRolesModel: WorkQueueUserRolesModel, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<WorkQueueUserRolesModel>>;
    public workQueueApiControllerUpdateUserRole(workQueueUserRolesModel: WorkQueueUserRolesModel, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<WorkQueueUserRolesModel>>;
    public workQueueApiControllerUpdateUserRole(workQueueUserRolesModel: WorkQueueUserRolesModel, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (workQueueUserRolesModel === null || workQueueUserRolesModel === undefined) {
            throw new Error('Required parameter workQueueUserRolesModel was null or undefined when calling workQueueApiControllerUpdateUserRole.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<WorkQueueUserRolesModel>(`${this.configuration.basePath}/workQueue/userRoles`,
            workQueueUserRolesModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param workQueueModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerUpdateWorkQueue(workQueueModel: WorkQueueModel, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<WorkQueueModel>;
    public workQueueApiControllerUpdateWorkQueue(workQueueModel: WorkQueueModel, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<WorkQueueModel>>;
    public workQueueApiControllerUpdateWorkQueue(workQueueModel: WorkQueueModel, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<WorkQueueModel>>;
    public workQueueApiControllerUpdateWorkQueue(workQueueModel: WorkQueueModel, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (workQueueModel === null || workQueueModel === undefined) {
            throw new Error('Required parameter workQueueModel was null or undefined when calling workQueueApiControllerUpdateWorkQueue.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<WorkQueueModel>(`${this.configuration.basePath}/workQueue/`,
            workQueueModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param workQueueItemModel
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerUpdateWorkQueueItemAssignment(workQueueItemModel: WorkQueueItemModel, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<WorkQueueItemModel>;
    public workQueueApiControllerUpdateWorkQueueItemAssignment(workQueueItemModel: WorkQueueItemModel, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<WorkQueueItemModel>>;
    public workQueueApiControllerUpdateWorkQueueItemAssignment(workQueueItemModel: WorkQueueItemModel, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<WorkQueueItemModel>>;
    public workQueueApiControllerUpdateWorkQueueItemAssignment(workQueueItemModel: WorkQueueItemModel, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (workQueueItemModel === null || workQueueItemModel === undefined) {
            throw new Error('Required parameter workQueueItemModel was null or undefined when calling workQueueApiControllerUpdateWorkQueueItemAssignment.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<WorkQueueItemModel>(`${this.configuration.basePath}/workQueue/item/assign`,
            workQueueItemModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param updateWorkQueueItemCommand
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workQueueApiControllerUpdateWorkQueueItemStatus(updateWorkQueueItemCommand: UpdateWorkQueueItemCommand, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<WorkQueueItemModel>;
    public workQueueApiControllerUpdateWorkQueueItemStatus(updateWorkQueueItemCommand: UpdateWorkQueueItemCommand, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpResponse<WorkQueueItemModel>>;
    public workQueueApiControllerUpdateWorkQueueItemStatus(updateWorkQueueItemCommand: UpdateWorkQueueItemCommand, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json'; }): Observable<HttpEvent<WorkQueueItemModel>>;
    public workQueueApiControllerUpdateWorkQueueItemStatus(updateWorkQueueItemCommand: UpdateWorkQueueItemCommand, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json'; }): Observable<any> {
        if (updateWorkQueueItemCommand === null || updateWorkQueueItemCommand === undefined) {
            throw new Error('Required parameter updateWorkQueueItemCommand was null or undefined when calling workQueueApiControllerUpdateWorkQueueItemStatus.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<WorkQueueItemModel>(`${this.configuration.basePath}/workQueue/item/status`,
            updateWorkQueueItemCommand,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
