import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RSModalComponent } from './components/template/modal-template.component';
import { RsModalHostDirective } from './directives/modal-host.directive';
import { RsModalTargetDirective } from './directives/modal-target.directive';
import { RSBackdropComponent } from './components/backdrop/backdrop.component';

@NgModule({
  declarations: [
    RSModalComponent,
    RsModalHostDirective,
    RsModalTargetDirective,
    RSBackdropComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    RSModalComponent,
    RsModalHostDirective,
    RsModalTargetDirective,
  ]
})
export class ModalsModule { }
