<div *ngIf="sidenavService.sidenavState$ | async as sidenavState">
    <div *ngIf="sidenavState.state.isVisible"
         class="items-center"
         [ngClass]="{'flex': sidenavState.state.isOverlay, 'flex lg:hidden': !sidenavState.state.isOverlay}">
        <!-- Mobile menu button-->
        <button type="button"
                (click)="sidenavService.toggle()"
                class="inline-flex items-center justify-center p-2 rounded-md text-th-secondary hover:text-white hover:bg-th-primary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <svg class="block w-8 h-8"
                 xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
                 aria-hidden="true">
                <path stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <svg class="hidden w-6 h-6"
                 xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
                 aria-hidden="true">
                <path stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
    </div>
</div>
