<div (clickOutside)="isToggled? onClickOutside($event) : null"
     [ngClass]="{ show: isToggled, 'dropdown-select': dropdownSelect, disabled: isDisabled }"
     class="dropdown {{ wrapClass }}">
  <button type="button"
          [id]="id"
          data-toggle="dropdown"
          aria-haspopup="true"
          [attr.aria-expanded]="isToggled"
          (click)="onClick($event)"
          (keyup.escape)="isToggled ? onClickOutside($event) : null"
          class="rs-btn-link text-left d-flex justify-content-between align-items-center stretch-button p-2 dropdown-toggle {{triggerWrapClass}}"
          [ngClass]="dropdownSelect ? 'btn-block select' : 'p-0'">
    <ng-container *ngIf="!hideLabel">
      <span *ngIf="label"
            class="label">{{ label | translate }}</span>
      <span *ngIf="value"
            class="value">{{ dropdownValue | translate }}</span>
      <span *ngIf="!value"
            class="value"> {{ label | translate }} </span>
    </ng-container>
    <ng-content></ng-content>
  </button>
  <div [ngClass]="{show: isToggled, 'dropdown-menu-right': headerDropdown}"
       class="dropdown-menu py-0 px-2"
       [attr.aria-labelledby]="id"
       role="menu">
    <ng-container *ngFor="let item of items, let i = index">
      <button *ngIf="!item.invisible"
              class="dropdown-item"
              [ngClass]="{ 'active': (multiple && value && value.includes(item.value)) || (!multiple && value === item.value)}"
              type="button"
              (keyup.escape)="isToggled ? onClickOutside($event) : null"
              (click)="onSelect($event, item, i)"
              role="menuitem">
        {{ item.label | translate }}
      </button>
    </ng-container>
  </div>
</div>
