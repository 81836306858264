export * from './batchMgr.service';
import { BatchMgrService } from './batchMgr.service';
export * from './comMgr.service';
import { ComMgrService } from './comMgr.service';
export * from './dataMgr.service';
import { DataMgrService } from './dataMgr.service';
export * from './evtMgr.service';
import { EvtMgrService } from './evtMgr.service';
export * from './issueMgr.service';
import { IssueMgrService } from './issueMgr.service';
export * from './iwsMgr.service';
import { IwsMgrService } from './iwsMgr.service';
export * from './orgMgr.service';
import { OrgMgrService } from './orgMgr.service';
export * from './pmtMgr.service';
import { PmtMgrService } from './pmtMgr.service';
export * from './pmtProc.service';
import { PmtProcService } from './pmtProc.service';
export * from './processMgr.service';
import { ProcessMgrService } from './processMgr.service';
export * from './reportMgr.service';
import { ReportMgrService } from './reportMgr.service';
export * from './siteMgr.service';
import { SiteMgrService } from './siteMgr.service';
export * from './userMgr.service';
import { UserMgrService } from './userMgr.service';
export * from './webhookMgr.service';
import { WebhookMgrService } from './webhookMgr.service';
export const APIS = [BatchMgrService, ComMgrService, DataMgrService, EvtMgrService, IssueMgrService, IwsMgrService, OrgMgrService, PmtMgrService, PmtProcService, ProcessMgrService, ReportMgrService, SiteMgrService, UserMgrService, WebhookMgrService];
