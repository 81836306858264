import { Component, Input } from '@angular/core';
import { EstimateServiceGroupModel } from '../../models/estimateServiceGroupModel';

@Component({
    selector: 'service-group-total',
    templateUrl: './service-group-total.component.html',
    styleUrls: ['../../scss/styles.scss']
})
export class ServiceGroupTotalComponent {
    @Input() public serviceGroup: EstimateServiceGroupModel | undefined;


    public get show() {

        if (!this.serviceGroup) {
            return false;
        }

        if (this.serviceGroup.totalVisible) {
            return true;
        }

        return false;
    }
}
