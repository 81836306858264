import { Action, createReducer, on } from '@ngrx/store';

import { initialState, State } from './state';
import { SetActiveAction } from './actions';

const organizationReducer = createReducer(
    initialState,

    on(SetActiveAction, (state, { organization }) => {
        return {
            ...state,
            organization,
        };
    }),
);

export function OrganizationReducer(state: State | undefined, action: Action) {
    return organizationReducer(state, action);
}
