// third-party imports
import { Component, Input } from '@angular/core';
/**
 *
 * @export
 * @class BsDateComponent
 */
@Component({
  selector: 'rs-date',
  template: `
  <div [formGroup]="element.group">
  <rs-date-holder  [formControlName]="element.name" [element]="element" ></rs-date-holder>
  </div>
  `
})
export class RsDateComponent {
  @Input() element;
}
