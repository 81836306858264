import { Injectable, ComponentRef, Type } from '@angular/core';
import { take } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';

import { RsModalHostDirective } from '../directives/modal-host.directive';
import { ModalWrapComponent } from '../models/modal-wrap-component';
import { ModalRegistryService } from './modal-registry.service';

@Injectable({ providedIn: 'root' })
export class ModalService {
    public readonly openModals: ComponentRef<ModalWrapComponent>[] = [];
    public readonly hasModalOpen$ = new BehaviorSubject<boolean>(false);
    public readonly openModal$ = new BehaviorSubject<ModalWrapComponent>(null);

    private host: RsModalHostDirective;
    private nonModalNodes;

    constructor(private readonly modalRegistryService: ModalRegistryService) {}

    public init(host: RsModalHostDirective) {
        this.host = host;
        this.host.viewContainerRef.clear();
    }

    public show(name: string, payload?: any): Observable<any> {
        const modalRef = this.resolve(name);

        if (this.openModals.length > 0) {
            const curModalInstance = this.openModals[this.openModals.length - 1].instance;
            curModalInstance.modal.hide();
            this.openModal$.next(curModalInstance);
        } else {
            this.setTabIndex();
        }

        const modalInstance = modalRef.instance as ModalWrapComponent;
        if (modalInstance.setModalRef) {
            modalInstance.setModalRef();
        }

        modalInstance.modal.show(payload);

        this.openModals.push(modalRef);
        this.hasModalOpen$.next(true);
        this.openModal$.next(modalRef.instance);

        return modalRef.instance.modal.onClose$.pipe(take(1));
    }

    public hide(payload?: any) {
        if (this.openModals.length === 0) {
            return;
        }
        const lastIndex = this.openModals.length - 1;
        const modal = this.openModals[lastIndex];
        modal.instance.modal.onClose$.pipe(take(1)).subscribe(() => modal.destroy());
        modal.instance.modal.destroy(payload);
        this.openModals.splice(lastIndex, 1);
        if (this.openModals.length > 0) {
            const modalInstance = this.openModals[this.openModals.length - 1].instance;
            modalInstance.modal.show(undefined, true);
            this.openModal$.next(modalInstance);
        } else {
            this.unsetTabIndex();
            this.hasModalOpen$.next(false);
            this.openModal$.next(null);
        }
    }

    private resolve(name: string) {
        const modal = this.modalRegistryService.resolve(name);
        if (!modal) {
            throw new Error(`Modal ${name} is not registered`);
        }
        const componentRef = this.host.viewContainerRef.createComponent<ModalWrapComponent>(modal);

        return componentRef;
    }

    private setTabIndex() {
        const modalNodes = Array.from(document.querySelectorAll('dialog *'));
        // by only finding elements that do not have tabindex="-1" we ensure we don't
        // corrupt the previous state of the element if a modal was already open
        this.nonModalNodes = Array.from(document.querySelectorAll('body *:not(dialog):not([tabindex="-1"])'));
        this.nonModalNodes.forEach((node: any) => {
            if (!modalNodes.includes(node)) {
                // save the previous tabindex state so we can restore it on close
                node._prevTabindex = node.getAttribute('tabindex');
                node.setAttribute('tabindex', -1);
                // tabindex=-1 does not prevent the mouse from focusing the node (which
                // would show a focus outline around the element). prevent this by disabling
                // outline styles while the modal is open
                // @see https://www.sitepoint.com/when-do-elements-take-the-focus/
                node.style.outline = 'none';
            }
        });
    }

    private unsetTabIndex() {
        document.body.style.overflow = null;
        // restore or remove tabindex from nodes
        this.nonModalNodes.forEach((node: any) => {
            if (node._prevTabindex) {
                node.setAttribute('tabindex', node._prevTabindex);
                node._prevTabindex = null;
            } else {
                node.removeAttribute('tabindex');
            }
            node.style.outline = null;
        });
    }
}
