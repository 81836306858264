<div *ngFor="let section of sections"
     [ngClass]="getSectionClass(section)"
     ng-form
     name="section">

    <ng-container *ngIf="section?.sections?.length > 0">
        <estimate-section [estimate]="estimate"
                          [sections]="section.sections"
                          [estimateChange]="estimateChange"></estimate-section>
    </ng-container>

    <div [@hasError]="hasError(section) ? 'error' : 'valid'"
         [ngClass]="getItemGroupClass(section)">
        <div *ngFor="let item of section?.items"
             [ngClass]="getItemClass(item)">

            <pa-hero-icon *ngIf="item.icon"
                          [name]="item.icon.name"
                          [size]="item.icon.size"
                          [class]="item.icon.class">
            </pa-hero-icon>

            <editable-item [estimate]="estimate"
                           [item]="item"
                           [estimateChange]="estimateChange">
            </editable-item>
        </div>
    </div>
</div>
