<div *ngIf="open()"
     class="d-flex alert"
     [ngClass]="{'alert-danger': alert?.priority === 'danger',
                  'alert-success': alert?.priority === 'success',
                  'alert-warning': alert?.priority === 'warning'}"
     @slideInOut>
    <span class="alert icon"
          [ngClass]="alert?.icon"></span>
    <ng-container *ngIf="alert?.message">
        <span class="alert message">
            {{ alert?.message | translate }}
        </span>
    </ng-container>
</div>
