import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { CustomSerializer } from './custom-router-serializer';
import { RouterEffects } from './effects';
import { reducers } from './state';


@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('global-router', reducers),
        EffectsModule.forFeature([RouterEffects]),
        StoreRouterConnectingModule.forRoot({
            serializer: CustomSerializer,
            stateKey: 'router'
        }),
    ],
    providers: []
})
export class GlobalRouterStoreModule { }
