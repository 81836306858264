import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormResponseModel, FormService } from '@shopping';
import { RsModalConfig } from '@revspringinc/rs-shared';
import { ModalWrapComponent } from '@revspringinc/rs-shared';
import { ShoppingSessionService } from '../../main/session.service';
import { ShoppingActivityType } from '../../main/shopping-activity-type';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@revspringinc/rs-shared';
import * as _ from 'lodash-es';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'rs-notice-message',
    templateUrl: './notice-message.component.html',
    styleUrls: ['./notice-message.scss'],
})
export class NoticeMessageModal implements ModalWrapComponent {
    public static modalName = 'NoticeMessageModal';

    @ViewChildren('resolver') public resolvers: QueryList<any>;
    @ViewChild('modal', { static: true }) public modal;

    public modalConfig = {
        activityType: ShoppingActivityType.NoticeMessage,
        name: 'NoticeMessageModal',
        maxWidth: '650px',
        bodyClass: 'bg-white',
        hasManualClose: true,
        hideHeader: true,
        footer: {
            saveEnabled: true,
            hidden: false,
        },
    } as RsModalConfig;

    public mainCard: any;

    private readonly sessionId: string;

    constructor(
        private readonly modalService: ModalService,
        private readonly formService: FormService,
        private readonly shoppingSessionService: ShoppingSessionService,
        private readonly translate: TranslateService,
    ) {
        this.sessionId = shoppingSessionService.sessionId;
    }

    public async show(formName: string) {
        const data = await lastValueFrom(this.formService.formApiControllerGetForm(this.sessionId, { formName }));
        this.setTranslations(data);

        this.mainCard = data.config.form.cards[0];
        this.modalConfig.footer.save = this.translate.instant(this.mainCard.groups[1].title);

        if (this.mainCard.groups.length > 2) {
            this.modalConfig.footer.close = this.translate.instant(this.mainCard.groups[2].title);
        }
    }

    public async cancel() {
        this.modalService.hide({ accepted: false });
    }

    public async send() {
        this.modalService.hide({ accepted: true });
    }

    private setTranslations(data: FormResponseModel) {
        Object.keys(data.language).forEach((lang) => this.translate.setTranslation(lang, data.language[lang], true));
    }
}
