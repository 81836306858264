<div class="w-full h-full min-w-full transition-all shadow-th-card font-body">
    <pa-alert *ngIf="estimate?.disclaimer"
              [alert]="{ icon: 'icon-alert', priority: 'warning', message: estimate?.disclaimer}"
              [alertAcknowledged]="estimateDisclaimerAcknowledged"></pa-alert>

    <div class="mt-4">
        <pa-alert *ngIf="estimate?.summary?.showBenefitDisclaimer"
                  [alert]="{ icon: 'icon-alert', priority: 'warning', message: estimate?.benefit_disclaimer}"
                  class="benefit-disclaimer"></pa-alert>
    </div>



    <pdf-viewer #viewer
                [show-all]="false"
                [(page)]="page"
                [autoresize]="true"
                [original-size]='false'
                [fit-to-page]='true'
                [src]="{
                    url: estimate?.attachments?.[0]?.content?.signedUrl,
                    password: estimate?.attachments?.[0]?.content?.password
                }"
                [render-text]="false"
                (after-load-complete)="onLoaded($event)"
                (pages-initialized)="onPagesInitialized($event)"
                (page-rendered)="pageRendered('page-rendered', $event)"></pdf-viewer>



    <div class="flex justify-end w-full space-x-2 bg-gray-400 divide-x rounded-b-lg p1">
        <button type="button"
                class="relative inline-flex items-center px-2 py-2 text-sm font-medium border border-gray-300 rounded-l-md text-th-primary hover:bg-th-primary hover:text-white focus:z-10 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                id="download"
                (click)="download()">
            <svg xmlns="http://www.w3.org/2000/svg"
                 class="w-4 h-4"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
                 stroke-width="2">
                <path stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
            </svg>
        </button>

        <button type="button"
                class="relative inline-flex items-center px-2 py-2 text-sm font-medium border border-gray-300 rounded-l-md text-th-primary hover:bg-th-primary hover:text-white focus:z-10 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                id="previousPage"
                (click)="previousPage()"
                [disabled]="this.page === 1">
            <svg xmlns="http://www.w3.org/2000/svg"
                 class="w-4 h-4"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor">
                <path stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11 17l-5-5m0 0l5-5m-5 5h12" />
            </svg>
        </button>

        <button type="button"
                class="relative inline-flex items-center px-2 py-2 text-sm font-medium border border-gray-300 rounded-r-md text-th-primary hover:bg-th-primary hover:text-white focus:z-10 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                id="nextPage"
                (click)="nextPage()"
                [disabled]="this.page ===this.totalPages">
            <svg xmlns="http://www.w3.org/2000/svg"
                 class="w-4 h-4"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor">
                <path stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 7l5 5m0 0l-5 5m5-5H6" />
            </svg>
        </button>
    </div>
</div>
