import { ChangeDetectorRef, Component, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormResponseModel, FormService } from '@shopping';
import { RsModalConfig } from '@revspringinc/rs-shared';
import { ModalWrapComponent } from '@revspringinc/rs-shared';
import { ShoppingSessionService } from '../../main/session.service';
import { ShoppingActivityType } from '../../main/shopping-activity-type';
import { TranslateService } from '@ngx-translate/core';
import { FormsService } from '@revspringinc/shared';
import * as _ from 'lodash-es';
import { ModalService } from '@revspringinc/rs-shared';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'rs-contact-me',
    templateUrl: './text-estimate-summary.component.html',
    styleUrls: ['./text-estimate-summary.component.scss'],
})
export class TextEstimateSummaryModal implements ModalWrapComponent, OnDestroy {
    public static modalName = 'TextEstimateSummaryModal';

    @ViewChildren('resolver') public resolvers: QueryList<any>;
    @ViewChild('modal', { static: true }) public modal;

    public modalConfig = {
        activityType: ShoppingActivityType.TextEstimateSummaryAction,
        name: 'TextEstimateSummaryModal',
        title: '',
        maxWidth: '650px',
        bodyClass: 'bg-white',
        blockBackdropScrolling: true,
        footer: {
            hidden: false,
            save: 'Send',
            saveEnabled: true,
        },
    } as RsModalConfig;

    public form = new UntypedFormGroup({});
    public mainForm: any;
    public modalName?: string;
    public visible?: boolean;
    public blockBackdrop?: boolean;
    public payloadSub?: any;

    public config: any;
    public orgSubmissionPayload: any;
    public readyForPreview: boolean;
    public readyForClosingMessage: boolean;
    public selectedMethod: string;

    private estimateSummary: any;
    private sessionId: string;
    private readonly formName: string = 'CollectEstimateSummarySMSInfo';

    constructor(
        private readonly modalService: ModalService,
        private readonly formService: FormService,
        private readonly shoppingSessionService: ShoppingSessionService,
        private readonly translate: TranslateService,
        private readonly formsService: FormsService,
        private readonly changeDetector: ChangeDetectorRef,
    ) {}

    public async show(payload: any) {
        this.estimateSummary = payload;
        this.sessionId = this.shoppingSessionService.sessionId;

        await this.loadForm();
    }

    public async loadForm() {
        this.clearForm();

        await this.setFormConfig();
        await this.createFormFields();

        this.resolveFormControls();
    }

    public async send() {
        this.formsService.validateAllFormFields(this.form);

        if (!this.form.valid) {
            return;
        }

        await this.submitData();
        this.modalService.hide({ cancel: true });
    }

    public ngOnDestroy() {
        this.clearForm();
    }

    private clearForm() {
        Object.keys(this.form.controls).forEach((key) => {
            this.form.removeControl(key);
        });

        this.mainForm = null;
        this.config = null;

        this.changeDetector.detectChanges();
    }

    private async setFormConfig(): Promise<void> {
        const data = await lastValueFrom(
            this.formService.formApiControllerGetForm(this.sessionId, { formName: this.formName }),
        );
        this.setTranslations(data);
        this.config = data.config;
    }

    private setTranslations(data: FormResponseModel) {
        Object.keys(data.language).forEach((lang) => this.translate.setTranslation(lang, data.language[lang], true));
    }

    private async createFormFields() {
        const fieldsGroup = this.config.form.cards[0].groups[0];

        this.modalConfig.title = this.translate.instant(this.config.form.cards[0].title);

        this.mainForm = this.formsService.create(fieldsGroup.DataKey, 'post', fieldsGroup.fields, {
            submitButtonVisible: false,
            theme: {
                submitBtn: 'btn btn-main btn-block btn-lg rounded-0 mb-4',
            },
            isGroup: true,
            existing: true,
        });
    }

    private resolveFormControls() {
        this.changeDetector.detectChanges();

        this.resolvers.toArray().forEach((resolver) => {
            const instance = resolver.children.first.component.instance;

            this.form.addControl('TextSummaryInfo', instance.formGroups);
        });
    }

    private async submitData() {
        this.orgSubmissionPayload = {
            formFields: {
                phoneNumber: this.form.value.TextSummaryInfo.PhoneNumber,
                estimateSummary: this.estimateSummary,
            },
            formName: this.formName,
        };

        await lastValueFrom(
            this.formService.formApiControllerSaveForm(this.formName, this.shoppingSessionService.sessionId, {
                ...this.orgSubmissionPayload,
            }),
        );
    }
}
