import { DefaultUrlSerializer } from '@angular/router';

interface LinkData {
  url: string;
  type: string;
  label?: string;
  image?: string;
  payload?: any;
}

const serializer = new DefaultUrlSerializer();

export class Link {
  label: string;
  image: string;
  type: string;
  url: string;
  query: any;
  IsExternal = true;
  IsModal = false;
  IsAction = false;
  payload: any;

  constructor(data: LinkData) {
    this.label = data.label;
    this.image = data.image;
    switch (data.type) {
      case 'MailTo': {
        this.url = `mailto:${data.url}`;
        break;
      }
      case 'Tel': {
        this.url = `tel:${data.url}`;
        break;
      }
      case 'InternalUrl': {
        this.IsExternal = false;
        const parsed = serializer.parse(data.url);
        this.query = parsed.queryParams;
        this.url = data.url.split('?')[0];
        break;
      }
      case 'Modal': {
        this.IsExternal = false;
        this.IsModal = true;
        this.url = data.url;
        break;
      }
      case 'Action': {
        this.IsExternal = false;
        this.IsAction = true;
        this.payload = data.payload;
        this.url = data.url;
        break;
      }
      case 'ExternalUrl':
      default: {
        this.url = data.url;
      }
    }
    if (!this.label && !this.image) {
      this.label = data.url;
    }
  }
}
