import { Component, Input, Output, EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { ProcedureService, ProcedureGroupModel, OrganizationLocation } from '@shopping';
import { TranslateService } from '@ngx-translate/core';
import { ShoppingSessionService } from '../../main/session.service';

@Component({
    selector: 'app-search-procedures',
    templateUrl: './search-procedures.component.html',
    styleUrls: ['./search-procedures.component.scss']
})
export class SearchProceduresComponent {

    public static modalName = 'searchProcedures';
    public procedures: any;

    @Input() public organizationName: string;
    @Input() public locationId: number;
    @Input() public selectedProcedures: any[];
    @Input() public searchMode: boolean;
    @Output() public searchModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public selectedProceduresChange: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() public toggleContactMeModal: EventEmitter<string> = new EventEmitter<string>();

    private lastSearchFilter: string = '';

    constructor(
        private readonly translate: TranslateService,
        private readonly procedureService: ProcedureService,
        private readonly sessionService: ShoppingSessionService
    ) {

    }

    public onSearch(search: { name: string, filter: any; }) {
        if (search.filter.value === '') {
            // Don't allow empty searches
            this.searchMode = false;
            this.searchModeChange.emit(this.searchMode);
            this.lastSearchFilter = '';

            return;
        }

        // The filter has not changed, don't search
        if (search.filter.value.trim() === this.lastSearchFilter.trim()) {
            return;
        }

        // This is a new search
        this.searchMode = true;
        this.searchModeChange.emit(this.searchMode);
        this.lastSearchFilter = search.filter.value;

        const query = this.lastSearchFilter;
        this.procedureService.procedureApiControllerGetAll(
            this.sessionService.sessionId,
            undefined, undefined,
            query,
            this.locationId
        ).pipe(
            map((data: any) => {
                Object.keys(data.language).forEach(lang => this.translate.setTranslation(lang, data.language[lang], true));

                return data.config;
            })
        )
            .subscribe((data: ProcedureGroupModel[]) => {
                const selectedProcedures = this.selectedProcedures.map((procedure) => procedure.id);

                this.procedures = data.filter(pg => {
                    const locations = (pg as any).locations;
                    const locationMatch = locations.find((location: OrganizationLocation) => location.id === this.locationId);

                    if (!locations.length || locationMatch || !this.locationId) return pg;
                }).map(procedureGroup => {
                    return {
                        ...procedureGroup,
                        selected: selectedProcedures.includes(procedureGroup.id),
                        open: false,
                        procedures: procedureGroup.proceduresAreVisible ? procedureGroup.procedures : [procedureGroup.procedures[0]]
                    };
                });
            });
    }

    public toggleProcedure(group) {
        const selectedProcedures = this.selectedProcedures.map((procedure) => procedure.id);
        const index = selectedProcedures.indexOf(group.id);

        if (index !== -1) {
            this.selectedProcedures.splice(index, 1);
        } else {
            this.selectedProcedures.push(group);
        }

        this.selectedProceduresChange.emit(this.selectedProcedures);
    }

    public showContactMeModal() {
        this.toggleContactMeModal.emit(this.lastSearchFilter);
    }
}
