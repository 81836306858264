export const logError = (context: string = 'registrar', message?: string, ...optionalParams: any[]) => {
    console.log(
        `🛑 %c ${context} %c Error %c ${message} %c`,
        'background:#3e5975 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff; font-size: 14px;',
        'background:#DD0031 ; padding: 1px; color: #fff; font-size: 14px;',
        'background:#C3002F ; padding: 1px; border-radius: 0px 3px 3px 0;  color: #fff; font-size: 14px;',
        'background:transparent', optionalParams
    );
};
export const logErrorDetails = (context: string = 'registrar', messages: string[]) => {
    console.group(
        `🛑 %c ${context} %c Error `,
        'background:#3e5975 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff; font-size: 14px;',
        'background:#DD0031 ; padding: 1px; color: #fff; font-size: 14px;',
    );

    for (let message of messages) {
        console.log(
            `%c${message}`,
            'background:transparent ; padding: 1px; color: #ff0000; font-size: 14px; ',
        );
    }
    console.groupEnd();
};

export const logInfo = (context: string = 'registrar', message?: string, ...optionalParams: any[]) => {
    console.info(
        `ℹ️ %c ${context} %c Info %c ${message} %c`,
        'background:#3e5975 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff; font-size: 14px;',
        'background:#233242  ; padding: 1px; color: #fff; font-size: 14px;',
        'background:#f4f6f9 ; padding: 1px; border-radius: 0px 3px 3px 0;  color: #233242; font-size: 14px;',
        'background:transparent', optionalParams
    );
};

export const logEvent = (context: string = 'registrar', message?: string, ...optionalParams: any[]) => {
    console.info(
        `✉️ %c ${context} %c Info %c ${message} %c`,
        'background:#0C72DA ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff; font-size: 14px;',
        'background:#233242  ; padding: 1px; color: #fff; font-size: 14px;',
        'background:#f4f6f9 ; padding: 1px; border-radius: 0px 3px 3px 0;  color: #233242; font-size: 14px;',
        'background:transparent', optionalParams
    );
};
