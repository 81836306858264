import { ChangeDetectorRef, Component, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FormResponseModel, FormService } from '@shopping';
import { RsModalConfig } from '@revspringinc/rs-shared';
import { ModalWrapComponent } from '@revspringinc/rs-shared';
import { ShoppingSessionService } from '../../main/session.service';
import { ShoppingActivityType } from '../../main/shopping-activity-type';
import { TranslateService } from '@ngx-translate/core';
import { FormsService } from '@revspringinc/shared';
import * as _ from 'lodash-es';
import { lastValueFrom, Subscription } from 'rxjs';
import { emailValidator, tenDigitPhoneNumber } from '@revspringinc/rs-core';

@Component({
    selector: 'rs-contact-me',
    templateUrl: './contact-me.component.html',
    styleUrls: ['./contact-me.component.scss'],
})
export class ContactMeModal implements ModalWrapComponent, OnDestroy {
    public static modalName = 'ContactMeModal';

    @ViewChildren('resolver') public resolvers: QueryList<any>;
    @ViewChild('modal', { static: true }) public modal;

    public modalConfig = {
        activityType: ShoppingActivityType.ContactMeAction,
        name: 'ContactMeModal',
        blockBackdropScrolling: true,
        title: '',
        maxWidth: '650px',
        bodyClass: 'bg-white',
        footer: {
            hidden: false,
            save: 'Confirm',
            saveEnabled: true,
        },
    } as RsModalConfig;

    public form = new UntypedFormGroup({});
    public mainForm: any;
    public modalName?: string;
    public visible?: boolean;
    public blockBackdrop?: boolean;
    public payloadSub?: any;

    public config: any;
    public orgSubmissionPayload: any;
    public readyForPreview: boolean;
    public readyForClosingMessage: boolean;
    public submissionInProgress: boolean;

    private readonly formName: string = 'CollectContactMeInfo';
    private currentProcedures: any[];
    private sessionId: string;
    private contactMethodFormControl$: Subscription;

    constructor(
        private readonly formService: FormService,
        private readonly shoppingSessionService: ShoppingSessionService,
        private readonly translate: TranslateService,
        private readonly formsService: FormsService,
        private readonly changeDetector: ChangeDetectorRef,
    ) {}

    public async show(payload: any) {
        const { currentProcedures, searchTerm } = payload;

        this.currentProcedures = currentProcedures;
        this.sessionId = this.shoppingSessionService.sessionId;

        await this.loadForm();

        if (searchTerm) {
            this.form.controls.ContactInformation.patchValue({
                AdditionalComments: `Unable to find procedure for: ${searchTerm}.`,
            });
        }
    }

    public async loadForm() {
        this.unsubscribeForm();

        await this.setFormConfig();
        await this.createFormFields();

        this.resolveFormControls();
        this.patchForm();
        this.subscribeForm();

        this.transitionModal(0);
    }

    public async send() {
        this.formsService.validateAllFormFields(this.form);

        if (!this.form.valid) {
            return;
        }

        if (!this.readyForPreview) {
            this.showPreview();
        } else {
            await this.submitData();
        }
    }

    public ngOnDestroy() {
        this.unsubscribeForm();
    }

    private unsubscribeForm() {
        if (this.contactMethodFormControl$) {
            this.contactMethodFormControl$.unsubscribe();
        }

        Object.keys(this.form.controls).forEach((key) => {
            this.form.removeControl(key);
        });

        this.mainForm = null;
        this.config = null;

        this.changeDetector.detectChanges();
    }

    private subscribeForm() {
        this.setContactMethodValidators(this.form.get('ContactInformation.ContactMethod').value);

        this.contactMethodFormControl$ = this.form
            .get('ContactInformation.ContactMethod')
            .valueChanges.subscribe((change) => {
                this.setContactMethodValidators(change);
            });
    }

    private async setFormConfig(): Promise<void> {
        const data = await lastValueFrom(
            this.formService.formApiControllerGetForm(this.sessionId, { formName: this.formName }),
        );
        this.setTranslations(data);
        this.config = data.config;
    }

    private setTranslations(data: FormResponseModel) {
        Object.keys(data.language).forEach((lang) => this.translate.setTranslation(lang, data.language[lang], true));
    }

    private async createFormFields() {
        this.mainForm = await Promise.all(
            this.config.form.cards[0].groups.map(async (group) => {
                return this.formsService.create(group.DataKey, 'post', group.fields, {
                    submitButtonVisible: false,
                    theme: {
                        submitBtn: 'btn btn-main btn-block btn-lg rounded-0 mb-4',
                    },
                    isGroup: true,
                    existing: true,
                });
            }),
        );
    }

    private resolveFormControls() {
        this.changeDetector.detectChanges();

        this.resolvers.toArray().forEach((resolver) => {
            const instance = resolver.children.first.component.instance;

            this.form.addControl('ContactInformation', instance.formGroups);
        });
    }

    private patchForm() {
        if (this.config.value) {
            delete this.config.value.formId;
            delete this.config.value.encounterId;
            this.form.controls.ContactInformation.patchValue(this.config.value.ContactInformation);
        }
    }

    private showPreview() {
        const contactInfo = this.form.value.ContactInformation;

        this.orgSubmissionPayload = {
            formFields: {
                memberFirstName: contactInfo.MemberFirstName,
                memberLastName: contactInfo.MemberLastName,
                healthInsurance: contactInfo.HealthInsurance,
                emailAddress: contactInfo.EmailAddress,
                phoneNumber: contactInfo.PhoneNumber,
                additionalComments: contactInfo.AdditionalComments,
                preferredContactMethod: contactInfo.ContactMethod,
                currentProcedures: this.currentProcedures,
                locationName: this.config.value.ContactInformation.LocationName,
            },
            formName: this.formName,
        };

        this.transitionModal(1);
    }

    private async submitData() {
        this.submissionInProgress = true;

        await lastValueFrom(
            this.formService.formApiControllerSaveForm(this.shoppingSessionService.sessionId, this.formName, {
                ...this.orgSubmissionPayload,
            }),
        );

        this.transitionModal(2);
        this.submissionInProgress = false;
    }

    private transitionModal(transitionStep: number) {
        switch (transitionStep) {
            case 0:
                this.modalConfig.title = this.translate.instant(this.config.form.cards[0].title);
                break;

            case 1:
                this.readyForPreview = true;
                this.modalConfig.footer.save = 'Send';
                this.modalConfig.title = this.translate.instant(this.config.form.cards[1].title);
                break;

            case 2:
                this.modalConfig.footer.hidden = true;
                this.modalConfig.title = this.translate.instant(this.config.form.cards[2].title);
                this.readyForClosingMessage = true;
                break;

            default:
                break;
        }
    }

    private setContactMethodValidators(selectedMethod: string): void {
        if (selectedMethod === 'Email') {
            this.setCustomValidator('ContactInformation.EmailAddress', emailValidator, true);
            this.setCustomValidator('ContactInformation.PhoneNumber', tenDigitPhoneNumber, false);
        } else {
            this.setCustomValidator('ContactInformation.PhoneNumber', tenDigitPhoneNumber, true);
            this.setCustomValidator('ContactInformation.EmailAddress', emailValidator, false);
        }
    }

    private setCustomValidator(
        controlLocation: string,
        customValidator: ValidatorFn,
        setRequired: boolean = false,
    ): void {
        const validators = setRequired ? [customValidator, Validators.required] : [customValidator];

        this.form.get(controlLocation).setValidators(validators);
        this.form.get(controlLocation).setErrors(null);
        this.form.get(controlLocation).updateValueAndValidity();
    }
}
