<div #modal
     [ngClass]="{show: isVisible}"
     class="modal"
     [@animateModal]="animationState"
     aria-hidden="true"
     backdrop="static"
     [attr.aria-labelledby]="name"
     tabindex="-1"
     role="dialog">
  <div *ngIf="isVisible"
       class="modal-dialog modal-lg"
       role="document"
       [ngStyle]="{ maxWidth: config?.maxWidth, 'disable-overflow': config?.disableYOverflow }">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center {{ config?.headerClass }}"
           *ngIf="!config?.hideHeader">
        <button *ngIf="config?.hideCancel"
                type="button"
                class="rs-btn-link fw-700 fs-18 back"
                aria-label="Close"
                (click)="closeModal('button')">
          Back
        </button>
        <h4 class="modal-title">
          <span class="fw-700">{{ config?.title }}</span>
        </h4>
        <button *ngIf="!config?.hideCancel"
                type="button"
                class="close"
                aria-label="Close"
                (click)="closeModal('button')">
          <span aria-hidden="true">×</span>
        </button>
        <button *ngIf="config?.hideCancel"
                type="button"
                class="rs-btn-link fw-700 fs-18"
                aria-label="Close"
                (click)="onSubmit()">
          Save selection
        </button>
      </div>
      <div class="modal-body {{ config?.bodyClass }}"
           [ngClass]="{ 'pt-0': !config?.hideHeader }"
           [ngStyle]="{ maxHeight: config?.maxHeight, minHeight: config?.minHeight }">
        <ng-content></ng-content>
        <!-- <p>Modal body text goes here.</p> -->
        <div *ngIf="config?.error"
             class="text-center alert-danger icon">{{ config?.error }}</div>
      </div>
      <div class="row no-gutters modal-footer"
           *ngIf="!config?.footer.hidden">
        <button type="button"
                *ngIf="config?.footer.close"
                class="btn btn-inverted btn-lg btn-lg-font-sm footer-item"
                [ngClass]="{ 'col-md-12': !multipleButtons, 'multiple-btns': multipleButtons}"
                (click)="closeModal('cancel')">{{ config?.footer.close }}</button>
        <button type="button"
                *ngIf="config?.footer.delete"
                class="btn btn-warning btn-lg btn-lg-font-sm footer-item"
                [ngClass]="{ 'col-md-12': !multipleButtons, 'multiple-btns': multipleButtons}"
                (click)="onDelete()">{{ config?.footer.delete }}</button>
        <button *ngIf="config?.footer.save"
                (click)="onSubmit()"
                class="btn btn-block btn-main btn-lg-loader btn-lg-font-sm d-flex align-items-center justify-content-center position-relative footer-item"
                [ngClass]="{ 'col-md-12': !multipleButtons, 'multiple-btns': multipleButtons}"
                [disabled]="!config?.footer.saveEnabled">
          <ng-container *ngIf="config?.footer?.saveLoading">
            <span class="ml-3 spinner-border spinner-border-sm text-light"
                  role="status"
                  aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </ng-container>

          {{ config?.footer.save }}
        </button>
      </div>
    </div>
  </div>
</div>
