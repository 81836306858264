<ul role="tablist"
    aria-orientation="horizontal"
    class="mb-4 nav nav-tabs no-gutters">
  <li *ngFor="let tab of tabs, let i = index"
      class="{{ css }}">
    <a role="tab"
       [attr.aria-selected]="tab.active"
       [attr.aria-controls]="tab.tabTitle + 'panel'"
       class="text-center nav-link fw-700"
       [id]="tab.title"
       [class.active]="tab.active"
       (click)="setActiveTab(i)"
       href="javascript:">
      <span *ngFor="let span of tab.additionalSpans"
            [ngClass]="span.class">{{ span.innerHtml }} </span>
      {{tab.tabTitle}}
    </a>
  </li>
</ul>
<ng-content></ng-content>
