import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { logInfo } from '../utils/log';

export type PortalError = {
    message: string;
    status?: number;
    statusText?: string;
    requestUrl?: string;
    responseUrl?: string | null;
    remaining?: string;
};

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService {
    private readonly errorsSource = new BehaviorSubject<PortalError | null>(null);

    public errors$ = this.errorsSource.asObservable();

    constructor() {
        logInfo('ErrorHandlerService', 'Created');
    }

    public handleError(error: HttpErrorResponse | string, request?: HttpRequest<unknown>): string {
        if (typeof error === 'string') {
            return error;
        }

        return error?.message || 'Unknown error';
    }

    countdown(minutes: number, delay: number = 0) {
        return new Observable<{ display: string; minutes: number; seconds: number }>((subscriber) => {
            timer(delay, 1000)
                .pipe(take(minutes))
                .pipe(map((v) => minutes - 1 - v))
                .pipe(takeWhile((x) => x >= 0))
                .subscribe((countdown) => {
                    // countdown => seconds
                    const minutes = Math.floor(countdown / 60);
                    const seconds = countdown - minutes * 60;

                    subscriber.next({
                        display: `${('0' + minutes.toString()).slice(-2)}:${('0' + seconds.toString()).slice(-2)}`,
                        minutes,
                        seconds,
                    });

                    if (seconds <= 0 && minutes <= 0) {
                        subscriber.complete();
                    }
                });
        });
    }

    public clear() {
        this.errorsSource.next(null);
    }
}
