<ng-container *ngIf="existingForm">
  <ng-container *ngIf="!isWrappedLayout else wrappedLayout">
    <ng-container *ngFor="let group of modelArray">
      <rs-form-group *ngIf="group.type === 'form-group'"
                    [controls]="group.controls"
                    [group]="formGroups.controls[group.name]">
      </rs-form-group>
      <rs-consumer-group *ngIf="group.type === 'group'"
                        [controls]="group.controls"
                        [group]="formGroups.controls[group.name]">
      </rs-consumer-group>
      <ng-container *ngIf="group.type !== 'form-group'"
                    rsResolve
                    [element]="group">
      </ng-container>
    </ng-container>
    <div class="text-center"
        *ngIf="element.submitButtonVisible">
      <button type="submit"
              [ngClass]="element.theme?.submitBtn || 'info'"
              [disabled]="!formGroups.valid">
        {{(element.buttonText | translate) || 'Submit'}}
      </button>
    </div>
  </ng-container>
  <ng-template #wrappedLayout>
      <!-- wrap the element in a row to support iterative (row|col) layout -->
      <div class="row">
        <ng-container *ngFor="let group of modelArray">
          <rs-form-group *ngIf="group.type === 'form-group'"
          [controls]="group.controls"
          [group]="formGroups.controls[group.name]">
        </rs-form-group>
        <rs-consumer-group *ngIf="group.type === 'group'"
        [controls]="group.controls"
        [group]="formGroups.controls[group.name]">
      </rs-consumer-group>
          <ng-container *ngIf="group.type !== 'form-group'"
          rsResolve
          [element]="group">
          </ng-container>
        </ng-container>
        <div class="text-center"
        *ngIf="element.submitButtonVisible">
        <button type="submit"
                  [ngClass]="element.theme?.submitBtn || 'info'"
                  [disabled]="!formGroups.valid">
                  {{(element.buttonText | translate) || 'Submit'}}
                </button>
        </div>

      </div>
</ng-template>


</ng-container>
<form *ngIf="!existingForm"
      [formGroup]="formGroups"
      (ngSubmit)="submit()">
  <ng-container *ngFor="let group of modelArray">
    <rs-form-group *ngIf="group.type === 'form-group'"
                   [controls]="group.controls"
                   [group]="formGroups.controls[group.name]">
    </rs-form-group>
    <rs-consumer-group *ngIf="group.type === 'group'"
                       [controls]="group.controls"
                       [group]="formGroups.controls[group.name]">
    </rs-consumer-group>
    <ng-container *ngIf="group.type !== 'form-group'"
                  rsResolve
                  [element]="group">
    </ng-container>
  </ng-container>

  <div class="text-center"
       *ngIf="element.submitButtonVisible">
    <button type="submit"
            [ngClass]="element.theme?.submitBtn || 'info'"
            [disabled]="!formGroups.valid">
      {{(element.buttonText | translate) || 'Submit'}}
    </button>
  </div>
</form>
