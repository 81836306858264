import { Injectable } from '@angular/core';
import { OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import * as HydrationActions from './actions';

@Injectable()
export class InitializationEffects implements OnInitEffects {
    constructor() {}

    public ngrxOnInitEffects(): Action {
        return HydrationActions.InitializationComplete();
    }
}
