<rs-modal #modal (show)="show()" [config]="modalConfig" (submit)="submit()">
    <div *ngIf="config">
        <div class="main-title-section">
            <h2 class="title">{{ config.title | translate }}</h2>
        </div>
        <div *ngFor="let group of config.groups">
            <div class="group-title-section">
                <h4>{{ group.title | translate }}</h4>
            </div>
            <div *ngFor="let paragraph of group.paragraphs">
                <p>{{ paragraph.value | translate }}</p>
            </div>
            <hr>
        </div>
    </div>
</rs-modal>
