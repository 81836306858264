import { CommonModule } from '@angular/common';
import {
    Component,
    ContentChildren,
    QueryList,
    Input,
    Output,
    EventEmitter,
    AfterViewChecked,
    NgModule,
} from '@angular/core';

import { TabComponent } from './tab.component';

@Component({
    selector: 'rs-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterViewChecked {
    @Input() public css: string;
    @Output() public getActiveTab: EventEmitter<any> = new EventEmitter();
    @ContentChildren(TabComponent) public tabChildren: QueryList<TabComponent>;
    private lastActiveIndex: number;

    constructor() {}

    ngAfterViewChecked(): void {
        // get all active tabs
        setTimeout(() => this.resetActiveTab());
    }

    private resetActiveTab() {
        const activeTabs = this.tabs.filter((tab) => tab.active);
        // if there is no active tab set, activate the first
        if (activeTabs.length === 0) {
            this.setActiveTab(this.lastActiveIndex ?? 0);
        }
    }

    get tabs() {
        return this.tabChildren && this.tabChildren.toArray();
    }

    public setActiveTab(index: number): void {
        const tabs = this.tabs;
        for (let i = 0; i < tabs.length; i++) {
            tabs[i].active = i === index;
        }

        this.lastActiveIndex = index;
        this.getActiveTab.emit({ tab: tabs[index], i: index });
    }
}
@NgModule({
    imports: [CommonModule],
    declarations: [TabComponent, TabsComponent],
    exports: [TabComponent, TabsComponent],
})
export class TabsComponentModule {}
