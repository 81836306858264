import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { EstimateServiceComponent } from './estimate-service/estimate-service.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MainComponent } from './main/main.component';
import { SessionResolverService } from './main/session-resolver.service';
import { ShoppingActivityType } from './main/shopping-activity-type';
import { FaqComponent } from './faq/faq.component';
import { ExternalRedirectGuard } from '@revspringinc/shared';
import { UnsupportedBrowserGuard } from './guards/unsupported-browser.guard';

const routes: Routes = [
  {
    path: 'external-redirect',
    canActivate: [ExternalRedirectGuard],
    data: { activityType: ShoppingActivityType.ExternalRedirect },
    component: PageNotFoundComponent
  },
  {
    path: ':orgName',
    component: MainComponent,
    canActivateChild: [UnsupportedBrowserGuard],
    resolve: { sessionId: SessionResolverService },
    children: [
      {
        path: 'page-not-found',
        component: PageNotFoundComponent,
        data: { pageName: 'pageNotFound', noLayout: true, activityType: ShoppingActivityType.PageNotFound }
      },
      {
        path: 'faq',
        component: FaqComponent,
        data: { pageName: 'faq', noLayout: true, activityType: ShoppingActivityType.FAQ }
      },
      {
        path: '',
        redirectTo: 'landing', // preservice -> /landing
        pathMatch: 'full',
        data: { activityType: ShoppingActivityType.Landing }
      },
      {
        path: 'landing',
        component: LandingComponent,
        data: { activityType: ShoppingActivityType.Landing }
      },
      {
        path: 'estimate-services',
        component: EstimateServiceComponent,
        data: { activityType: ShoppingActivityType.EstimateServices }
      },
      {
        path: '**',
        redirectTo: 'page-not-found'
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
