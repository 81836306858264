import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Inject,
    Input,
    OnChanges,
    Renderer2,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ICON_SET_TOKEN } from './constants';
import { HeroIconName } from './icons';

@Component({
    selector: 'pa-hero-icon',
    template: '<span><svg #icon></svg></span>',

    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['../../../../scss/styles.scss'],
    styles: [
        `
            :host {
                @apply contents;
            }
        `,
    ],
})
export class IconComponent implements OnChanges, AfterViewInit {
    @Input() name: HeroIconName | undefined;
    @Input() size = 24;
    @Input() class = '';

    @ViewChild('icon', { read: ElementRef }) icon: ElementRef<SVGSVGElement> | undefined;

    private readonly icons: Readonly<Record<string, string>> = {};
    private iconName: string | undefined;

    constructor(
        private readonly renderer: Renderer2,
        @Inject(ICON_SET_TOKEN) iconsets: ReadonlyArray<Record<string, string>>,
    ) {
        const iconsetGroups = iconsets as ReadonlyArray<Record<string, string>>;

        this.icons = iconsetGroups.reduce((icons, iconset) => ({
            ...icons,
            ...iconset,
        }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        const name = this.camelize(this.name);
        this.iconName = name;

        if (!this.icons[name]) {
            return console.warn(
                `No icon named ${name} was found. You may need to import it using the withIcons function.`,
            );
        }

        const nameHasChanged = changes.name.previousValue !== changes.name?.currentValue;
        if (nameHasChanged) {
            this.setIcon(name);
        }

        const classHasChanged = changes.class.previousValue !== changes.class?.currentValue;
        const sizeHasChanged = changes.size?.previousValue !== changes.size?.currentValue;
        if (classHasChanged || sizeHasChanged || nameHasChanged) {
            this.renderStyle();
        }
    }

    setIcon(name: string) {
        if (this.icon) {
            this.renderer.setProperty(this.icon.nativeElement, 'innerHTML', this.icons[name]);
            this.renderStyle();
        }
    }

    camelize(value: string = ''): string {
        return value
            ?.replace(/(-|_|\.|\s)+(.)?/g, (match, separator, character) => (character ? character.toUpperCase() : ''))
            ?.replace(/^([A-Z])/, (match) => match.toLowerCase());
    }

    ngAfterViewInit(): void {
        if (this.iconName) {
            this.setIcon(this.iconName);
        }
    }

    renderStyle() {
        const style: string[] = [];

        if (this.size) {
            style.push(`w-${this.size} h-${this.size}`);
        }

        if (this.class) {
            style.push(`${this.class}`);
        }

        if (this.icon) {
            this.renderer.setAttribute(this.icon.nativeElement, 'class', style.join(' '));
        }
    }
}
