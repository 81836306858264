<rs-modal #modal
          (show)="show($event)"
          (submit)="submit()"
          [config]="modalConfig">

  <app-search-procedures [(searchMode)]="searchMode"
                         [locationId]="options.locationId"
                         [(selectedProcedures)]="options.selectedProcedures"
                         (selectedProceduresChange)=selectedProceduresChanged($event)
                         (toggleContactMeModal)="showContactMeModal($event)">
  </app-search-procedures>

  <div *ngIf="!searchMode"
       class="procedures-list">
    <cdk-virtual-scroll-viewport #scroll
                                 itemSize="75">
      <button *cdkVirtualFor="let procedure of proceduresList"
              (click)="selectProcedures(procedure)"
              class="rs-btn-link p-4 bg-white w-100 color-secondary mb-3">
        <div [ngClass]="{'pb-3': procedure?.selected?.length > 0}"
             class="d-flex justify-content-between align-items-center btn-inner w-100 fw-700 fs-18">
          {{ procedure.name | translate }}
        </div>
        <ng-container *ngIf="procedure?.selected?.length > 0">
          <div *ngFor="let item of procedure.selected"
               class="procedure-list top-border w-100 text-left w-100 pt-3 d-flex align-items-center">
            <div class="text-trim-2">
              {{ item.name | translate }}
            </div>
          </div>
        </ng-container>
      </button>
    </cdk-virtual-scroll-viewport>
  </div>
</rs-modal>