import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { EstimateAction } from '../../models/estimateAction';
import { EstimateConfigModel } from '../../models/estimateConfigModel';

@Component({
    selector: 'estimate-actions',
    templateUrl: './estimate-actions.component.html',
    styleUrls: [
        '../../scss/styles.scss',
        './estimate-actions.component.scss'
    ]
})
export class EstimateActionsComponent implements OnChanges {
    @Input() public loading = false;
    @Input() public estimate!: EstimateConfigModel;
    @Output() public actionClicked: EventEmitter<EstimateAction> = new EventEmitter<EstimateAction>();

    public hasEstimated!: boolean;

    constructor() {

    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.estimate && changes.estimate.currentValue) {
            this.hasEstimated = this.estimate.estimateNumber.includes('na_estimate') ? false : true;
        }
    }

    public toEstimateActions(action: any) {
        this.actionClicked.emit(this.estimate.actions.find((x: any) => x.id === action.id));
    }

    public toActionIcon(iconName: string) {
        return `../assets/img/actions/${iconName}.svg`;
    }
}
