import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

export interface RouterState {

}


export const reducers: ActionReducerMap<RouterState> = {
    router: routerReducer
};
