import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { set } from 'lodash-es';
import { EstimateRenderConfigDelta } from '../../models/estimate-render-config-delta';
import { EstimateSectionItem } from '../../models/estimate-section';
import { EstimateConfigModel } from '../../models/estimateConfigModel';
import { EstimateObserverService } from '../../services/estimate-observer.service';
@Component({
    selector: 'editable-item',
    templateUrl: './editable-item.component.html',
    styleUrls: [
        '../../scss/styles.scss'
    ],
    styles: [`
        :host {
            @apply contents;
        }
    `],
})
export class EditableItemComponent {
    @Input() item: EstimateSectionItem | undefined;
    @Input() estimate: EstimateConfigModel | undefined;
    @Input() public estimateChange: ((estimate: EstimateConfigModel) => Promise<boolean>) | undefined;

    @ViewChild('input', { read: ElementRef }) input: ElementRef<HTMLInputElement> | undefined;

    public editing: boolean = false;

    constructor(private readonly observer: EstimateObserverService) { }

    private observe() {
        if (this.estimate) {
            this.observer.observe(this.estimate);
        }
    }

    public edit() {
        this.editing = true;
        this.input?.nativeElement.focus();
        this.observe();
    }

    public async onEdit($event: Event) {
        this.editing = false;

        if (!this.estimate || !this.item?.text) {
            return;
        }

        const newValue = this.input?.nativeElement.value;

        if (newValue === this.item.text.content) {
            return;
        }

        this.item.text.content = newValue || '';

        this.estimate = set(this.estimate, this.item.text.path, newValue);
        const diff = this.observer.generateDiff();
        this.observer.unobserve();

        if (!this.estimate.deltas) {
            this.estimate.deltas = [];
        }

        if (diff.length === 0) {
            return;
        }

        this.estimate.deltas.push(EstimateRenderConfigDelta.create({
            diff,
        }));

        if (this.estimateChange) {
            console.log('saving estimate');
            const done = await this.estimateChange(this.estimate);

            console.log('saved!', done);
        }
    }

}
