import {
    Component,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    Renderer2,
    SimpleChanges,
    Inject
} from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { RsModalConfig } from '../../models/modal-config';
import { OnChanges } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/** @dynamic */
@Component({
    selector: 'rs-modal',
    templateUrl: './modal-template.component.html',
    styleUrls: ['./modal-template.component.scss'],
    animations: [
        trigger('animateBackdrop', [
            state('initial', style({
                opacity: '0',
            })),
            state('final', style({
                opacity: '1',
            })),
            transition('initial=>final', animate('225ms 250ms ease-in')),
            transition('final=>initial', animate('225ms ease-out')),
        ]),
        trigger('animateModal', [
            state('initial', style({
                opacity: '0',
                transform: 'translate(0, -50px)',
            })),
            state('final', style({
                opacity: '1',
                transform: 'translate(0, 0)',
            })),
            transition('initial=>final', animate('225ms 400ms ease-in')),
            transition('final=>initial', animate('225ms ease-out')),
        ]),
    ],
})
export class RSModalComponent implements OnChanges {
    @ViewChild('modal', {static: true}) public modal;
    @Input() public config: RsModalConfig;
    // tslint:disable-next-line:no-output-rename
    @Output('show') public showModal = new EventEmitter<void>();
    @Output() public submit = new EventEmitter<void>();
    @Output() public close = new EventEmitter<any>();
    @Output() public delete = new EventEmitter<any>();
    @Output() public closeTriger = new EventEmitter<any>();
    @Output() public cancel = new EventEmitter<any>();
    @Output() public print = new EventEmitter<void>();
    public keyboardClose;
    public name;
    public animationState = 'initial';
    public onClose$ = this.close.asObservable();
    public isVisible = false;

    public get multipleButtons(): boolean {
        return [
            this.config.footer.save,
            this.config.footer.close,
            this.config.footer.delete,
        ].filter(x => x).length > 1;
    }

    constructor(
        private readonly modalService: ModalService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly renderer: Renderer2,
        @Inject(DOCUMENT) private readonly doc: Document) {
    }

    public show(payload: any, isRestore = false) {
        this.isVisible = true;
        this.setupKeyboardEvent();
        this.modal.nativeElement.focus();
        this.changeDetector.detectChanges();
        this.animationState = 'final';

        if (isRestore) return;
        this.showModal.emit(payload);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.config && changes.config.currentValue?.blockBackdropScrolling) {
            this.renderer.addClass(this.doc.body, 'modal-open');
        }
    }

    public setupKeyboardEvent() {
        this.keyboardClose = (e: KeyboardEvent) => {
            e.preventDefault();
            e.stopPropagation();

            if (e.keyCode === 27 && !this.config.blockEscapeClosing) {
                this.closeModal('keyboard');
                window.removeEventListener('keyup', this.keyboardClose);
            }
        };
        window.addEventListener('keyup', this.keyboardClose);
    }

    public hide() {
        window.removeEventListener('keyup', this.keyboardClose);
        this.keyboardClose = undefined;
        this.animationState = 'initial';

        setTimeout(() => {
            this.isVisible = false;
        }, 300);
    }

    public destroy(payload?: any) {
        this.hide();

        if (this.config.blockBackdropScrolling) {
            this.renderer.removeClass(this.doc.body, 'modal-open');
        }

        setTimeout(() => {
            this.close.emit(payload);
        }, 300);
    }

    public closeModal(target: string) {
        if (target === 'backdrop' && this.config.blockBackdrop) return;
        if (target === 'cancel') this.cancel.emit({target});
        if (target === 'button' && this.config.buttonCancels) this.cancel.emit({target});

        if (this.config.hasManualClose) {
            this.closeTriger.emit();

            return;
        }
        if (this.config.withoutService) {
            this.destroy();

            return;
        }
        this.modalService.hide();
    }

    public onSubmit() {
        this.submit.emit();
    }

    public onDelete() {
        if(confirm('Are you sure you want to delete?')) {
            this.delete.emit();
        }
    }
}
