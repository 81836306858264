import { Component, Input, OnInit } from '@angular/core'
import { values, mapValues } from 'lodash-es'
import { UntypedFormGroup } from '@angular/forms'
import { RsFormGroup } from '../schema/rs-form.schema';

@Component({
  selector: 'rs-consumer-group',
  templateUrl: './rs-consumer-group.component.html'
})
export class RsConsumerGroupComponent implements OnInit {
  // tslint:disable-next-line:no-any I tried to type this but ran into issues with generics.
  @Input() controls: any
  @Input() group: UntypedFormGroup
  @Input() element: RsFormGroup
  controlsArray: any
  /**
   * OnInit we need to mape the control group to the control.
   *
   * @memberof RsFormGroupComponent
   */
  ngOnInit() {
    this.controls = mapValues(this.controls, control => {
      control.group = this.group
      return control
    })
    // would be better if the this.controls was already defined as an array.
    // we might by able replace this.controls with the array version
    this.controlsArray = values(this.controls);
  }
}
