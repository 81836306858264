import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class RSTranslateLoader implements TranslateLoader {
  translations = {};
  lang: string;

  constructor() {
    this.translations = {};
  }
  getTranslation(lang: string): Observable<any> {
    return of(this.translations[lang] || {});
  }
}
