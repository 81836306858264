import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    loadingSubscription: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    loadingInProgress: Map<string, boolean> = new Map<string, boolean>();


    public setLoading(loading: boolean, url: string): void {
        if (loading === true) {
            this.loadingInProgress.set(url, loading);
            this.loadingSubscription.next(true);
        } else if (loading === false && this.loadingInProgress.has(url)) {
            this.loadingInProgress.delete(url);
        }

        if (this.loadingInProgress.size === 0) {
            this.loadingSubscription.next(false);
        }
    }
}
