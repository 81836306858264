/**
 * Shopping Api
 * The Shopping api
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ProcedureCategoryDetailResponseModel } from '../model/models';
import { ProcedureCategoryResponseModel } from '../model/models';
import { ProcedureResponseModel } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class ProcedureService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === 'object' && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * @param organizationName
     * @param limit
     * @param index
     * @param searchTerm
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public procedureApiControllerGetAll(xSessionid: string, limit?: number, index?: number, searchTerm?: string, locationId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProcedureResponseModel>;
    public procedureApiControllerGetAll(xSessionid: string, limit?: number, index?: number, searchTerm?: string, locationId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProcedureResponseModel>>;
    public procedureApiControllerGetAll(xSessionid: string, limit?: number, index?: number, searchTerm?: string, locationId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProcedureResponseModel>>;
    public procedureApiControllerGetAll(xSessionid: string, limit?: number, index?: number, searchTerm?: string, locationId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xSessionid === null || xSessionid === undefined) {
            throw new Error('Required parameter xSessionid was null or undefined when calling estimationApiControllerEstimateLatest.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            limit as any, 'limit');
        }
        if (index !== undefined && index !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            index as any, 'index');
        }
        if (searchTerm !== undefined && searchTerm !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            searchTerm as any, 'searchTerm');
        }

        if (locationId !== undefined && locationId !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                locationId as any, 'locationId');
        }

        let headers = this.defaultHeaders;
        if (xSessionid !== undefined && xSessionid !== null) {
            headers = headers.set('x-sessionid', String(xSessionid));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ProcedureResponseModel>(`${this.configuration.basePath}/procedures/all`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * @param organizationName
     * @param categoryId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public procedureApiControllerGetCategoryById(xSessionid: string, categoryId: number, locationId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProcedureCategoryDetailResponseModel>;
    public procedureApiControllerGetCategoryById(xSessionid: string, categoryId: number, locationId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProcedureCategoryDetailResponseModel>>;
    public procedureApiControllerGetCategoryById(xSessionid: string, categoryId: number, locationId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProcedureCategoryDetailResponseModel>>;
    public procedureApiControllerGetCategoryById(xSessionid: string, categoryId: number, locationId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xSessionid === null || xSessionid === undefined) {
            throw new Error('Required parameter xSessionid was null or undefined when calling estimationApiControllerEstimateLatest.');
        }
        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling procedureApiControllerGetCategoryById.');
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });

        if (locationId !== undefined && locationId !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                locationId as any, 'locationId');
        }

        let headers = this.defaultHeaders;
        if (xSessionid !== undefined && xSessionid !== null) {
            headers = headers.set('x-sessionid', String(xSessionid));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ProcedureCategoryDetailResponseModel>(`${this.configuration.basePath}/procedures/${encodeURIComponent(String(categoryId))}`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * @param organizationName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public procedureApiControllerGetProcedureCategories(xSessionid: string, locationId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProcedureCategoryResponseModel>;
    public procedureApiControllerGetProcedureCategories(xSessionid: string, locationId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProcedureCategoryResponseModel>>;
    public procedureApiControllerGetProcedureCategories(xSessionid: string, locationId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProcedureCategoryResponseModel>>;
    public procedureApiControllerGetProcedureCategories(xSessionid: string, locationId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (xSessionid === null || xSessionid === undefined) {
            throw new Error('Required parameter xSessionid was null or undefined when calling estimationApiControllerEstimateLatest.');
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });

        if (locationId !== undefined && locationId !== null) {
            queryParameters = this.addToHttpParams(queryParameters,
                locationId as any, 'locationId');
        }

        let headers = this.defaultHeaders;
        if (xSessionid !== undefined && xSessionid !== null) {
            headers = headers.set('x-sessionid', String(xSessionid));
        }

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ProcedureCategoryResponseModel>(`${this.configuration.basePath}/procedures`,
            {
                params: queryParameters,
                responseType: responseType as any,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
