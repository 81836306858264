import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

import { Params } from '@angular/router';

export interface RouterStateUrl {
    url: string;
    params: Params;
    queryParams: Params;
    data?: any;
}

export const specialTypeHandlersDefault = [
    // Set and Map are included by default, since JSON.stringify tries (and fails) to serialize them by default
    { type: Set, keys: (a: any[]) => a.keys(), get: (a: any, key: any) => key, delete: (a: { delete: (arg0: any) => any; }, key: any) => a.delete(key) },
    { type: Map, keys: (a: any[]) => a.keys(), get: (a: { get: (arg0: any) => any; }, key: any) => a.get(key), delete: (a: { set: (arg0: any, arg1: undefined) => any; }, key: any) => a.set(key, undefined) },
];

export function RemoveCircularLinks(node: any, specialTypeHandlers = specialTypeHandlersDefault, nodeStack_set = new Set()) {
    nodeStack_set.add(node);

    const specialHandler = specialTypeHandlers.find((a: any) => {
        return node instanceof a.type;
    });

    for (const key of specialHandler ? specialHandler.keys(node) : Object.keys(node)) {
        const value = specialHandler ? specialHandler.get(node, key) : node[key];
        // if the value is already part of visited-stack, delete the value (and don't tunnel into it)
        if (nodeStack_set.has(value)) {
            if (specialHandler) specialHandler.delete(node, key);
        }
        // else, tunnel into it, looking for circular-links at deeper levels
        else if (typeof value == 'object' && value != null) {
            RemoveCircularLinks(value, specialTypeHandlers, nodeStack_set);
        }
    }

    nodeStack_set.delete(node);
}

export type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends ((k: infer I) => void) ? I : never;
export type PickAndFlatten<T, K extends keyof T> = UnionToIntersection<T[K]>;
export type Relationships<T> = { [K in keyof T]-?: T[K] extends any ? K : never }[keyof T];


export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
    serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        let route = routerState.root;

        while (route.firstChild) {
            route = route.firstChild;
        }

        const {
            url, root: { queryParams },
        } = routerState;

        const { params, data } = route;


        // Only return an object including the URL, params and query params
        // instead of the entire snapshot
        return { url, params, queryParams, data };
    }
}
