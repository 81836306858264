<rs-modal #modal (show)="show($event)" [config]="modalConfig" (submit)="send()" (cancel)="cancel()">
  <div *ngIf="mainCard">
    <span class="modal-title d-flex justify-content-center fw-700">{{ mainCard.title | translate}}</span>
    <div class="container p-3">
      <div class="flex-grow-1">
        <div class="container">
          <p>{{ mainCard.groups[0].title | translate}}</p>
        </div>
      </div>
    </div>
  </div>
</rs-modal>
