import { Component, Input } from '@angular/core';
import { EstimateGroupModel } from '../../models/estimateGroupModel';

@Component({
    selector: 'provider-group-services',
    templateUrl: './provider-group-services.component.html',
    styleUrls: [
        '../../scss/styles.scss'
    ]
})
export class ProviderGroupServicesComponent {

    @Input() public providerGroup: EstimateGroupModel | undefined;
}
