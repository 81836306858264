<div
  [ngClass]="{ show: isToggled, 'dropdown-select': dropdownSelect, disabled: isDisabled }"
  class="dropdown {{ wrapClass }}">
  <button
    type="button"
    id="dropdownMenuButton"
    data-toggle="dropdown"
    aria-haspopup="true"
    [attr.aria-expanded]="isToggled"
    (click)="onClick()"
    (clickOutside)="onClickOutside()"
    (keyup.escape)="onClickOutside()"
    class="rs-btn-link text-left d-flex justify-content-between align-items-center dropdown-toggle"
    [ngClass]="dropdownSelect ? 'btn-block btn-lg select' : 'p-0'">
    <span class="label">{{ label }}</span>
    <span *ngIf="value" class="value">{{ value }}</span>
    <span *ngIf="!value" class="value"> {{ label }} </span>
  </button>
  <div
    [ngClass]="{show: isToggled}"
    class="dropdown-menu py-0 px-2"
    aria-labelledby="dropdownMenuButton"
    role="menu">
    <button
      *ngFor="let item of items, let i = index"
      class="dropdown-item"
      (keyup.escape)="onClickOutside()"
      (click)="onSelect(item.value)"
      role="menuitem">
      {{ item.label }}
    </button>
  </div>
</div>
