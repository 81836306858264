import { Component, Input, Type } from '@angular/core';
import { BaseComponent } from '../../base-component';

@Component({
    selector: 'pa-sidenav-content',
    template: `<ng-container *ngComponentOutlet="component"></ng-container>`,
})
export class SidenavContentComponent {
    @Input() component!: Type<BaseComponent>;
}
