export interface GlobalOrganization {
    id: number | string;
    externalReferenceId?: string;
    account_id?: string;
    application_id?: string;
}

export interface State {
    activeOrganization: GlobalOrganization | null;
    isLoading: boolean;
    error: any;
}

export const initialState: State = {
    activeOrganization: null,
    isLoading: false,
    error: null,
};
