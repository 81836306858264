import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { values, mapValues } from 'lodash-es';

import { RsFormFactory } from './rs-form-factory.class';
import { RsForm, RsFormModel } from '../schema/rs-form.schema';

/**
 * RsForm handles the building of the form group.
 * Similar to how the section factory operated previously.
 * It will receive an RsFormConfig component from JSON.
 *
 *
 * @export
 * @class RsFormComponent
 */
@Component({
    selector: 'rs-form',
    templateUrl: './rs-form.component.html'
})
export class RsFormComponent implements OnInit {
    @Input() public name: string;
    @Input() public element: RsForm;

    public model: RsFormModel;
    public formGroups: UntypedFormGroup;
    public modelArray: any;
    public existingForm: boolean;
    public isWrappedLayout: boolean;

    constructor(
        private readonly formFactory: RsFormFactory,
    ) { }

    ngOnInit() {
        this.formGroups = this.buildFormGroups(this.element.model);
        this.model = this.buildModel(this.element.model);
        this.existingForm = !!this.element.existing;
        // Angular requires that iterables be an array and wont iterate over an object.
        // For that reason we use lodash.values to get the values of the object model into our UI.
        // validate if values(...) is really needed / moved from controls() function
        // would be better if the this.model was already defined as an array.
        // we might by able replace this.model with the array version but model is of type RsFormModel
        this.modelArray = values(this.model);

        // @TODO: check why this is not coming up from form..
        if (!this.name) {
            this.name = this.element.name;
        }

        this.isWrappedLayout = this.modelArray?.every(x => x.theme?.control?.includes('pre-wrapped-element'));
    }

    buildFormGroups(model: RsFormModel) {
        return this.formFactory.create(model);
    }

    public buildModel(model: RsFormModel) {
        return mapValues(model, (field, fieldName) => {
            if (field.type !== 'form-group') {
                field['group'] = this.formGroups;
            }

            return field;
        });
    }
}
