import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'rs-radio-item',
    templateUrl: './radio-item.component.html',
    styleUrls: ['./radio-item.component.scss']
})
export class RadioItemComponent {
    @Input() id: string;
    @Input() value: string;
    @Input() label: string;
    @Input() name: string;
    @Input() radioControl: UntypedFormControl;
}
