import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import 'focus-visible/dist/focus-visible.min.js';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ModalWrapComponent } from '@revspringinc/rs-shared';

import { ModalService } from '@revspringinc/rs-shared';
import { ShoppingSessionService } from './main/session.service';
import { catchError, filter, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { ServerSentEventsService } from './services/sse.service';
import { CookieDisclaimerService } from './services/cookie-disclaimer.service';
import { SessionService, ShoppingSessionResponseModel, SiteConfigService } from '@shopping';
import { logEvent } from '@revspringinc/shared';
import { lastValueFrom } from 'rxjs';
@Component({
    selector: 'rs-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    public title = 'shopping';
    public headerData: any;
    public footerData: any;
    public previousRoute: string;
    public hasAcceptedDisclaimer: boolean;
    private previousModal: ModalWrapComponent;
    private session: ShoppingSessionResponseModel;

    constructor(
        private readonly translate: TranslateService,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly sessionService: SessionService,
        private readonly shoppingSessionService: ShoppingSessionService,
        private readonly modalService: ModalService,
        private readonly siteConfigService: SiteConfigService,
        private readonly translateService: TranslateService,
        private readonly sseService: ServerSentEventsService,
        private readonly cookieDisclaimerService: CookieDisclaimerService,
        @Inject(DOCUMENT) private readonly doc: HTMLDocument,
    ) {
        this.hasAcceptedDisclaimer = this.cookieDisclaimerService.hasAcceptedDisclaimer;
        this.subscribeToSession();
    }

    public async ngOnDestroy() {
        await this.sseService.closeServerSentEvent(this.session.sessionId);
    }

    @HostListener('window:unload', ['$event'])
    public unloadHandler(event) {
        this.sseService.closeServerSentEvent(this.session.sessionId);
    }

    public ngOnInit(): void {
        this.translate.setDefaultLang('en_US');
        this.translate.use('en_US');

        // Subscribe to navigation events for tracking
        this.router.events.subscribe(async (event: any) => {
            if (event instanceof NavigationEnd) {
                let route = this.activatedRoute.firstChild;
                let child = route;

                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                        route = child;
                    } else {
                        child = null;
                    }
                }

                if (this.session && route) {
                    route.data.subscribe(async (routeData) => {
                        await lastValueFrom(
                            this.sessionService.shoppingSessionControllerLogActivity(this.session.sessionId, {
                                toRoute: event.urlAfterRedirects,
                                fromRoute: this.previousRoute,
                                activityType: routeData?.activityType,
                            }),
                        );

                        this.previousRoute = event.urlAfterRedirects;
                    });
                } else {
                    this.router.navigate(['/external-redirect', { externalUrl: 'https://revspringinc.com/support' }]);
                }
            }
        });

        // Subscribe to modal events for tracking
        this.modalService.openModal$.subscribe(async (modal) => {
            if (modal) {
                // Log the session activity
                await lastValueFrom(
                    this.sessionService.shoppingSessionControllerLogActivity(this.session.sessionId, {
                        toRoute: modal.modalConfig.name,
                        fromRoute: this.previousModal?.modalConfig.name || this.previousRoute,
                        activityType: modal?.modalConfig?.activityType as any,
                    }),
                );

                this.previousModal = modal;

                return;
            }

            if (this.previousModal) {
                // Log the session activity
                await lastValueFrom(
                    this.sessionService.shoppingSessionControllerLogActivity(this.session.sessionId, {
                        toRoute: this.previousRoute,
                        fromRoute: this.previousModal.modalConfig.name,
                        activityType: modal?.modalConfig.activityType as any,
                    }),
                );

                this.previousModal = null;
            }
        });
    }

    public async cookieDisclaimerMoreInfo(): Promise<void> {
        await this.cookieDisclaimerService.showCookiePolicyModal();
    }

    public acceptCookieDisclaimer() {
        this.hasAcceptedDisclaimer = true;
        this.cookieDisclaimerService.setDisclaimerAccepted();
    }

    private subscribeToSession(): void {
        // subscribe to the session for and changes/restarts/timeouts
        this.shoppingSessionService.session.pipe(filter((x) => !!x)).subscribe(async (session) => {
            this.session = session;
            const siteConfig = await lastValueFrom(
                this.siteConfigService.siteConfigApiControllerGetSiteConfig(session.sessionId, 'org-layout'),
            );

            this.setTranslation(siteConfig);
            this.setHeaderFooter(siteConfig);
            this.setFavicon(siteConfig);

            this.sseService
                .getServerSentEvent(session.sessionId)
                .pipe(
                    filter((x) => !!x),
                    catchError((x) => {
                        throw new Error(x.message ?? 'AppComponent - SSE Service - Failed to get event');
                    }),
                )
                .subscribe((data) => {
                    if (data.data === ':\n\n') {
                        // Heartbeat, can be ignored.
                        logEvent('ServerSentEventService', 'Heartbeat received');
                        return;
                    }

                    const sessionPayload = JSON.parse(data.data);

                    // update the estimate on the shopping session service for downstream subscribers
                    if (sessionPayload.event === 'estimate-created') {
                        this.shoppingSessionService.setEstimateUpdated(sessionPayload);
                    } else if (sessionPayload.event === 'rte-updated') {
                        console.log('~RECEIVED THE MONEY', sessionPayload);
                        this.shoppingSessionService.setRteUpdated(sessionPayload);
                    }
                });
        });
    }

    private setTranslation(siteConfig: any) {
        Object.keys(siteConfig.language).forEach((lang) =>
            this.translateService.setTranslation(lang, siteConfig.language[lang], true),
        );
    }

    private setHeaderFooter(siteConfig: any): void {
        const currentYear = new Date().getFullYear();
        const footer = siteConfig.config.footer;
        const header = siteConfig.config.header;

        this.headerData = {
            logo_src: this.translateService.instant(header.logo_src),
            logo_link: this.translateService.instant(header.logo_link),
        };

        this.footerData = {
            logo_src: this.translateService.instant(footer.logo_src),
            sections: footer.sections.map((section) => {
                return {
                    title: [this.translateService.instant(section.title)],
                    subSections: section.subSections.map((subSection) => {
                        const link = subSection.link ? this.translateService.instant(subSection.link) : '';
                        const description = subSection.description
                            ? this.translateService.instant(subSection.description)
                            : '';
                        const title = subSection.title
                            ? this.translateService.instant(subSection.title).replace(':year', currentYear)
                            : '';
                        const linkType = subSection.linkType ? this.translateService.instant(subSection.linkType) : '';

                        return { link, title, description, linkType };
                    }),
                };
            }),
        };
    }

    private setFavicon(siteConfig: any): void {
        const faviconHref = this.translateService.instant(siteConfig.config.headElements.favicon);
        this.doc.getElementById('favicon').setAttribute('href', faviconHref);
    }
}
