import { ActionTypes } from '../actions/rs-form.actions'
import UiCoreState, { FormValue } from './rs-form.state'

// initialState of the UiCoreState
const initialState: UiCoreState = {
  AllSubmittedForms: {},
  LatestSubmittedForms: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD: {
      const addSubmittedForms = {...action.payload.formValue}
      const formName = action.payload.formName

      return addFormToStates(state, formName, addSubmittedForms)
    }

    default:
      return state
  }
}
/**
 * In this method The submitted form will be added to the state and the new state will be returned
 *
 * @export
 * @param {UiCoreState} state
 * @param {string} latestSubmittedFormName
 * @param {FormValue} latestSubmittedFormValue
 * @returns {UiCoreState}
 */
export function addFormToStates(
  state: UiCoreState,
  latestSubmittedFormName: string,
  latestSubmittedFormValue: FormValue): UiCoreState {
  //This object will contained all the submitted forms
  const finalObject = {}

  //the object will contain the latest submitted form and override the previous form in the state
  const overrideObject = {}

  const formNames = Object.keys(state.AllSubmittedForms)

  overrideObject[latestSubmittedFormName] = latestSubmittedFormValue

  //If the they are any existing forms in the state then It will loop through it names and it will attach the key value pairs
  //name of the form as key and value of the form as value for that key

  if (formNames.length > 0) {
    formNames.forEach(formName => {
      finalObject[formName] = state.AllSubmittedForms[formName]
    })

    finalObject[latestSubmittedFormName] = latestSubmittedFormValue

    return {
      AllSubmittedForms: finalObject,
      LatestSubmittedForms: overrideObject
    }

  } else {

    finalObject[latestSubmittedFormName] = latestSubmittedFormValue

    return {
      AllSubmittedForms: finalObject,
      LatestSubmittedForms: overrideObject
    }
  }


}
