<div
  class="w-full p-4 m-auto transition-all bg-white shadow-lg font-body md:p-12">
  <h2 class="mb-4 text-xl font-weight-bold md:text-3xl">
    {{ estimate?.title | translate }}
  </h2>

  <pa-alert
    *ngIf="estimateConfig?.disclaimer"
    [alert]="disclaimerAlert"
    [alertAcknowledged]="estimateDisclaimerAcknowledged"></pa-alert>

  <ng-container *ngIf="showDetails">
    <div
      *ngFor="let serviceGroup of estimateConfig?.serviceGroups; let first = first"
      [ngClass]="{ hidden: serviceGroup.providerGroups?.length === 0, 'mt-4': !first }"
      class="print service-group">
      <service-group-header [serviceGroup]="serviceGroup"></service-group-header>

      <div
        *ngFor="let providerGroup of serviceGroup.providerGroups; let last = last"
        [ngClass]="{ 'mb-6': !last }"
        class="p-4 mb-3 border border-opacity-50 print:provider-group md:p-12 shadow-th-card">
        <provider-group-header
          [providerGroup]="providerGroup"></provider-group-header>
        <provider-group-services
          [providerGroup]="providerGroup"></provider-group-services>
        <provider-group-total
          [providerGroup]="providerGroup"></provider-group-total>
      </div>

      <service-group-total [serviceGroup]="serviceGroup"></service-group-total>
      <selfpay-discount-total
        [selfpayDiscounts]="
        estimate?.variables?.['accountSelfpays']"></selfpay-discount-total>
    </div>
  </ng-container>

  <div
    class="flex flex-col items-center justify-between py-4 my-4 print:estimate-total-resp md:flex-row">
    <div class="font-bold whitespace-nowrap">
      {{ estimate?.summary?.responsibility?.label | translate }}
    </div>
    <div class="text-5xl font-bold whitespace-nowrap">
      {{ estimate?.summary?.responsibility?.value | translate }}
    </div>

    <a *ngIf="canShowDetails" (click)="toggleDetails()" class="p-2 py-2 text-xs font-bold border rounded-sm shadow-sm align-items-center justify-content-center md:p-4 text-th-primary md:text-sm cursor-pointer">
      <ng-container *ngIf="!showDetails">Show Details</ng-container>
      <ng-container *ngIf="showDetails">Hide Details</ng-container>
    </a>
  </div>
</div>
