import { Component, OnInit, Input, Output, EventEmitter, forwardRef, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'rs-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
})
export class CheckboxComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @ViewChild('input', { static: true }) inputElement: ElementRef;
    @Input() id: string;
    @Input() name: string;
    @Input() label: string;
    @Input() formControl: UntypedFormControl;
    @Input() wrapClass: string;
    @Input() disabled?: boolean;
    @Output() change = new EventEmitter<boolean>();
    private cb: (_: any) => any;
    private cbBlur: any;
    private subscription: Subscription;

    constructor() { }

    ngOnInit() {
        if (this.formControl) {
            this.inputElement.nativeElement.value = this.formControl.value;
            this.subscription = this.formControl.valueChanges
                .pipe(filter((val) => val !== this.inputElement.nativeElement.value))
                .subscribe((val) => this.inputElement.nativeElement.value = val);
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
    }

    writeValue(value: boolean): void {
        this.inputElement.nativeElement.checked = value;
    }

    registerOnChange(fn: any): void {
        this.cb = fn;
    }

    registerOnTouched(fn: any): void {
        this.cbBlur = fn;
    }

    setDisabledState(value: boolean): void {
        this.inputElement.nativeElement.disabled = value;
    }

    onChange(value: boolean) {
        if (this.cb) this.cb(value);
        if (this.formControl) this.formControl.setValue(value);
        this.change.emit(value);
    }
}
