import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JoinPipe } from './join.pipe';
import { PrettyPipe } from './pretty.pipe';
import { SafePipe } from './sanitization.pipe';
import { YesNoPipe } from './yesNo.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [
        JoinPipe,
        PrettyPipe,
        SafePipe,
        YesNoPipe
    ],
    exports: [
        JoinPipe,
        PrettyPipe,
        SafePipe,
        YesNoPipe
    ]
})

export class SharedPipeModule { }
