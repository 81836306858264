<div class="container" [id]="element?.id">
  <div class="row" id="topRow">
    <div class="col">
      <!-- RsSearchCard -->
      <div class="card" *ngIf="element?.search?.visible">
        <div class="card-body">
          <h5 class="card-title">
            Search All
          </h5>
          <div class="form-group">
            <input type="text" class="form-control" [placeholder]="element?.search?.placeholder" [value]="element?.search?.value" (keyup)="filterAll($event.target.value)">
          </div>
        </div>
      </div>
      <!-- RsSearchCard -->
    </div>
    <div class="col">
      <!-- RsExportCard -->
      <div class="card" *ngIf="element?.export?.visible" [ngClass]="element?.export?.theme.outerWrap">

        <div class="card-body">
          <h5 class="card-title">
            Export Options
          </h5>
          <ng-container *ngFor="let exportOpt of element?.export?.options">
            <button class="btn btn-primary" [ngClass]="element?.export?.theme.btn" type="button" (click)="exportCollection(exportOpt)">
              {{ exportOpt.name }}
            </button>
          </ng-container>
        </div>
      </div>
      <!-- RsExportCard -->
    </div>
  </div>
  <div class="row">
    <div class="col">
      <!-- RsTableCard -->
      <div class="card overflow">
        <table class="table">
          <!-- RsTableHead -->
          <thead>
            <tr>
              <ng-container *ngFor="let column of element?.columns">
                <th [class.sortable]="column.sortable" (click)="sortColumn(column)">
                  {{ column.label }}

                  <i *ngIf="column.sortable && element?.sort?.active && element?.sort?.onColumn === column.property" class="pull-right fa"
                    [ngClass]="{'fa-chevron-down': element?.sort?.direction === 'desc', 'fa-chevron-up': element?.sort?.direction === 'asc'}">
                  </i>
                </th>
              </ng-container>
            </tr>
          </thead>
          <!-- TODO: RsTableHead -->
          <tbody>
            <!-- RsFilterRow -->
            <tr>
              <ng-container *ngFor="let column of element?.columns">
                <td>
                  <input type="text" class="form-control" *ngIf="column.filterable" (keyup)="filterColumn($event.target.value, column)">
                </td>
              </ng-container>
            </tr>
            <!-- RsFilterRow -->
            <!-- RsRow -->
            <ng-container *ngFor="let row of currentPage; let i = index">
              <tr>
                <ng-container *ngFor="let column of element?.columns">
                  <td (click)="clickedCell(column, row, i)">
                    <button class="btn btn-primary" type="button" *ngIf="column.type==='button'">
                      {{ getCellData(row, column.property) }}
                    </button>
                    <input class="form-control" type="text" *ngIf="column.type==='text'" [value]="getCellData(row, column.property)">
                    <input class="form-control" type="phone" *ngIf="column.type==='phone'" [value]="getCellData(row, column.property)">
                    <input type="date" class="form-control" id="myDate" [attr.value]="getCellData(row, column.property) | date:'yyyy-MM-dd'"
                      *ngIf="column.type==='date'">
                    <input class="form-control" type="email" *ngIf="column.type==='email'" [value]="getCellData(row, column.property)">
                    <input class="form-control" type="currency" *ngIf="column.type==='currency'" [value]="getCellData(row, column.property)">
                  </td>
                </ng-container>
              </tr>
              <tr class="table-info" *ngIf="expandedIndex === i ">
                <td [attr.colspan]="element?.columns.length + 1">
                  {{ row | json }}
                </td>
              </tr>
            </ng-container>
            <!-- RsRow -->
          </tbody>
        </table>
      </div>
      <!-- RsTableCard -->
    </div>
  </div>
  <!-- RsTableRow -->
  <!-- RsPagination -->
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">
            Pagination
          </h5>
          <div class="row">

            <div class="col">
              <div class="btn-group">
                <button class="btn btn-primary" (click)="goToPage(0)">
                  First Page
                </button>
                <button class="btn btn-primary" (click)="goToPage(element?.pagination.page - 1)">
                  Previous Page
                </button>
              </div>
            </div>
            <div class="col rs-pagination-btns">
              <button class="btn btn-primary" [class.active]="(page) === element?.pagination.page" (click)="goToPage(page)" *ngFor="let page of pageList">

                Page: {{ page + 1 }}
              </button>
            </div>

            <div class="col">
              <div class="btn-group">
                <button class="btn btn-primary" (click)="goToPage(element?.pagination.page + 1)">
                  Next Page
                </button>
                <button class="btn btn-primary" (click)="goToPage(pageList.length - 1)">
                  Last Page
                </button>
              </div>
            </div>

            <div class="col">
              <div class="form-group">
                <label for="itemsPerPageOptions">Items Per Page</label>
                <select name="itemsPerPageOptions" id="itemsPerPageOptions" (change)="changeItemsPerPage($event.target.value)">
                  <option [value]="opt" *ngFor="let opt of element?.pagination.itemsPerPageOptions">
                    {{ opt }}
                  </option>
                </select>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- RsPagination -->
</div>
