import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { SiteConfigService } from '@shopping';
import { RsModalConfig } from '@revspringinc/rs-shared';
import { ModalWrapComponent } from '@revspringinc/rs-shared';
import { ShoppingSessionService } from '../../main/session.service';
import { ShoppingActivityType } from '../../main/shopping-activity-type';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@revspringinc/rs-shared';
import * as _ from 'lodash-es';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'rs-cookie-disclaimer',
    templateUrl: './cookie-disclaimer.component.html',
    styleUrls: ['./cookie-disclaimer.scss'],
})
export class CookieDisclaimerModal implements ModalWrapComponent {
    public static modalName = 'CookieDisclaimerModal';
    public config: any;
    public modalConfig = {
        activityType: ShoppingActivityType.CookieDisclaimer,
        name: 'CookieDisclaimerModal',
        maxWidth: '650px',
        bodyClass: 'bg-white',
        blockBackdropScrolling: true,
        footer: {
            saveEnabled: false,
            hidden: false,
        },
    } as RsModalConfig;

    @ViewChildren('resolver') public resolvers: QueryList<any>;
    @ViewChild('modal', { static: true }) public modal;

    constructor(
        private readonly modalService: ModalService,
        private readonly shoppingSessionService: ShoppingSessionService,
        private readonly translate: TranslateService,
        private readonly siteConfigService: SiteConfigService,
    ) {}

    public async show() {
        const siteConfig = await lastValueFrom(
            this.siteConfigService.siteConfigApiControllerGetSiteConfig(
                this.shoppingSessionService.sessionId,
                'cookie-disclosure',
            ),
        );
        this.setTranslations(siteConfig);
        this.config = siteConfig.config;
    }

    public async cancel() {
        this.modalService.hide({ accepted: false });
    }

    private setTranslations(data: any) {
        Object.keys(data.language).forEach((lang) => this.translate.setTranslation(lang, data.language[lang], true));
    }
}
