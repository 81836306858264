<fieldset>
  <rs-dropdown
    #dropdown
    [label]="controlsArray[index].elementOptions.label | translate"
    wrapClass="btn-group"
    triggerWrapClass="rs-btn-link fs-18 fw-700"
    (select)="changeElement($event)"
    [displaySelectedLabel]="true"
    [items]="dropdownItems">
  </rs-dropdown>

  <div class="form-group">
    <ng-container *ngFor="let control of controlsArray; let i = index">
      <ng-container rsResolve *ngIf="i === index" [element]="control"></ng-container>
    </ng-container>
  </div>
</fieldset>