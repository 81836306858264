import { Validators, AbstractControl, ValidationErrors, ValidatorFn, UntypedFormGroup } from '@angular/forms';
import moment from 'moment';;

import { Registry } from '../../registry/registry';
import { RsValidation } from '../../forms-module/models/validation.model';

type ValidationRegistryCallback = (registry: ValidationRegistryService) => ValidationRegistryService;

export class ValidationRegistryService extends Registry<any> {
    static configure(cb: ValidationRegistryCallback) {
        const registry = new ValidationRegistryService()
            .add('required', Validators.required)
            .add('minlength', Validators.minLength)
            .add('maxlength', Validators.maxLength)
            .add('min', Validators.min)
            .add('max', Validators.max)
            .add('pattern', customRegexValidation)
            .add('regex', Validators.pattern)
            .add('email', emailValidator)
            .add('nullValidator', Validators.nullValidator)
            .add('compose', Validators.compose)
            .add('custom1', () => null)
            .add('custom2', () => null)
            .add('custom3', () => null)
            .add('invalidcardexpiration', validateExpDate)
            .add('invaliddate', invalidDate)
            .add('nonfuturedate', futureDateValidation)
            .add('identical', identicalValidation);

        return cb(registry);
    }

    constructor() {
        super('Validations');
    }

    getControlValidators(validationConfig: RsValidation) {
        return Object.keys(validationConfig).map((validationName) => {
            const validation = validationConfig[validationName];
            const validationFunc = this.resolve(validationName);

            if (Array.isArray(validation.Parameters)) {
                if (validationName === 'pattern') {
                    return validationFunc(validation.Parameters[0], validation.Parameters[1]);
                } else {
                    if (validationName === 'identical') {
                        return validationFunc(validation.Parameters[0]);
                    }

                    return validationFunc(...validation.Parameters);
                }
            }

            return validationFunc;
        });
    }
}

export function validateExpDate(control: AbstractControl): ValidationErrors | null {
    if (Validators.required(control) !== undefined && Validators.required(control) !== null) {
        return { InvalidCardExpiration: true };
    }
    const controlValue = control.value;

    if (typeof controlValue !== 'undefined' && controlValue.length === 5) {
        let [month, year] = controlValue.replace(/[^0-9 ]/g, '/').split(/[\s/]+/, 2);
        let prefix;

        if ((year != null ? year.length : void 0) === 2 && /^\d+$/.test(year)) {
            prefix = new Date().getFullYear();
            prefix = prefix.toString().slice(0, 2);
            year = prefix + year;
        }
        month = parseInt(month, 10).toString();
        year = parseInt(year, 10).toString();

        if (/^\d+$/.test(month) && /^\d+$/.test(year) && (month >= 1 && month <= 12)) {
            const expiry = new Date(year, month);
            const currentTime = new Date();

            expiry.setMonth(expiry.getMonth() - 1);
            expiry.setMonth(expiry.getMonth() + 1, 1);

            if (expiry > currentTime) {
                return null;
            } else {
                return { InvalidCardExpiration: true };
            }
        } else {
            return { InvalidCardExpiration: true };
        }
    } else {
        return null;
    }
}


export function invalidDate(control: AbstractControl): ValidationErrors {
    const value = (control.value) ? control.value.replace(/[^\d]/g, '') : null;
    if (value && value.length === 8) {
        const datePattern = /^(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])\d{4}$/g;
        if (datePattern.test(value)) {
            return null;
        } else {
            return { invaliddate: true };
        }
    }
}

export function futureDateValidation(control: AbstractControl): ValidationErrors {
    const value = (control.value) ? control.value.replace(/[^\d]/g, '') : null;
    if (value && value.length === 8) {
        const todayDate = moment().format('MMDDYYYY');
        const userEnteredDate = moment(value, 'MMDDYYYY');
        const today = moment(todayDate, 'MMDDYYYY');
        if (userEnteredDate.diff(today, 'days') > 0) {
            return { nonfuturedate: true };
        } else {
            return null;
        }
    }
}

export function emailValidator(control: AbstractControl): Validators | null {
    if (control.dirty) {
        // tslint:disable-next-line:max-line-length
        const isValid = /^(([^<>()[]\\.,;:\s@"]+(\.[^<>()[]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(control.value);
        if (isValid || control.value === '') {
            return null;
        } else {
            return { email: true };
        }
    }
}

export function customRegexValidation(regexValue: string, regexFlag: string = ''): ValidatorFn {
    return function patternValidation(control: AbstractControl): Validators | null {
        if (control.dirty && regexValue) {
            const newRex = new RegExp(regexValue, regexFlag);
            const isValid = newRex.test(control.value);
            if (isValid || control.value === '') {
                return null;
            } else {
                return { pattern: true };
            }
        }
    };
}

export function identicalValidation(identicalKey: string): ValidatorFn {
    return (control: AbstractControl) => {
        if (!control.parent && !identicalKey) return null;
        if (
            control.parent &&
            (control.parent as UntypedFormGroup).controls[identicalKey] &&
            (control.parent as UntypedFormGroup).controls[identicalKey].value !== control.value
        ) {
            return { identical: true };
        }
        return null;
    };
}
