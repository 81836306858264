import { AddInsurancePlanModal } from './modals/add-insurance-plan/add-insurance-plan.component';
import { SelectServicesModal } from './modals/select-services/select-services.component';
import { SelectLocationModal } from './modals/select-location/select-location.component';
import { SelectProceduresModal } from './modals/select-procedures/select-procedures.component';
import { CompareCostsModal } from './modals/compare-costs/compare-costs.component';
import { ContactMeModal } from './modals/contact-me/contact-me.component';
import { TextEstimateSummaryModal } from './modals/text-estimate-summary/text-estimate-summary.component';
import { NoticeMessageModal } from './modals/notice-message/notice-message.component';
import { CookieDisclaimerModal } from './modals/cookie-disclaimer/cookie-disclaimer.component';
import { AmaNoticeModal } from './modals/ama-notice/ama-notice.component';

export const ModalComponents = {
  AddInsurancePlanModal,
  SelectServicesModal,
  SelectLocationModal,
  SelectProceduresModal,
  CompareCostsModal,
  ContactMeModal,
  TextEstimateSummaryModal,
  NoticeMessageModal,
  CookieDisclaimerModal,
  AmaNoticeModal
};
