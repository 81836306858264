import { createAction, props } from '@ngrx/store';


export enum ActionTypes {
    GLOBAL_ORGANIZATION_SET_ACTIVE = '[Global Organization] Set Active'
}

export const SetActiveAction = createAction(
    ActionTypes.GLOBAL_ORGANIZATION_SET_ACTIVE,
    props<{
        organization: {
            id: number | string,
            externalReferenceId?: string;
            account_id?: string;
            application_id?: string;
        };
    }>(),
);
