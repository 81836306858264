import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { BaseComponent } from '../../base-component';
import { SidenavService } from './sidenav.service';

@Component({
    selector: 'pa-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['../../../../../scss/styles.scss', './sidenav.component.scss'],
})
export class SidenavComponent extends BaseComponent {
    constructor(public readonly sidenavService: SidenavService, translate: TranslateService) {
        super(translate);
    }
}
