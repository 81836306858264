<div *ngIf="show"
     class="flex flex-row items-center justify-between mb-4">
    <h3 class="flex ml-4 text-sm font-bold md:text-lg">{{ service?.title | translate }}</h3>

    <div class="flex space-x-2">
        <div class="inline-flex items-center p-2 space-x-2 font-bold text-white align-middle rounded-sm shadow-sm bg-th-primary"
             *ngFor="let diagnosisCode of service?.diagnosisCodes">
            <pa-hero-icon [name]="'healing-solid'"
                          [size]="4"
                          [class]="'text-white'">
            </pa-hero-icon>
            <span class="ml-4 text-sm">{{ diagnosisCode || 'Diagnosis' }}</span>
        </div>

        <div class="inline-flex items-start p-2 space-x-2 font-bold text-white align-middle rounded-sm shadow-sm bg-th-primary"
             *ngIf="service?.procedureCode">
            <pa-hero-icon [name]="'local-hospital-solid'"
                       [size]="4"
                       [class]="'text-white'">
            </pa-hero-icon>

            <span class="ml-4 text-sm">{{ service?.procedureCode || 'Procedure' }}</span>

        </div>
    </div>
</div>
