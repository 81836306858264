<div [formGroup]="element.group"
     class="form-group"
     [ngClass]="element?.theme?.outerWrap"
     *ngIf="visible">
  <label *ngIf="element?.elementOptions?.label" [ngClass]="element?.theme?.labelClass">{{ element?.elementOptions?.label | translate }}
      <b *ngIf="element.validations?.required" style="color:red">*</b>
    <span *ngIf="element?.tooltip?.display">
      <i class="fa fa-question-circle" aria-hidden="true" placement="{{element.tooltip.placement || 'right'}}" ngbTooltip="{{element.tooltip.text}}"></i>
    </span> : </label>
  <ng-container *ngFor="let option of element?.elementOptions?.options; let i = index">
    <div [ngClass]="element?.theme?.alignmentClass">
      <div [ngClass]="element?.theme?.sampleClass" class="wrapper wrapper-radio mb-3">
        <input
          [id]="element?.id + '-' + i"
          [name]="element?.name"
          type="radio"
          [ngClass]="element?.theme?.control"
          [formControlName]="element.name"
          [value]="option.value"
          [attr.disabled]="option.disabled === true ? true : null">
        <label [for]="element?.id + '-' + i" [ngClass]="element?.theme?.childLabel">{{ option.label | translate }}</label>
      </div>
    </div>
  </ng-container>
  <div class="alert alert-danger" role="alert" *ngIf="element.group.controls[this.element['name']].dirty && !element.group.controls[this.element['name']].pristine && (element.group.controls[this.element['name']].status === 'INVALID')">
    <rs-error-message [fieldForm]="element.group.controls[this.element['name']]" [field]="element"></rs-error-message>
  </div>
</div>
