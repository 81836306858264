import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { EstimateSection, EstimateSectionItem } from '../../models/estimate-section';
import { EstimateConfigModel } from '../../models/estimateConfigModel';

@Component({
    selector: 'estimate-section',
    templateUrl: './estimate-section.component.html',
    styleUrls: ['../../scss/styles.scss'],
    styles: [`
        :host {
            @apply contents;
        }
    `],
    animations: [
        trigger('hasError', [
            state('error', style({
                borderColor: '#da2c2c',
                borderWidth: '1px',
            })),
            state('valid', style({
                borderWidth: '1px',
                borderColor: 'transparent',
            })),
            transition('* => valid', [
                style({
                    borderColor: '#16a34a',
                    borderWidth: '1px',
                }),
                animate('1s'),
                style({
                    borderColor: 'transparent',
                    borderWidth: '1px',
                })
            ]),
            transition('* => error', [
                animate('0.5s')
            ]),
        ]),
    ],
})
export class EstimateSectionComponent {
    @Input() public sections: EstimateSection[] | undefined;
    @Input() public estimate: EstimateConfigModel[] | undefined;
    @Input() public estimateChange: ((estimate: EstimateConfigModel) => Promise<boolean>) | undefined;

    public getSectionClass(section: EstimateSection): string {
        return [
            section.class,
        ].filter(x => (x || '') !== '').join(' ');
    }

    public getItemClass(item: EstimateSectionItem): string {
        return [
            item.class,
        ].filter(x => (x || '') !== '').join(' ');
    }

    public hasError(section: EstimateSection): boolean {
        return section.items?.filter(x => x.text).some(i => (i.text?.content || '') === '' && i.text?.required) || false;
    }

    public getItemGroupClass(section: EstimateSection): string {
        return [
            section.class,
        ].filter(x => (x || '') !== '').join(' ');
    }
}
