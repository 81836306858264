import { Component } from '@angular/core';

import { SidenavService } from '../../sidenav/sidenav.service';

@Component({
    selector: 'pa-mobile-menu-button',
    templateUrl: './mobile-menu-button.component.html',
    styleUrls: ['../../../../../../scss/styles.scss'],
})
export class MobileMenuButtonComponent {
    constructor(
        public readonly sidenavService: SidenavService,
    ) {}
}
