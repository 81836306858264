import { Directive, ElementRef, HostListener, Input } from '@angular/core'
/**
 * RsTooltipDirective will used to display the tooltip with the text passed to it and align the tooltip based on the placement passed to it.
 *
 * @export
 * @class RsTooltipDirective
 */
@Directive({
  selector: '[rsTooltip]'
})
export class RsTooltipDirective {
  /**
   * tooltip stores the Html element which will be rendered on the UI as tooltip
   *
   * @type {HTMLElement}
   * @memberof RsTooltipDirective
   */
  tooltip: HTMLElement
  /**
   * refElementPlacement stores the position of the element on which the tooltip is to be displayed
   *
   * @type {ClientRect}
   * @memberof RsTooltipDirective
   */
  refElementPlacement: ClientRect
  /**
   * offset of the tooltip
   *
   * @memberof RsTooltipDirective
   */
  tooltipOffset = 0
  /**
   * tooltipText variable stores the text that need to be displayed on the tooltip
   *
   * @memberof RsTooltipDirective
   */
  @Input('rsTooltip') tooltipText = ''
  /**
   * placement stores the alignment of the tooltip with respective to the element on which it is dispalyed. Default is right
   *
   * @type {string}
   * @memberof RsTooltipDirective
   */
  @Input() placement: string
  /**
   * The width of the tooltip. Default is 200px
   *
   * @type {string}
   * @memberof RsTooltipDirective
   */
  @Input() width: string
  /**
   * Listening to mouse entering events to show tooltip
   *
   * @memberof RsTooltipDirective
   */
  @HostListener('focusin')
  @HostListener('mouseenter')
  @HostListener('mousemove')
  onMouseEnter() {
    this.getrefElementPlacement()

    if (!this.tooltip) {
      this.createTooltip()
      this.setTooltipplacement()
      this.showTooltip()
    }
  }
  /**
   * Listening to mouse Leaving events to remove tooltip
   *
   * @memberof RsTooltipDirective
   */
  @HostListener('focusout')
  @HostListener('mouseleave')
  @HostListener('mousedown')
  onMouseLeave() {
    this.removeTooltip()
  }

  constructor(private elementRef: ElementRef) { }

  /**
   * fetching the placement of the reference element on which the directive is attached
   *
   * @memberof RsTooltipDirective
   */
  getrefElementPlacement() {
    this.refElementPlacement = this.elementRef.nativeElement.getBoundingClientRect()
  }
  /**
   * creating and appending the tooltip to the document
   *
   * @memberof RsTooltipDirective
   */
  createTooltip() {
    this.tooltip = document.createElement('span')
    this.tooltip.className += 'rs-tooltip rs-tooltip-' + this.placement
    this.tooltip.textContent = this.tooltipText
    this.tooltip.style.position = 'absolute'
    this.tooltip.style.background = 'black'
    this.tooltip.style.color = 'white'
    this.tooltip.style['max-width'] = this.width || '200px'

    document.body.appendChild(this.tooltip)
  }
  /**
   * adding the show class to the tooptip element
   *
   * @memberof RsTooltipDirective
   */
  showTooltip() {
    if (this.tooltip) {
      this.tooltip.className += ' rs-tooltip-show'
    }
  }
  /**
   * to removeTooltip removeTooltip the tooltip
   *
   * @memberof RsTooltipDirective
   */
  removeTooltip() {
    if (this.tooltip) {
      this.tooltip.classList.remove('rs-tooltip-show')
      window.setTimeout(() => {
        this.tooltip.parentNode.removeChild(this.tooltip)
        this.tooltip = null
      }, 50)
    }
  }
  /**
   * to postion the tooltip depends on the position of the element on whcih the tooltip need to be rendered
   *
   * @memberof RsTooltipDirective
   */
  setTooltipplacement() {
    const { offsetHeight } = this.elementRef.nativeElement
    const { offsetWidth } = this.elementRef.nativeElement
    const { clientHeight } = this.tooltip
    const tooltipWidth = this.tooltip.offsetWidth
    const { pageYOffset } = window
    //If placement is empty nothing is passed from the component. The It is defaulted to right
    if (this.placement === '' || this.placement === undefined) {
      this.placement = 'right'
    }

    if (this.placement === 'top') {
      this.tooltip.style.top =
        this.refElementPlacement.top +
        pageYOffset -
        (clientHeight + this.tooltipOffset) +
        'px'
      this.tooltip.style.left =
        this.refElementPlacement.left +
        offsetWidth / 2 -
        tooltipWidth / 2 +
        'px'
    } else if (this.placement === 'bottom') {
      this.tooltip.style.top =
        this.refElementPlacement.top +
        pageYOffset +
        offsetHeight +
        this.tooltipOffset +
        'px'
      this.tooltip.style.left =
        this.refElementPlacement.left +
        offsetWidth / 2 -
        tooltipWidth / 2 +
        'px'
    } else if (this.placement === 'left') {
      this.tooltip.style.left =
        this.refElementPlacement.left -
        tooltipWidth -
        this.tooltipOffset +
        'px'
      this.tooltip.style.top =
        this.refElementPlacement.top +
        pageYOffset +
        offsetHeight / 2 -
        this.tooltip.clientHeight / 2 +
        'px'
    } else if (this.placement === 'right') {
      this.tooltip.style.left =
        this.refElementPlacement.left + offsetWidth + this.tooltipOffset + 'px'
      this.tooltip.style.top =
        this.refElementPlacement.top +
        pageYOffset +
        offsetHeight / 2 -
        this.tooltip.clientHeight / 2 +
        'px'
    }
  }
}
