import { Component, Input, } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';

export class Alert {
    public title?: string;
    public message!: string;
    public icon!: string;
    public priority!: string;
}

@Component({
    selector: 'pa-alert',
    templateUrl: './alert.component.html',
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ overflow: 'hidden', opacity: 0, maxHeight: 0 }),
                animate('500ms ease-in-out', style({ opacity: 1, maxHeight: '200px' }))
            ]),
            transition(':leave', [
                animate('500ms ease-in-out', style({ opacity: 0 }))
            ])
        ]),
    ],
    styleUrls: ['../../../../scss/styles.scss']
})
export class AlertComponent {
    @Input() public alert!: Alert;
    @Input() public alertAcknowledged?: Date | string | undefined;

    public open() {
        return this.alert !== null;
    }
}
