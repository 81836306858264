import { Component, Input, Output, EventEmitter, NgModule } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'rs-collapse',
    templateUrl: './collapse.component.html',
    styleUrls: ['./collapse.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ overflow: 'hidden', opacity: 0, maxHeight: 0 }),
                animate('500ms ease-in-out', style({ opacity: 1, maxHeight: '200px' })),
            ]),
            transition(':leave', [animate('500ms ease-in-out', style({ opacity: 0 }))]),
        ]),
    ],
})
export class CollapseComponent {
    @Input() title: string;
    @Input() btnWrapClass: string;
    @Input() contentWrapClass: string;
    @Input() buttonVisible = true;
    @Input() id: string;
    @Input() isOpen: boolean = false;
    @Output('show') showEmit: EventEmitter<void> = new EventEmitter<void>();
    @Output('hide') hideEmit: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    toggle() {
        this.isOpen = !this.isOpen;
        this.isOpen ? this.showEmit.emit() : this.hideEmit.emit();
    }

    show() {
        this.isOpen = true;
        this.showEmit.emit();
    }

    hide() {
        this.isOpen = false;
        this.hideEmit.emit();
    }
}
@NgModule({
    imports: [CommonModule, TranslateModule.forChild()],
    declarations: [CollapseComponent],
    exports: [CollapseComponent],
})
export class CollapseModule {}
