import { Component, Input } from '@angular/core';
import { EstimateServiceModel } from '../../models/estimateServiceModel';

@Component({
    selector: 'provider-group-service-header',
    templateUrl: './provider-group-service-header.component.html',
    styleUrls: ['../../scss/styles.scss'],
})
export class ProvideGroupServiceHeaderComponent {
    @Input() public service: EstimateServiceModel | undefined;

    public get show() {
        if (!this.service) {
            return false;
        }

        return !!this.service.headerVisible;
    }
}
