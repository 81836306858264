// third-party imports
import { Component, Input, OnInit } from '@angular/core';
/**
 *
 *
 * @export
 * @class RsButtonComponent
 */
@Component({
    selector: 'rs-button',
    templateUrl: './rs-button.component.html',
    styleUrls: ['../../../scss/styles.scss']
})
export class RsButtonComponent implements OnInit {
    @Input() public element;
    @Input() public value;

    public ngOnInit() {
        this.value = this.value ? this.value : this.element.elementOptions.value;
    }

    public defaultEvent() {
    }
}
