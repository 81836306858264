import { OnInit, OnDestroy, Input, HostBinding, Directive } from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { uniqueId } from 'lodash-es';
import { distinctUntilChanged } from 'rxjs/operators';
import * as _ from 'lodash-es';

import { DisplayModel } from './schemas/displayModel';
import { Condition } from './schemas/condition';
@Directive()
export class RsBaseComponent implements OnInit, OnDestroy {

    @Input() public element;
    @HostBinding('className') public hostClass = '';

    public control: UntypedFormControl;
    public id: string;
    public error = '';
    public visible: boolean = true;

    private readonly displaySubscriptions: Subscription[] = [];
    private errorSubscription: Subscription;

    public ngOnInit(): void {

        this.id = uniqueId(`${this.element.id}-`);

        this.control = this.element.group.get(this.element.name);

        this.hostClass = this.element?.theme?.hostClass ?? '';

        if (this.element.display) {
            const display = this.element.display as DisplayModel;

            this.visible = display.initialValue === 'visible';

            display.conditions.forEach(condition => {

                const displayConditionSubscription = this.control.root.get(condition.source)
                    ?.valueChanges
                    .pipe(distinctUntilChanged())
                    .subscribe((value: any) => {

                        const result = this.checkCondition(value, condition);

                        if (display.value === 'visible') {
                            this.visible = result;
                        } else if (display.initialValue === 'hidden') {
                            this.visible = result;
                        } else if (display.initialValue === 'toggle') {
                            this.visible = !this.visible;
                        }

                        if (!this.visible) {
                            this.control.disable();
                        } else {
                            this.control.enable();
                        }

                        this.control.updateValueAndValidity();
                    });

                this.displaySubscriptions.push(displayConditionSubscription);
            });
        }

        if (!this.element.validations) return;

        if (this.control.errors) {
            this.setError(Object.keys(this.control.errors));
        }

        this.errorSubscription = this.control.statusChanges.subscribe((status) => {
            if (status !== 'VALID') {
                this.setError(Object.keys(this.control?.errors ?? []));

                return;
            }

            this.error = '';
        });
    }

    public ngOnDestroy(): void {
        this.displaySubscriptions.forEach(x => x.unsubscribe());

        if (!this.element.validations) return;
        this.errorSubscription.unsubscribe();
    }

    public setError(errors) {
        if (errors.length === 0) {
            this.error = '';

            return;
        }

        let firstErrName = errors[0];
        if (['Max', 'Min'].includes(firstErrName)) {
            firstErrName = `${firstErrName}Length`;
        }

        this.error = this.element.validations[firstErrName]?.message;
    }

    public buttonClick(payload?: any) {
        if (payload?.onClick) {
            payload.onClick(payload);
        }
    }

    private checkCondition(value: any, condition: Condition) {

        if (condition.path) {
            value = _.get(value, condition.path);
        }

        if (condition.operator === 'equals') {
            return value === condition.value;
        }

        if (condition.operator === 'not equals') {
            return value !== condition.value;
        }

        if (condition.operator === 'contains') {
            return String(value).includes(String(condition.value));
        }

        if (condition.operator === 'starts with') {
            return String(value).startsWith(String(condition.value));
        }

        if (condition.operator === 'ends with') {
            return String(value).endsWith(String(condition.value));
        }
    }
}
