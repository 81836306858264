import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../components/base-component';

@Pipe({
    name: 'lang',
    pure: false
})
export class LangPipe extends BaseComponent implements PipeTransform {

    constructor(translateService: TranslateService) {
        super(translateService);
    }

    public transform(value: any, ...args: any[]): string {
        if (!value || typeof value === 'string') {
            return value;
        }

        return value[this.translateService?.currentLang || 'en_US'];
    }
}
