import { assign } from 'lodash-es'
import { RsGridState } from './rs-grid.state'
import { RsGridConfig, RsGridColumn, RsSortSettings, RsExportOption } from '../schema/rs-grid.schema'

function scrollToTopRow() {
  // console.log('Scrolling to top row...')

  const topRow = document.getElementById('topRow')

  topRow.scrollIntoView({
    behavior: 'smooth'
  })
}

export const RsGridMutations = {
  loadConfig,
  loadData,
  sortOnColumn,
  filterAll,
  goToPage,
  filterOnColumn,
  changeItemsPerPage,
  exportCollection,
  expandRow,
  showRow
}

function loadConfig(state: RsGridState, payload: {
  config: RsGridConfig
}): RsGridState {

  return assign({}, state, {
    config: payload.config
  })
}

function loadData<T = any> (state: RsGridState, payload: {
  data: T[]
}): RsGridState {
  return assign({}, state, {
    expandedIndex: -1,
    collection: payload.data
  })
}

function sortOnColumn(state: RsGridState, payload: {
  column: RsGridColumn
}): RsGridState {

  const currentSortColumn    = state.config.sort.onColumn
  const currentSortDirection = state.config.sort.direction
  const nextSortColumn       = payload.column.property

  const nextSort: RsSortSettings = {
    active: true,
    onColumn: nextSortColumn,
    direction: 'asc'
  }

  if (currentSortColumn === nextSortColumn) {
    if (currentSortDirection === 'asc') {
      nextSort.direction = 'desc'
    } else {
      nextSort.direction = 'asc'
    }
  }

  return assign({}, state, {
    expandedIndex: -1,
    config: {
      ...state.config,
      sort: nextSort,
      pagination: {
        ...state.config.pagination,
        page: 0 // TODO: Document going back to first page
      },
    }
  })
}

function filterAll(state: RsGridState, payload: {
  query: string
}): RsGridState {
  return assign({}, state, {
    filter: {
      ...state.filter,
      global: payload.query
    }
  })
}

function goToPage(state: RsGridState, payload: {
  pageNumber: number
}): RsGridState {

  scrollToTopRow()

  return assign({}, state, {
    expandedIndex: -1,
    config: {
      ...state.config,
      pagination: {
        ...state.config.pagination,
        page: payload.pageNumber
      }
    }
  })
}

function filterOnColumn(state: RsGridState, payload: {
  column: RsGridColumn,
  query: string
}): RsGridState {

  const columnFilters: any = {
    ...state.filter.columns,
    [payload.column.property]: payload.query
  }

  const emptyQuery = payload.query.length === 0

  if (emptyQuery) {
    delete columnFilters[payload.column.property]
  }

  return assign({}, state, {
    expandedIndex: -1,
    config: {
      ...state.config,
      pagination: {
        ...state.config.pagination,
        page: 0 // TODO: Document Reset to page 0.
      }
    },
    filter: {
      ...state.filter,
      columns: columnFilters
    }
  })
}

function changeItemsPerPage(state: RsGridState, payload: {
  itemsPerPage: number
}): RsGridState {

  // TODO: Should scroll to top be an effect?
  scrollToTopRow()

  return assign({}, state, {
    expandedIndex: -1,
    config: {
      ...state.config,
      pagination: {
        ...state.config.pagination,
        page: 0, // TODO: Document reset page to 0 because losing track
        itemsPerPage: payload.itemsPerPage
      }
    }
  })
}

function exportCollection(state: RsGridState, payload: {
  exportOpt: RsExportOption
}): RsGridState {
  // ! Trigger export with collection and name/type?
  // ! Basically a no-op and should be an effect...
 // console.log('Export Collection', payload.exportOpt)
  return state
}

function expandRow(state: RsGridState, payload: {
  rowIndex: number
}): RsGridState {
  const currentExpandedIndex = state.expandedIndex

  if (payload.rowIndex === currentExpandedIndex) {
    payload.rowIndex = -1
  }

  return assign({}, state, {
    expandedIndex: payload.rowIndex
  })
}

function showRow(state: RsGridState, payload: {
  row: any
}): RsGridState {
  return assign({}, state, {
    selectedRow: payload.row
  })
}
