import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SiteConfigService } from '@shopping';

import { ShoppingSessionService } from '../main/session.service';
import { AppComponent } from '../app.component';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'rs-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  public faqData: any;
  public returnRoute: string;

  constructor(
    private readonly siteConfigService: SiteConfigService,
    private readonly shoppingSessionService: ShoppingSessionService,
    private readonly translateService: TranslateService,
    private readonly appComponent: AppComponent,
    private readonly location: Location,
    private readonly router: Router,
  ) { }

  public async ngOnInit() {
    this.returnRoute = this.appComponent.previousRoute ? this.appComponent.previousRoute : `${this.location.path().replace('faq', 'landing')}`;
    this.faqData = await lastValueFrom(this.siteConfigService.siteConfigApiControllerGetSiteConfig(this.shoppingSessionService.sessionId, 'faq'));
    this.setTranslation(this.faqData);
  }

  public goBack() {
    this.router.navigate([`${this.returnRoute}`]).then(x => {
      // need to trigger a reload on /estimate-services since cached session info will not populate until after a refresh
      if (this.returnRoute.includes('estimate-services')) {
        window.location.reload();
      }
    });
  }

  private setTranslation(siteConfig: any) {
    Object.keys(siteConfig.language).forEach(lang => this.translateService.setTranslation(lang, siteConfig.language[lang], true));
  }

}
