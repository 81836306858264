import { Component, OnInit,  } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'rs-backdrop',
  templateUrl: './backdrop.component.html',
  styleUrls: ['./backdrop.component.scss'],
  animations: [
    trigger('animateBackdrop', [
      state('initial', style({
        opacity: '0',
      })),
      state('final', style({
        opacity: '0.85',
      })),
      transition('initial=>final', animate('225ms 225ms ease-in')),
      transition('final=>initial', animate('250ms ease-out')),
    ]),
  ],
})
export class RSBackdropComponent {
  animationState$: Observable<string> = this.modalService.hasModalOpen$.pipe(map((hasOpen) => hasOpen ? 'final' : 'initial'));

  constructor(
    private modalService: ModalService,
  ) { }
}
