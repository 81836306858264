import { ApolloLink } from '@apollo/client';
import { JwtHelperService } from '@auth0/angular-jwt';
import { logEvent } from '@pa-ui';

const jwtHelper = new JwtHelperService();

export const buildHeader = (accessTokenStorageKey: string, headers: any = {}): any => {
    const token = getTokenFromLocalStorage(accessTokenStorageKey) || getTokenFromCookie(accessTokenStorageKey);

    if (!token) {
        headers = {};
        return headers;
    }

    const tokenState = getTokenState(token);

    if (tokenState.valid) {
        headers = {
            headers: {
                ...headers,
                Authorization: `Bearer ${token}`,
            },
        };
    }

    return headers;
};

export const getTokenFromLocalStorage = (accessTokenStorageKey: string): string | null => {
    const token = localStorage.getItem(accessTokenStorageKey) || sessionStorage.getItem(accessTokenStorageKey);
    return token;
};

export const getTokenFromCookie = (accessTokenStorageKey: string) => {
    const cookie = `; ${document.cookie}`.match(`;\\s*${accessTokenStorageKey}=([^;]+)`);
    const token = cookie && decodeURIComponent(cookie?.[1]);
    return token;
};

export const tokenIsExpiring = (expirationSeconds: number, bufferInSeconds = 0) =>
    expirationSeconds * 1000 - bufferInSeconds * 1000 <= new Date().getTime();

export function getTokenState(token?: string | null) {
    if (!token) {
        return { valid: false, needRefresh: true, expired: true, expiresIn: 0 };
    }

    if (token.split('.').length !== 3) {
        return { valid: false, needRefresh: true, expired: true, expiresIn: 0 };
    }

    const decoded = jwtHelper.decodeToken(token);
    const timestamp = Math.floor(new Date().getTime() / 1000);

    if (!decoded) {
        return { valid: false, needRefresh: true, expired: true, expiresIn: 0 };
    }

    if (decoded.exp && timestamp >= decoded.exp) {
        return { valid: true, needRefresh: true, expired: true, expiresIn: 0 };
    }

    return { valid: true, needRefresh: false, expired: false, expiresIn: decoded.exp };
}

export const authLink = (accessTokenStorageKey: string) =>
    new ApolloLink((operation, forward) => {
        // add the authorization to the headers
        operation.setContext(({ headers = {} }) => {
            const context = buildHeader(accessTokenStorageKey, headers);
            logEvent('AuthLink', 'context set to =>', context);

            return context;
        });

        return forward(operation);
    });
