import { Injectable } from '@angular/core';
import { Registry } from '../../registry/registry';

@Injectable({ providedIn: 'root' })
export class ModalRegistryService extends Registry<any> {
    static configure(cb: (registry: ModalRegistryService) => ModalRegistryService) {
        const registry = new ModalRegistryService();

        return cb(registry);
    }

    constructor() {
        super('Modal');
    }
}
