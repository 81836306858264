import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, ParamMap, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { OrganizationService } from './organization.service';
import { ShoppingSessionService } from './session.service';
import * as Sentry from '@sentry/angular';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class SessionResolverService implements Resolve<any> {
    constructor(
        private readonly organizationService: OrganizationService,
        private readonly shoppingSessionService: ShoppingSessionService,
        private readonly router: Router,
        private readonly localStorageService: LocalStorageService) { }

    public async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const orgName = route.paramMap.get('orgName');
        const existingSessionId = this.localStorageService.getSessionId();
        this.organizationService.setOrganization(orgName);

        try {
            // either create a new session or re-hydrate the existing session
            if (existingSessionId) {
                const existingSession = await this.shoppingSessionService.getExistingSession(existingSessionId);

                if (existingSession.orgName.toLowerCase() !== orgName.toLowerCase()) {
                    const newSession = await this.shoppingSessionService.createSession(orgName, true);
                    this.localStorageService.setSessionId(newSession.sessionId);
                }
            }
            else {
                const newSession = await this.shoppingSessionService.createSession(orgName, true);
                this.localStorageService.setSessionId(this.shoppingSessionService.sessionId);
            }
        } catch (error) {
            Sentry.captureException(error);

            // ToDo: redirect to an internal error page?
            this.router.navigate(['/external-redirect', { externalUrl: 'https://revspringinc.com/support' }]);
        }

        // Return the session id to be consumed by the components.
        return this.shoppingSessionService.sessionId;
    }
}
