import { Component, Input } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'rs-tabbed-consumer-group',
  templateUrl: './rs-tabbed-consumer-group.component.html'
})
export class RsTabbedConsumerGroupComponent {
  @Input() controlsArray: any
  index = 0;
  group: UntypedFormGroup
  control;

  changeElement(event) {
    if (!event) return;
    this.index = event.i;
    this.control = this.controlsArray[this.index];
    this.group.reset();
  }

  ngOnInit(): void {
    if (this.controlsArray.length > 0) {
      this.group = this.controlsArray[0].group;
      this.control = this.controlsArray[0];
    }
  }
}
