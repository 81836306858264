<div class="has-float-label" [ngClass]="wrapClass">
  <div class="mydp picker d-block">
    <div tabindex="0" (focus)="open()">
      <input 
        #dp="angular-mydatepicker"
        angular-mydatepicker
        type="text"
        tabindex="-1"
        class=""
        [ngClass]="inputClass"
        aria-label="datePicker"
        [id]="id || config.DataKey"
        [name]="name || config.DataKey"
        (dateChanged)="setDate($event)"
        (calendarToggle)="$event > 1 ? close() : null"
        [value]="formControl.value"
        [options]="settings"
        [readonly]="config.readonly"
        (click)="open();"
        [disabled]="isDisabled">
      <label [for]="id || config.DataKey" *ngIf="(label || config.Label) | translate">
        {{ config.Label | translate:translationValues }}
      </label>
    </div>
    <span class="inputVal text-danger" *ngIf="error">
      {{ error | translate:translationValues }}
    </span>
    <div *ngIf="isOpen" tabindex="0" (focus)="close()" (click)="close()" class="backdrop"></div>
  </div>
</div>
