import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import { RsFormState } from './rs-form.state'
import { RsFormSelectors } from './rs-form.selectors'
import { RsFormDispatcher } from './rs-form.dispatcher'
import { RsFormActions } from './rs-form.actions'
import { RsFormReducer } from './rs-form.reducer'
import { RsState } from '../rs.state';

@Injectable()
export class RsFormStore {
  dispatcher: RsFormDispatcher
  selectors: RsFormSelectors

  constructor(private store: Store<RsState>) {
    this.dispatcher = new RsFormDispatcher(store)
    this.selectors  = new RsFormSelectors(store)
  }
}

export {
  RsFormState,
  RsFormSelectors,
  RsFormDispatcher,
  RsFormActions,
  RsFormReducer
}
