import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

interface FormConfig {
    theme?: any;
    submitButtonVisible?: boolean;
    buttonText?: string;
    existing?: boolean;
    isGroup?: boolean;
}

@Injectable()
export class FormsService {
    public formPayload;
    private readonly closeOtherFormsSource = new Subject<any>();

    constructor(
        private readonly http: HttpClient,
    ) { }

    private get closeFormAnnounced$() {
        return this.closeOtherFormsSource.asObservable();
    }

    public create(name, method, elements, config: FormConfig = {}) {
        const form = {
            elements: [
                {
                    type: 'form',
                    action: method,
                    name,
                    submitButtonVisible: typeof config.submitButtonVisible === 'undefined' ? true : config.submitButtonVisible,
                    model: {},
                    theme: config.theme,
                    buttonText: config.buttonText,
                    existing: !!config.existing,
                },
            ],
        };
        elements.forEach(current => {
            const control = { ...current, DataKey: current.DataKey || `el-${uuid()}` };

            return form.elements[0].model[control.DataKey] = config.isGroup ? this._createGroups(control) : this.createInput(control);
        });

        return form;
    }

    public fixFormat(obj, fullLower = false) {
        if (Array.isArray(obj)) return obj;

        const formatted = {};

        Object.keys(obj).forEach((key) => {
            let correctKey = fullLower ? key.toLowerCase() : key[0].toLowerCase() + key.slice(1);
            if (correctKey === 'regex') {
                correctKey = 'pattern';
            }

            if (typeof obj[key] === 'object' && (obj !== null)) {
                formatted[correctKey] = this.fixFormat(obj[key]);
            } else {
                formatted[correctKey] = obj[key];
            }
        });

        return formatted;
    }

    public createInput(control) {
        let validations;
        if (control.Validation) {
            validations = this.fixFormat(control.Validation, true);
        }

        return {
            type: control.Type.toLowerCase(),
            name: control.DataKey,
            id: `${control.DataKey}-${uuid()}`,
            elementOptions: {
                label: control.Label,
                value: control.value || '',
                disabled: control.disabled || false,
                type: control.InputType && control.InputType.toLowerCase(),
                placeholder: control.PlaceHolder,
                autocomplete: '',
                required: control.required,
                options: ['Select', 'Radio'].includes(control.Type) ? control.Options.map(element => {
                    return {
                        value: element.Value,
                        label: element.Label,
                        disabled: element.Disabled,
                        hidden: element.Hidden
                    };
                }) : null
            },
            mask: (control.Type === 'Input' || control.type === 'Input') ? (control?.Regex?.Mask || control?.regex?.mask) : undefined,
            prefix: control.Regex && control.Type === 'Input' ? control.Regex.Prefix : undefined,
            dropSpecialCharacters: control.Regex && control.Type === 'Input' ? control.Regex.DropSpecialCharacters : undefined,
            maxLength: control.Type === 'Input' ? control.MaxLength : null,
            minLength: control.Type === 'Input' ? control.MinLength : null,
            validations,
            theme: {
                outerWrap: `${control.Theme?.Class ?? ''}`,
                control: `form-control form-control-lg rounded-0 ${control.Theme?.ControlClass ?? ''}`,
                labelClass: `${control.Theme?.LabelClass ?? ''}`,
                hostClass: `${control.Theme?.HostClass ?? ''}`,
            },
            display: control.Display,
            onClick: control.onClick,
            action: control.Action
        };
    }

    public closeOtherForms(formIdSufix, formType) {
        this.closeOtherFormsSource.next({ formIdSufix, formType });
    }

    public validateAllFormFields(formGroup: UntypedFormGroup) {
        formGroup.markAsTouched({ onlySelf: true });

        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);

            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    private _createGroups(control) {
        if (control.Type !== 'group') return this.createInput(control);

        const id = control.DataKey;
        const group = {
            name: id,
            id,
            type: 'group',
            controls: {},
        };

        (control.Elements || control.fields).forEach((el) => {
            group.controls[el.DataKey] = this.createInput(el);
        });

        return group;
    }
}
