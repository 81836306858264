<ng-container *ngFor="let service of services; let i = index">
    <content-loader viewBox="0 0 1024 90" 
                    height="100%"
                    width="100%"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    [speed]="0.4">
        <svg:rect x="25"
                  y="25"
                  rx="0"
                  ry="0"
                  width="90"
                  height="22"/>
        <svg:rect x="25"
                  y="55"
                  rx="0"
                  ry="0"
                  width="130"
                  height="15"/>
        <svg:circle cx="985"
                    cy="40"
                    r="15"/>
        <svg:rect *ngIf="i + 1 !== serviceCount"
                  x="0"
                  y="89"
                  rx="0"
                  ry="0"
                  width="100%"
                  height="1"/>
    </content-loader>
</ng-container>
