import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

type LanguageProperty = {
    language: {
        [key: string]: object;
    };
};

@Component({
    selector: 'pa-subscription-component',
    template: ``,
})
export abstract class SubscriptionComponent implements OnDestroy {
    protected subscriptionNotifier = new Subject();

    public ngOnDestroy() {
        if (this.subscriptionNotifier) {
            this.subscriptionNotifier.next(true);
            this.subscriptionNotifier.complete();
        }
    }
}

@Component({
    selector: 'pa-base',
    template: ``,
})
export abstract class BaseComponent extends SubscriptionComponent {
    protected subscriptionNotifier = new Subject();

    constructor(protected translateService?: TranslateService) {
        super();
    }

    public changeLanguage(language: string) {
        if (this.translateService) {
            this.translateService.currentLang = language;
            this.translateService.setDefaultLang(language);
        }
    }

    protected setTranslation<T extends LanguageProperty>(siteConfig: T) {
        if (this.translateService && siteConfig) {
            for (const lang of Object.keys(siteConfig.language)) {
                this.translateService.setTranslation(lang, siteConfig.language[lang], true);
            }
        }
    }
}
