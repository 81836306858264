import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { OrganizationReducer } from './reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('global-organization', OrganizationReducer),
        EffectsModule.forFeature([])
    ],
    providers: []
})
export class GlobalOrganizationStoreModule { }
