import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { configureSentry, logInfo } from '@revspringinc/shared';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { VERSION } from './environments/version';

if (environment.production) {
    enableProdMode();
}

logInfo('💎 version 💎', VERSION);

configureSentry({
    dsn: 'https://dcb8464809df467ebf01825ad76d189f@o367895.ingest.sentry.io/5496400',
    environment: environment.name,
    version: VERSION,
});

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
