import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { InitializationEffects } from './effects';
import { InitializationReducer } from './reducer';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        StoreModule.forFeature('init', InitializationReducer),
        EffectsModule.forFeature([InitializationEffects]),
    ],
    providers: [InitializationEffects],
})
export class InitializationStoreModule {}
