import { Injectable } from '@angular/core';

@Injectable()
export class AmaNoticeService {
    private readonly localStorageKey = 'viewedAmaNotice';

    constructor() {
    }

    public setViewed() {
        localStorage.setItem(this.localStorageKey, JSON.stringify(Date.now()));
    }

    public isViewed(): boolean {
        return !!localStorage.getItem(this.localStorageKey);
    }
}
