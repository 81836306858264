<div class="popover {{ wrapClass }}">
  <div class="row no-gutters pt-3"
       *ngIf="sort">
    <button *ngFor="let item of sortItems, let i = index"
            stop-propagation
            [ngClass]="{active: item.active}"
            (click)="setSorting(item)"
            class="rs-btn-link btn sort col-6 fw-700 pb-2">
      {{ item.label | translate }}
    </button>
  </div>
  <div class="item-list">
    <ng-container *ngFor="let item of items; let i = index">
        <button [disabled]="item.icon"
                role="menuitem"
                (click)="onSelect(item)"
                [ngClass]="{'pb-4': items.length !== i+1, 'item': !item.icon}"
                class="btn rs-btn-link text-left w-100">
        <div class="d-flex">
          <span *ngIf="item.icon"
                [ngClass]="item.icon"
                class="prefix mr-3">
          </span>
          <div>
            <div [ngClass]="{ danger: item.value === 'remove','pb-2':  item.description }"
                class="title fw-700">{{ item.label | translate }}</div>
            <div *ngIf="item.description"
                class="description">{{ item.description }}</div>
          </div>
        </div>
        <div class="popover-separator separator-dark mt-3"
            *ngIf="separator && items.length !== i+1"></div>
      </button>
    </ng-container>
  </div>
</div>
