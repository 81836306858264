<nav class="navbar mb-md-4 pt-4">
  <div class="container p-0 px-md-3">
    <button aria-label="brand logo"
            (click)="redirect(headerData.logo_link)"
            class="navbar-brand rs-btn-link">
      <img [src]="headerData.logo_src"
           alt="">
    </button>
    <!-- <ul
      class="navbar-nav flex-row align-items-center">
      <li
        class="nav-item">
        <rs-dropdown
          id="languageMenuId"
          [items]="languageSelect"
          [label]="languageLabel"
          (select)="changeLanguage($event)"
          wrapClass="fw-700"
          triggerWrapClass="color-secondary"
          headerDropdown="true">
        </rs-dropdown>
      </li>
    </ul> -->
  </div>
</nav>
