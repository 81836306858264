import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'estimate-disclaimer',
    templateUrl: './estimate-disclaimer.component.html',
    styleUrls: ['../../scss/styles.scss']
})
export class EstimateDisclaimerComponent {
    public acknowledged = false;
    @Input() public estimateDisclaimerConfig: any;
    @Output() public estimateDisclaimerAcknowledged = new EventEmitter();

    constructor() { }

    public setEstimateDisclaimerAcknowledged(event: any) {
        if (event === true) {
            this.acknowledged = true;
            this.estimateDisclaimerAcknowledged.emit();
        }
    }
}
