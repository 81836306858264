import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  forwardRef,
  OnInit,
  OnDestroy,
  AfterViewInit,
  KeyValueDiffers,
  DoCheck,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  ControlValueAccessor,
  Validator,
  AbstractControl,
  ValidationErrors,
  UntypedFormControl,
  ValidatorFn,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RsValidation } from '../../models/validation.model';
import { ValidationRegistryService } from '../../services/validation-registry.service';
import { InputHandler } from './input.handler';

@Component({
  selector: 'rs-currency-field',
  templateUrl: './currency-field.component.html',
  styleUrls: ['./currency-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyFieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CurrencyFieldComponent),
      multi: true,
    },
  ],
})
export class CurrencyFieldComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy, AfterViewInit, DoCheck {
  @ViewChild('input', { static: true }) inputElement: ElementRef;
  @Input() id: string;
  @Input() type: string;
  @Input() label: string;
  @Input() name: string;
  @Input() wrapClass: string;
  @Input() autofocus = false;
  @Input() formControl: UntypedFormControl;
  @Input() validation: RsValidation;
  @Input() options = {
    align: 'left',
    allowNegative: false,
    allowZero: true,
    decimal: '.',
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: '',
    nullable: false,
  };

  @Output() change = new EventEmitter<string>();
  @Output() focus = new EventEmitter<void>();
  @Output() blur = new EventEmitter<void>();
  private subscription: Subscription;
  private validatorFunctions: ValidatorFn[];
  error;
  private keyValueDiffer;
  private inputHandler;

  constructor(
    private readonly validationService: ValidationRegistryService,
    private readonly keyValueDiffers: KeyValueDiffers,
  ) {}

  ngOnInit() {
    if (this.formControl) {
      this.inputElement.nativeElement.value = this.formControl.value;
      this.subscription = this.formControl.valueChanges
        .pipe(filter((val) => val !== this.inputElement.nativeElement.value))
        .subscribe((val) => this.inputElement.nativeElement.value = val);
    }
    this.inputHandler = new InputHandler(this.inputElement.nativeElement, this.options);
    this.keyValueDiffer = this.keyValueDiffers.find({}).create();
    // this.validatorFunctions = this.validationService.getControlValidators(this.validation);
  }

  ngDoCheck() {
    if (this.keyValueDiffer.diff(this.options)) {
      this.inputElement.nativeElement.style.textAlign = this.options.align || 'left';
      this.inputHandler.updateOptions(this.options);
    }
  }

  ngAfterViewInit() {
    this.inputElement.nativeElement.style.textAlign = this.options.align || 'left';
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  writeValue(value: string): void {
    this.inputHandler.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.inputHandler.setOnModelChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.inputHandler.setOnModelTouched(fn);
  }

  setDisabledState(value: boolean): void {
    this.inputElement.nativeElement.disabled = value;
  }

  validate(control: AbstractControl): ValidationErrors {
    // this.validatorFunctions.forEach((validationFuction: ValidatorFn) => {
    // })
    return null;
  }

  onChange(event: any) {
    this.change.emit(event.target.value);
    this.formControl.setValue(event.target.value);
    if (this.isChromeAndroid()) {
      this.inputHandler.handleInput(event);
    }
  }

  onBlur(event: any) {
    this.inputHandler.getOnModelTouched().apply(event);
    this.blur.emit();
  }

  onFocus($event) {
    this.focus.emit();
  }

  setInputFocus() {
    this.inputElement.nativeElement.focus();
  }

  onCut(event: any) {
    if (!this.isChromeAndroid()) {
      this.inputHandler.handleCut(event);
    }
  }

  onKeydown(event: any) {
    this.inputHandler.handleKeydown(event);
  }

  onKeypress(event: any) {
    this.inputHandler.handleKeypress(event);
  }

  onPaste(event: any) {
    this.inputHandler.handlePaste(event);
  }

  isChromeAndroid(): boolean {
    return /chrome/i.test(navigator.userAgent) && /android/i.test(navigator.userAgent);
  }
}
