import { Component, Input, Output, EventEmitter } from '@angular/core';

interface PopoverItem {
    icon?: string;
    value: string | number;
    label: string;
    description?: string;
}

@Component({
    selector: 'rs-popover',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent {
    @Input() items: PopoverItem[];
    @Input() sortItems: any[];
    @Input() wrapClass?: string;
    @Input() separator?: boolean;
    @Input() sort?: boolean;
    @Output() select = new EventEmitter<any>();

    // sortItems = [
    //   {
    //     value: 'ascending',
    //     label: 'Ascending',
    //     active: true
    //   },
    //   {
    //     value: 'descending',
    //     label: 'Descending',
    //     active: false
    //   }
    // ];

    constructor() {}

    get sortDirection() {
        return this.sortItems?.find((dir) => dir.active).value;
    }

    onSelect(value: PopoverItem) {
        if (!value.icon) {
            this.select.emit(value);
        }
    }
    setSorting(item, i) {
        for (i = 0; i < this.sortItems.length; i++) {
            this.sortItems[i].active = false;
        }
        item.active = !item.active;
    }
}
