import { Inject } from '@angular/core';
import {
  Directive,
  Input,
  OnInit,
  ComponentFactoryResolver,
  ViewContainerRef,
  ComponentRef
} from '@angular/core'
import { Registry } from '../registry/registry.class';

@Directive({
  selector: '[rsResolve]'
})
export class RsResolveElementDirective implements OnInit {
  // TODO: Add typing to this. Should be an RsUIConfig type schema.
  @Input() element

  component: ComponentRef<any>

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    @Inject('ComponentRegistryService')
    private componentRegistry: Registry<any>
  ) {}

  ngOnInit() {
    this.container.clear()

    try {
      const targetComponent = this.componentRegistry.resolve(this.element.type);
      const componentFactory = this.resolver.resolveComponentFactory(
        targetComponent
      );
      const component = this.container.createComponent(componentFactory);
      component.instance['element'] = this.element;
      this.component = component;
    } catch (e) {
      console.error(e, 'error in rs resolve');
    }
  }
}
