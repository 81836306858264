import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'yesNo' })
export class YesNoPipe implements PipeTransform {
    public transform(value: any, ...args: any[]): any {
        if (typeof value === 'boolean') {
            return value ? 'Yes' : 'No';
        }

        if(value === 'true') { return 'Yes'; }
        if(value === 'false') { return 'No'; }

        return value;
    }
}
