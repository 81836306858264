<div [formGroup]="element.group" [ngClass]="element?.theme?.outerWrap" class="form-group">
  <label [ngClass]="element?.theme?.labelClass">{{ element.elementOptions.label }}
      <b *ngIf="element.validations?.required" style="color:red">*</b>
    <span *ngIf="element?.tooltip?.display">
      <i class="fa fa-question-circle" aria-hidden="true" placement="{{element.tooltip.placement || 'right'}}" 
      ngbTooltip="{{element.tooltip.text}}"></i>
    </span> :</label>
  <select class="form-control" multiple [formControlName]="element.name" [attr.id]="element.id" [ngClass]="element?.theme?.control"
    >
    <option value="" *ngIf="element.elementOptions.placeholder">{{ element.elementOptions.placeholder }}</option>
    <option *ngFor="let option of element.elementOptions.options" [value]="option.value">
      {{ option.label }}
    </option>
  </select>

  <div class="alert alert-danger" role="alert" *ngIf="element.group.controls[this.element['name']].dirty && !element.group.controls[this.element['name']].pristine && (element.group.controls[this.element['name']].status === 'INVALID')">
    <rs-error-message [fieldForm]="element.group.controls[this.element['name']]" [field]="element"></rs-error-message>
  </div>

</div>
