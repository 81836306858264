<div class="wrapper wrapper-radio">
  <input 
    [id]="id" 
    [name]="name"
    [value]="value"
    [formControl]="radioControl"
    type="radio"/>
  <label [for]="id">
    <ng-content></ng-content>
    {{ label }}
  </label>
</div>