<div [formGroup]="element.group"
     [ngClass]="element?.theme?.outerWrap"
     *ngIf="visible">
  <ng-container [ngSwitch]="element.elementOptions?.type">
    <ng-container *ngSwitchCase="'date-picker'">
      <div class="has-float-label">
        <input aria-label="date-picker"
               matInput
               [mask]="element?.mask"
               [attr.id]="id"
               [name]="element.name"
               [formControlName]="element.name"
               [dropSpecialCharacters]="false"
               inputmode="numeric"
               placeholder="MM/DD/YYYY"
               autocomplete="off"
               class="mydp picker input-datepicker" />

        <label [attr.for]="id"
               *ngIf="(element.elementOptions.label | translate)"
               [ngClass]="element?.theme?.labelClass">
          {{ element.elementOptions.label | translate }}
        </label>

        <span class="datepicker-toggle">
          <span class="datepicker-toggle-button icon icon-calendar"></span>
          <input type="date"
                 min="1899-01-01"
                 [max]="maxDateCurrentDate"
                 class="datepicker-input">
        </span>

        <div class="input-err text-danger">
          <span *ngIf="element?.group?.controls[element.name]?.touched && error"
                data-test="{{name}}-error">{{ error | translate }}</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      <div class="has-float-label">
        <input aria-label="date-numeric"
               matInput
               [mask]="element?.mask"
               [attr.id]="id"
               [name]="element.name"
               [formControlName]="element.name"
               [dropSpecialCharacters]="false"
               [showMaskTyped]="true"
               inputmode="numeric"
               placeholder="MM/DD/YYYY"
               autocomplete="off"
               class="mydp picker input-datepicker" />

        <label [attr.for]="id"
               *ngIf="(element.elementOptions.label | translate)"
               [ngClass]="element?.theme?.labelClass">
          {{ element.elementOptions.label | translate }}
        </label>
        <div class="input-err text-danger">
          <span *ngIf="element?.group?.controls[element.name]?.touched && error"
                data-test="{{name}}-error">{{ error | translate }}</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'button'">
      <input [id]="element.id"
             [type]="element.elementOptions.type"
             [disabled]="element.elementOptions.disabled"
             class="form-control"
             [ngClass]="element?.theme?.control"
             [title]="element?.elementOptions?.label | translate"
             [tabindex]="element?.elementOptions?.tabindex"
             (click)="buttonClick(element)"
             [value]="element?.elementOptions?.label | translate" />
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="has-float-label">
        <input #input
               [attr.id]="id"
               [attr.type]="element.elementOptions.type"
               [name]="name"
               [formControlName]="element.name"
               [ngClass]="element?.theme?.control"
               [value]="element.elementOptions.value"
               [Mask]="element?.mask"
               [RsMask]="element?.mask"
               [RsMaskPrefix]="element?.prefix"
               [RsMaskDropSpecialCharacters]="element?.dropSpecialCharacters"
               autocomplete="off"
               placeholder=" " />
        <label *ngIf="(element.elementOptions.label | translate)"
               [ngClass]="element?.theme?.labelClass"
               [for]="id">
          {{ element.elementOptions.label | translate }}
          <span *ngIf="element?.tooltip?.display">
            <i class="fa fa-question-circle"
               aria-hidden="true"
               placement="{{element.tooltip.placement || 'right'}}"
               ngbTooltip="{{element.tooltip.text}}"></i>
          </span>
        </label>
        <div class="input-err text-danger">
          <span *ngIf="element?.group?.controls[element.name]?.touched && error"
                attr.data-test="{{element?.name}}-error">{{ error | translate }}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>