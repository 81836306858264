import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { EstimateEffects } from './estimate.effects';
import { EstimateReducer } from './estimate.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('estimate', EstimateReducer),
        EffectsModule.forFeature([EstimateEffects]),
    ],
    providers: [EstimateEffects],
})
export class EstimateStoreModule {}
