import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';

import { ValidationRegistryService } from './services/validation-registry.service';
import { ComponentRegistryService } from './services/component-registry.service';
import { FormsService } from './services/forms.service';
import { CurrencyFieldComponent } from './components/currency-field/currency-field.component';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { CheckboxComponent } from './components/checkbox-item/checkbox.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { RsFormComponent } from './components/form/form.component';
import { RsMaskDirective } from './directives/rs-mask/rs-mask.directive';
import { StopPropagation } from './directives/propagation.directive';
import { FormsModule } from '@angular/forms';

const validationRegistry = ValidationRegistryService.configure((registry) => {
    return registry;
});

@NgModule({
    declarations: [
        InputFieldComponent,
        CurrencyFieldComponent,
        CheckboxComponent,
        RsMaskDirective,
        StopPropagation,
        DropdownComponent,
        RsFormComponent,
        RsMaskDirective,
    ],
    providers: [
        {
            provide: ValidationRegistryService,
            useValue: validationRegistry,
        },
        ComponentRegistryService,
        FormsService,
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        FormsModule,
        ClickOutsideModule,
    ],
    exports: [
        InputFieldComponent,
        CurrencyFieldComponent,
        CheckboxComponent,
        RsMaskDirective,
        StopPropagation,
        DropdownComponent,
        RsFormComponent
    ]
})
export class RsFormsModule { }
