<div class="has-float-label {{ wrapClass }}">
  <input #input
         [id]="id"
         [value]="value ? value : null"
         [name]="name"
         [type]="type"
         (focus)="onFocus();$event.target.select()"
         (blur)="onBlur()"
         (input)="onChange($event.target.value)"
         (keydown.enter)="onEnter()"
         rsAutofocus
         [RsMask]="mask"
         [attr.autocomplete]="autocomplete"
         [autofocus]="autofocus" />
  <label (click)="setInputFocus()"
         [for]="id">
    {{ label | translate }}
  </label>
  <span *ngIf="error"
        attr.data-test="{{element?.name}}-error"
        class="input-err text-danger">
    {{ error }}
  </span>
</div>
