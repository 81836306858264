<div class="w-full sm:w-6/12 md:w-4/12 px-4">
    <div class="relative inline-flex align-middle">
        <button #btnDropdownRef
                class="p-2 rounded-md text-th-secondary hover:text-white hover:bg-th-primary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                type="button"
                (click)="toggleDropdown()"
                aria-controls="mobile-menu"
                aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <svg class="block w-8 h-8"
                 xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
                 aria-hidden="true">
                <path stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h16"/>
            </svg>
            <svg class="hidden w-6 h-6"
                 xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
                 aria-hidden="true">
                <path stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"/>
            </svg>
        </button>
    </div>
</div>

<div #popoverDropdownRef
     class="z-auto"
     *ngIf="dropdownOptions && dropdownOptions.length > 0">
    <div class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1"
         [ngClass]="dropdownPopoverShow ? 'block' : 'hidden'">
        <a *ngFor="let option of dropdownOptions"
           class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-th-primary cursor-pointer"
           (click)="option.onClick()">
            {{ option.label }}
        </a>
    </div>
</div>
