import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UnsupportedBrowserService } from '@revspringinc/shared';
import { SiteConfigService } from '@shopping';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'rs-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
    public leadImage = '../../assets/img/landing-bck-1.jpg';
    public browserIsSupported: boolean;
    public landingConfig: any;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly siteConfigService: SiteConfigService,
        private readonly translateService: TranslateService,
        private readonly unsupportedBrowserService: UnsupportedBrowserService,
    ) {}

    public async ngOnInit() {
        const siteConfig = await lastValueFrom(
            this.siteConfigService.siteConfigApiControllerGetSiteConfig(this.route.snapshot.data.sessionId, 'landing'),
        );
        this.setTranslation(siteConfig);

        this.landingConfig = siteConfig.config;
        this.browserIsSupported = this.unsupportedBrowserService.browserIsSupported();
    }

    public toEstimate() {
        this.router.navigate(['../estimate-services'], { relativeTo: this.route });
    }

    private setTranslation(siteConfig) {
        Object.keys(siteConfig.language).forEach((lang) =>
            this.translateService.setTranslation(lang, siteConfig.language[lang], true),
        );
    }
}
