import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { logInfo } from '../../utils/log';
import { SidenavModule } from './sidenav/sidenav.module';
import { HeaderModule } from './header/header.module';
import { GoBackComponent } from './go-back/go-back.component';

@NgModule({
    imports: [
        CommonModule,
        SidenavModule,
        HeaderModule
    ],
    exports: [
        SidenavModule,
        HeaderModule,
        GoBackComponent
    ],
    declarations: [
        GoBackComponent
    ]
})
export class LayoutModule {
    constructor() {
        logInfo('LayoutModule', 'Initialized');
    }
}
