import { createAction, props } from '@ngrx/store';
import { EstimateRenderData } from '@encounter';

export const LoadEstimateRequest = createAction(
    '[Estimate] Load Estimate Request',
    props<{ estimateId?: number; externalReferenceId?: string; includePdf?: boolean }>(),
);

export const LoadEstimateSuccess = createAction(
    '[Estimate] Load Estimate Success',
    props<{ estimate: EstimateRenderData }>(),
);

export const LoadEstimateFailure = createAction('[Estimate] Load Estimate Failure', props<{ error: any }>());

export const EstimateSubscriptionRequest = createAction(
    '[Estimate] Estimate Subscription Request',
    props<{ externalReferenceId: string }>(),
);
export const EstimateUpdatedSuccess = createAction(
    '[Estimate] Estimate Updated Success',
    props<{ externalReferenceId: string; estimate: EstimateRenderData }>(),
);

export const ClearEstimate = createAction('[Estimate] Clear Estimate');

export const EstimateUpdatedFailure = createAction('[Estimate] Estimate Updated Failure', props<{ error: any }>());

export const SetEstimateIsLoading = createAction('[Estimate] Set Estimate Is Loading', props<{ isLoading: boolean }>());
