import { Injectable } from '@angular/core';
import { ModalService } from '@revspringinc/rs-shared';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CookieDisclaimerService {
    public hasAcceptedDisclaimer: boolean;
    private readonly storageKey = 'cookieDisclaimerAccepted';

    constructor(private readonly modalService: ModalService) {
        this.checkIfAccepted();
    }

    public async showCookiePolicyModal() {
        await lastValueFrom(this.modalService.show('CookieDisclaimerModal'));
    }

    public setDisclaimerAccepted() {
        localStorage.setItem(this.storageKey, JSON.stringify(Date.now()));
        this.checkIfAccepted();
    }

    private checkIfAccepted() {
        this.hasAcceptedDisclaimer = !!localStorage.getItem(this.storageKey);
    }
}
