import { Action, createReducer, on } from '@ngrx/store';

import { SetTranslationAction, SetTranslationLanguageAction } from './actions';
import { initialTranslationState, TranslationState } from './state';

const translationReducer = createReducer(
    initialTranslationState,
    on(SetTranslationAction, (state, { languageConfig }) => {
        return {
            ...state,
            configs: [...state.configs, languageConfig],
        };
    }),
    on(SetTranslationLanguageAction, (state, { language }) => {
        return {
            ...state,
            language,
        };
    }),
);

export function TranslationReducer(state: TranslationState | undefined, action: Action) {
    return translationReducer(state, action);
}
