import { createReducer, on } from '@ngrx/store';
import * as InitializationActions from './actions';

export interface InitializationState {
    initialized: boolean;
}
export const initialInitializationState: InitializationState = {
    initialized: false,
};

export const InitializationReducer = createReducer(
    initialInitializationState,
    on(InitializationActions.InitializationComplete, (state, action) => {
        return {
            ...state,
            initialized: true,
        };
    }),
);
