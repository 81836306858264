import { Injectable } from '@angular/core';
import * as jsonpatch from 'fast-json-patch';
import { EstimateConfigModel } from '../models/estimateConfigModel';

@Injectable({ providedIn: 'root' })
export class EstimateObserverService {

    public patch: jsonpatch.Operation[] | undefined;
    private patchObserver: jsonpatch.Observer<EstimateConfigModel> | undefined;

    ngOnDestroy(): void {
        this.patchObserver?.unobserve();
    }

    public observe(estimate: EstimateConfigModel): jsonpatch.Observer<EstimateConfigModel> {
        if (this.patchObserver) {
            this.patchObserver.unobserve();
        }

        this.patchObserver = jsonpatch.observe<EstimateConfigModel>(estimate);

        return this.patchObserver;
    }

    public unobserve(): void {
        if (this.patchObserver) {
            this.patchObserver.unobserve();
        }
    }

    public generateDiff(): jsonpatch.Operation[] {
        if (this.patchObserver) {
            this.patch = jsonpatch.generate(this.patchObserver);
            return this.patch;
        }

        return [];
    }
}
