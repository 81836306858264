import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { ComponentRegistryService } from '../../services/component-registry.service';
import { UntypedFormGroup, ValidationErrors, UntypedFormBuilder } from '@angular/forms';
import { ValidationRegistryService } from '../../services/validation-registry.service';

@Component({
  selector: 'rs-form',
  templateUrl: './form.component.html',
})
export class RsFormComponent implements OnInit {
  @ViewChild('formFields', { static: true, read: ViewContainerRef }) fields: ViewContainerRef;
  form: UntypedFormGroup;
  elements: any[];
  submitButtonVisible = true;
  buttonText: string;
  theme: any;
  existingForm = false;

  constructor(
    private componentResolver: ComponentFactoryResolver,
    private componentRegistry: ComponentRegistryService,
    private validationRegistry: ValidationRegistryService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.fields.clear();
    this.form = this.fb.group(this.createForm(this.elements));
    // this.fields.createComponent();
  }



  createForm(model: any[]) {
    return model.map((field: any) => {
      if (!['form-group', 'group'].includes(field.type)) {
        return this.createControl(field);
      }
      const settings = field.type === 'group' ? {
        validator: (group: UntypedFormGroup): ValidationErrors => {
          const controls = Object.keys(group.controls);
          const oneValid = controls.find(control =>
            group.controls[control].valid && group.controls[control].value);
          if (oneValid) {
            controls.forEach((control: string) => {
              group.controls[control].setErrors(null);
            });
            return null;
          }
          return { oneValid: true };
        },
      } : undefined;
      return this.fb.group(this.createGroup(field), settings);
    });
  }

  createGroup(group: any) {
    return group.controls.map((field: any) => {
      if (!['form-group', 'group'].includes(field.type)) {
        return this.createControl(field);
      }
      return this.fb.group(this.createGroup(field));
    });
  }

  createControl(input: any) {
    const validations = this.validationRegistry.getControlValidators(
      input.validations
    );

    // TODO ? Type this? It's the form control config.
    // tslint:disable-next-line:no-any
    const config: any  = {};

    if (input.elementOptions) {
      config.value = input.elementOptions.value;
      config.disabled = !!input.elementOptions.disabled;
    }

    return this.fb.control(config, validations);
  }

  private resolve(name) {
    const component = this.componentRegistry.resolve(name);
    if (!component) throw new Error();
    return this.componentResolver.resolveComponentFactory<RsFormComponent>(component);
  }
}
