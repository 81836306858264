// third-party imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// local imports
import { RsInputComponent } from './rs-input/rs-input.component';
import { RsTextAreaComponent } from './rs-text-area/rs-text-area.component';
import { RsSelectComponent } from './rs-select/rs-select.component';
import { RsMultiSelectComponent } from './rs-multi-select/rs-multi-select.component';
import { RsButtonComponent } from './rs-button/rs-button.component';
import { RsRadioComponent } from './rs-radio/rs-radio.component';
import { RsCheckBoxComponent } from './rs-checkbox/rs-checkbox.component';
import { RsDateComponent } from './rs-date/rs-date.component';
import { RsDateHolderComponent } from './rs-date/rs-date-holder.component';
import { RsCardComponent } from './rs-card/rs-card.component';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NgxMaskModule } from 'ngx-mask';
import { RsCoreModule } from '@revspringinc/rs-core';

/**
 * Acts as container for all Ui-Bootstrap components,services,pipes and directives
 *
 * @export
 * @class RsComponentModule
 */
@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        RsCoreModule,
        RouterModule,
        TranslateModule,
        AngularMyDatePickerModule,
        NgxMaskModule.forChild(),
    ],
    providers: [TranslateModule],
    declarations: [
        RsInputComponent,
        RsTextAreaComponent,
        RsRadioComponent,
        RsSelectComponent,
        RsMultiSelectComponent,
        RsButtonComponent,
        RsCheckBoxComponent,
        RsDateComponent,
        RsDateHolderComponent,
        RsCardComponent,
    ],
    exports: [
        RsCardComponent,
        RsButtonComponent,
        TranslatePipe,
    ]
})
export class RsComponentModule { }
