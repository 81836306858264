import { Directive, ViewContainerRef, OnInit, ComponentFactoryResolver } from '@angular/core';
import { ModalService } from '../services/modal.service';
import { RSBackdropComponent } from '../components/backdrop/backdrop.component';

@Directive({
  selector: '[rsModalHost]',
})
export class RsModalHostDirective implements OnInit {
  constructor(
    public viewContainerRef: ViewContainerRef,
    private modalService: ModalService,
    private componentResolver: ComponentFactoryResolver,
  ) { }


  ngOnInit(): void {
    this.modalService.init(this);
    const componentFactory = this.componentResolver.resolveComponentFactory<RSBackdropComponent>(RSBackdropComponent);
    this.viewContainerRef.createComponent<RSBackdropComponent>(componentFactory);
    // RSBackdropComponent
  }
}
