import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SiteConfigService } from '@shopping';
import { AmaNoticeService } from '@revspringinc/shared';
import { ModalService } from '@revspringinc/rs-shared';
import { ModalWrapComponent } from '@revspringinc/rs-shared';
import { RsModalConfig } from '@revspringinc/rs-shared';

import { ShoppingSessionService } from '../../main/session.service';
import { ShoppingActivityType } from '../../main/shopping-activity-type';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'rs-ama-notice',
    templateUrl: './ama-notice.component.html',
    styleUrls: ['./ama-notice.component.scss'],
})
export class AmaNoticeModal implements ModalWrapComponent {
    public static modalName = 'AmaNoticeModal';
    public config: any;
    public modalConfig = {
        activityType: ShoppingActivityType.AmaNotice,
        name: 'AmaNoticeModal',
        maxWidth: '650px',
        bodyClass: 'bg-white',
        blockBackdropScrolling: true,
        hasManualClose: false,
        blockEscapeClosing: true,
        hideHeader: true,
        footer: {
            hidden: false,
            saveEnabled: true,
            save: 'Acknowledge',
        },
    } as RsModalConfig;

    @ViewChildren('resolver') public resolvers: QueryList<any>;
    @ViewChild('modal', { static: true }) public modal;

    constructor(
        private readonly modalService: ModalService,
        private readonly amaNoticeService: AmaNoticeService,
        private readonly shoppingSessionService: ShoppingSessionService,
        private readonly translate: TranslateService,
        private readonly siteConfigService: SiteConfigService,
    ) {}

    public async show() {
        const { config, language } = await lastValueFrom(
            this.siteConfigService.siteConfigApiControllerGetSiteConfig(
                this.shoppingSessionService.sessionId,
                'ama-notice',
            ),
        );
        this.setTranslations(language);
        this.config = config;
    }

    public async submit() {
        this.amaNoticeService.setViewed();
        await this.shoppingSessionService.viewedAmaNotice();

        this.modalService.hide(true);
    }

    private setTranslations(language: any) {
        Object.keys(language).forEach((lang) => this.translate.setTranslation(lang, language[lang], true));
    }
}
