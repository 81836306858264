<div class="d-flex flex-column vh-100">
  <header>
    <rs-header *ngIf="headerData" [headerData]="headerData"></rs-header>
  </header>
  <main class="flex-grow-1 flex-shrink-offset">
    <router-outlet></router-outlet>
  </main>
  <footer>
    <rs-footer *ngIf="footerData" [footerData]="footerData"></rs-footer>
  </footer>

  <!-- Modlas host -->
  <div rsModalHost></div>

  <div *ngIf="!hasAcceptedDisclaimer" class="p-1 bg-dark text-white justify-content-between  footer-position">
    <div class="row ml-1 mr-1 d-flex align-items-center">
      <div class="col disclaimer-text w-100">
        By using this site, you agree with our use of cookies.
      </div>
      <div class="container mb-1 col d-flex justify-content-end">
        <div class="d-flex">
          <div class="col">
            <button class="disclaimer-button btn btn-sm btn-light" (click)="acceptCookieDisclaimer()">I CONSENT TO COOKIES</button>
          </div>
          <div class="col">
            <button class="disclaimer-button btn btn-sm btn-light" (click)="cookieDisclaimerMoreInfo()">WANT TO KNOW MORE?</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
