//third-party imports
import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
/**
 *
 *
 * @export
 * @class RsDateHolderComponent
 * @implements {ControlValueAccessor}
 */
@Component({
    selector: 'rs-date-holder',
    templateUrl: './rs-date-holder.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RsDateHolderComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => RsDateHolderComponent),
            multi: true
        }
    ],
    styleUrls: ['../../../scss/styles.scss']
})
export class RsDateHolderComponent implements ControlValueAccessor {
    group: UntypedFormGroup;
    min: RsDate;
    max: RsDate;
    @ViewChild('datePicker', { static: false }) datePicker;
    @Input() element;
    /**
     * Creates an instance of BsDateHolderComponent.
     *
     * NgbDateParserFormatter used to parse the date object passed by the ng-boostrap into the string which later attached to the form and vice-versa
     * @param {NgbDateParserFormatter} parserFormatter
     * @memberof BsDateHolderComponent
     */
    constructor(private parserFormatter: NgbDateParserFormatter) { }
    /**
     * implemented method of ControlValueAccessor to set the value
     *
     * @param {string} obj
     * @memberof BsDateHolderComponent
     */
    writeValue(obj: string): void { }
    /**
     * returns the error
     *
     * @param {FormControl} c
     * @returns {ValidationErrors}
     * @memberof BsDateHolderComponent
     */
    validate(c: UntypedFormControl): ValidationErrors {
        if (this.group.controls.control.errors) {
            for (const error in this.group.controls.control.errors.ngbDate) {
                // converting the ng-bootstrap errors into the built-in errors
                if (error === 'invalid') { return { pattern: true }; }
                if (error === 'requiredBefore') { return { min: true }; }
                if (error === 'requiredAfter') { return { max: true }; }
            }
        }
    }
    /**
     * emits changes to form when value changes
     *
     * @param {*} ch
     * @memberof BsDateHolderComponent
     */
    registerOnChange(ch: any) {
        this.propagateChange = ch;
    }
    /**
     * for touch events,not used it but has to be as a implemented method of ControlValueAccessor
     *
     * @memberof BsDateHolderComponent
     */
    registerOnTouched() { }

    /**
     * callback function register by the registerOnChange to emit the changes back to the form
     *
     * @private
     * @memberof BsDateHolderComponent
     */
    private propagateChange = (_: any) => { };

    ngOnInit() {
        const value = this.parserFormatter.parse(this.element.elementOptions.value) || null;
        const disabled = this.element.elementOptions.disabled;
        if (this.element.elementOptions.min) {
            this.min = this.parserFormatter.parse(this.element.elementOptions.min);
        }
        if (this.element.elementOptions.min) {
            this.max = this.parserFormatter.parse(this.element.elementOptions.max);
        }
        this.group = new UntypedFormGroup({
            control: new UntypedFormControl({ value, disabled })
        });
        this.group.controls.control.valueChanges.subscribe(value => {
            this.propagateChange(this.parserFormatter.format(value));
        });
    }
}

export interface RsDate {
    year: number;
    month: number;
    day: number;
}
