<form *ngIf="!existingForm" [formGroup]="formGroup" (ngSubmit)="submit()">
  <!-- <ng-container *ngFor="let group of modelArray">
    <rs-form-group *ngIf="group.type === 'form-group'" [controls]="group.controls" [group]="formGroup.controls[group.name]">
    </rs-form-group>
    <rs-consumer-group *ngIf="group.type === 'group'" [controls]="group.controls" [group]="formGroup.controls[group.name]">
    </rs-consumer-group>
    <ng-container *ngIf="group.type !== 'form-group'" rsResolve [element]="group">
    </ng-container>
  </ng-container> -->
  <div #formFields></div>

  <div class="text-center" *ngIf="element.submitButtonVisible">
    <button type="submit" [ngClass]="element.theme?.submitBtn || 'info'"
    [disabled]="!formGroup.valid">
      {{(element.buttonText | translate) || 'Submit'}}
    </button>
    <!-- mdBootstrap button-->
    <!-- <button
    mdbBtn
    type="button"
    [color]="element?.theme?.color"
    [ngClass]="element.theme?.submitBtn"
    mdbWavesEffect>
    {{element.buttonText || 'Submit'}}
    </button> -->
  </div>
</form>
