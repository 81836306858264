import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'rs-dropdown-consumer-group',
  templateUrl: './rs-dropdown-consumer-group.component.html'
})
export class RsDropdownConsumerGroupComponent implements OnInit {
  @Input() controlsArray: any;
  index = 0;
  group: UntypedFormGroup;
  dropdownItems = [];

  changeElement(index) {
    if (this.index === index) return;
    this.group.reset();
    this.index = index;
  }

  ngOnInit(): void {
    if (this.controlsArray.length > 0) {
      this.group = this.controlsArray[0].group;
      this.dropdownItems = this.controlsArray.map((control, index) => ({
        label: control.elementOptions.label,
        value: index,
      }));
    }
  }
}
