import { Store } from '@ngrx/store'
import { RsExportOption, RsGridColumn } from '../schema/rs-grid.schema'
import { RsGridState } from './rs-grid.state'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { RsGridActions } from './rs-grid.actions'

// TODO We littered this everywhere...
interface AppState {
  grids: RsGridState
}


@Injectable()
export class RsGridDispatcher {
  constructor(private store: Store<AppState>) { }

  /**
   * Utility method for subscriber to get current grid state.
   *
   * @returns {Observable<any>}
   * @memberof RsGridDispatcher
   */
  getState(): Observable<any> {
    return this.store.select('grids')
  }

  /**
   * Dispatcher for loading json configuration
   *
   * TODO: Add RsGridConfigSchema type annotation
   * @param {*} config
   * @memberof RsGridDispatcher
   */
  loadConfig(config) {
    this.store.dispatch({
      type: RsGridActions.loadConfig,
      payload: {
        config
      }
    })
  }

  /**
   * Dispatcher for loading data inside rs-grid view.
   *
   * @template T
   * @param {T} data
   * @memberof RsGridDispatcher
   */
  loadData<T = any>(data: T) {
    this.store.dispatch({
      type: RsGridActions.loadData,
      payload: {
        data
      }
    })
  }

  /**
   * Dispatcher for dispatching sort action on particular column
   *
   * @param {RsGridColumn} column
   * @memberof RsGridDispatcher
   */
  sortOnColumn(column: RsGridColumn) {
    this.store.dispatch({
      type: RsGridActions.sortOnColumn,
      payload: {
        column
      }
    })
  }

  /**
   *
   *
   * @param {RsGridColumn} column
   * @param {string} query
   * @memberof RsGridDispatcher
   */
  filterOnColumn(column: RsGridColumn, query: string) {
    this.store.dispatch({
      type: RsGridActions.filterOnColumn,
      payload: {
        column,
        query
      }
    })
  }

  /**
   * Dispatcher for executing filter accross all columns
   *
   * @param {string} query
   * @memberof RsGridDispatcher
   */
  filterAcrossAllColumns(query: string) {
    this.store.dispatch({
      type: RsGridActions.filterAcrossAllColumns,
      payload: {
        query
      }
    })
  }

  /**
   * dispatcher to dispatch click on particular goto page button.
   * @param {number} pageNumber
   * @memberof RsGridDispatcher
   */
  goToPage(pageNumber: number) {
    this.store.dispatch({
      type: RsGridActions.goToPage,
      payload: {
        pageNumber
      }
    })
  }

  /**
   * Dispatcher for pagination to arrange number of rows inside
   * grid appropriately
   *
   * @param {number} itemsPerPage
   * @memberof RsGridDispatcher
   */
  changeItemsPerPage(itemsPerPage: number) {
    this.store.dispatch({
      type: RsGridActions.changeItemsPerPage,
      payload: {
        itemsPerPage
      }
    })
  }

  /**
   * Dispatcher to dispatch expant row action with payload as rowIndex
   * @param {number} rowIndex
   * @memberof RsGridDispatcher
   */
  expandRow(rowIndex: number) {
    this.store.dispatch({
      type: RsGridActions.expandRow,
      payload: {
        rowIndex
      }
    })
  }
  /**
   * Show Row dispatcher to dispatch currently selected row data
   *
   * @param {*} row
   * @memberof RsGridDispatcher
   */
  showRow<T = any>(row: any) {
    this.store.dispatch({
      type: RsGridActions.showRow,
      payload: {
        row
      }
    })
  }

  /**
   * A dispatcher to dispatch Currently clicked export option
   * @param {RsExportOption} exportOpt
   * @memberof RsGridDispatcher
   */
  exportCollection(exportOpt: RsExportOption) {
    this.store.dispatch({
      type: RsGridActions.exportCollection,
      payload: {
        exportOpt
      }
    })
  }
}
