<div [formGroup]="element?.group">
  <div [ngClass]="element?.theme?.outerWrap">
    <label [ngClass]="element?.theme?.labelClass"
           *ngIf="element?.elementOptions?.label">{{ element?.elementOptions?.label }}
      <b *ngIf="element.validations?.required"
         style="color:red">*</b>
      <span *ngIf="element?.tooltip?.display">
        <i class="fa fa-question-circle"
           aria-hidden="true"
           placement="{{element?.tooltip?.placement || 'right' }}"
           ngbTooltip="{{element.tooltip.text}}"></i>
      </span> : </label>
    <!-- Check all check box to check all the checkboxes -->
    <div [ngClass]="element?.theme?.alignmentClass"
         *ngIf="element.elementOptions.checkAll">
      <input type="checkbox"
             [attr.name]="element?.elementOptions.name"
             [attr.value]="element?.elementOptions.value"
             [ngClass]="element?.theme?.control"
             [checked]="isAllCheckBoxesChecked()"
             (change)="checkAllCheckBoxes($event);" />
      <label [ngClass]="element?.theme?.childLabel"
             [attr.for]="element?.elementOptions.id">
        {{ element?.elementOptions.checkAllLabel }}</label>
    </div>
    <!-- These checkboxes will be rendered dependes on the elements present in the checkBoxOptionsArray -->
    <ng-container *ngFor="let option of checkBoxOptionsArray">
      <div [ngClass]="element?.theme?.alignmentClass">
        <input type="checkbox"
               [attr.name]="element.elementOptions.name"
               [attr.value]="option.value"
               [ngClass]="element?.theme?.control"
               [checked]="option.checked"
               [disabled]="option.disabled"
               (change)="onCheckBoxClickTrigged($event)">
        <label [ngClass]="element?.theme?.childLabel">{{ option.label || option.value }} </label>
      </div>
    </ng-container>
    <div class="alert alert-danger"
         role="alert"
         style="width:100%"
         *ngIf="element.group.controls[this.element['name']]?.dirty && !element?.group?.controls[this.element['name']]?.pristine && element?.group?.controls[this.element['name']]?.errors">
      <rs-error-message [fieldForm]="element?.group?.controls[this.element['name']]"
                        [field]="element"></rs-error-message>
    </div>
  </div>

</div>
