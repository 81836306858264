import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiRequestConfiguration } from './api-request-configuration';
import { BYPASS_ERROR } from './bypass-error.decorator';
import { ErrorHandlerService } from './error-handler.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly apiRequestConfig: ApiRequestConfiguration,
        private readonly toastService: ToastrService,
    ) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        request = this.apiRequestConfig.apply(request);

        if (request.context.get(BYPASS_ERROR) === true) {
            return next.handle(request).pipe(catchError((error) => throwError(() => error)));
        }

        return next.handle(request).pipe(
            // retry(1),
            catchError((error: HttpErrorResponse) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 0) {
                        this.toastService.error('Unable to connect to the API');
                        return throwError(() => 'Unable to cConnect to the API');
                    }
                }
                const errorMessage = this.errorHandlerService.handleError(error, request);
                return throwError(() => errorMessage);
            }),
        );
    }
}
