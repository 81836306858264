import { Component, OnInit, Input } from '@angular/core'
import _ from 'lodash-es'

import { RsGridConfig, RsGridColumn, RsExportOption } from '../schema/rs-grid.schema'
import { RsGridStore, RsGridActions } from './rs-grid.store'

@Component({
  selector: 'rs-grid',
  templateUrl: './rs-grid.component.html',
  styleUrls: [
    './rs-grid.scss'
  ]
})
export class RsGridComponent<T = any> implements OnInit {
  // TODO: Replace with Partial<RsGridConfig>?
  @Input() element: Partial<RsGridConfig> = {}
  @Input() data: T[] = [] // ? Temp? Convert to generic or access from store?

  currentPage: T[] = []
  pageList: number[]
  recordTotal: number

  expandedIndex = -1

  constructor(private gridStore: RsGridStore) {}

  ngOnInit() {
    // TODO: Ugly hack.
    setTimeout(() => {
      this.actualInit()
    }, 1500)
  }

  actualInit() {
    console.log('Actual Init.')

    // TODO: Move loading config & data to container of this.
    this.gridStore.dispatcher.loadConfig(this.element)
    this.gridStore.dispatcher.loadData(this.data)

    this.gridStore.selectors.config()
      .subscribe(config => {
        console.log('config?', config)
        this.element = config
      })

    this.gridStore.selectors.currentPage()
      .subscribe(currentPage => {
        this.currentPage = currentPage
      })

    this.gridStore.selectors.pageList()
      .subscribe(pageList => {
        this.pageList    = pageList.pages
        this.recordTotal = pageList.recordCount
      })

    this.gridStore.selectors.expandedIndex()
      .subscribe(expandedIndex => {
        this.expandedIndex = expandedIndex
      })
  }

  getCellData(row: any, propertyName: string) {
    return _.get(row, propertyName)
  }

  exportCollection(exportOpt: RsExportOption) {
    this.gridStore
      .dispatcher
      .exportCollection(exportOpt)
  }

  filterAll(query: string) {
    this.gridStore
      .dispatcher
      .filterAcrossAllColumns(query)
  }

  filterColumn(query: string, column: RsGridColumn) {
    if (!column.filterable) {
      return
    }

    this.gridStore
      .dispatcher
      .filterOnColumn(column, query)
  }

  sortColumn(column: RsGridColumn) {
    if (!column.sortable) {
      return
    }

    this.gridStore
      .dispatcher
      .sortOnColumn(column)
  }

  goToPage(page: number) {
    this.gridStore
      .dispatcher
      .goToPage(page)
  }

  changeItemsPerPage(itemsPerPage: string) {
    this.gridStore
      .dispatcher
      .changeItemsPerPage(Number.parseInt(itemsPerPage))
  }

  clickedCell(column: RsGridColumn, row: any, rowIndex: number) {

    const columnNotClickable = !column.clickable

    if (columnNotClickable) {
      return
    }

    const actionName = column.onClick

    // TODO I don't like this mini-reducer.
    switch (actionName) {
      case RsGridActions.showRow: {
        return this.gridStore.dispatcher.showRow(row)
      }
      case RsGridActions.expandRow: {
        return this.gridStore.dispatcher.expandRow(rowIndex)
      }

      default: {
        console.log('switch no op')
        break
      }
    }
  }
}
