import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { lastValueFrom, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { ModalWrapComponent, RsModalConfig } from '@revspringinc/rs-shared';
import { ModalService } from '@revspringinc/rs-shared';

import { ProcedureService, ProcedureCategoryModel, ProcedureGroupModel, OrganizationLocation } from '@shopping';
import { ShoppingActivityType } from '../../main/shopping-activity-type';
import { LocationDeterminationService } from '../../services/location-determination.service';
import { ShoppingSessionService } from '../../main/session.service';

@Component({
    selector: 'rs-select-procedures',
    templateUrl: './select-procedures.component.html',
    styleUrls: ['./select-procedures.component.scss'],
})
export class SelectProceduresModal implements ModalWrapComponent {
    public static modalName = 'SelectProceduresModal';
    @ViewChild('modal', { static: true }) public modal;
    public selected = false;
    public checkboxFormArray: UntypedFormArray;
    public procedureGroups: ProcedureGroupModel[];
    public subscription: Subscription;

    public options: {
        procedureCategory: ProcedureCategoryModel;
        selectedProcedures: ProcedureGroupModel[];
        locationId?: number;
        locations: OrganizationLocation[];
        hasLocationEnabled: boolean;
    };

    public modalConfig = {
        activityType: ShoppingActivityType.SelectProcedures,
        name: 'SelectProceduresModal',
        title: 'Select Procedures',
        maxWidth: '650px',
        footer: {
            hidden: false,
            save: 'Save',
            saveEnabled: true,
        },
    } as RsModalConfig;

    constructor(
        private readonly modalService: ModalService,
        private readonly translate: TranslateService,
        private readonly procedureService: ProcedureService,
        private readonly sessionService: ShoppingSessionService,
        private readonly locationDeterminationService: LocationDeterminationService,
    ) {}

    public cancel(target?: any) {
        this.hide();
    }

    public show(options: {
        procedureCategory: ProcedureCategoryModel;
        selectedProcedures: ProcedureGroupModel[];
        locationId?: number;
        locations: OrganizationLocation[];
        hasLocationEnabled: boolean;
    }): void {
        this.options = options;
        this.modalConfig.title = this.translate.instant(this.options.procedureCategory.name);

        this.subscription = this.procedureService
            .procedureApiControllerGetCategoryById(
                this.sessionService.sessionId,
                this.options.procedureCategory.id,
                this.options.locationId,
            )
            .subscribe((procedureGroups) => {
                if (this.options.hasLocationEnabled && this.options.locationId && procedureGroups?.config?.groups) {
                    procedureGroups.config.groups = procedureGroups.config.groups.filter((x) => {
                        const locations = (x as any).locations;
                        const locationMatch = locations.find(
                            (location: OrganizationLocation) => location.id === this.options.locationId,
                        );
                        if (!locations.length || locationMatch) return x;
                    });
                }

                procedureGroups?.config?.groups?.forEach((x) => {
                    (x as any).selected = this.options.selectedProcedures.some((p) => p.id === x.id);
                });

                // Show individual procedures in a group if proceduresAreVisible === true; else treat as a group of 1.
                this.procedureGroups = procedureGroups?.config?.groups?.map((procedureGroup) =>
                    procedureGroup.proceduresAreVisible
                        ? { ...procedureGroup, procedures: procedureGroup.procedures }
                        : { ...procedureGroup, procedures: [procedureGroup.procedures[0]] },
                );

                if (this.procedureGroups) {
                    this.checkboxFormArray = this.createCheckboxes();
                }

                this.setLanguage(procedureGroups.language);
            });
    }

    public hide(): void {
        if (this.subscription) this.subscription.unsubscribe();
        this.modal.hide();
    }

    public async submit() {
        const selectedProcedures = this.procedureGroups.filter((x) => x.selected);

        if (this.options.hasLocationEnabled) {
            const allOtherSelections = this.options.selectedProcedures.filter((x) =>
                this.procedureGroups.every((y) => y.id !== x.id),
            );

            const allSelectedProcedures = selectedProcedures.concat(allOtherSelections);

            const { activeList, inactiveList } = this.locationDeterminationService.determineLocationLimitations({
                selectedProcedures: allSelectedProcedures,
                locations: this.options.locations,
            });

            if (inactiveList.length === this.options.locations.length || activeList.length === 0) {
                const modalData = await lastValueFrom(
                    this.modalService.show('NoticeMessageModal', 'ProcedureLocationConflictNoticeMessage'),
                );
            } else {
                this.modalService.hide(selectedProcedures);
            }
        } else {
            this.modalService.hide(selectedProcedures);
        }
    }

    public createCheckboxes() {
        const procedureGroupCheckboxes = this.procedureGroups.map(
            (procedureGroup) => new UntypedFormControl(procedureGroup.selected),
        );

        return new UntypedFormArray(procedureGroupCheckboxes);
    }

    public selectProcedureGroup(selected: Event, procedureGroup: ProcedureGroupModel) {
        if (selected instanceof Event) {
            procedureGroup.selected = !procedureGroup.selected;
        }
    }

    private setLanguage(language: any) {
        Object.keys(language).forEach((lang) => this.translate.setTranslation(lang, language[lang], true));
    }
}
