import { Component, Input, } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';

import { Alert } from '../../models/alert.model';

@Component({
    selector: 'rs-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ overflow: 'hidden', opacity: 0, maxHeight: 0 }),
                animate('500ms ease-in-out', style({ opacity: 1, maxHeight: '200px' }))
            ]),
            transition(':leave', [
                animate('500ms ease-in-out', style({ opacity: 0 }))
            ])
        ]),
    ],
})
export class AlertComponent {
    @Input() public alert: Alert;

    constructor() { }

    public open() {
        return this.alert !== null;
    }
}
