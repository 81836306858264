import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

/**
 *Types of Action we support
 *
 * @export
 * @enum {number}
 */
export enum ActionTypes {
    // TODO: Rename to AddToFormState? It's for putting the form value into our store?
    ADD = 'ADD_TO_RS_FORM_STATE'
}

/**
 *Actions are payloads of information that send data from your rs-core components  to store.
  Actions are the payload that contains needed information to alter store. Action has two properties type and payload
 *
 * @export
 * @class OnSubmitAction
 */
@Injectable()
export class OnSubmitAction {
    // TODO: Maybe make generic?
    constructor(private store: Store<any>) { }

    getState(): Observable<any> {
        return this.store.select('forms');
    }

    // TODO: Maybe make generic?
    addSubmittedFormToStore(formValue: any, formName: string) {
        this.store.dispatch({
            type: ActionTypes.ADD,
            payload: {
                formValue,
                formName
            }
        });
    }


}
