import { Injectable, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, mergeMap, Observable, tap } from 'rxjs';
import { CommonModule } from '@angular/common';

export type Features = {
    [key: string]: boolean;
};

@Injectable({ providedIn: 'root' })
export class FeatureService {
    private basePath: string;
    private readonly featuresSource = new BehaviorSubject<Features>(null);
    public readonly features$ = this.featuresSource.asObservable();

    constructor(private readonly http: HttpClient) {}

    get features(): Features {
        return this.featuresSource.getValue();
    }

    public setBathPath(basePath: string) {
        this.basePath = basePath;
    }

    public setFeatures(features: Features): void {
        this.featuresSource.next(features);
    }

    public loadFeatures(tokenRaw: string, basePath: string = null): Observable<Features> {
        if (!basePath && !this.basePath) {
            throw new Error('Feature service base path is not set! Unable to load features.');
        }

        if (basePath) {
            this.basePath = basePath;
        }

        return this.http
            .get<Features>(`${this.basePath}/feature`, {
                headers: { Authorization: `Bearer ${tokenRaw}` },
            })
            .pipe(
                tap((features) => this.setFeatures(features)),
                mergeMap(() => this.features$),
            );
    }
}

@NgModule({
    imports: [CommonModule],
    declarations: [],
})
export class FeatureServiceModule {}
