import { Store, createSelector } from '@ngrx/store'
import { RsGridState } from './rs-grid.state'
import _ from 'lodash-es'

// TODO: Centralize.
interface AppState {
  grids: RsGridState
}

const getGridState = (state: AppState) => {
  return state.grids
}

const getConfig = createSelector(
  getGridState,
  (gridState) => {
    return gridState.config
  })

const getPaginationConfig = createSelector(
  getConfig,
  (gridConfig) => {
    return gridConfig.pagination
  })

const getSortConfig = createSelector(
  getConfig,
  (gridConfig) => {
    return gridConfig.sort
  }
)

const getCollection = createSelector(
  getGridState,
  (state) => {
    return state.collection
  })

const getCurrentPageNumber = createSelector(
  getPaginationConfig,
  (state) => {
    return state.page
  })

const getItemsPerPage = createSelector(
  getPaginationConfig,
  (state) => {
    return state.itemsPerPage
  })

const getStartingIndex = createSelector(
  getCurrentPageNumber,
  getItemsPerPage,
  (currentPageNumber, itemsPerPage) => {
    return currentPageNumber * itemsPerPage
  }
)

const getEndingIndex = createSelector(
  getStartingIndex,
  getItemsPerPage,
  (startOfPage, itemsPerPage) => {
    return startOfPage + itemsPerPage
  }
)

const getFilterState = createSelector(
  getGridState,
  (gridConfig) => {
    return gridConfig.filter
  }
)

const getGlobalFilter = createSelector(
  getFilterState,
  (filterState) => {
    return filterState.global
  }
)

const getFilteredCollection = createSelector(
  getFilterState,
  getCollection,
  (filterState, collection) => {
    // Uhhh...iterate over filter state columns?
    const globalQuery = filterState.global.toLowerCase()
    const emptyGlobalQuery = globalQuery.length === 0

    if (!emptyGlobalQuery) {
      // TODO: Filter with global query thing...
    }

    _.each(filterState.columns, (columnQuery, columnProperty) => {
      columnQuery = columnQuery.toLowerCase()
      collection = _.filter(collection, row => {
        const columnValue = _.get(row, columnProperty).toLowerCase()

        // console.log('ugh filter...', {
        //   columnQuery,
        //   columnProperty,
        //   columnValue,
        //   included: columnValue.includes(columnQuery)
        // })

        return columnValue.includes(columnQuery)
      })
    })

    // console.log('filteredCollection...?', collection)
    // console.log('filteredCollection...?', filterState)

    return collection
  }
)

const getSortedCollection = createSelector(
  getFilteredCollection,
  getSortConfig,
  (collection, sortConfig) => {
    if (!sortConfig.active) {
      return collection
    }

    collection = _.sortBy(collection, sortConfig.onColumn)

    if (sortConfig.direction === 'desc') {
      collection = _.reverse(collection)
    }

    return collection
  }
)

const getGlobalFilteredCollection = createSelector(
  getCollection,
  getGlobalFilter,
  (collection) => {
    return collection
  }
)

const getRecordCount = createSelector(
  getCollection,
  (collection) => {
    return collection.length
  }
)

const getPageCount = createSelector(
  getItemsPerPage,
  getRecordCount,
  (itemsPerPage, recordCount) => {
    return recordCount / itemsPerPage
  }
)

const getPageList = createSelector(
  getPageCount,
  getRecordCount,
  (pageCount, recordCount) => {
    return {
      pages: _.range(pageCount).map((v, i) => i),
      recordCount
    }
  }
)

const getExpandedIndex = createSelector(
  getGridState,
  (gridState) => {
    return gridState.expandedIndex
  }
)

const getCurrentPage = createSelector(
  getStartingIndex,
  getEndingIndex,
  getSortedCollection,
  (startingIndex, endingIndex, collection) => {
    return _.slice(collection, startingIndex, endingIndex)
  }
)

export class RsGridSelectors {
  constructor(private store: Store<AppState>) {
    store.subscribe(state => {
      console.log('rsGridSelectors.state', state)
    })
  }

  config() {
    return this.store.select(getConfig)
  }

  currentPage() {
    return this.store.select(getCurrentPage)
  }

  pageList() {
    return this.store.select(getPageList)
  }

  expandedIndex() {
    return this.store.select(getExpandedIndex)
  }

}
