import { Component, Input } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import _ from 'lodash-es'
import { RsFormControl } from '../schema/rs-form-control.schema';


@Component({
  selector: 'rs-error-message',
  template: `
  <div *ngIf="fieldForm.dirty && !fieldForm.pristine" style="color:red">
    {{ rsErrorMessage }}
  </div>
  `
})
export class RsErrorMessageComponent {
  @Input() fieldForm: UntypedFormControl
  @Input() field: RsFormControl

  /**
   * Checks for the respective error and get the message for that error
   * Only returns the first error in the erray.
  */
  get rsErrorMessage(): string {
    const errors = _.keys(this.fieldForm.errors)

    if (errors.length === 0) {
      return ''
    }

    const firstErrName = errors[0]

    return this.field.validations[firstErrName].message
  }
}
