import { NgModule } from '@angular/core';
import { ApolloModule } from 'apollo-angular';

@NgModule({
    imports: [ApolloModule],
    declarations: [],
    exports: [],
    providers: [],
})
export class EncounterGraphQLModule {}
