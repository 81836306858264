import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'rs-session-toolbar',
  templateUrl: './session-toolbar.component.html',
  styleUrls: ['./session-toolbar.component.scss']
})
export class SessionToolbarComponent implements OnInit {

  @Output() public startOverClicked = new EventEmitter();

  constructor() { }

  public ngOnInit(): void {
  }

  public startOver(): void {
    this.startOverClicked.emit();
  }
}
