import { Component, ChangeDetectorRef, HostListener } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subscription } from 'rxjs';
import { ModalService } from '@revspringinc/rs-shared';

export interface Toast {
    title: string;
    message: string;
    confirmation?: string;
}

@Component({
    selector: 'rs-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    animations: [
        trigger('animateToast', [
            state(
                'initial',
                style({
                    transform: 'scale(0)',
                    opacity: '0',
                }),
            ),
            state(
                'final',
                style({
                    transform: 'scale(1)',
                    opacity: '1',
                }),
            ),
            state(
                'initialMobile',
                style({
                    opacity: '0',
                    transform: 'translate(0, -50px)',
                }),
            ),
            state(
                'finalMobile',
                style({
                    opacity: '1',
                    transform: 'translate(0, 0)',
                }),
            ),
            transition('initial => final', animate('300ms 300ms ease-in')),
            transition('initialMobile => finalMobile', animate('300ms 300ms ease-in')),
            transition('final => initial', animate('300ms ease-out')),
            transition('finalMobile => initialMobile', animate('300ms ease-out')),
        ]),
        trigger('animateBackdrop', [
            state(
                'initial',
                style({
                    transform: 'scale(0)',
                    opacity: '0',
                }),
            ),
            state(
                'final',
                style({
                    transform: 'scale(1)',
                    opacity: '1',
                }),
            ),
            state(
                'initialMobile',
                style({
                    opacity: '0',
                }),
            ),
            state(
                'finalMobile',
                style({
                    opacity: '1',
                }),
            ),
            transition('initial => final', animate('300ms 300ms ease-in')),
            transition('final => initial', animate('300ms ease-out')),
            transition('initialMobile => finalMobile', animate('3500ms 300ms ease-in')),
            transition('finalMobile => initialMobile', animate('300ms ease-out')),
        ]),
    ],
})
export class ToastComponent {
    screenWidth: number;
    currentState = 'initial';
    data: any;
    gtagData: {
        name: string;
        data: any;
    };
    tabindex = 0;
    hasModalOpen$: Subscription;

    constructor(private changeDetector: ChangeDetectorRef, private modalsService: ModalService) {
        this.getScreenSize();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenWidth = window.innerWidth;
    }

    showToast(data) {
        this.data = data;
        // this.gtagData = {
        //   name: 'ConsumerSmartActions',
        //   data: {
        //     actionCategory: data.ActionCategory,
        //     actionType: data.ActionType,
        //     actionTrigger: data.ActionTrigger,
        //   },
        // };

        if (this.screenWidth >= 768) {
            this.currentState = 'final';
            this.changeDetector.detectChanges();
        } else {
            this.currentState = 'finalMobile';
            this.changeDetector.detectChanges();
        }

        this.hasModalOpen$ = this.modalsService.hasModalOpen$.subscribe((isOpen) => {
            return (this.tabindex = isOpen ? -1 : 0);
        });
    }

    closeToast() {
        if (this.screenWidth >= 768) {
            this.currentState = 'initial';
        } else {
            this.currentState = 'initialMobile';
        }
        this.data = undefined;

        if (this.hasModalOpen$) {
            this.hasModalOpen$.unsubscribe();
        }
    }
}
