import { cloneDeep } from 'lodash-es'
import { RsFormState, RsFormInitialState } from './rs-form.state';
import { RsAction } from '../rs-grid/rs-grid.reducer';
import { RsFormActions } from './rs-form.actions';


export function RsFormReducer(
  initialState: RsFormState = RsFormInitialState,
  action: RsAction
) {
  const state   = cloneDeep(initialState)
  const payload = cloneDeep(action.payload)

  switch (action.type) {
    case RsFormActions.submitForm: {
      state[payload.formName] = payload.formModel
      state.lastSubmitted = {}
      state.lastSubmitted[payload.formName] = payload.formModel
      return state
    }

    case RsFormActions.syncFormModel: {
      state[payload.formName] = payload.formModel
      return state
    }

    default: {
      return state
    }
  }
}
