import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'estimate-service-placeholder',
    templateUrl: './estimate-service-placeholder.component.html',
    styleUrls: ['./estimate-service-placeholder.component.scss']
})
export class EstimateServicePlaceHolderComponent implements OnChanges {
    @Input() public readonly serviceCount;
    public services: number[];

    public ngOnChanges(changes: SimpleChanges) {
        this.services = Array.from(Array(this.serviceCount).keys())
    }
}
