<div class="wrapper wrapper-checkbox {{ wrapClass }}">
  <input #input
         [id]="id"
         [name]="name"
         [disabled]="disabled"
         (change)="onChange($event.target.checked)"
         type="checkbox" />
  <label [for]="id">
    {{ label }}
    <ng-content></ng-content>
  </label>
</div>
