import * as jsonpatch from 'fast-json-patch';
import { EstimateConfigModel } from './estimateConfigModel';

export class EstimateRenderConfigDelta {
    public id: number | undefined;

    public estimateId: number | undefined;

    public diff: jsonpatch.Operation[] | undefined;

    public createdAt?: Date;
    public updatedAt?: Date;

    protected constructor(estimateData: Partial<EstimateRenderConfigDelta>) {
        Object.assign(this, estimateData);
    }

    public static create(estimateData: Partial<EstimateRenderConfigDelta>): EstimateRenderConfigDelta {
        return new EstimateRenderConfigDelta(estimateData);
    }

    public static patch(
        existingEstimate: EstimateConfigModel,
        deltas: EstimateRenderConfigDelta[],
    ): EstimateConfigModel {

        for (const delta of deltas) {
            if (delta.diff) {
                existingEstimate = jsonpatch.applyPatch(existingEstimate, delta.diff).newDocument as EstimateConfigModel;
            }
        }

        return existingEstimate;
    }

    public static diff(
        existingEstimate: EstimateConfigModel,
        newEstimate: Partial<EstimateConfigModel>,
    ): EstimateRenderConfigDelta {
        const diff = jsonpatch.compare(existingEstimate, newEstimate);
        return EstimateRenderConfigDelta.create({ diff });
    }
}
