<div class="h-100 faqContent" *ngIf="faqData">
    <div *ngIf="returnRoute" class="goBack">
        <div class="container-fluid d-flex justify-content-start">
            <button (click)="goBack()" class="rs-btn-link  line-height-1 d-flex align-items-start">
                <span class="color-secondary icon icon-back-arrow"></span>
                <span class="color-secondary fs-22">Back</span>
            </button>
        </div>
    </div>
    <div class="container-fluid d-flex justify-content-start">
        <h1>{{ faqData.config.title | translate }}</h1>
    </div>
    <div class="container-fluid questionsAnswers p-2">
        <h2><b>{{ faqData.config.questionsAnswers.title | translate }}</b></h2>
        <div *ngFor="let qa of faqData.config.questionsAnswers.groups" class="qa">
            <h3>{{ qa.question | translate }}</h3>
            <div *ngFor="let answerSegment of qa.answer.segments">
                <div class="answerSegment" *ngIf="answerSegment.display === 'block'; else listSegment">
                    {{ answerSegment.values[0].text | translate }}
                </div>

                <ng-template #listSegment>
                    <ul>
                        <li *ngFor="let listItem of answerSegment.values">{{ listItem.text | translate }}</li>
                    </ul>
                </ng-template>

            </div>
        </div>
    </div>

    <div class="container-fluid definitions p-2">
        <h2><b>{{ faqData.config.definitions.title | translate }}</b></h2>
        <ul>
            <li *ngFor="let definitionGroup of faqData.config.definitions.groups">
                <b>{{ definitionGroup.word | translate }}</b>:
                {{ definitionGroup.definition |translate }} </li>
        </ul>
    </div>

</div>