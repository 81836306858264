import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Configuration } from './configuration';

import { BatchMgrService } from './api/batchMgr.service';
import { ComMgrService } from './api/comMgr.service';
import { DataMgrService } from './api/dataMgr.service';
import { EvtMgrService } from './api/evtMgr.service';
import { IssueMgrService } from './api/issueMgr.service';
import { IwsMgrService } from './api/iwsMgr.service';
import { OrgMgrService } from './api/orgMgr.service';
import { PmtMgrService } from './api/pmtMgr.service';
import { PmtProcService } from './api/pmtProc.service';
import { ProcessMgrService } from './api/processMgr.service';
import { ReportMgrService } from './api/reportMgr.service';
import { SiteMgrService } from './api/siteMgr.service';
import { UserMgrService } from './api/userMgr.service';
import { WebhookMgrService } from './api/webhookMgr.service';

@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [],
  exports:      [],
  providers: [
    BatchMgrService,
    ComMgrService,
    DataMgrService,
    EvtMgrService,
    IssueMgrService,
    IwsMgrService,
    OrgMgrService,
    PmtMgrService,
    PmtProcService,
    ProcessMgrService,
    ReportMgrService,
    SiteMgrService,
    UserMgrService,
    WebhookMgrService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        }
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import your base AppModule only.');
        }
    }
}
