import { AbstractControl } from '@angular/forms';

export class Registry<T> {
  private label: string;
  private items: Map<string, T> = new Map();

  constructor(label: string) {
    this.label = `${label}Registry`;
  }

  add(name: string, item: T): this {
    if (this.exists(name)) {
      return this;
    }

    this.items.set(name, item);
    return this;
  }

  override(name: string, item: T): this {
    this.items.set(name, item);

    return this;
  }

  load(list: object): this {
    Object.keys(list).forEach((key: string) => {
      this.add(key, list[key]);
    });

    return this;
  }

  overrideEntries(list: object) {
    Object.keys(list).forEach((key) => {
      this.override(key, list[key]);
    });

    return this;
  }

  exists(name: string) {
    return this.items.has(name);
  }

  isMissing(name: string) {
    return !this.items.has(name);
  }

  entries() {
    return Array.from(this.items.entries());
  }

  resolve(name: string) {
    if (this.isMissing(name)) {
      console.warn(`${name} is missing in ${this.label}, returing the default function`);
      return (control?: AbstractControl) => null;
    }

    return this.items.get(name);
  }
}
