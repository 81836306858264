import { createReducer, on } from '@ngrx/store';

import { EstimateRenderData } from '@encounter';
import * as EstimateActions from './estimate.actions';

export const estimateFeatureKey = 'estimate';

export interface EstimateState {
    estimate: EstimateRenderData | null;
    externalReferenceId: string | null;
    error: unknown;
    isLoading: boolean;
    manualEstimate: boolean;
}

export const initialEstimateState: EstimateState = {
    estimate: null,
    externalReferenceId: null,
    error: null,
    isLoading: true,
    manualEstimate: false,
};

export const EstimateReducer = createReducer(
    initialEstimateState,

    on(EstimateActions.LoadEstimateRequest, (state, action) => {
        return {
            ...state,
            estimate: null,
            isLoading: true,
            error: null,
        };
    }),
    on(EstimateActions.LoadEstimateSuccess, (state, action) => {
        return {
            ...state,
            estimate: action.estimate,
            isLoading: false,
            error: null,
        };
    }),
    on(EstimateActions.LoadEstimateFailure, (state, action) => {
        return {
            ...state,
            estimate: null,
            isLoading: false,
            error: action.error,
        };
    }),
    on(EstimateActions.EstimateSubscriptionRequest, (state, action) => {
        return {
            ...state,
            estimate: null,
            isLoading: true,
            error: null,
        };
    }),
    on(EstimateActions.EstimateUpdatedSuccess, (state, action) => {
        return {
            ...state,
            estimate: action.estimate,
            isLoading: false,
            error: null,
            manualEstimate: action.estimate?.procedures?.length ? false : true,
        };
    }),
    on(EstimateActions.EstimateUpdatedFailure, (state, action) => {
        return {
            ...state,
            estimate: null,
            error: action.error,
            isLoading: false,
        };
    }),
    on(EstimateActions.ClearEstimate, () => initialEstimateState),
    on(EstimateActions.SetEstimateIsLoading, (state, action) => {
        return {
            ...state,
            isLoading: action.isLoading,
        };
    }),
);
