import { Directive, HostListener, Input, OnInit } from '@angular/core'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'
import { RsMaskService } from './rs-mask.service'

@Directive({
  selector: '[RsMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RsMaskDirective,
      multi: true
    },
    RsMaskService
  ]
})
export class RsMaskDirective implements OnInit, ControlValueAccessor {
  /**
   * _rsMaskPattern will store the pattern passed by the user
   *
   * @type {string}
   * @memberof RsMaskDirective
   */
  _rsMaskPattern: string
  /**
   * Onchange method is callback function registered by registerOnChange method and it will triggered every time the native form control is updated
   * and it will take updated value as a parameter after removing the mask which will update the form element value.
   *
   * @memberof RSMaskDirective
   */
  onChange = (_: any) => { }
  /**
   * onTouch method is callback function registered by registerOnTouch method to update the touch events on the native form control
   *
   * @memberof RSMaskDirective
   */
  onTouch = () => { }
  /**
   * Creates an instance of RSMaskDirective.
   * @param {RsMaskService} rsMaskService  where all the masking logic resides
   * @memberof RSMaskDirective
   */
  constructor(private rsMaskService: RsMaskService) { }

  // setting the pattern passes by the user to mask the native form control value
  @Input('RsMask')
  set rsMaskPattern(value: string) {
    this._rsMaskPattern = value || ''
    if (!this._rsMaskPattern) {
      return
    }
    this.rsMaskService.rsMaskPattern = this._rsMaskPattern
  }

  @Input() RsMaskPrefix = '';
  @Input() RsMaskDropSpecialCharacters = true;

  // Listens to the native form control for key board events
  @HostListener('input', ['$event'])
  onControlValueChanges(event: KeyboardEvent): void {
    const element: HTMLInputElement = event.target as HTMLInputElement
    // if any pattern is not set by the user It will return the same input value with out any masking
    if (!this._rsMaskPattern) {
      this.onChange(element.value)
      return
    }
    const currentPosition: number = element.selectionStart
    let cursorPosition = 0
    //this is the value which will be attached to the form element after removing the mask
    const formElementValue = this.rsMaskService.onRsControlValueChanges(
      currentPosition,
      (moveCursorBy: number) => (cursorPosition = moveCursorBy)
    )
    setTimeout(() => {
      this.onChange(formElementValue)
    }, 100)
  }

  /**
   * Registers a callback that is expected to be triggered every time the native form control is updated (onChange callback in this case)
   *
   * @param {*} fn
   * @memberof RSMaskDirective
   */
  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  /**
   * Registers a callback that is expected to be triggered every time the touch events fired on native form control (right now we are handling the blur event )
   *
   * @param {*} fn
   * @memberof RSMaskDirective
   */
  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  /**
   * It disables the input element if user passes disabled is true
   *
   * @param {boolean} setDisabled
   * @memberof RSMaskDirective
   */
  setDisabledState(setDisabled: boolean): void {
    const setDisabledString = setDisabled ? 'true' : 'false'
    this.rsMaskService.setFormControlValue(['disabled', setDisabledString])
  }

  ngOnInit() {
    this.rsMaskService.prefix = this.RsMaskPrefix || '';
    if (typeof this.RsMaskDropSpecialCharacters !== 'boolean') this.RsMaskDropSpecialCharacters = true;
    this.rsMaskService.isDropSpecialCharacters = this.RsMaskDropSpecialCharacters;
  }

  /**
   * Writes a new value to the element (model -> view) when changes are requested.
   *
   * @param {string} controlValue
   * @returns
   * @memberof RSMaskDirective
   */
  async writeValue(controlValue: string) {
    // if (controlValue === undefined || controlValue === null) {
    //   return
    // }
    // if no rsMask is set Then return the initial Value
    if (this._rsMaskPattern.length === 0 && controlValue !== undefined) {
      this.rsMaskService.setFormControlValue(['value', controlValue])
      return
    }
    const maskExpression: string = this.rsMaskService.rsMaskPattern;
    const value = controlValue ? this.rsMaskService.toRsMaskControlValue(controlValue, maskExpression) : '';
    const maksedValue = this.RsMaskPrefix ? (this.RsMaskPrefix + value) : value;
    setTimeout(() => {
      this.rsMaskService.setFormControlValue(['value', maksedValue]);
    }, 100);
  }
  /**
   * Listening to the blur events to set the untouched class to the input FormControl
   *
   * @memberof RSMaskDirective
   */
  @HostListener('blur')
  onBlur(): void {
    this.onTouch()
  }
}
