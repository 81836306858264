<rs-modal #modal
          (show)="show($event)"
          (submit)="submit()"
          (cancel)="cancel($event)"
          (hide)="hide()"
          [config]="modalConfig">
  <div class="pb-5">
    <div *ngIf="benefitAlert && !loading">
        <rs-alert [alert]='benefitAlert'></rs-alert>
    </div>
    <fieldset>
      <legend>
      <!--   <h4 class="fs-22"
            *ngIf="!loading">form.title{{ config.form.title | translate }}</h4> -->
      </legend>


      <form [formGroup]="insuranceForm"
            (ngSubmit)="submit()">
        <div class="insurance-form flex-grow-1">
          <div *ngIf="config && forms"
               class="container">
            <div class="card mb-3"
                 *ngFor="let card of config.form.cards; let i1 = index;">
              <h2 *ngIf="card.title"
                  class="service-status d-flex align-items-center fw-700 pb-3 mb-0">
                {{ card.title | translate }}
              </h2>
              <div *ngIf="card.subtitle"
                   class="info-block ml-5 pb-3 mb-5">
                {{ card.subtitle | translate }}
              </div>
              <div *ngFor="let group of card.groups; let i2 = index; let lastGroup = last;">
                <!-- <h6 *ngIf="group.title"
                    class="pb-2 fw-700">{{ group.title | translate }}</h6> -->
                <div *ngIf="group.subtitle"
                     class="info-block ml-5 pb-3 mb-5">
                  {{ group.subtitle | translate }}
                </div>

                <rs-ui [config]="forms[i1][i2]"
                       #resolver></rs-ui>

                <hr *ngIf="!lastGroup" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </fieldset>
    <div *ngIf="loading && !benefit"
         class="w-100 position-relative">
      <rs-spinner [loading]="true"
                  wrapClass="spinner-border-lg spinner-center">
      </rs-spinner>
    </div>
    <div *ngIf="benefit && loading">
      <h4 class="fs-24 fw-700 pb-4 text-center">Verifying Information</h4>
      <div class="progress">
        <div class="progress-bar"
             role="progressbar"
             [ngStyle]="{'width.%': 100}"
             [attr.aria-valuenow]="100"
             aria-valuemin="0"
             aria-valuemax="100"
             min="0"
             max="100"
             [attr.aria-valuemin]="0"
             [attr.aria-valuemax]="100">
        </div>
      </div>
    </div>

    <div *ngIf="benefit && !loading">
      <rs-alert [alert]='benefitAlert'></rs-alert>
      <!-- <ul class="list-unstyled">
        <li *ngFor="let item of benefitItems"
            class="bottom-border-md pb-2 p-md-4 d-flex justify-content-between align-items-center">
          <div [ngClass]="item.label_class">{{ item.label }}</div>
          <div [ngClass]="item.value_class">{{ item.value }}</div>
        </li>
      </ul> -->
    </div>
  </div>
</rs-modal>
