import { Component, Input } from '@angular/core';
import { EstimateGroupModel } from '../../models/estimateGroupModel';

@Component({
    selector: 'provider-group-header',
    templateUrl: './provider-group-header.component.html',
    styleUrls: ['../../scss/styles.scss']
})
export class ProviderGroupHeaderComponent {
    @Input() providerGroup: EstimateGroupModel | undefined;

    public get show() {

        if (!this.providerGroup) {
            return false;
        }

        if (this.providerGroup?.services?.length > 0 && this.providerGroup?.groupingKey) {
            return true;
        };

        if (this.providerGroup.headerVisible) {
            return true;
        }

        return false;
    }
}
