import { ElementRef, Injectable, Renderer2 } from '@angular/core'

@Injectable()
export class RsMaskService {
  prefix = '';
  isDropSpecialCharacters = true;
  /**
   * This a variable to store the mask pattern passes by the user and its value is assigned inside the RsMaskDirective
   * @type {string}
   * @memberof RSMaskService
   */
  rsMaskPattern = ''
  /**
   * These special symbools which can be used to build a mask pattern by user and these symbools will be removed from control value just for masking purpose
   * @type {RsMask['symbols']}
   * @memberof RSMaskService
   */
  rsSymbolsConfig: RsMask['symbols'] = ['/', '(', ')', '.', ':', '-', ' ', '+']
  /**
   * rsPatternsConfig contains the patterns That are used to apply the mask on the control value, 0 stands for only number (example:(000)-000-000)
   * A stands for letters(example:AAA-AA). If you user want mixed pattern (example:(000)-AAA-000))
   * @type {RsMask['patterns']}
   * @memberof RSMaskService
   */
  rsPatternsConfig: RsMask['patterns'] = {
    '0': {
      pattern: new RegExp('\\d')
    },
    A: {
      pattern: new RegExp('[a-zA-Z]')
    }
  }
  /**
   * rsRegToRemove contains the regular expression which be used for removing the rsObjectSymbols from the control value while updating the form object element value
   * Because we don't want to send a value with mask while sending to database
   * @type {RegExp}
   * @memberof RSMaskService
   */
  rsRegToRemove: RegExp = new RegExp(
    this.rsSymbolsConfig.map((item: string) => `\\${item}`).join('|'),
    'gi'
  )
  /**
   * This used to store the positions of the rsObjectSymbols symbools Which determines the skipping the cursor position by one when special
   * rsSymbolsConfig symbols are present in the value
   * @type {Set<number>}
   * @memberof RSMaskService
   */
  symbolsPosition: Set<number> = new Set()
  /**
   * These is used to grab the instance of the input element and used to set the input value to masked value which will be visible to user.
   *  Later we will remove the mask when attaching the value to the form object element which will finally send to the database
   * @type {HTMLInputElement}
   * @memberof RSMaskService
   */
  formControl: HTMLInputElement

  constructor(private elRef: ElementRef, private renderEl: Renderer2) {
    this.formControl = this.elRef.nativeElement
  }
  /**
   *onRsControlValueChanges will be invoked from the RsMaskDirective when FormControl value changes and This method will called the toRsMaskControlValue
   *to apply the RsMask on the value.The returned masked value by toRsMaskControlValue method will set to the form control value and returns the
   * value after removing the RsMask by calling removeRsSymbolConfigSymbols method. The resultant value will set to form object element value inside RsMaskDirective
   * @param {number} [position=0], @param {Function} [cb=() => {}]
   * @memberof RSMaskService
   */
  onRsControlValueChanges(
    position: number = 0,
    cb: Function = () => { }
  ): string {
    const value = this.formControl.value || '';
    const removePrefixValue = this.prefix ? (value.replace(this.prefix, '')) : value;
    if ( removePrefixValue.length > 0) {
      const maskedInput: string = this.toRsMaskControlValue(
        removePrefixValue,
        this.rsMaskPattern,
        position,
        cb
      )
      this.formControl.value = this.prefix ? (this.prefix + maskedInput) : maskedInput;
      return this.removeRsSymbolConfigSymbols(maskedInput)
    }else{
      this.formControl.value = this.prefix
      return this.prefix || '';
    }
  }
  /**
   * add the RsMask Pattern to the Control Value
   * @param {string} controlValue,
   * @param {string} rsMaskPattern,
   * @param {number} [currentCursorPosition=0], @param {Function} [moveCursorPosition=() => {}]
   * @returns {string}
   * @memberof RSMaskService
   */
  toRsMaskControlValue(
    controlValue: string,
    rsMaskPattern: string,
    currentCursorPosition: number = 0,
    moveCursorPosition: Function = () => { }
  ): string {
    if (controlValue === undefined || controlValue === null) {
      return ''
    }
    let cursorPosition = 0
    let maskedControlValue = ''
    const controlValueArray: string[] = controlValue.toString().split('')
    for (
      let indexOfControlValueArray = 0,
      controlValueArrayElement: string = controlValueArray[0];
      indexOfControlValueArray < controlValueArray.length;
      indexOfControlValueArray++ ,
      controlValueArrayElement = controlValueArray[indexOfControlValueArray]
    ) {
      //to break the loop if the control Value length is equal to the Mask pattern length. So Though user enters more entrires It will not get added to the value
      if (maskedControlValue.length === rsMaskPattern.length) {
        break
      }
      //check chekForEqualOrValidatesRsPattern comments if true It will add that controlValueArrayElement to the maskedControlValue
      if (
        this.chekForEqualOrValidatesRsPattern(
          controlValueArrayElement,
          rsMaskPattern[cursorPosition]
        )
      ) {
        // console.log(
        //   'method',
        //   controlValueArrayElement,
        //   indexOfControlValueArray
        // )
        maskedControlValue += controlValueArrayElement
        cursorPosition++
      } else if (
        this.rsSymbolsConfig.indexOf(rsMaskPattern[cursorPosition]) !== -1
      ) {
        // These is where mask symbols are attached to the control value. The following condition will check the presence of symbol in the rsSymbolsConfig for that cursor position.If so I will add symbol to the control value
        // and the position as user mentioned in mask pattern and index will reduced by one so that the character preceding to the symbol will be added in the above condition and the cursor position is increased by one so that
        // the character preceding to the symbol will be compared with the next symbol of mask and cursor position is saved in the symbolsPosition to move the cursor position programatically later in the code
        // console.log(
        //   '-111',
        //   rsMaskPattern[cursorPosition],
        //   indexOfControlValueArray
        // )
        maskedControlValue += rsMaskPattern[cursorPosition]
        cursorPosition++
        this.symbolsPosition.add(cursorPosition)
        indexOfControlValueArray--
      }
    }
    if (
      maskedControlValue.length + 1 === rsMaskPattern.length &&
      this.rsSymbolsConfig.indexOf(rsMaskPattern[rsMaskPattern.length - 1]) !==
      -1
    ) {
      maskedControlValue += rsMaskPattern[rsMaskPattern.length - 1]
    }
    //to movecursorPositionBy the position of the cursorPosition by one in case of the special characters
    let moveCursorPositionBy = 1
    let precedingCursorPosition: number = currentCursorPosition + 1
    while (this.symbolsPosition.has(precedingCursorPosition)) {
      moveCursorPositionBy++
      precedingCursorPosition++
    }
    //This function will determine the cursor position  based on above logic
    moveCursorPosition(
      this.symbolsPosition.has(currentCursorPosition) ? moveCursorPositionBy : 0
    )
    return maskedControlValue
  }
  /**
   * This method will check for either equality of two parameters(controlValueArrayElement and maskSymbol for index of controlValueArrayElement)
   * or does controlValueArrayElementsatisfies the pattern of the RsMask at that particular position. if one condition is true it returns true
   * @private
   * @param {string} controlValueArrayElement, @param {string} maskSymbol, @returns {boolean}
   * @memberof RSMaskService
   */
  private chekForEqualOrValidatesRsPattern(
    controlValueArrayElement: string,
    maskSymbol: string
  ): boolean {
    // console.log(controlValueArrayElement, maskSymbol)
    return (
      controlValueArrayElement === maskSymbol ||
      (this.rsPatternsConfig[maskSymbol] &&
        this.rsPatternsConfig[maskSymbol].pattern &&
        this.rsPatternsConfig[maskSymbol].pattern.test(
          controlValueArrayElement
        ))
    )
  }
  /**
   * Removes the rsSymbolsConfig symbols of rsMask inside the form control value and returns the resultant Value which later attached to form object element value
   * @param {string} value, @returns {string}
   * @memberof RSMaskService
   */
  removeRsSymbolConfigSymbols(value: string): string {
    if (value) {
      const maskValue = this.isDropSpecialCharacters ? value.replace(this.rsRegToRemove, '') : value;
      const prefixValue = this.prefix ? (this.prefix + maskValue) : maskValue
      return prefixValue
    }
  }
  /**
   * Sets the Value of the native form control
   * @param {[string, string]} [name, value]
   * @memberof RSMaskService
   */
  setFormControlValue([name, value]: [string, string]) {
    this.renderEl.setProperty(this.formControl, name, value);
  }
}

export interface RsMask {
  removeSymbols: boolean
  symbols: string[]
  patterns: {
    [character: string]: {
      pattern: RegExp;
      optional?: boolean;
    };
  }
}
