<div class="mx-auto bg-gray-300 h-100 print:bg-white">
  <div class="p-5 bg-white header">
    <div class="flex flex-col mx-auto md:max-w-4xl lg:max-w-6xl">
      <h1 class="pb-3 text-3xl text-blue-900" id="container-title">Estimate services</h1>
      <ng-template #serviceLoadingBlock>
        <div class="border divide-y rounded-lg border-gray divide-gray">
          <estimate-service-placeholder [serviceCount]=3>
          </estimate-service-placeholder>
        </div>
      </ng-template>

      <ng-container *ngIf="locationsReady(); else serviceLoadingBlock;">
        <ul class="border divide-y rounded-lg border-gray divide-gray">
          <li *ngFor="let item of estimateItems, let i = index" class="p-4">
            <button (click)="estimateInfo(item.id)"
              class="flex p-0 text-lg w-100 align-items-center justify-content-between">
              <div class="pr-4 text-left service-list-item-action w-3/4">
                <div class="text-xl service-list-item-action-title">
                  {{ item.label | translate }}
                </div>
                <div class="font-bold text-blue-500 truncate service-list-item-action-link"
                  *ngIf="!item.value?.length; else itemHasValueBlock">
                  <ng-container>{{ item.placeholder | translate }}</ng-container>
                </div>
                <ng-template #itemHasValueBlock>
                  <div class="font-bold text-blue-500 service-list-item-action-link mt-2"
                    *ngFor="let lineItem of item.value">
                    {{ lineItem | translate }}
                  </div>
                </ng-template>
              </div>
              <div class="flex flex-shrink-0 service-list-item-icon align-items-center">
                <span *ngIf="item.id === 2 && item.value && item.value != '' && item.value != 'Select Insurance'"
                  class="pr-2 text-base font-bold text-th-primary">
                  Remove
                </span>
                <div class="position-relative">
                  <span *ngIf="item.id === 0"
                    class="flex badge badge-primary justify-content-center align-items-center">
                    {{ item.selectedCount }}
                  </span>
                  <span [ngClass]="item.id === 2 && item.value && item.value != '' && item.value != 'Select Insurance' ? 'icon-minus-outline' : 'icon-plus-outline' "
                    class="text-3xl text-th-primary"></span>
                </div>
              </div>
            </button>
          </li>
        </ul>
      </ng-container>

      <rs-session-toolbar *ngIf="estimate?.serviceGroups?.length" (startOverClicked)="restartSession($event)">
      </rs-session-toolbar>

      <rs-alert [alert]="alert"></rs-alert>
    </div>
  </div>

  <div class="flex my-4 w-100 align-items-center justify-content-center">
    <pa-spinner [loading]="loading">
    </pa-spinner>
  </div>

  <div class="flex flex-col py-5" id="estimateContainer">
    <div class="print-header">
      <h2 class="mb-0 font-bold">{{printConfig.organizationName}}</h2>
      <p class="mb-4">{{printConfig.dateTimeStamp}}</p>
      <p class="mb-4" *ngIf="printConfig.locationName?.length > 1">Location: {{printConfig.locationName}}</p>
      <p class="mb-4" *ngIf="printConfig.insurance?.length > 1">Insurance: {{printConfig.insurance}}</p>
    </div>

    <ng-container *ngIf="!blockedByDisclaimer();
                    else estimateDisclaimerBlock">
      <div class="relative flex flex-col my-4 align-items-center align-self-center md:max-w-4xl lg:max-w-6xl">
        <estimate [estimate]="estimate" [loading]="loading"
          [estimateDisclaimerAcknowledged]="estimateDisclaimerAcknowledgedMessage" [appName]="'pricing'" #estimateView
          id="estimateView">
        </estimate>

        <div class="absolute p-0 pointer-events-none inset-2">
          <estimate-score #estimateScore *ngIf="features?.consumerShoppingEstimateScore" [estimate]="estimate">
          </estimate-score>
        </div>
      </div>
    </ng-container>
    <ng-template #estimateDisclaimerBlock>
      <div class="relative flex flex-col my-4 align-items-center align-self-center md:max-w-4xl lg:max-w-6xl">
        <estimate-disclaimer [estimateDisclaimerConfig]="estimateDisclaimerConfig"
          (estimateDisclaimerAcknowledged)="setEstimateDisclaimerAcknowledged()">
        </estimate-disclaimer>
      </div>
    </ng-template>

    <estimate-actions [estimate]="estimate" [loading]="loading" (actionClicked)="toEstimateActions($event)">
    </estimate-actions>
  </div>
</div>