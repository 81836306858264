import {
    Component,
    ViewChild,
    ChangeDetectorRef,
    ViewChildren,
    QueryList,
    AfterViewInit,
    OnDestroy,
    ViewEncapsulation,
    OnInit,
} from '@angular/core';
import { ModalWrapComponent } from '@revspringinc/rs-shared';
import { ModalService } from '@revspringinc/rs-shared';
import { UntypedFormGroup } from '@angular/forms';

import { FormService, FormResponseModel, ShoppingSessionResponseModel, SessionService } from '@shopping';
import { FormsService } from '@revspringinc/shared';

import { ShoppingSessionService } from '../../main/session.service';
import { TranslateService } from '@ngx-translate/core';
import { ValidationRegistryService } from '@revspringinc/rs-core';
import { PayerService } from '@shopping';
import { lastValueFrom, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import * as _ from 'lodash-es';
import * as Sentry from '@sentry/angular';

import { Alert } from '@revspringinc/shared';
import { ShoppingActivityType } from '../../main/shopping-activity-type';
import { BenefitStatus } from '@encounter';

@Component({
    selector: 'rs-add-insurance-plan',
    templateUrl: './add-insurance-plan.component.html',
    styleUrls: ['./add-insurance-plan.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AddInsurancePlanModal implements ModalWrapComponent, OnInit, OnDestroy {
    public static modalName = 'AddInsurancePlanModal';

    @ViewChild('modal', { static: true }) public modal;
    @ViewChildren('resolver') public resolvers: QueryList<any>;

    public modalConfig = {
        activityType: ShoppingActivityType.AddInsurancePlan,
        name: 'AddInsurancePlanModal',
        title: 'Add Insurance Plan',
        maxWidth: '650px',
        error: '',
        buttonCancels: true,
        hasManualClose: true,
        blockBackdropScrolling: true,
        footer: {
            hidden: false,
            save: 'Continue',
            saveEnabled: true,
            close: '',
        },
        steps: {
            default: [
                {
                    formName: 'CollectShoppingInsuranceNew',
                    onSubmit: async () => {
                        return this.submitPayerInfo();
                    },
                    footer: {
                        close: 'Remove insurance',
                        save: 'Continue',
                    },
                },
            ],
            payer: [
                {
                    formName: 'CollectShoppingInsuranceMemberDetails',
                    onSubmit: async () => this.submitMemberDetails(),
                    onCancel: async () => {
                        return this.skipButtonClick();
                    },
                    footer: {
                        close: 'Skip this step',
                        save: 'Continue',
                    },
                },
                {
                    // Keep the previous form open after submitting.
                    formName: '',
                    footer: {
                        save: 'Finish',
                    },
                },
            ],
        },
    };

    public loading = false;
    public benefit = false;

    public config;
    public forms;
    public insuranceForm = new UntypedFormGroup({});

    public benefitItems = [];

    public modalName?: string;
    public visible?: boolean;
    public blockBackdrop?: boolean;
    public payloadSub?: any;
    public payerMasters: [
        {
            label: '1';
            value: '1';
        },
    ];

    public benefitAlert: Alert;

    private readonly payerMasterDataKey: string = 'Insurance.PayerMaster';
    private readonly planMasterDatakey: string = 'Insurance.PlanMaster';
    private currentFormName: string;
    private payerMasterFormControl$: Subscription;
    private selectedPayerMaster: any;
    private planMasterField: any;
    private payerMasterField: any;
    private currentStep: any;
    private benefitStatus: string;
    private session: ShoppingSessionResponseModel;

    constructor(
        private readonly modalService: ModalService,
        private readonly formService: FormService,
        private readonly formsService: FormsService,
        private readonly translate: TranslateService,
        private readonly shoppingSessionService: ShoppingSessionService,
        private readonly payerService: PayerService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly sessionService: SessionService,
    ) {
        /**/
    }

    public ngOnDestroy(): void {
        this.payerMasterFormControl$.unsubscribe();
    }

    public async ngOnInit(): Promise<void> {
        this.shoppingSessionService.session.subscribe((session) => {
            this.session = session;

            this.benefitStatus = this.session.benefitStatus;

            // subscribe to backend estimate updates sent via SSE
            this.shoppingSessionService.rteUpdated.pipe(filter((x) => !!x)).subscribe((eventData) => {
                // get the updated estimate
                if (eventData) {
                    this.pingForRte(eventData);
                }
            });
        });
    }

    public async show(payload: any): Promise<void> {
        this.formsService.formPayload = payload;

        const currentStep = await this.getNextStep();

        if (payload) {
            this.setModalFooter(currentStep);
        }

        this.currentStep = currentStep;
        this.currentFormName = currentStep.formName;

        await this.loadForm(this.currentFormName);

        this.setBenefitStatusDisplay(this.selectedPayerMaster);
    }

    public async loadForm(formName: string, unsubscribe: boolean = false) {
        const { sessionId } = this.shoppingSessionService;

        this.loading = true;

        this.unsubscribeForm(unsubscribe);

        await this.setFormConfig(sessionId, formName);
        await this.createFormFields();
        this.resolveFormControls();
        this.patchForm();
        this.subscribeForm();

        this.loading = false;
    }

    public cardStatus(card: any) {
        for (const group of card.groups) {
            const formStatus = this.insuranceForm.get(group.DataKey)?.valid || false;

            if (!formStatus) {
                return 'status-warning';
            }
        }

        return 'status-success';
    }

    public hide(inState?: boolean): void {
        this.modal.hide();
    }

    public async cancel(payload?: any) {
        // Clicked the remove insurance or skip this step dialog
        if (payload.target === 'cancel') {
            try {
                if (this.currentStep.onCancel) {
                    await this.currentStep.onCancel();

                    return;
                }

                this.resetPayer();
                this.loading = true;

                await lastValueFrom(
                    this.formService.formApiControllerSaveForm(
                        this.shoppingSessionService.sessionId,
                        this.currentFormName,
                        {
                            formName: this.currentFormName,
                        },
                    ),
                );

                this.modalService.hide({ cancel: false, update: true });
            } catch (error) {
                Sentry.captureException(error);

                this.loading = false;
                this.modalConfig.error = error.error?.message;
            } finally {
                this.loading = false;
            }
        } else {
            // Clicked the x on the dialog
            this.modalService.hide({ cancel: true });
        }
    }

    public async getNextStep() {
        const payerName = this.selectedPayerMaster?.payerName ?? 'SELFPAY';
        const isMapped =
            !this.selectedPayerMaster || payerName === 'SELFPAY' || !this.config?.value.Features?.rte
                ? false
                : await this.checkPayerMapping(this.selectedPayerMaster?.id);
        const steps =
            payerName === 'SELFPAY' || !this.config.value.Features?.rte || !isMapped
                ? this.modalConfig.steps.default
                : this.modalConfig.steps.payer;

        return steps.shift();
    }

    public async submitPayerInfo() {
        this.loading = true;

        const payload = _.merge({}, this.config.value, this.insuranceForm.value);
        payload.context = 'payerinfo';

        this.config.value = payload;

        try {
            await lastValueFrom(
                this.formService.formApiControllerSaveForm(
                    this.shoppingSessionService.sessionId,
                    this.currentFormName,
                    {
                        formName: this.currentFormName,
                        ...payload,
                    },
                ),
            );

            this.loading = false;
            this.benefit = false;
            this.setModalFooter(this.currentStep);
        } catch (error) {
            Sentry.captureException(error);

            this.loading = false;
            this.benefit = false;
            this.modalConfig.error = error.error.message;
        }
    }

    public async submitMemberDetails() {
        this.loading = true;

        const payload = _.merge({}, this.config.value, this.insuranceForm.value);
        payload.context = 'verify';

        try {
            this.benefit = true;

            const result = await this.shoppingSessionService.verifyInsurance({
                formName: this.currentFormName,
                ...payload,
            });
        } catch (error) {
            Sentry.captureException(error);

            this.loading = false;
            this.benefit = false;

            this.benefitAlert = {
                icon: 'icon-alert',
                priority: 'danger',
                message: `There was a problem verifying your insurance.`,
            };
        }
    }

    public async pingForRte(eventData: any) {
        await lastValueFrom(this.sessionService.shoppingSessionControllerGetInsurance(this.session.sessionId)).then(
            async (insuranceResponse) => {
                this.benefitStatus = insuranceResponse.benefitStatus;

                if (insuranceResponse.benefitStatus === BenefitStatus.Active) {
                    this.benefitAlert = {
                        icon: 'icon-solid2',
                        priority: 'success',
                        message: `Your insurance was verified.`,
                    };
                } else if (insuranceResponse.benefitStatus === BenefitStatus.NotActive) {
                    this.benefitAlert = {
                        icon: 'icon-alert',
                        priority: 'warning',
                        message: `Your insurance was verified, but your coverage is not active.`,
                    };
                } else if (insuranceResponse.benefitStatus === BenefitStatus.MemberNotFound) {
                    this.benefitAlert = {
                        icon: 'icon-alert',
                        priority: 'warning',
                        message: `Your insurance was verified, but the provided member was not found.`,
                    };
                } else if (insuranceResponse.benefitStatus === BenefitStatus.Error) {
                    this.benefitAlert = {
                        icon: 'icon-alert',
                        priority: 'danger',
                        message: `There was a problem verifying your insurance.`,
                    };
                }

                const valueClass = insuranceResponse.benefitStatus === BenefitStatus.Active ? 'success' : 'danger';
                this.benefitItems.push({
                    label: 'Status',
                    value: insuranceResponse.benefitStatus,
                    value_class: valueClass,
                });

                this.loading = false;
                this.setModalFooter(this.currentStep);
            },
        );
    }

    public async submit() {
        this.formsService.validateAllFormFields(this.insuranceForm);

        if (!this.insuranceForm.valid) {
            return;
        }

        if (this.currentStep.onSubmit) {
            await this.currentStep.onSubmit();
        }
        const currentStep = await this.getNextStep();

        if (currentStep) {
            this.currentStep = currentStep;
            this.currentFormName = this.currentStep.formName;

            if (this.currentFormName) {
                this.setModalFooter(this.currentStep);
                await this.loadForm(this.currentFormName, true);
            }

            return;
        } else {
            const payload = _.merge({ benefitStatus: this.benefitStatus }, this.config.value, this.insuranceForm.value);
            this.modalService.hide({ cancel: false, update: true, payload });

            return;
        }
    }

    public async buttonClick(element) {
        if (element.action === 'SKIP_CURRENT_STEP') {
            await this.skipButtonClick();
        }
    }

    private async skipButtonClick() {
        const payload = {
            Insurance: {
                PayerMaster: this.config.value.Insurance.PayerMaster,
                PlanMaster: this.config.value.Insurance.PlanMaster,
            },
            context: 'skip',
        };

        this.loading = true;
        this.config.value = payload;

        try {
            await lastValueFrom(
                this.formService.formApiControllerSaveForm(
                    this.shoppingSessionService.sessionId,
                    this.currentFormName,
                    {
                        formName: this.currentFormName,
                        ...payload,
                    },
                ),
            );

            this.loading = false;
            this.benefit = false;

            this.modalService.hide({ cancel: false, update: true, payload });
        } catch (error) {
            Sentry.captureException(error);

            this.loading = false;
            this.benefit = false;
            this.modalConfig.error = error.error.message;
        }
    }

    private setModalFooter(currentStep: any) {
        this.modalConfig.footer.close = currentStep.footer.close;
        this.modalConfig.footer.save = currentStep.footer.save;
        this.modalConfig.error = '';
    }

    private async setFormConfig(sessionId: string, formName: string) {
        const formData = await lastValueFrom(this.formService.formApiControllerGetForm(sessionId, { formName }));
        this.setTranslations(formData);

        this.config = formData.config;
        this.modalConfig.title = this.translate.instant(this.config.form.title);
    }

    private async createFormFields() {
        this.forms = await Promise.all(
            this.config.form.cards.map(async (card) => {
                return Promise.all(
                    card.groups.map(async (group) => {
                        await this.resolveExternalFormData(group);

                        return this.formsService.create(group.DataKey, 'post', group.fields, {
                            submitButtonVisible: false,
                            theme: {
                                submitBtn: 'btn btn-main btn-block btn-lg rounded-0 mb-4',
                            },
                            isGroup: true,
                            existing: true,
                        });
                    }),
                );
            }),
        );
    }

    private patchForm() {
        if (this.config.value) {
            delete this.config.value.formId;
            delete this.config.value.encounterId;

            this.insuranceForm.patchValue(this.config.value);
        }
    }

    private subscribeForm() {
        const payerMasterFormControl = this.insuranceForm.get(this.payerMasterDataKey);
        const planMasterFormControl = this.insuranceForm.get(this.planMasterDatakey);

        if (!payerMasterFormControl) {
            return;
        }

        // Wire up change detection for the payer master selection
        this.payerMasterFormControl$ = payerMasterFormControl.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(async (selectedPayerMaster) => {
                this.selectedPayerMaster = selectedPayerMaster;

                await this.loadForm(this.currentFormName, true);
            });
    }

    private unsubscribeForm(unsubscribe: boolean) {
        if (unsubscribe) {
            if (this.payerMasterFormControl$) {
                this.payerMasterFormControl$.unsubscribe();
            }

            Object.keys(this.insuranceForm.controls).forEach((key) => {
                this.insuranceForm.removeControl(key);
            });

            this.forms = null;
            this.config = null;

            this.changeDetector.detectChanges();
        }
    }

    private resolveFormControls() {
        this.changeDetector.detectChanges();

        this.resolvers.toArray().forEach((resolver) => {
            const instance = resolver.children.first.component.instance;

            this.insuranceForm.addControl(instance.element.name, instance.formGroups);
        });
    }

    private async resolveExternalFormData(group: any) {
        console.log('resolving external form data', group);
        // Add the payer master drop down to the insurance form group
        // TODO: we need a better way to propagate the dropdown.
        // Perhaps an endpoint config that can populate the options dynamically.
        if (group.DataKey === 'Insurance') {
            this.planMasterField = group.fields.find((x) => x.DataKey === 'PlanMaster');
            this.payerMasterField = group.fields.find((x) => x.DataKey === 'PayerMaster');

            await this.setPayerMasterOptions();
            await this.setPlanMasterOptions();
        }

        const buttons = group.fields.filter((x) => x.InputType === 'button');
        buttons.forEach((button) => (button.onClick = (p) => this.buttonClick(p)));
    }

    private async setPayerMasterOptions() {
        if (this.payerMasterField) {
            this.payerMasterField.Options = await lastValueFrom(
                this.payerService.payerApiControllerGetAll(this.shoppingSessionService.sessionId),
            );
        }

        if (!this.selectedPayerMaster && this.config.value?.Insurance?.PayerMaster?.payerName !== 'SELFPAY') {
            this.selectedPayerMaster = this.config.value.Insurance.PayerMaster;
        }

        this.config.value.Insurance.PayerMaster = this.selectedPayerMaster;
    }

    private async setPlanMasterOptions() {
        if (!this.planMasterField) {
            return;
        }

        const payerId = this.selectedPayerMaster?.id ?? this.config.value.Insurance.PayerMaster?.id;

        if (payerId) {
            this.planMasterField.Options = await lastValueFrom(
                this.payerService.payerApiControllerGetPlans(this.shoppingSessionService.sessionId, payerId),
            );

            if (this.config.value.Insurance.PlanMaster) {
                this.config.value.Insurance.PlanMaster = this.planMasterField.Options.map((x) => x.Value).find(
                    (x) => x.id === this.config.value.Insurance.PlanMaster.id,
                );
            }
        }
    }

    private setTranslations(data: FormResponseModel) {
        Object.keys(data.language).forEach((lang) => this.translate.setTranslation(lang, data.language[lang], true));
    }

    private setBenefitStatusDisplay(selectedPayerMaster: any) {
        const payerName = selectedPayerMaster?.payerName;
        if (payerName && payerName !== 'SELFPAY') {
            if (this.benefitStatus === BenefitStatus.Active) {
                this.benefitAlert = {
                    icon: 'icon-solid2',
                    priority: 'success',
                    message: `Your insurance was verified.`,
                };
            } else if (this.benefitStatus === BenefitStatus.NotActive) {
                this.benefitAlert = {
                    icon: 'icon-alert',
                    priority: 'warning',
                    message: `Your insurance was verified, but your coverage is not active.`,
                };
            } else if (this.benefitStatus === BenefitStatus.MemberNotFound) {
                this.benefitAlert = {
                    icon: 'icon-alert',
                    priority: 'warning',
                    message: `Your insurance was verified, but the provided member was not found.`,
                };
            } else if (this.benefitStatus === BenefitStatus.Error) {
                this.benefitAlert = {
                    icon: 'icon-alert',
                    priority: 'danger',
                    message: `There was a problem verifying your insurance.`,
                };
            } else {
                this.benefitAlert = null;
            }
        } else {
            this.benefitAlert = null;
        }
    }

    private async checkPayerMapping(payerId: number) {
        const isMapped = await lastValueFrom(
            this.payerService.payerApiControllerGetPlanMapping(this.shoppingSessionService.sessionId, payerId),
        );

        return isMapped;
    }

    private resetPayer() {
        this.selectedPayerMaster = null;
        this.benefitAlert = null;
        this.benefitItems = [];
        this.benefitStatus = '';
    }
}
