/* eslint-disable tailwindcss/no-custom-classname */
import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../base-component';

@Component({
    selector: 'pa-go-back',
    template: `
        <a class="sidenav-item" (click)="goBack()">
            <span class="icon icon-back-arrow"></span>
            <span class="item-label">{{ backLabel }}</span>
        </a>
    `,
    styleUrls: ['../../../../../scss/styles.scss'],
})
export class GoBackComponent extends BaseComponent {
    @Input() backLabel = 'Back';
    constructor(private readonly locationService: Location, translate: TranslateService) {
        super(translate);
    }

    public async goBack() {
        this.locationService.back();
    }
}
