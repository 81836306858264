import { Component, Input } from '@angular/core';
/**
 *
 *
 * @export
 * @class BsCheckBoxComponent
 */
@Component({
    selector: 'rs-checkbox',
    templateUrl: './rs-checkbox.component.html',
    styleUrls: ['../../../scss/styles.scss']
})
export class RsCheckBoxComponent {
    /**
     * Element is an object  which is needed to render the component
     *
     * @memberof BsCheckBoxComponent
     */
    @Input() element;
    /**
     * checkBoxValuesArray is an array of strings used to store the values array of the control elementOptions in element
     *
     * @type {string[]}
     * @memberof BsCheckBoxComponent
     */
    checkBoxValuesArray: string[] = [];
    /**
     * checkBoxOptionsArray is an array of strings used to store the options of the array of the element
     *
     * @type {CheckBoxOptions[]}
     * @memberof BsCheckBoxComponent
     */
    checkBoxOptionsArray: CheckBoxOptions[] = [];

    ngOnInit() {
        // setting the checkBoxValuesArray to the Value of the control
        this.checkBoxValuesArray = this.element.elementOptions.value || [];
        // setting the checkBoxOptionsArray to the options of the control
        this.checkBoxOptionsArray = this.element.elementOptions.options;
        this.checkIntialValueAndMarkAsChecked();
    }

    /**
     * checkAllCheckBoxes is used to check or uncheck all the checkboxes. It will loop through the checkBoxOptionsArray and if the value of disabled property is not true and  if it is event of type check
     * then it will push the value of the value property of element of checkBoxOptionsArray into the checkBoxValuesArray and if it is a type of uncheck then It will remove the all the values from the
     * values array we don't consider the disabled checkbox while checking or unchecking the select box or pushing the diabled value or removing disabled value into the valuesArray. and
     * then it will set the value of the control.
     *
     * @param {any} event
     * @memberof BsCheckBoxComponent
     */
    checkAllCheckBoxes(event): void {
        this.checkBoxOptionsArray.forEach(item => {
            if (!item.disabled) {
                if (event.target.checked) {
                    this.checkBoxValuesArray.indexOf(item.value) === -1
                        ? this.checkBoxValuesArray.push(item.value)
                        : null;
                } else {
                    this.checkBoxValuesArray = this.checkBoxValuesArray.filter(
                        val => val !== item.value
                    );
                }
                this.setControlValueAndUpdateControlStatus();
                return (item.checked = event.target.checked);
            }
        });
    }

    /**
     * checkIntialValueAndMarkAsChecked will loops through the checkBoxOptionsArray and checks for any value of its value property matches with the checkBoxValuesArray
     * if so It will set the checked property to true and returns the option if not it will returns the same option which out any manupulation
     *
     * @memberof BsCheckBoxComponent
     */
    checkIntialValueAndMarkAsChecked(): void {
        if (this.checkBoxValuesArray) {
            this.checkBoxOptionsArray.forEach(item => {
                this.checkBoxValuesArray.forEach(value => {
                    if (item.value === value) {
                        item.checked = true;
                        return item;
                    } else {
                        return item;
                    }
                });
            });
        }
    }

    /**
     * isAllCheckBoxesChecked method loops through the checkBoxOptionsArray and returns the checked property which is boolean used to check or uncheck the selectAll checkbox. If any one
     * of the checkbox is disabled it will return true for that because we don't consider the disabled checkbox while checking or unchecking the select box.
     *
     * @returns boolean
     * @memberof BsCheckBoxComponent
     */
    isAllCheckBoxesChecked(): boolean {
        return this.checkBoxOptionsArray.every(item => {
            if (!item.disabled) {
                return item.checked;
            } else if (item.disabled) {
                return true;
            }
        });
    }

    /**
     * onCheckBoxClickTrigged method is fired on the checkbox change event. Inside the method,It will iterate through the checkBoxOptionsArray and if it a checked event
     *  it will push the value attached to the checkbox to the control value array and it will return the checked property as true. If it a unchecked event
     *  It will removed it value from the control value array and it will return the checked property as false, Which will used by isAllCheckBoxesChecked method to check selectAll or not
     * @param event
     */
    onCheckBoxClickTrigged(event): void {
        this.checkBoxOptionsArray.forEach((item, i) => {
            if (event.target.checked && item.value === event.target.value) {
                this.checkBoxValuesArray.push(event.target.value);
                this.setControlValueAndUpdateControlStatus();
                return (item.checked = true);
            } else if (item.value === event.target.value) {
                this.checkBoxValuesArray = this.checkBoxValuesArray.filter(
                    val => val !== event.target.value
                );
                this.setControlValueAndUpdateControlStatus();
                return (item.checked = false);
            }
        });
    }
    /**
     * setControlValueAndUpdateControlStatus method will set the value of the checkbox control programmatically to the checkBoxValuesArray
     * and programmatically upate the value and validity and If changed event is triggered on any one of the checkbox This method will set the checkbox control to dirty
     *
     * @memberof BsCheckBoxComponent
     */
    setControlValueAndUpdateControlStatus(): void {
        this.element.group.controls[this.element.name].setValue(
            this.checkBoxValuesArray
        );
        this.element.group.controls[this.element.name].updateValueAndValidity();
        this.element.group.controls[this.element.name].markAsDirty();
    }
}

export interface CheckBoxOptions {
    name?: string;
    value: string;
    disabled: boolean;
    checked?: boolean;
}
