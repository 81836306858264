import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { Action, Store } from '@ngrx/store';
import { tap, withLatestFrom } from 'rxjs/operators';

import { HyrdateTranslationsAction, SetTranslationAction } from './actions';
import { TranslationState } from './state';
import { selectTranslationConfigs, selectTranslationLanguage } from './selectors';

@Injectable()
export class TranslationEffects implements OnInitEffects {
    constructor(
        private readonly action$: Actions,
        private readonly translate: TranslateService,
        private readonly store$: Store<TranslationState>,
    ) {}

    public setTranslation$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(SetTranslationAction),
                tap((action) => {
                    Object.keys(action.languageConfig).forEach((lang) => {
                        this.translate.setTranslation(lang, action.languageConfig[lang], true);
                    });
                }),
            );
        },
        { dispatch: false },
    );

    public hydrateTranslations$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(HyrdateTranslationsAction),
                withLatestFrom(
                    this.store$.select(selectTranslationConfigs),
                    this.store$.select(selectTranslationLanguage),
                ),
                tap(([action, configs, language]) => {
                    this.translate.setDefaultLang('en_US');
                    this.translate.use(language);

                    for (const config of configs) {
                        for (const lang of Object.keys(config)) {
                            this.translate.setTranslation(lang, config[lang], true);
                        }
                    }
                }),
            );
        },
        { dispatch: false },
    );

    public ngrxOnInitEffects(): Action {
        // Run the Translate effect on init
        return HyrdateTranslationsAction();
    }
}
