import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ShoppingSessionResponseModel, SessionService } from '@shopping';
import * as Sentry from '@sentry/angular';

@Injectable({ providedIn: 'root' })
export class ShoppingSessionService {
    public readonly session = new BehaviorSubject<ShoppingSessionResponseModel>(null);
    public readonly estimateUpdated = new BehaviorSubject<any>(null);
    public readonly rteUpdated = new BehaviorSubject<any>(null);

    constructor(private readonly sessionService: SessionService) {
        /**/
    }

    public get sessionId() {
        return this.session.getValue().sessionId;
    }

    public setSession(session: ShoppingSessionResponseModel) {
        this.session.next(session);
    }

    public setEstimateUpdated(updated: any) {
        this.estimateUpdated.next(updated);
    }

    public setRteUpdated(updated: any) {
        this.rteUpdated.next(updated);
    }

    public async createSession(orgName: string, createNew: boolean = false): Promise<ShoppingSessionResponseModel> {
        if (!this.session.getValue() || createNew) {
            const shoppingSession = await lastValueFrom(
                this.sessionService.shoppingSessionControllerCreateSession(orgName),
            );

            if (shoppingSession) {
                this.session.next(shoppingSession);

                Sentry.setUser({ sessionId: this.sessionId });
            }

            return shoppingSession;
        }
    }

    public async getExistingSession(sessionId: string): Promise<ShoppingSessionResponseModel> {
        const shoppingSession = await lastValueFrom(this.sessionService.shoppingSessionControllerGetSession(sessionId));

        if (shoppingSession) {
            this.session.next(shoppingSession);

            Sentry.setUser({ sessionId: this.sessionId });
        }

        return shoppingSession;
    }

    public async verifyInsurance(payload: any) {
        return lastValueFrom(this.sessionService.shoppingSessionControllerVerifyInsurance(this.sessionId, payload));
    }

    public async viewedAmaNotice(): Promise<void> {
        await lastValueFrom(this.sessionService.viewedAmaNotice(this.sessionId));
    }

    public async estimateDisclaimerAcknowledged(): Promise<void> {
        await lastValueFrom(this.sessionService.estimateDisclaimerAcknowledged(this.sessionId));
    }
}
