<span [ngClass]="{ 'pl-3 border-l-0 md:border-l-2 border-th-primary border-opacity-25': !!providerGroup?.groupingKey }"
      class="flex flex-col m-3">
    <div *ngFor="let service of providerGroup?.services; let last = last"
         [ngClass]="{ 'pb-6': !last }">
        <provider-group-service-header [service]="service"></provider-group-service-header>

        <ul class="print:list-unstyled">
            <li *ngFor="let service of service?.totals; let i = index">
                <provider-group-service *ngIf="service.value"
                                        [service]="service"></provider-group-service>
            </li>
        </ul>


    </div>
</span>
