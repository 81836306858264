import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { logInfo } from '../../../utils/log';
import { SidenavState } from '../sidenav.state';

@Injectable({ providedIn: 'root' })
export class SidenavService {
    private readonly sidenavStateSource = new BehaviorSubject<SidenavState>({
        state: {
            isVisible: true,
            isOverlay: false,
        },
        components: [],
    });

    public readonly sidenavState$ = this.sidenavStateSource.asObservable();

    constructor(private readonly router: Router) {
        logInfo('Sidenav Service', `created`);

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.router.routerState.root),
                map((route) => {
                    let state: SidenavState = {
                        state: { isVisible: true },
                        components: [],
                        secondaryComponents: {},
                    };

                    while (route.firstChild) {
                        route = route.firstChild;
                        const newState = route.snapshot.data.sidenav;

                        state = {
                            ...state,
                            ...newState,
                            state: {
                                ...state,
                                route: {
                                    queryParams: route.snapshot.queryParams,
                                    secondaryComponents: route.snapshot.data.secondaryComponents,
                                },
                            },
                        };
                    }

                    logInfo('Sidenav Service', `traversing state tree ->`, state);

                    return state;
                }),
            )
            .subscribe((state) => {
                this.updateState(state);
            });
    }

    public getSidenavState() {
        return this.sidenavStateSource.getValue();
    }

    public updateState(newState: SidenavState): void {
        const currentState = this.getSidenavState();

        newState = {
            ...currentState,
            ...newState,
            state: { ...currentState.state, ...newState.state },
        };

        logInfo('Sidenav Service', 'Updating sidenav state', newState);

        this.sidenavStateSource.next(newState);
    }

    public toggle() {
        const currentState = this.getSidenavState();

        this.updateState({
            ...currentState,
            state: {
                isOverlay: !currentState.state.isOverlay,
            },
        });
    }
}
