<div>
  <rs-input-field #inputRef
                  [id]="id"
                  [value]="value"
                  name="search"
                  type="text"
                  [label]="label"
                  [formControl]="formControl"
                  (focus)="toggleSearch(true)"
                  (blur)="toggleSearch(false)"
                  (change)="valueChanged($event)"
                  (enter)="onClick(true)"
                  [autofocus]="autofocus">
  </rs-input-field>
  <button *ngIf="isSearchEnabled && value"
          class="input-addon"
          [ngClass]="{ 'search': !hasSearched, 'clear': hasSearched }"
          (mousedown)="onClick()"
          (keydown.enter)="onClick()"
          aria-label="Search">
  </button>
</div>
