<rs-modal
  #modal 
  (show)="show($event)"
  [config]="modalConfig">
  <div class="pb-4">
    <div class="bg-white pb-4 text-center px-4">
      <button class="rs-btn-link">Use my current location: 
        <span class="fw-700">{{ currentLocation }}</span>
    </button>
    </div>
    <div class="px-4 pt-4">
      <ng-container *ngFor="let location of locations, let i = index">
        <div
          *ngIf="location.selected"
          class="card p-4">
          <div>
            <div
              class="location-title fw-700 color-secondary fs-20 line-height-1 d-flex align-items-center mb-4">
              {{ location.label }}
            </div>
            <div>
              <div class="fs-14">3 Procedures</div>
              <div class="fs-14">Total Patient Responsibility</div>
              <div class="color-primary fs-28 fw-700">${{ location.cost }}</div>
            </div>
          </div>
        </div>
      </ng-container>
      <p class="py-3 text-center mb-0">Compare to our other locations nearby</p>
      <ng-container *ngFor="let location of locations, let i = index">
        <div
          *ngIf="!location.selected"
          [ngClass]="{'mb-3': i != (locations.length - 1)}"
          class="card p-4">
          <div class="mb-4">
            <div
              class="location-title fw-700 color-secondary fs-20 line-height-1 d-flex align-items-center mb-4">
              {{ location.label }}
            </div>
            <div>
              <div class="fs-14">3 Procedures</div>
              <div class="fs-14">Total Patient Responsibility</div>
              <div class="color-primary fs-28 fw-700">${{ location.cost }}</div>
            </div>
          </div>
          <button
            (click)="selectLocation(location)"
            class="btn-primary p-3 fw-700 fs-16">
            Select location
        </button>
        </div>
      </ng-container>
    </div>
    <button
      (click)="showMoreLocations()"
      class="rs-btn-link fw-700 btn-block mt-4">Show more locations</button>
  </div>
</rs-modal>