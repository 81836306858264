import { Component, Input, Type } from '@angular/core';
import { BaseComponent } from '../../base-component';

@Component({
    selector: 'pa-header-content',
    template: `<ng-container *ngComponentOutlet="component"></ng-container>`,
    styles: [':host { display: contents; }'],
})
export class HeaderContentContainerComponent {
    @Input() component!: Type<BaseComponent>;
}
