<rs-modal #modal
          (show)="show($event)"
          (submit)="submit()"
          [config]="modalConfig">

  <div *ngIf="checkboxFormArray && procedureGroups">

    <cdk-virtual-scroll-viewport #scroll
                                 itemSize="80">

      <div *cdkVirtualFor="let checkbox of checkboxFormArray?.controls; let i = index"
           class="bottom-border pt-4 pb-2">
        <div class="selected-item d-flex justify-content-between align-items-center">
          <div class="selected-item-title align-items-top">
            <rs-checkbox ngDefaultControl
                         [id]="procedureGroups[i].id"
                         [label]="procedureGroups[i].name | translate"
                         [formControl]="checkbox"
                         (change)="selectProcedureGroup($event, procedureGroups[i])">
            </rs-checkbox>

            <button *ngIf="procedureGroups[i].procedures.length > 1"
                    (click)="procedureGroups[i].open = !procedureGroups[i].open"
                    (clickOutside)="procedureGroups[i].open = false"
                    class="rs-btn-link d-flex align-items-center fw-700 stack">
              {{ procedureGroups[i].procedures.length }}
              {{ procedureGroups[i].procedures.length === 1 ? 'procedure' : 'procedures' }}
            </button>
          </div>
          <div *ngIf="procedureGroups[i].procedures.length > 1 && procedureGroups[i].open"
               class="pulldown-menu p-3">
            <div class="fw-700 fs-18 mb-3">Procedures in this group</div>
            <div *cdkVirtualFor="let child of procedureGroups[i].procedures">
              {{ child.name | translate }}
            </div>
          </div>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
    
  </div>
</rs-modal>