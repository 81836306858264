// third-party imports
import { Component, Input } from '@angular/core';
import { RsBaseComponent } from '../rs-base.component';
/**
 *
 *
 * @export
 * @class BsRadioComponent
 */
@Component({
    selector: 'rs-radio',
    templateUrl: './rs-radio.component.html',
    styleUrls: ['./rs-radio.component.scss', '../../../scss/styles.scss']
})
export class RsRadioComponent extends RsBaseComponent {
    @Input() public element;
}
