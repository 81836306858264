import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { DefaultService } from './api/default.service';
import { EstimateService } from './api/estimate.service';
import { FormService } from './api/form.service';
import { FormOrganizationSubmissionService } from './api/formOrganizationSubmission.service';
import { PayerService } from './api/payer.service';
import { ProcedureService } from './api/procedure.service';
import { SessionService } from './api/session.service';
import { SiteConfigService } from './api/siteConfig.service';
import { LocationService } from './api/location.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }
}
