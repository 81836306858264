import { Component, Input } from '@angular/core';
import { EstimateGroupLineModel } from '../../models/estimateGroupLineModel';

@Component({
    selector: 'provider-group-service',
    templateUrl: './provider-group-service.component.html',
    styleUrls: [
        '../../scss/styles.scss'
    ]
})
export class ProviderGroupServiceComponent {

    @Input() service: EstimateGroupLineModel | undefined;

    getServiceClass() {
        return {
            'md:font-bold': this.service?.class === 'fw-700',
            'font-semibold': this.service?.class === 'fw-700'
        };
    }

    public startsWith(value: string, key: string): boolean {
        return value ? value.startsWith(key) : false;
    }

    public toText(value: any) {
        return value.toString().replace(/\$/g, '');
    }
}
