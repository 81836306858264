import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
    public readonly organization = new BehaviorSubject<string>(undefined);

    public setOrganization(organization: string) {
        this.organization.next(organization);
    }
}
