<div *ngIf="estimateDisclaimerConfig"
     class="p-4 transition-all card md:p-12 shadow-card print:estimate-view print:shadow-none">
  <div class="print:estimate-header print:page-break-before-always">
    <div class="mb-4">
      <h2 class="mb-4 text-2xl font-bold text-primary">
        {{ estimateDisclaimerConfig.config.title | translate}} </h2>
      <div class="p-4 border-l-4 border-th-line">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Heroicon name: solid/information-circle -->
            <svg xmlns="http://www.w3.org/2000/svg"
                 class="w-5 h-5 text-primary"
                 viewBox="0 0 20 20"
                 fill="currentColor">
              <path fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clip-rule="evenodd" />
            </svg>
          </div>
          <div class="mt-3 ml-3 md:mt-0">
            <p class="text-gray-900 text-md">
              {{ estimateDisclaimerConfig.config.body | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex mt-4 align-items-center justify-content-center">
    <div class="wrapper wrapper-checkbox">
      <rs-checkbox ngDefaultControl
                   [id]="1"
                   [label]="estimateDisclaimerConfig.config.actionLabel | translate"
                   (change)="setEstimateDisclaimerAcknowledged($event)"
                   [disabled]="acknowledged">
      </rs-checkbox>
    </div>
  </div>
</div>
