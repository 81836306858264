export interface LanguageModel {
    en_US: string;
    es: string;
    [key: string]: string;
}

export interface TranslationState {
    language: string;
    configs: LanguageModel[];
}

export const initialTranslationState: TranslationState = {
    language: 'en_US',
    configs: [],
};
