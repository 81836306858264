import _ from 'lodash-es';
import { Registry } from '../registry/registry.class';
import { Validators, AbstractControl, ValidationErrors, ValidatorFn, UntypedFormGroup } from '@angular/forms';
import { RsValidation } from '../schema/rs-validation.schema';
import moment from 'moment';

interface ValidationRegistryCallback {
  (registry: ValidationRegistryService): ValidationRegistryService
}

export class ValidationRegistryService extends Registry<any> {
  static configure(cb: ValidationRegistryCallback) {
    const registry = new ValidationRegistryService()
      .add('required', Validators.required)
      .add('minlength', Validators.minLength)
      .add('maxlength', Validators.maxLength)
      .add('min', Validators.min)
      .add('max', Validators.max)
      .add('pattern', customRegexValidation)
      .add('regex', Validators.pattern)
      .add('email', emailValidator)
      .add('nullValidator', Validators.nullValidator)
      .add('compose', Validators.compose)
      .add('custom1', () => null)
      .add('custom2', () => null)
      .add('custom3', () => null)
      .add('invalidcardexpiration', validateExpDate)
      .add('invaliddate', invalidDate)
      .add('nonfuturedate', futureDateValidation)
      .add('identical', identicalValidation)
      .add('tendigitphonenumber', tenDigitPhoneNumber);

    return cb(registry)
  }

  constructor() {
    super('Validations')
  }

  getControlValidators(validationConfig: RsValidation) {
    return _.map(validationConfig, (validation, validationName) => {
      const validationFunc = this.resolve(validationName)

      if (_.isArray(validation.parameters)) {
        if (validationName === 'pattern') {
          return validationFunc(validation.parameters[0], validation.parameters[1])
        } else if (validationName === 'identical') {
          return validationFunc(validation.parameters[0])
        } else {
          return validationFunc.apply(
            null, validation.parameters)
        }
      }

      return validationFunc
    })
  }
}

export function validateExpDate(control: AbstractControl): ValidationErrors | null {
  if (Validators.required(control) !== undefined && Validators.required(control) !== null) {
    return { 'InvalidCardExpiration': true };
  }
  const controlValue = control.value;

  if (typeof controlValue !== 'undefined' && controlValue.length === 5) {
    let [month, year] = controlValue.replace(/[^0-9 ]/g, "/").split(/[\s\/]+/, 2),
      prefix;

    if ((year != null ? year.length : void 0) === 2 && /^\d+$/.test(year)) {
      prefix = new Date().getFullYear();
      prefix = prefix.toString().slice(0, 2);
      year = prefix + year;
    }
    month = parseInt(month, 10).toString();
    year = parseInt(year, 10).toString();

    if (/^\d+$/.test(month) && /^\d+$/.test(year) && (month >= 1 && month <= 12)) {
      let currentTime, expiry;
      expiry = new Date(year, month);
      currentTime = new Date();
      expiry.setMonth(expiry.getMonth() - 1);
      expiry.setMonth(expiry.getMonth() + 1, 1);

      if (expiry > currentTime) {
        return null;
      } else {
        return { 'InvalidCardExpiration': true };
      }
    } else {
      return { 'InvalidCardExpiration': true };
    }
  } else {
    return null;
  }
}


export function invalidDate(control: AbstractControl): ValidationErrors {
  const value = (control.value) ? control.value.replace(/[^\d]/g, "") : null;
  if (value && value.length === 8) {
    const datePattern = /^(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])\d{4}$/g;
    if (datePattern.test(value)) {
      return null;
    } else {
      return { invaliddate: true };
    }
  }
}

export function futureDateValidation(control: AbstractControl): ValidationErrors {
  const value = (control.value) ? control.value.replace(/[^\d]/g, "") : null;
  if (value && value.length === 8) {
    const todayDate = moment().format('MMDDYYYY');
    const userEnteredDate = moment(value, 'MMDDYYYY');
    const today = moment(todayDate, 'MMDDYYYY');
    if (userEnteredDate.diff(today, 'days') > 0) {
      return { nonfuturedate: true };
    } else {
      return null;
    }
  }
}

export function emailValidator(control: AbstractControl): Validators | null {
  if (control.dirty) {
    let isValid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(control.value);
    if (isValid || control.value === '') {
      return null;
    } else {
      return { email: true }
    }
  }
}

export function customRegexValidation(regexValue: string, regexFlag: string = ''): ValidatorFn {
  return function patternValidation(control: AbstractControl): Validators | null {
    if (control.dirty && regexValue) {
      const newRex = new RegExp(regexValue, regexFlag);
      const isValid = newRex.test(control.value);
      if (isValid || control.value === '') {
        return null;
      } else {
        return { pattern: true }
      }
    }
  }
}

export function identicalValidation(identicalKey: string): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.parent && !identicalKey) return null;
    const identicalControl = control.parent ? (control.parent as UntypedFormGroup).controls[identicalKey] : null;
    if (identicalControl && identicalControl.value && control.value && identicalControl.value !== control.value) {
      if (!identicalControl.hasError('identical')) {
        identicalControl.setErrors({ identical: true })
        identicalControl.updateValueAndValidity();
      }
      return { identical: true };
    } else if (identicalControl && identicalControl.value && control.value && identicalControl.value === control.value && identicalControl.hasError('identical')) {
      identicalControl.setErrors({ identical: false })
      identicalControl.updateValueAndValidity();
    }
    return null;
  }
}

export function tenDigitPhoneNumber(control: AbstractControl): ValidationErrors {
  if (control.dirty) {
    const isValid = /^\(\d{3}\)\s?\d{3}-\d{4}$/.test(control.value);
    if (isValid || control.value === '') {
      return null;
    } else {
      return { tendigitphonenumber: true };
    }
  }
}
