import { Component, OnInit, ViewChild } from '@angular/core';
import { RsModalConfig } from '@revspringinc/rs-shared';
import { ModalWrapComponent } from '@revspringinc/rs-shared';
import { ModalService } from '@revspringinc/rs-shared';
import { ShoppingActivityType } from '../../main/shopping-activity-type';

@Component({
  selector: 'rs-compare-costs',
  templateUrl: './compare-costs.component.html',
  styleUrls: ['./compare-costs.component.scss']
})
export class CompareCostsModal implements ModalWrapComponent {
  [s: string]: any;

  public static modalName = 'CompareCostsModal';
  @ViewChild('modal', { static: true }) public modal;
  public modalConfig = {
    activityType: ShoppingActivityType.CompareCosts,
    name: 'CompareCostsModal',
    title: 'Compare cost by location',
    maxWidth: '650px',
    bodyClass: 'gray-bck px-0',
    footer: {
      hidden: true
    }
  } as RsModalConfig;
  public currentLocation = '08106';

  public locations = [
    {
      id: 0,
      label: 'Santa Clara Medical Center',
      selected: true,
      cost: '1,350'
    },
    {
      id: 0,
      label: 'Berger Hospital',
      selected: false,
      cost: '920'
    },
    {
      id: 1,
      label: 'Dublin Methodist Hospital',
      selected: false,
      cost: '1,420'
    }
  ];
  public modalName?: string;
  public visible?: boolean;
  public blockBackdrop?: boolean;
  public payloadSub?: any;

  constructor(private readonly modalService: ModalService) { }

  public cancel(target?: any) {
    this.hide();
  }

  public show(payload: any): void {

  }

  public hide(inState?: boolean): void {
    this.modal.hide();
  }

  public selectLocation(location) {
    this.modalService.hide();
  }

  public showMoreLocations() { }
}
