import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    forwardRef,
    OnInit,
    OnDestroy,
} from '@angular/core';
import {
    NG_VALUE_ACCESSOR,
    ControlValueAccessor,
    AbstractControl,
    ValidationErrors,
    UntypedFormControl,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { RsValidation } from '../../models/validation.model';

@Component({
    selector: 'rs-input-field',
    templateUrl: './input-field.component.html',
    styleUrls: ['./input-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputFieldComponent),
            multi: true,
        },
    ],
})
export class InputFieldComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @ViewChild('input', { static: true }) public inputElement: ElementRef;
    @Input() public id: string;
    @Input() public value: string;
    @Input() public type: string;
    @Input() public label: string;
    @Input() public name: string;
    @Input() public mask: string;
    @Input() public wrapClass: string;
    @Input() public autofocus = false;
    @Input() public formControl: UntypedFormControl;
    @Input() public validation: RsValidation = {};
    @Input() public autocomplete: string;
    @Output() public change = new EventEmitter<string>();
    @Output() public focus = new EventEmitter<void>();
    @Output() public blur = new EventEmitter<void>();
    @Output() public enter = new EventEmitter<string>();
    public error;
    private cb: (_: any) => void;
    private cbBlurred: any;
    private subscription: Subscription;

    public ngOnInit() {
        if (this.formControl) {
            this.value = this.formControl.value;
            this.inputElement.nativeElement.value = this.formControl.value;
            this.validate(this.formControl);
            this.subscription = this.formControl.valueChanges
                .pipe(
                    map((val) => {
                        this.validate(this.formControl);

                        return val;
                    }),
                    filter((val) => val !== this.inputElement.nativeElement.value),
                )
                .subscribe((val) => {
                    this.value = val;
                    this.inputElement.nativeElement.value = val;
                });
        }
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public writeValue(value: string): void {
        this.inputElement.nativeElement.value = value;
    }

    public registerOnChange(fn: any): void {
        this.cb = fn;
    }

    public registerOnTouched(fn: any): void {
        this.cbBlurred = fn;
    }

    public onEnter() {
        this.enter.emit(this.inputElement.nativeElement.value);
    }

    public validate(control: AbstractControl): ValidationErrors {
        const errors = control.errors && Object.keys(control.errors);
        if (errors && errors.length > 0) {
            this.error = this.validation[errors[0]];

            return control.errors;
        }
        this.error = undefined;

        return null;
    }

    public onChange(value: string) {
        if (this.cb) {
            this.cb(value);
        }
        if (this.formControl) {
            this.formControl.setValue(value);
        }
        this.change.emit(value);
    }

    public onBlur() {
        if (this.cbBlurred) {
            this.cbBlurred();
        }
        this.blur.emit();
    }

    public onFocus() {
        this.focus.emit();
    }

    public setInputFocus() {
        this.inputElement.nativeElement.focus();
    }
}
