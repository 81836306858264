<ng-container *ngIf="this.sidenavService.sidenavState$ | async as sidenavState">
   <aside *ngIf="sidenavState.state.isVisible"
          [ngClass]="{
             'md:flex flex-col justify-between h-screen min-h-screen py-4 px-4 transition-all bg-th-sidenav-background': true,
             'border-r shadow-sm flex border-th-line print:hidden overflow-x-hidden': true,
             'hidden w-20 lg:w-60 sticky top-0 overflow-auto z-auto': !sidenavState.state.isOverlay,
             'overlay overflow-visible fixed inset-y-0 left-0 max-w-full z-50 w-60': sidenavState.state.isOverlay
          }">
      <pa-sidenav-content *ngFor="let component of sidenavState.components"
                          [component]="component">
      </pa-sidenav-content>
   </aside>
</ng-container>
