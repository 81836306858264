<div [ngClass]="{opened: isOpen}"
     class="rs-collapse {{ contentWrapClass }}--wrapper">
  <button *ngIf="buttonVisible"
          [ngClass]="{opened: isOpen}"
          class="rs-btn-link {{ btnWrapClass }}"
          [attr.aria-expanded]="isOpen"
          [attr.aria-controls]="id"
          (click)="toggle()"
          type="button">
    {{ title | translate }}
  </button>
  <div *ngIf="isOpen"
       @slideInOut
       [id]="id"
       class="content {{ contentWrapClass }}">
    <ng-content></ng-content>
  </div>
  <div>
