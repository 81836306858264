<div class="doughnut-container">
    <div class="doughnut">
        <div class="accuracy">Confidence</div>
        <ngx-gauge class="gauge"
                type="semi"
                size="50"
                thick="6"
                [value]="gaugeValue"
                [thresholds]="gaugeThresholdConfig">
            <ngx-gauge-value>
                {{ gaugeDisplayValue }}
            </ngx-gauge-value>
            <ngx-gauge-append>
                {{ gaugeDisplayValue == '?' ? '' : '%' }}
            </ngx-gauge-append>
        </ngx-gauge>
    </div>
</div>
