import { Inject, Injectable, NgZone } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { SseService as SseApiService, BASE_PATH } from '@shopping';

@Injectable({
    providedIn: 'root',
})
export class ServerSentEventsService {
    protected basePath = 'http://localhost:3100';
    private eventSource: EventSource;

    constructor(
        private readonly _zone: NgZone,
        @Inject(BASE_PATH) basePath: string,
        private readonly sseApiService: SseApiService,
    ) {
        this.basePath = basePath;
    }

    public getServerSentEvent(sessionId: string): Observable<any> {
        return Observable.create((observer) => {
            this.eventSource = this.getEventSource(sessionId);

            this.eventSource.onmessage = (event) => {
                this._zone.run(() => {
                    observer.next(event);
                });
            };

            this.eventSource.onerror = (error) => {
                this._zone.run(() => {
                    // commenting this out intentionally since we expect the load balancer
                    // to close the connection after 60 seconds and we don't want the error
                    // being logged to Sentry
                    // we may need to add better reconnect logic here
                    // observer.error(error);
                    // this.eventSource.close();
                });
            };
        });
    }

    public async closeServerSentEvent(sessionId: string) {
        console.log('closing server sent events for ' + sessionId);
        try {
            this.eventSource?.close();
            await lastValueFrom(this.sseApiService.serverSentEventsControllerUnsubscribeFromEvents(sessionId));
        } catch (e) {
            throw new Error(e.message ?? 'SSE Service - Failed to close eventSource');
        }
    }

    private getEventSource(sessionId: string): EventSource {
        try {
            const eventSource = new EventSource(this.basePath + '/sse/' + sessionId);

            return eventSource;
        } catch (e) {
            throw new Error(e.message ?? 'SSE Service - Failed to get eventSource');
        }
    }
}
