<div class="bg-white px-4">
  <p>Have a procedure code from your doctor?</p>
  <div class="position-relative mt-2">
    <rs-search-field label="Enter procedure code or description"
                     id="procedureSearch"
                     name="value"
                     (search)="onSearch($event)"
                     autofocus="true">
    </rs-search-field>
  </div>
</div>

<div class="procedures-list bg-white p-4"
     *ngIf="searchMode">
  <div class="card p-0"
       *ngIf="procedures && procedures.length > 0">
    <div class="fw-700 fs-18 mb-3">
      {{ procedures.length }} Results
    </div>

    <cdk-virtual-scroll-viewport #scroll
                                 itemSize="57">
      <div *cdkVirtualFor="let group of procedures"
           class="selected-item d-flex justify-content-between align-items-center py-3">
        <div class="d-flex flex-column">
          <div class="d-flex">{{ group.name | translate }}</div>
          <button *ngIf="group.procedures.length > 1"
                  (click)="group.open = !group.open"
                  (clickOutside)="group.open = false"
                  class="rs-btn-link d-flex align-items-center fw-700 stack">
            {{ group.procedures.length }} {{ group.procedures.length === 1 ? 'procedure' : 'procedures'}}
          </button>
        </div>
        <button (click)="toggleProcedure(group); group.selected = !group.selected"
                class="rs-btn-link fw-700">
          <span *ngIf="group.selected">Remove</span>
          <span *ngIf="!group.selected">Select</span>
        </button>
        <div *ngIf="group.open"
             class="pulldown-menu p-3">
          <div class="fw-700 fs-18 mb-3">Procedures in this group</div>
          <div *cdkVirtualFor="let procedure of group.procedures">{{ procedure.name | translate }}</div>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>

  </div>
</div>
<div *ngIf=" searchMode && procedures && procedures.length == 0"
     class="card p-3">
  <div class="fw-700 fs-18 mb-3">
    <span class="color-primary">0</span>
    Results
  </div>
  <div class="selected-item fs-18 py-3">
    <div class="fw-700">
      If you're having trouble finding a procedure to be estimated…
    </div>
    <div>
      Only procedures that are approved to be estimated can be searched and selected.
      Try to broaden your search to include more results,
      or <b class="rs-btn-link"
         (click)="showContactMeModal()">
        contact a representative
      </b> about your search results.
    </div>
  </div>
</div>
<div *ngIf="searchMode && procedures && procedures.length !== 0"
     class="card p-3">
  <div class="selected-item fs-18 py-3">
    <div class="fw-700">
      Still not seeing what you are looking for?
    </div>
    <div>
      Have a
      <b class="rs-btn-link"
         (click)="showContactMeModal()">
        Patient Representative
      </b> contact me.
    </div>
  </div>
</div>