import * as Sentry from '@sentry/angular';
import { Breadcrumb, BreadcrumbHint } from '@sentry/angular';
import { RewriteFrames } from '@sentry/integrations';

export type SentryOptions = { dsn: string; environment: string; version: string; enabled?: boolean };

export const configureSentry = (options: SentryOptions) => {
    Sentry.init({
        dsn: (options.enabled ?? true) && options.dsn,
        environment: options.environment,
        autoSessionTracking: true,
        attachStacktrace: true,
        enabled: options.enabled ?? true,
        ignoreErrors: [
            "Cannot read properties of null (reading 'scrollTop')",
            "null is not an object (evaluating 't.target.scrollTop')",
            "undefined is not an object (evaluating 'this.currentGroupId.toString')",
            "Cannot read properties of undefined (reading 'toString')",
            'e.target is null',
            't.target is null',
        ],
        beforeBreadcrumb: (breadCrumb: Breadcrumb, hint: BreadcrumbHint): Breadcrumb => {
            if (
                breadCrumb.category === 'http' &&
                ['GetSamplingRules', 'SamplingTargets'].some((x) => breadCrumb?.message?.includes(x))
            ) {
                return null;
            }

            return breadCrumb;
        },
        release: options.version,
        integrations: [
            new RewriteFrames({
                iteratee: function iteratee(frame) {
                    // Remove dynamic part from path in order to resolve sourcemaps
                    frame.filename = frame.filename.replace(/.*\/\/.*?\//, '~/');

                    return frame;
                },
            }),
        ],
    });
};
