<footer class="footer-wrapper pt-5">
  <div class="container">
    <div class="row footer">
      <div class="col">
        <div class="navbar-brand p-0">
          <img [src]="footerData.logo_src" alt="">
        </div>
        <hr class="d-md-none my-4">
      </div>
      <div class="col-md" *ngFor="let column of footerData.sections">
        <h3 class="h4 pb-4">
          {{ column.title }}
        </h3>
        <ul class="list-unstyled">
          <li class="pb-4" *ngFor="let card of column.subSections; let i=index">
            <span *ngIf="card.title" id="footer-{{ card.title }}-{{i}}">{{ card.title }}</span>
            <a *ngIf="card.description" (click)="redirect(card.link, card.linkType)"
              class="rs-btn-link fw-700 d-block pt-1 text-left">{{ card.description }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="version">
    <span [innerHTML]="version"></span>
  </div>
</footer>
