export const RsDataMapper = {
    'StandardField': '',
    'CustomField': '',
    'DefaultValue': '',
    'Label': '',
    'Description': ''
}

export interface DataMapper {
    StandardField: string
    CustomField: string
    DefaultValue: string
    Label: string
    Description: string
}

export interface DataSourceSetting {
    Environment: string;
    ClientId: string;
    ClientIdType: string;
    ExternalClientId: string;
    ExternalClientIdType: string;
    ExternalPayment: boolean;
    Statement: boolean;
    AccountTransaction: boolean;
    Event:boolean;
    SearchLookUpType: string;
    OrgConfiguration: OrgConfiguration[]
}

export interface OrgConfiguration {
    ClientId: string;
    OrgId: number
}
