import { Component, Input, ViewChildren, Output, EventEmitter, AfterViewInit, OnInit } from '@angular/core';
import { values, cloneDeep } from 'lodash-es';

import { RsUIConfig } from '../schema/rs-ui-config.schema';
import { RsResolveElementDirective } from '../rs-resolve/rs-resolve.directive';

/**
 * The RsUI Component is the top level component. It builds and contains all sub-components.
 *
 * @export
 * @class RsUIComponent
 */
@Component({
    selector: 'rs-ui',
    templateUrl: './rs-ui.component.html'
})
export class RsUIComponent implements OnInit, AfterViewInit{
    // ? Replace with store??
    @Input() public config: RsUIConfig;
    @Output() public elementsSet = new EventEmitter<any>();
    @ViewChildren(RsResolveElementDirective) public children;
    public elements: any;

    constructor() {
    }

    public ngOnInit() {
        if (this.config && this.config.elements) {
            // moved from elements() function, binding to a function causes rerendering since the value was always new.
            // would be better if the this.controls was already defined as an array.
            // we might by able replace this.controls with the array version
            this.elements = values(cloneDeep(this.config.elements));
        } else {
            this.elements = [];
        }
    }

    public ngAfterViewInit() {
        this.elementsSet.emit(this.children);
    }
}
