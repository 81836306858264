import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EstimateRenderData } from '@encounter';

@Component({
    selector: 'estimate-score',
    templateUrl: './estimate-score.component.html',
    styleUrls: ['../../scss/styles.scss', './estimate-score.component.scss'],
})
export class EstimateScoreComponent implements OnInit, OnChanges {
    @Input() public estimate!: EstimateRenderData;
    @Input() public estimateThresholdConfig: any;

    public gaugeValue = 0;
    public gaugeDisplayValue: string = '0';
    public gaugeThresholdConfig: any;
    public defaultThresholdConfig = {
        '0': { color: 'red' },
        '50': { color: 'orange' },
        '75.5': { color: 'green' },
    };

    constructor() {}

    public ngOnInit() {
        this.gaugeThresholdConfig = this.estimateThresholdConfig || this.defaultThresholdConfig;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.estimate?.currentValue) {
            this.gaugeDisplayValue = changes.estimate.currentValue.estimateScore;

            if (this.gaugeDisplayValue === '?') {
                this.gaugeValue = 0;
            } else {
                this.gaugeValue = parseInt(this.gaugeDisplayValue);
            }
        }
    }
}
