import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    forwardRef,
    OnDestroy,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
@Component({
    selector: 'rs-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true,
        },
    ],
})
export class DropdownComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
    @Input() label: string;
    @Input() id: string;
    @Input() items: any;
    @Input() dropdownSelect: boolean;
    @Input() wrapClass: string;
    @Input() multiple = false;
    @Input() formControl: UntypedFormControl;
    @Input() triggerWrapClass: string;
    @Input() displaySelectedLabel = false;
    @Input() hideLabel = false;
    @Input() headerDropdown: boolean;
    // tslint:disable-next-line:no-output-native
    @Output() select = new EventEmitter<any>();
    @Output() toggle = new EventEmitter<boolean>();
    isToggled = false;
    isDisabled = false;
    value;
    private selected;
    private cb: (_: any) => any;
    private cbBlur: () => any;
    private subscription: Subscription;

    ngOnInit() {
        if (this.multiple) {
            this.initForMultiple();
        } else {
            this.initForSingle();
        }
    }
    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.items && changes.items.currentValue && this.value !== undefined) this.selectFromValue();
    }

    get dropdownValue() {
        if (this.multiple) {
            let val = this.label;
            if (this.displaySelectedLabel) {
                val = this.selected.map((item) => item.label).join(', ');
            } else {
                val = this.value.join(', ');
            }
            return val;
        }
        return this.displaySelectedLabel && this.selected ? this.selected.label : this.value;
    }

    initForMultiple() {
        this.value = [];
        if (this.formControl) {
            this.value = this.formControl.value || [];
            if (this.value && this.items) this.selectFromValue();
            this.subscription = this.formControl.valueChanges
                .pipe(filter((val) => this.arraysEqual(val, this.value)))
                .subscribe((val) => {
                    this.value = val || [];
                    this.selectFromValue();
                });
        }
    }

    initForSingle() {
        if (this.formControl) {
            this.value = this.formControl.value;
            if (this.value && this.items) this.selectFromValue();
            this.subscription = this.formControl.valueChanges
                .pipe(filter((val) => val !== this.value))
                .subscribe((val) => {
                    this.value = val;
                    this.selectFromValue();
                });
        }
    }

    selectFromValue(): any[] {
        if (!this.items) return;
        if (this.multiple) {
            const value = this.value || [];
            this.selected = this.items.filter((item) => value.includes(item.value)).map((item) => item);
        } else {
            this.selected = this.items.find((item) => isEqual(item.value, this.value)) || this.value;
        }
        return this.selected;
    }

    writeValue(value: any): void {
        this.value = value;
        this.selectFromValue();
    }

    registerOnChange(fn: any): void {
        this.cb = fn;
    }

    registerOnTouched(fn: any): void {
        this.cbBlur = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    selectSingle(item) {
        this.value = item.value;
        this.isToggled = false;
    }

    selectMultiple(item) {
        if (!this.value) this.value = [];
        const found = this.value.findIndex((val) => val === item.value);
        if (found !== -1) {
            this.value.splice(found, 1);
        } else {
            this.value.push(item.value);
        }
    }

    onSelect(event: Event, item: any, index: number) {
        if (this.isDisabled) return;
        this.multiple ? this.selectMultiple(item) : this.selectSingle(item);
        this.selectFromValue();
        if (this.cb) this.cb(this.value);
        if (this.formControl) this.formControl.setValue(this.value);
        this.select.emit(this.value);

        event.preventDefault();
        event.stopPropagation();
    }

    onClick(event: Event) {
        console.log('clicked dropdown');
        if (this.isDisabled && !this.isToggled) return;
        this.isToggled = !this.isToggled;
        this.toggle.emit(this.isToggled);

        event.preventDefault();
        event.stopPropagation();
    }

    show() {
        this.isToggled = true;
    }

    close() {
        this.isToggled = false;
    }

    onClickOutside(event: Event) {
        if (this.cbBlur) this.cbBlur();
        this.isToggled = false;
        this.toggle.emit(this.isToggled);

        event.preventDefault();
        event.stopPropagation();
    }

    arraysEqual(_arr1, _arr2) {
        if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) return false;
        const arr1 = _arr1.concat().sort();
        const arr2 = _arr2.concat().sort();
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }
        return true;
    }
}
