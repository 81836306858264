import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'rs-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() public headerData: any;
  public currentLanguage: string;
  public languageLabel = 'Languages';

  public languageSelect = [
    {
      value: 0,
      label: 'English',
    },
    {
      value: 1,
      label: 'Spanish',
    }
  ];

  constructor(private readonly router: Router) { }

  public ngOnInit() { }

    public changeLanguage(lang) {
    this.languageLabel = this.languageSelect[lang].label;
  }

  public redirect(link: string): void {
    // If dummy link then append /#
    if (link.includes('#')) {
      window.location.href = `${window.location.href}/${link}`;
    } else {
      this.router.navigate(['external-redirect', { externalUrl: link }], { skipLocationChange: true });
    }
  }

}
