import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { TranslationEffects } from './effects';
import { TranslationReducer } from './reducer';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        StoreModule.forFeature('translation', TranslationReducer),
        EffectsModule.forFeature([TranslationEffects]),
    ],
    providers: [],
})
export class TranslationStoreModule {}
