import { AfterContentInit, ChangeDetectionStrategy, EventEmitter, OnInit, Output } from '@angular/core';
import { Component, HostListener, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { delay, endWith, filter, switchMap, tap } from 'rxjs/operators';

import { HeaderDropdownOption } from '@revspringinc/shared';

import { BaseComponent } from '../../base-component';
import { SidenavService } from '../sidenav/sidenav.service';
import { HeaderService } from './header.service';
import { ErrorHandlerService, PortalError } from '../../../services/error-handler.service';
import { LoadingService } from '../../../services/loading.service';

@Component({
    selector: 'pa-header',
    templateUrl: './header.component.html',
    styleUrls: ['../../../../../scss/styles.scss'],
    styles: [':host { display: contents; }'],
})
export class HeaderComponent extends BaseComponent implements OnInit, AfterContentInit {
    @Input() public paymentFlow: boolean | undefined;
    @Input() public headerPadding: boolean | undefined;
    @Input() public dropdownOptions: HeaderDropdownOption[] | undefined;

    public renderingForms: boolean | undefined;
    public scrolled = false;
    public loading = false;
    public error: PortalError | null = null;

    constructor(
        public readonly headerService: HeaderService,
        public readonly sidenavService: SidenavService,
        public readonly loadingService: LoadingService,
        public readonly errorHandler: ErrorHandlerService,
        translate: TranslateService,
    ) {
        super(translate);
    }

    public closeError(): void {
        this.errorHandler.clear();
    }

    public ngAfterContentInit(): void {
        this.headerService.headerState$
            .pipe(filter((x) => !!x))
            .subscribe((x) => (this.renderingForms = x.state.renderForms));
    }

    public ngOnInit(): void {
        this.loadingService.loadingSubscription
            // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
            .pipe(delay(0))
            .subscribe((loading) => {
                this.loading = loading;
            });

        this.errorHandler.errors$
            // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
            .pipe(
                delay(0),
                tap((errors) => (this.error = errors)),
                switchMap(() => this.errorHandler.countdown(10).pipe(endWith(null))),
            )
            .subscribe((errors) => {
                if (errors) {
                    if (this.error) {
                        this.error.remaining = errors.display;
                    }
                } else {
                    this.errorHandler.clear();
                }
            });
    }

    @HostListener('window:scroll', [])
    private onScroll(): void {
        const scrollPosition =
            window.scrollY ||
            window.pageYOffset ||
            document.body.scrollTop + ((document.documentElement && document.documentElement.scrollTop) || 0);

        if (scrollPosition > 0) {
            this.scrolled = true;
        } else {
            this.scrolled = false;
        }
    }
}
