<rs-modal #modal (show)="show()" [config]="modalConfig" (cancel)="cancel()">
  <div *ngIf="config">
    <div class="main-title-section">
      <h2 class="title">{{ config.title | translate }}</h2>
    </div>
    <div *ngFor="let group of config.groups">
      <div class="group-title-section">
        <h4>{{ group.title | translate }}</h4>
      </div>
      <div *ngFor="let paragraph of group.paragraphs">
        <p>{{ paragraph.value | translate }}</p>
      </div>
      <div *ngFor="let subsection of group.subsections">
        <div class="subtitle-section">
          <h5>{{ subsection.title | translate }}</h5>
        </div>
        <div *ngFor="let subParagraph of subsection.paragraphs">
          <p>{{ subParagraph.value | translate }}</p>
        </div>
      </div>
      <hr>
    </div>
  </div>
</rs-modal>
