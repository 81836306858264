import { each } from 'lodash-es'
import { AbstractControl } from '@angular/forms';

export class Registry<T> {
  private label: string
  private items: Map<string, T> = new Map()

  constructor(label: string) {
    this.label = `${label}Registry`
  }

  add(name: string, item: T): this {
    if (this.exists(name)) {
      throw new Error(
        `${name} already exists in ${
          this.label
        }, did you intend to override it?`
      )
    }

    this.items.set(name, item)

    return this
  }

  override(name: string, item: T): this {
    this.items.set(name, item)

    return this
  }

  load(list: ItemList<T>): this {
    each(list, (item, name) => {
      this.add(name, item)
    })

    return this
  }

  overrideEntries(list: ItemList<T>) {
    each(list, (item, name) => {
      this.override(name, item)
    })

    return this
  }

  exists(name: string) {
    return this.items.has(name)
  }

  isMissing(name: string) {
    return !this.items.has(name)
  }

  entries() {
    return Array.from(this.items.entries())
  }

  resolve(name: string) {
    if (this.isMissing(name)) {
      console.warn(`${name} is missing in ${this.label}, returing the default function`)
      return (control?: AbstractControl) => {
        console.warn(control);
        return null;
      };
    }

    return this.items.get(name)
  }
}

interface ItemList<T> {
  [name: string]: T
}
