import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';

import { logInfo } from './utils/log';
import { LayoutModule } from './components/layout/layout.module';
import { HeaderModule } from './components/layout/header/header.module';
import { AlertComponent } from './components/alert/alert.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { LangPipe, SafeUrlPipe, OrderByPipe } from './pipes';

import { AfterValueChangedDirective } from './directives/after-value-changes.directive';
import { MarkdownModule } from 'ngx-markdown'

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        NgxMaskModule.forChild(),
        LayoutModule,
        HeaderModule,
        ToastrModule,
        MarkdownModule
    ],
    declarations: [
        AlertComponent,
        SpinnerComponent,

        // Pipes
        LangPipe,
        SafeUrlPipe,
        OrderByPipe,

        // Directives
        AfterValueChangedDirective,
    ],
    exports: [
        LayoutModule,
        HeaderModule,
        AlertComponent,
        SpinnerComponent,

        // Pipes
        LangPipe,
        SafeUrlPipe,
        OrderByPipe,

        // Directives
        AfterValueChangedDirective,
    ],
})
export class PaUiModule {
    constructor() {
        logInfo('PaUiModule', 'Initialized');
    }
}
