import { RsGridState, RsGridInitialState } from './rs-grid.state'
import { RsGridActions } from './rs-grid.actions'
import { RsGridMutations } from './rs-grid.mutations'

export interface RsAction<T = any> {
  type: string
  payload: T
}

export function RsGridReducer(
    state: RsGridState = RsGridInitialState,
    action: RsAction
  ): RsGridState {

  switch (action.type) {
    case RsGridActions.loadConfig: {
      return RsGridMutations.loadConfig(state, action.payload)
    }

    case RsGridActions.loadData: {
      return RsGridMutations.loadData(state, action.payload)
    }

    case RsGridActions.sortOnColumn: {
      return RsGridMutations.sortOnColumn(state, action.payload)
    }

    case RsGridActions.filterAcrossAllColumns: {
      return RsGridMutations.filterAll(state, action.payload)
    }

    case RsGridActions.filterOnColumn: {
      return RsGridMutations.filterOnColumn(state, action.payload)
    }

    case RsGridActions.goToPage: {
      return RsGridMutations.goToPage(state, action.payload)
    }

    case RsGridActions.changeItemsPerPage: {
      return RsGridMutations.changeItemsPerPage(state, action.payload)
    }

    case RsGridActions.exportCollection: {
      return RsGridMutations.exportCollection(state, action.payload)
    }

    case RsGridActions.expandRow: {
      return RsGridMutations.expandRow(state, action.payload)
    }

    case RsGridActions.showRow: {
      return RsGridMutations.showRow(state, action.payload)
    }

    default: {
      return state
    }

  }
}
