import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { logInfo } from '@pa-ui';
import { EstimateRenderData, ApolloAngularSDK } from '@encounter';
import * as EstimateActions from './estimate.actions';

@Injectable()
export class EstimateEffects {
    constructor(private actions$: Actions, private readonly encounterServices: ApolloAngularSDK) {
        logInfo('EstimateEffects', 'created');
    }

    loadEstimate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EstimateActions.LoadEstimateRequest),
            switchMap((action) =>
                this.encounterServices
                    .renderEstimate(
                        {
                            externalReferenceId: action.externalReferenceId,
                            estimateId: action.estimateId,
                            includePdf: action.includePdf,
                        },
                        { fetchPolicy: 'network-only' },
                    )
                    .pipe(
                        map((response) => {
                            if (!response) {
                                return EstimateActions.LoadEstimateFailure({
                                    error: 'Unable to communicate with the estimate api',
                                });
                            }

                            return EstimateActions.LoadEstimateSuccess({
                                estimate: response?.data?.renderEstimate as EstimateRenderData,
                            });
                        }),
                        catchError((error) => {
                            if (action.includePdf) {
                                // Try to load the estimate without the pdf
                                return of(EstimateActions.LoadEstimateRequest({ ...action, includePdf: false }));
                            }

                            return of(EstimateActions.LoadEstimateFailure({ error: error?.message || error }));
                        }),
                    ),
            ),
        );
    });

    estimateUpdated$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EstimateActions.EstimateSubscriptionRequest),
            switchMap((action) =>
                this.encounterServices.estimateUpdated({ externalReferenceId: action.externalReferenceId }).pipe(
                    map((response) => {
                        if (!response) {
                            return EstimateActions.EstimateUpdatedFailure({
                                error: 'Unable to communicate with the estimate subscription',
                            });
                        }

                        return EstimateActions.EstimateUpdatedSuccess({
                            externalReferenceId: action.externalReferenceId,
                            estimate: response.data?.estimateUpdated?.estimate as EstimateRenderData,
                        });
                    }),
                    catchError((error) =>
                        of(EstimateActions.EstimateUpdatedFailure({ error: error?.message || error })),
                    ),
                ),
            ),
        );
    });
}
