import { Component, Input, SimpleChanges } from '@angular/core';
import { EstimateRenderData } from '@encounter';
import { EstimateConfigModel } from '../../../models/estimateConfigModel';
import { Alert } from '@pa-ui';

@Component({
    selector: 'estimate-default-renderer',
    templateUrl: './default-estimate-renderer.component.html',
    styleUrls: ['../../../scss/styles.scss'],
})
export class DefaultEstimateRendererComponent {
    estimateConfig: EstimateConfigModel | undefined;
    disclaimerAlert: Alert | undefined;

    @Input()
    public estimate: EstimateRenderData | undefined;

    @Input() public loading: boolean | undefined;
    @Input() public estimateDisclaimerAcknowledged: Date | undefined;

    public showDetails: boolean = false;
    public canShowDetails: boolean = true;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['estimate']) {
            this.estimateConfig = this.estimate?.estimateConfig?.estimate || this.estimate;

            this.disclaimerAlert = {
                icon: 'icon-alert',
                priority: 'warning',
                message: this.estimateConfig?.disclaimer || '',
                title: 'Disclaimer',
            };

            this.showDetails = this.estimateConfig?.settings?.details?.visible === 'expanded' || false;
            this.canShowDetails = this.estimateConfig?.settings?.details?.visible !== 'hidden';
        }
    }

    public startsWith(value: string, key: string): boolean {
        return value ? value.startsWith(key) : false;
    }

    public toText(value: any) {
        return value.toString().replace(/\$/g, '');
    }

    toggleDetails() {
        this.showDetails = !this.showDetails;
    }
}
