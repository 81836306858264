import { Directive, ViewContainerRef, OnInit, ComponentFactoryResolver, Input } from '@angular/core';
import { ComponentRegistryService } from '../services/component-registry.service';
import { FormConfig } from '../services/forms.service';
import { RsForm } from '../models/form.model';
import { RsFormComponent } from '../components/form/form.component';

@Directive({
  selector: '[rsForm]',
})
export class RsFormDirective {
  private component: RsFormComponent;

  constructor(
    public viewContainerRef: ViewContainerRef,
    private componentResolver: ComponentFactoryResolver,
  ) { }

  @Input() set rsForm(config: RsForm) {
    const component = RsFormComponent;
    if (!component) throw new Error();
    const factory = this.componentResolver.resolveComponentFactory<RsFormComponent>(component);
    const componentRef = this.viewContainerRef.createComponent<RsFormComponent>(factory);
    this.component = componentRef.instance;
    this.component.elements = Object.values(config.model);
    this.component.submitButtonVisible = config.submitButtonVisible;
    this.component.buttonText = config.buttonText;
    this.component.theme = config.theme;

    // Object.keys(config.model).forEach((key) => {
    //   const el = config.model[key];
    //   const factory = this.resolve(el.type);
    //   const componentRef = this.viewContainerRef.createComponent<any>(factory);
      // componentRef.instance
    // });
    // const targetComponent = this.componentRegistry.resolve(this.element.type);
    // const componentFactory = this.resolver.resolveComponentFactory(
    //   targetComponent
    // );
    // const component = this.container.createComponent(componentFactory);
    // component.instance['element'] = this.element;
    // this.component = component;
  }

  private resolve(name: string) {
  }
}
