<div class="py-5 bg-white">
  <div class="container max-w-6xl mx-auto">
    <h3 *ngIf="hasEstimated"
        class="pb-5 text-xl text-center">{{ estimate.estimateNumber | translate }}</h3>
    <div class="relative flex flex-col flex-wrap justify-center px-3 md:flex-row">
      <button *ngFor="let action of estimate?.actions"
              (click)="toEstimateActions(action)"
              class="flex flex-col items-center justify-between w-full p-12 mb-4 sm:w-1/4 rs-btn-link action-item ">
        <div class="flex justify-center w-full pb-12 text-center">
          <img [src]="toActionIcon(action.icon)"
               alt=""
               width="60">
        </div>
        <div class="items-end w-full h-full pb-6 text-lg text-center">
          <div class="w-full ">
            {{ action.label | translate }}
          </div>
        </div>

      </button>
    </div>
  </div>
</div>
