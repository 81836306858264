<div class="form-group" [formGroup]="group">
  <div  >
    <label [attr.for]="element.elementOptions.id" [ngClass]="element?.theme?.labelClass" *ngIf="element.elementOptions.label">
      {{ element.elementOptions.label }}
      <b *ngIf="element.validations?.required" style="color:red">*</b>
      <span *ngIf="element?.tooltip?.display">
        <i class="fa fa-question-circle" aria-hidden="true" placement="right" ngbTooltip="{{element.tooltip.text || 'right'}}"></i>
      </span> :
    </label>
  </div>
    <div class="input-group"  [ngClass]="element?.theme?.childLabel">
    <input class="form-control" ngbDatepicker #d="ngbDatepicker" formControlName="control" [minDate]="min" [maxDate]="max">
    
      <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
        <img src="assets/calendar-icon.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;" />
      </button>

  </div>
  <div class="alert alert-danger" role="alert" *ngIf="element.group.controls[this.element['name']].dirty && !element.group.controls[this.element['name']].pristine &&element.group.controls[this.element['name']].errors">
    <rs-error-message [fieldForm]="element.group.controls[this.element['name']]" [field]="element"></rs-error-message>
  </div>
</div>
