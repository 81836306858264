import {
    createFeatureSelector,
    createSelector,
    MemoizedSelector
} from '@ngrx/store';

import { GlobalOrganization, State } from './state';

const getError = (state: State): any => state.error;
const getIsLoading = (state: State): boolean => state.isLoading;
const getActiveOrganization = (state: State): GlobalOrganization | null => state.activeOrganization;

export const selectOrganizationState: MemoizedSelector<object, State> = createFeatureSelector<State>('global-organization');

export const selectOrganizationError: MemoizedSelector<object, string> = createSelector(
    selectOrganizationState,
    getError
);

export const selectOrganizationIsLoading: MemoizedSelector<object, boolean> = createSelector(
    selectOrganizationState,
    getIsLoading
);

export const selectActiveOrganization = createSelector(
    selectOrganizationState,
    getActiveOrganization
);

export const hasOrganizationSelected = createSelector(
    selectOrganizationState,
    state => !!state.activeOrganization
);
