import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { EstimateRenderData } from '@encounter';
import { EstimateSection } from '../../models/estimate-section';

@Component({
    selector: 'estimate',
    templateUrl: './estimate.component.html',
    styleUrls: ['../../scss/styles.scss'],
    styles: [
        `
            :host {
                display: contents;
            }
        `,
    ],
})
export class EstimateComponent implements OnInit, OnChanges {
    @Input() public loading: boolean = true;
    @Input() public estimate!: EstimateRenderData;
    @Input() public estimateDisclaimerAcknowledged?: Date;
    @Input() public estimateChange: ((estimate: EstimateRenderData) => Promise<boolean>) | undefined;
    @Input() public appName!: 'patientPortal' | 'pricing' | 'registrar';

    public headerSections!: EstimateSection[];
    public disclaimerSections?: EstimateSection[];
    public additionalSections?: EstimateSection[];

    ngOnInit(): void {
        this.loading = false;

        this.createSections();
    }

    ngOnChanges() {
        this.createSections();
    }

    private createSections() {
        // if (this.estimate?.deltas && this.estimate?.deltas.length > 0) {
        //     this.estimate = EstimateRenderConfigDelta.patch(this.estimate, this.estimate.deltas);
        // }
        // this.headerSections = this.estimate?.headerSections?.map((section, index) => EstimateSection.create(section, ["headerSections", index.toString()], this.estimate.variables)
        // ) || [];
        // this.additionalSections = this.estimate?.additionalSections?.map((section, index) => EstimateSection.create(section, ["additionalSections", index.toString()], this.estimate.variables)
        // ) || [];
        // this.disclaimerSections = this.estimate?.disclaimerSections?.map((section, index) => EstimateSection.create(section, ["disclaimerSections", index.toString()], this.estimate.variables)
        // ) || [];
    }
}
