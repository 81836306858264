<div class="relative ml-6">
    <div *ngIf="service?.value">
        <div class="absolute inset-0 flex items-center"
             aria-hidden="true">
            <div class="w-full border-t border-gray-600"></div>
        </div>
        <div class="relative flex items-center justify-between">
            <span class="pr-3 text-xs bg-white md:text-base"
                  [ngClass]="getServiceClass()">
                {{ service.label | translate }}
            </span>
            <div
                 class="inline-flex justify-between shadow-sm px-4 py-1.5 border-gray-600  md:text-base text-xs leading-5 font-medium rounded-sm bg-white md:w-60">

                <span *ngIf="startsWith((service.value | translate), '$')"
                      class="text-gray-700">$</span>

                <!-- Heroicon name: solid/check -->
                <svg class="-ml-1.5 mr-1 h-5 w-5 text-green-500"
                     *ngIf="!startsWith((service.value | translate), '$')"
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7" />
                </svg>


                <span [ngClass]="getServiceClass()">
                    {{ toText(service.value | translate)}}
                </span>
            </div>
        </div>
    </div>

    <div class="relative"
         *ngIf="!service?.value"
         [ngClass]="service?.class">
        <div class="absolute inset-0 flex items-center"
             aria-hidden="true">
            <div class="w-full border-t border-gray-600"></div>
        </div>
        <div class="relative flex justify-center">
            <div class="inline-flex items-center px-4 py-1.5  text-sm leading-5 font-medium bg-white">
                <span> {{ service?.label | translate }}</span>
            </div>
        </div>
    </div>
</div>
