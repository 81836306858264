import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';

import { HeaderContentContainerComponent } from './header-content-container.component';
import { HeaderComponent } from './header.component';
import { HeaderTitleComponent } from './header-title/header-title.component';
import { SidenavModule } from '../sidenav/sidenav.module';
import { LoadingInterceptor } from '../../../services/loading.interceptor';
import { MobileMenuButtonComponent } from './menu-button/mobile-menu-button.component';
import { HeaderDropdownComponent } from './header-dropdown/header-dropdown.component';

@NgModule({
    declarations: [HeaderComponent, HeaderContentContainerComponent, HeaderTitleComponent, HeaderDropdownComponent, MobileMenuButtonComponent],
    imports: [CommonModule, SidenavModule, TranslateModule],
    exports: [HeaderComponent, HeaderContentContainerComponent, HeaderTitleComponent, HeaderDropdownComponent, MobileMenuButtonComponent],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }],
})
export class HeaderModule {}
