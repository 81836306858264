<div class="w-full min-w-full transition-all shadow-th-card font-body">
    <estimate-section [estimate]="estimate"
                      [sections]="headerSections"
                      [estimateChange]="estimateChange"></estimate-section>

    <pdf-viewer #viewer
                [show-all]="true"
                [autoresize]="true"
                [original-size]='false'
                [fit-to-page]='true'
                [src]="src"
                [render-text]="true"
                (after-load-complete)="onLoaded($event)"
                (pages-initialized)="onPagesInitialized($event)"
                (page-rendered)="pageRendered('page-rendered', $event)"></pdf-viewer>

    <!-- <div *ngFor="let serviceGroup of estimate?.serviceGroups; let first = first"
         [ngClass]="{ hidden: serviceGroup.groups?.length === 0, 'mt-4': !first }">

        <service-group-header [serviceGroup]="serviceGroup"></service-group-header>

        <div *ngFor="let providerGroup of serviceGroup.providerGroups; let last = last"
             [ngClass]="{ 'mb-6': !last }"
             class="p-4 mb-3 border border-opacity-50 print:provider-group md:p-12 shadow-th-card">

            <provider-group-header [providerGroup]="providerGroup"></provider-group-header>
            <provider-group-services [providerGroup]="providerGroup"></provider-group-services>
            <provider-group-total [providerGroup]="providerGroup"></provider-group-total>
        </div>

        <service-group-total [serviceGroup]="serviceGroup"></service-group-total>

    </div> -->

    <estimate-section [estimate]="estimate"
                      [sections]="additionalSections"
                      [estimateChange]="estimateChange"></estimate-section>

    <estimate-section [estimate]="estimate"
                      [sections]="disclaimerSections"
                      [estimateChange]="estimateChange"></estimate-section>

    <ng-container *ngIf="showDownloadToolbar">
        <div class="flex justify-end w-full space-x-2 bg-gray-400 divide-x rounded-b-lg p1">
            <button type="button"
                    class="relative inline-flex items-center px-2 py-2 text-sm font-medium border border-gray-300 rounded-l-md text-th-primary hover:bg-th-primary hover:text-white focus:z-10 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                    id="download"
                    (click)="download()">
                <svg xmlns="http://www.w3.org/2000/svg"
                     class="w-4 h-4"
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke="currentColor"
                     stroke-width="2">
                    <path stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                </svg>
            </button>


        </div>
    </ng-container>

</div>
