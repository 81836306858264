import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { LanguageModel, TranslationState } from './state';

export const selectTranslationState: MemoizedSelector<object, TranslationState> =
    createFeatureSelector<TranslationState>('translation');

export const selectTranslationConfigs: MemoizedSelector<object, LanguageModel[]> = createSelector(
    selectTranslationState,
    (state: TranslationState) => state.configs,
);

export const selectTranslationLanguage: MemoizedSelector<object, string> = createSelector(
    selectTranslationState,
    (state: TranslationState) => state.language,
);
