import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { cloneDeep } from 'lodash-es'
import { RsFormActions } from './rs-form.actions';
import { RsFormState } from './rs-form.state';

@Injectable()
export class RsFormDispatcher {
  constructor(private store: Store<RsFormState>) { }

  // TODO: Rename to form value?
  syncFormModel<T = any>(formName: string, formModel: T) {
    formModel = cloneDeep(formModel)

    this.store.dispatch({
      type: RsFormActions.syncFormModel,
      payload: {
        formName,
        formModel
      }
    })
  }

  saveEvent() {
    //Dispatchers are an entry point for your application to dispatch your action in the store directly
    this.store.dispatch({
        type: RsFormActions.saveEvent
    })
}

  // TODO: Rename to form value?
  // TODO: Do we need this? Should we use it strictly for effects or...
  submitForm<T = any>(formName: string, formModel: T) {
    formModel = cloneDeep(formModel)

    this.store.dispatch({
      type: RsFormActions.submitForm,
      payload: {
        formName,
        formModel
      }
    })
  }
}
