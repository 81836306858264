import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, State, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import * as HydrationActions from './actions';

@Injectable()
export class HydrationEffects implements OnInitEffects {
    public hydrate$ = createEffect(() =>
        this.action$.pipe(
            ofType(HydrationActions.HydrateRequest),
            map(() => {
                const storageValue = localStorage.getItem('state');
                if (storageValue) {
                    try {
                        const state = JSON.parse(storageValue);

                        return HydrationActions.HydrateSuccess({ state });
                    } catch {
                        localStorage.removeItem('state');

                        return HydrationActions.ClearState({ failure: true });
                    }
                }

                return HydrationActions.HydrateFailure();
            }),
        ),
    );

    public serialize$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(HydrationActions.HydrateSuccess, HydrationActions.HydrateFailure),
                switchMap(() => this.store),
                distinctUntilChanged(),
                tap((state) => localStorage.setItem('state', JSON.stringify(state))),
            ),
        { dispatch: false },
    );

    public clear$ = createEffect(() =>
        this.action$.pipe(
            ofType(HydrationActions.ClearState),
            tap(() => localStorage.removeItem('state')),
            filter((action) => action.failure ?? false),
            switchMap(() => of(HydrationActions.HydrateFailure())),
        ),
    );

    constructor(private readonly action$: Actions, private readonly store: Store<State<unknown>>) {}

    public ngrxOnInitEffects(): Action {
        // Run the hydrate effect on init
        return HydrationActions.HydrateRequest();
    }
}
