import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { RsGridState } from './rs-grid.state'
import { RsGridSelectors } from './rs-grid.selectors'
import { RsGridActions } from './rs-grid.actions'
import { RsGridDispatcher } from './rs-grid.dispatcher'
import { RsGridReducer } from './rs-grid.reducer'
import { RsState } from '../rs.state'

@Injectable()
export class RsGridStore {
  dispatcher: RsGridDispatcher
  selectors: RsGridSelectors

  constructor(private store: Store<RsState>) {
    this.dispatcher = new RsGridDispatcher(store)
    this.selectors  = new RsGridSelectors(store)
  }
}

export {
  RsGridActions,
  RsGridDispatcher,
  RsGridSelectors,
  RsGridState,
  RsGridReducer
}

