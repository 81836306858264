import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { BaseComponent } from '../../../base-component';
import { HeaderService } from '../header.service';

@Component({
    templateUrl: './header-title.component.html',
})
export class HeaderTitleComponent extends BaseComponent {

    constructor(
        public readonly headerService: HeaderService,
        translate: TranslateService) {
        super(translate);
    }
}
