import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EstimateSelfpayDiscount } from '../../models/estimate-selfpay-discount';
import { orderBy } from 'lodash';

@Component({
    selector: 'selfpay-discount-total',
    templateUrl: './selfpay-discount-total.component.html',
    styleUrls: ['../../scss/styles.scss'],
})
export class SelfpayDiscountTotalComponent implements OnChanges {
    @Input() selfpayDiscounts?: EstimateSelfpayDiscount[];
    public discounts?: EstimateSelfpayDiscount[];

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selfpayDiscounts) {
            this.discounts = orderBy(this.selfpayDiscounts, 'accountSelfpay.priority');
        }
    }
}
