import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';

import {
    NavigateUrlAction,
    NavigateRouteAction,
    NavigateToPreviousPage,
    NavigateExternalAction
} from './actions';


@Injectable()
export class RouterEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private location: Location,
    ) { }

    navigateUrl$ = createEffect(
        () => this.actions$.pipe(
            ofType(NavigateUrlAction),
            map((action) => {
                if (action.payload) {
                    this.router.navigateByUrl(action.payload);
                }
            })
        ), { dispatch: false }
    );

    navigateRoute$ = createEffect(
        () => this.actions$.pipe(
            ofType(NavigateRouteAction),
            map((action) => {
                this.router.navigate(action.commands, action.extras);
            })
        ), { dispatch: false }
    );

    navigateToPreviousPage$ = createEffect(
        () => this.actions$.pipe(
            ofType(NavigateToPreviousPage),
            map(() => this.location.back())
        ), { dispatch: false }
    );

    navigateExternal$ = createEffect(
        () => this.actions$.pipe(
            ofType(NavigateExternalAction),
            map((action) => {
                if (action.url) {
                    window.location.href = action.url;
                }
            })
        ), { dispatch: false }
    );
}
