import {
    Directive,
    ViewContainerRef,
    Input,
    HostListener,
    ElementRef,
    Output,
    EventEmitter
} from '@angular/core';

import { ModalService } from '../services/modal.service';

@Directive({
    selector: '[rsModalTarget]',
})
export class RsModalTargetDirective {
    @Output() public onClose = new EventEmitter<any>();
    @Input() public payload?: any;
    private name: string;

    constructor(
        public viewContainerRef: ViewContainerRef,
        private modalService: ModalService,
        private el: ElementRef,
    ) {
    }

    @Input() set rsModalTarget(name: string) {
        this.name = name;
    }

    @HostListener('click') public onClick() {
        if (!this.name) {
            return;
        }

        this.modalService.show(this.name, this.payload).subscribe((payload?: any) => {
            this.onClose.emit(payload);
            this.el.nativeElement.focus();
        });
    }
}
