<rs-modal #modal (show)="show($event)" [config]="modalConfig" (submit)="send()">
  <div *ngIf="config && mainForm">
    <div class="container p-3">
      <form [formGroup]="form" (ngSubmit)="send()">

        <div class="flex-grow-1">
          <div class="container">
            <h3 *ngIf="config.form.cards[0].groups[0].title" class="fs-20 pb-2 fw-700">
              {{ config.form.cards[0].groups[0].title | translate }}</h3>
            <rs-ui [config]="mainForm" #resolver></rs-ui>
            <hr />
            <div class="container">
              <p class="fs-14 fw-700">
                {{config.form.cards[0].groups[1].title | translate}}
              </p>
              <small class="fs-11">
                {{ config.form.cards[0].groups[2].title | translate}}
              </small>
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>
</rs-modal>