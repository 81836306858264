<fieldset>
  <rs-tabs
    #staticTabs
    (getActiveTab)="changeElement($event)"
    css="rs-tabs">
    <ng-container *ngFor="let control of controlsArray; let i = index">
      <rs-tab [tabTitle]="control.elementOptions.label | translate">
        <div class="form-group">
          <ng-container rsResolve [element]="control" *ngIf="i === index"></ng-container>
        </div>
      </rs-tab>
    </ng-container>
  </rs-tabs>
</fieldset>