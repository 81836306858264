import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { EstimateConfigModel } from '../../../models/estimateConfigModel';
import { EstimateSection } from '../../../models/estimate-section';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
    selector: 'estimate-attachment-renderer',
    templateUrl: './attachment-estimate-renderer.component.html',
    styleUrls: ['../../../scss/styles.scss'],
    styles: [
        `
            :host {
                display: contents;
            }
            pdf-viewer {
                height: 100vh;
                position: relative;
            }
            .ng2-pdf-viewer-container {
                overflow: hidden;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        `,
    ],
})
export class AttachmentEstimateRendererComponent {
    @Input() public estimateSections: EstimateSection[] | undefined;
    @Input() public estimate: EstimateConfigModel | undefined;
    @Input() public loading: boolean | undefined;

    @ViewChild('viewer', { read: ElementRef, static: false })
    private pdfComponent: ElementRef | undefined = undefined;
    private pdfDocumentProxy: PDFDocumentProxy | undefined = undefined;

    public page: number = 1;
    public totalPages: number = 0;
    public height: string = '';

    public nextPage() {
        let page = this.page;

        page++;

        this.page = page;
    }

    public previousPage() {
        let page = this.page;

        page--;

        if (page < 1) {
            page = 1;
        }

        this.page = page;
    }

    public async download() {
        const createSaveLink = (function () {
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');

            return function (data: Blob, fileName: string) {
                const blob = new Blob([data], { type: 'octet/stream' });
                const url = window.URL.createObjectURL(blob);

                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
            };
        })();

        const pdfData = await this.pdfDocumentProxy?.getData();

        if (pdfData) {
            let blob = new Blob([pdfData.buffer], {
                type: 'application/pdf',
            });

            createSaveLink(blob, 'estimate' + this.estimate?.estimateNumber + '.pdf');
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.estimate?.currentValue) {
            this.loading = false;
        }
    }

    public toText(value: any) {
        return value.toString().replace(/\$/g, '');
    }

    public startsWith(value: string, key: string): boolean {
        return value ? value.startsWith(key) : false;
    }

    public pageRendered(type: string, event: any): void {
        this.loading = true;

        if (type === 'page-rendered') {
            this.loading = false;

            this.totalPages += 1;
            this.height = `${event.source.height + 32}px`;

            if (this.pdfComponent) {
                this.pdfComponent.nativeElement.style.height = this.height;
                this.pdfComponent.nativeElement.getElementsByClassName('ng2-pdf-viewer-container')[0].style.height =
                    this.height;
            }
        }
    }

    public onLoaded(pdf: PDFDocumentProxy) {
        this.pdfDocumentProxy = pdf;
    }

    public onPagesInitialized(event: any) {}
}
