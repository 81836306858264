export enum RsGridActions {
  loadConfig = '[rs-grid] loadConfig',
  loadData = '[rs-grid] loadData',
  sortOnColumn = '[rs-grid] sortOnColumn',
  filterOnColumn = '[rs-grid] filterOnColumn',
  filterAcrossAllColumns = '[rs-grid] filterAcrossAllColumns',
  paginateCollection = '[rs-grid] paginateCollection',
  goToPage = '[rs-grid] goToPage',
  changeItemsPerPage = '[rs-grid] changeItemsPerPage',
  expandRow = '[rs-grid] expandRow',
  showRow = '[rs-grid] showRow',
  exportCollection = '[rs-grid] exportCollection',
}
