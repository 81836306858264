
export enum ShoppingActivityType {
  Landing,
  EstimateServices,
  AddInsurancePlan,
  CompareCosts,
  SelectLocation,
  SelectProcedures,
  SelectServices,
  None,
  PageNotFound,
  ExternalRedirect,
  ContactMeAction,
  TextEstimateSummaryAction,
  FAQ,
  NoticeMessage,
  CookieDisclaimer,
  AmaNotice,
}
