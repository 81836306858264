/**
 * A single tab page. It renders the passed template
 * via the @Input properties by using the ngTemplateOutlet
 * and ngTemplateOutletContext directives.
 */

import { Component, Input, NgModule, OnInit } from '@angular/core';

export interface TabChanged {
    tabChanged(index: number): void;
}

@Component({
    selector: 'rs-tab',
    templateUrl: './tab.component.html',
})
export class TabComponent implements OnInit {
    @Input() tabTitle: string;
    @Input() additionalSpans?: { class: string; innerHtml?: string }[];
    @Input() uniqueIdentifier?: number | string;
    @Input() active = false;

    public loaded = false;

    public ngOnInit() {
        this.loaded = true;
    }
}
