export const DATE_FORMAT = 'MM/dd/YYYY';
export const DATE_FORMAT_HTML = 'MM/dd/yyyy';
export const DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';
export const MOMENT_DATE_TIME_FORMAT = 'YYYY-MM-dd HH:mm';

export const FIELD_TYPES = {
    TEXT: 'Text',
    LONG_TEXT: 'LongText',
    RADIO: 'Radio',
    SELECT: 'Select',
};

export const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// tslint:disable: no-bitwise
export const PRICE_TRANSPARENCY_EXPORT_INDICATORS = [
    {
        label: 'None',
        value: 0,
    },
    {
        label: 'Consumer Friendly',
        value: 1 << 0,
    },
    {
        label: 'Machine Readable',
        value: 1 << 1,
    },
    {
        label: 'All',
        value: ~(~0 << 4),
    },
];

export const ALLOWED_SITES = [
    {
        label: 'None',
        value: 0,
    },
    {
        label: 'Registrar',
        value: 1 << 0,
    },
    {
        label: 'Consumer',
        value: 1 << 1,
    },
    {
        label: 'Shopping',
        value: 1 << 2,
    },
    {
        label: 'All',
        value: ~(~0 << 4),
    },
    {
        label: 'Shopping & Registrar',
        value: (1 << 2) + (1 << 0),
    },
    {
        label: 'Shopping & Consumer',
        value: (1 << 2) + (1 << 1),
    },
    {
        label: 'Registrar & Consumer',
        value: (1 << 0) + (1 << 1),
    },
];

export const PROCEDURE_CODE_MATCH_STRATEGIES = ['all', 'regex'];

export const PLAN_NETWORKS = ['in', 'out', 'both'];

export const CARE_SETTINGS = [
    {
        label: 'Inpatient',
        value: 'ip',
    },
    {
        label: 'Outpatient',
        value: 'op',
    },
];

export const LANGUAGES = [
    {
        label: 'English - US',
        value: 'en_US',
    },
    {
        label: 'Spanish - US',
        value: 'es_US',
    },
];

export const REPORT_GROUPING_TYPES = [
    { label: 'Single File', value: 1, fileNamingPatterns: [] },
    { label: 'Single File per Location', value: 2, fileNamingPatterns: [/:locationName/] },
    { label: 'Single File per Location and Plan', value: 3, fileNamingPatterns: [/:locationName/, /:planName/] },
    { label: 'Single File per Plan (All Locations)', value: 4, fileNamingPatterns: [/:planName/] },
];

export const BILLING_CODE_TYPES = [
    { label: 'CPT', value: 1 },
    { label: 'HCPCS', value: 2 },
    { label: 'ICD', value: 3 },
    { label: 'DRG', value: 4 },
    { label: 'APC', value: 5 },
];

export const CONSUMER_FRIENDLY_EXCLUSIONS = [
    { label: 'Do not exclude', value: 0 },
    { label: 'Exclude if no contracts', value: 1 },
    { label: 'Exclude always', value: 2 },
];

export const EXPORT_FLAG_OPTIONS = [
    { label: 'None', value: 0 },
    { label: 'Consumer Friendly', value: 1 << 0 },
    { label: 'Machine Readable', value: 1 << 1 },
];
