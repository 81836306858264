import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { Subscription } from "rxjs";
import { UntypedFormControl } from "@angular/forms";
import { formatDate } from '@angular/common';

@Component({
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    selector: 'rs-datepicker',
})
export class RsDatepicker implements OnInit, OnDestroy {
    @ViewChild('dp', { static: false }) datepicker;
    @Input() settings: IAngularMyDpOptions = {
        dateFormat: 'mm/dd/yyyy',
    };
    @Input() config: any = {};
    @Input() label;
    @Input() id?: string;
    @Input() name?: string;
    @Input() wrapClass: string;
    @Input() formControl: UntypedFormControl;
    @Input() inputClass: string;
    @Input() value: any;
    @Input() translationValues: any = {};
    @Input() isDisabled = false;
    errorSubscription: Subscription;
    errors: any = {};
    error = '';
    isOpen = false;
    isFirstTimeClose = true;

    public ngOnInit(): void {
        if (this.config.Validation) {
            Object.keys(this.config.Validation).forEach((key) => {
                this.errors[key.toLowerCase()] = this.config.Validation[key];
            });
        }
        if (this.formControl.errors) {
            const errors = Object.keys(this.formControl.errors);
            this.setError(errors);
        }
        this.errorSubscription = this.formControl.statusChanges.subscribe((status) => {
            if (status !== 'VALID' && this.formControl.errors) {
                const errors = Object.keys(this.formControl.errors);
                this.setError(errors);
                return;
            }
            this.error = '';
        });
    }

    public ngOnDestroy(): void {
        if (this.errorSubscription) this.errorSubscription.unsubscribe();
    }

    setDate(e) {
        this.formControl.setValue(formatDate(e.singleDate.jsDate, this.settings.dateFormat.toUpperCase(), 'en-US'));
    }

    setError(errors) {
        if (errors.length === 0) {
            this.error = '';
            return;
        }

        let firstErrName = errors[0].toLowerCase();
        if (['max', 'min'].includes(firstErrName)) {
            firstErrName = `${firstErrName}length`;
        }
        this.error = (this.errors && this.errors[firstErrName]) ? this.errors[firstErrName].Message : '';
    }

    open() {
        this.datepicker.openCalendar();
        this.isOpen = true;
    }

    close() {
        const isFirstClose = this.isFirstTimeClose;
        this.isFirstTimeClose = false;
        if (isFirstClose) {
            setTimeout(() => {
                this.open();
            }, 0);
            return;
        }
        this.datepicker.closeCalendar();
        this.isOpen = false;
        this.formControl.updateValueAndValidity();
        this.formControl.markAsTouched({ onlySelf: true });
    }
}
